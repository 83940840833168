import React, { useEffect, useState } from "react";
import { Card, Col, Divider, Row, Statistic, Table, Switch, Button, Tag, Tooltip, Input, DatePicker, message } from "antd";
import "./reports.css";
import { makeStyles } from "@mui/styles";
import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import { formatCurrency } from "../../Components/common/CommonFun";
import { netsuite_url } from "../../Components/config";
import { deliveryStatuses, StockChangeReasons } from "../../Components/common/constants";
import moment from "moment";
import { hideLoading, showLoading, useLoadingDispatch } from "../../context/loadingContext";
import dayjs from "dayjs";
import { Grid, Pagination } from "@mui/material";

const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: "15px",
    background: "white",
    "& .ant-card .ant-card-body": {
      padding: "4px",
      backgroundColor: "rgb(240, 240, 240)",
    },
    "& .ant-statistic .ant-statistic-content": {
      fontSize: "12px",
      fontWeight: "bold",
    },
    "& .ant-statistic .ant-statistic-title": {
      fontSize: "12px",
    },
    "& .example-custom-input": {
      width: "100% !important",
    },
    "& .react-datepicker-wrapper": {
      width: "100% !important",
    },
  },
}));
const AllReturnsNew = () => {
  const classes = useStyles();
   var layoutDispatch = useLoadingDispatch();
   const currentDate = new Date();
   const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
   const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
   const [startDate, setStartDate] = useState(firstDayOfMonth);
   const [endDate, setEndDate] = useState(lastDayOfMonth);
   const [loadMessage, setLoadMessage] = useState("");
   const [type, setType] = useState("");
   const [returns, setReturns] = useState([]); 

    const [page, setPage] = React.useState(1);
     const [pageChange, setPageChange] = React.useState(false);
     const [limit] = React.useState(50);
     const [totalPages, setTotalPages] = React.useState(0);
     const [totalCount, setTotalCount] = React.useState(null);
   
 
 
   const getAllReturns = async (download) => {
     let search = {};
     if (startDate) search.start_date = moment(startDate).format("YYYY-MM-DD");
     if (endDate) search.end_date = moment(endDate).format("YYYY-MM-DD");
     if (page && pageChange) search.page = page;

    if (download) search.download = 1;


 
     showLoading(layoutDispatch);
     try {
     const res = await Auth.fetch("/route/load-route-returns", {
         method: "POST",
         body: JSON.stringify(search),
       });
       if (res?.ack) {
        if (download == 1) {
            window.open(res.file_url, "_blank", "noreferrer");
          } else {
         setReturns(res?.daily_returns)
         setTotalPages(Math.ceil(res?.total_count / limit));
         setTotalCount(res.total_count);
          }
       } else {
         setReturns([])
         message.error(res?.message)
       }
     } catch (err){
         console.log(err);
         message.error(err?.message)
     } finally {
         hideLoading(layoutDispatch);
     }
   };
 
   useEffect(() => {
     const timeOutId = setTimeout(() => getAllReturns(), 1000);
     return () => clearTimeout(timeOutId);
   }, [
     startDate,
     endDate,
     page
   ]);

   const handleChange = (event, value) => {
    setPage(value);
    setPageChange(true)
  };
 
   const detailsCol = [
     {
       title: "Route",
       dataIndex: "route",
       key: "route",
       width: 80,
     },
     {
       title: "Driver",
       dataIndex: "driver",
       key: "driver",
       width: 120,
     },
     {
       title: "Vehicle",
       dataIndex: "vehicle",
       key: "vehicle",
       width: 80,
     },
     {
       title: "Customer",
       align: 'center',
       render(record) {
         return (
           <span><a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${record.customer_internal_id}`} target="_blank">{record.customer_name}</a></span>
         );
       },
       width: 90,
     },
     {
       title: "Invoice",
       render(record) {
         return (
           <span>
                 <a 
                   href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${record?.inv_internal_id}`} 
                   target="_blank" 
                   rel="noopener noreferrer"
               >
                   {record?.invoice_no}
               </a>
             </span>
         );
       },
       width: 90,
     },
     {
       title: "Order",
       render(record) {
         return (
           <span>
                 <a 
                   href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${record?.internal_id}`} 
                   target="_blank" 
                   rel="noopener noreferrer"
               >
                   {record?.sale_order_no}
               </a>
             </span>
         );
       },
       width: 90,
     },
     {
       title: "Delivery Status",
       render(record) {
         let statusText;
         let backgroundColor;
         // let delivered_at = record?.delivered_at || "N/A";
 
         const delivered_at = record?.delivered_at && moment(record.delivered_at).isValid()
       ? moment(record.delivered_at).format('YYYY-MM-DD HH:mm:ss')
       : "N/A";
 
 
         switch (record?.delivered) {
           case 1:
             statusText = "Delivered";
             backgroundColor = "#80cb80";
             break;
           case 2:
             statusText = "Postponed";
             backgroundColor = "#f0d787";
             break;
             case -1:
             statusText = "Submitted";
             backgroundColor = "#1890FF";
             break;
           default:
             statusText = "Not Delivered";
             backgroundColor = "#edb1a7";
             break;
         }
 
         return {
           props: {
             style: {
               background: backgroundColor,
               textAlign: 'center',
               width: "300px",
 
 
             },
           },
           children: (
             <>
             {statusText}
             {record?.delivered === 1 && (
               <Tooltip title={`Delivered at: ${delivered_at}`}>
                 <InfoCircleOutlined style={{ marginLeft: 8, color: "#000" }} />
               </Tooltip>
             )}
           </>
           ),
         };
       },
     },
     {
       title: "Item Code",
       dataIndex: "item_id",
       key: "item_id",
       width: 80,
     },
     {
       title: "Item Name",
       dataIndex: "item_name",
       key: "item_name",
       width: 250,
     },
     
     
     
     {
       title: "Ordered QTY",
       dataIndex: "quantity",
       key: "quantity",
       width: 80,
       align: 'center'
     },
     {
       title: "Delivered QTY",
       dataIndex: "delivered_quantity",
       key: "delivered_quantity",
       width: 80,
       align: 'center'
 
     },
     {
       title: "Returned QTY",
       render: (text, record) => {
         return (
           <span>
             
               <>{Number(record?.quantity) - Number(record?.delivered_quantity)}</>
           </span>
         );
       },
       width: 80,
       align: 'center'
 
     },
     {
       title: "Warehouse Received QTY (Quarantine)",
       dataIndex: "returned_quantity",
       key: "returned_quantity",
       width: 80,
       align: 'center'
 
     },
     {
       title: "Returned To Stock QTY",
       dataIndex: "returned_quantity_to_stock",
       key: "returned_quantity_to_stock",
       width: 80,
       align: 'center'
 
     },
     {
           title: "Damages",
           render: (text, record) => {
             const value = record?.returned_quantity_to_damages || 0; 
             return (
               <span>
                 {value > 0 ? (
                   <Tag color="red">{value}</Tag>
                 ) : (
                   <span>{value}</span> 
                 )}
               </span>
             );
           },
           width: 100,
           align: "center",
         },
     
     {
       title: "Customer Delivery Status",
       render: (record) => {
               const status = deliveryStatuses.find(item => item.id === record?.delivery_status)
               return (
                  <span>{status ? status.title : ''}</span>
               );
             },
       width: 120,
       align: 'center'
 
     },
     {
       title: "Warehouse Delivery Status",
       render: (record) => {
               const status = StockChangeReasons.find(item => item.id === record?.returned_quantity_to_stock_status)
               return (
                  <span>{status ? status.title : ''}</span>
               );
             },
       width: 120,
       align: 'center'
 
     },
   ];
 
   const downloadCSV = () => {
     // Prepare data for CSV
     const csvData = returns?.map((record) => ({
       Route: record.title || "",
       Driver: record.driver_name || "",
       Vehicle: record.registration_number || "",
       Customer: record.customer_name || "",
       Invoice: record.invoice_no || "",
       Order: record.sale_order_no || "",
       "Delivery Status": (() => {
         switch (record.delivered) {
           case 1:
             return "Delivered";
           case 2:
             return "Postponed";
           case -1:
             return "Submitted";
           default:
             return "Not Delivered";
         }
       })(),
       // "Delivered At":
       //   record.delivered_at && moment(record.delivered_at).isValid()
       //     ? moment(record.delivered_at).format("YYYY-MM-DD HH:mm:ss")
       //     : "N/A",
       "Item Code": record.item_id || "",
       "Item Name": record.item_name || "",
       "Ordered QTY": record.quantity || 0,
       "Delivered QTY": record.delivered_quantity || 0,
       "Returned QTY": record.quantity - record.delivered_quantity || 0,
       "Warehouse Received QTY (Quarantine)": record.returned_quantity || 0,
       "Returned To Stock QTY": record.returned_quantity_to_stock || 0,
       Damages: record.returned_quantity_to_damages || 0,
       "Customer Delivery Status": deliveryStatuses.find((item) => item.id === record.delivery_status)?.title || "",
       "Warehouse Delivery Status": StockChangeReasons.find((item) => item.id === record.returned_quantity_to_stock_status)?.title || "",
     }));
 
 
   const csvString =
       "data:text/csv;charset=utf-8," +
       [
         Object.keys(csvData[0]).join(","), // Header row
         ...csvData.map((row) => Object.values(row).join(",")), // Data rows
       ].join("\n");
 
     // Create a link and trigger download
     const link = document.createElement("a");
     link.href = encodeURI(csvString);
     link.download = `allreturns${moment().format("YYYY-MM-DD")}.csv`;
     document.body.appendChild(link);
     link.click();
     document.body.removeChild(link);
   };

   useEffect(() => {
       message.config({
         top: window.innerHeight - 100, 
         duration: 3, 
       });
     }, [])

  return (
     <div className={classes.dataSheet}>
            <div style={{ marginBottom: "10px",display: "flex", justifyContent: "space-between" }}>
            <h3>All Returns</h3>
            {returns?.length > 0 && (
              <div >
                <Button type="primary" 
                // onClick={downloadCSV}
                onClick={() => { getAllReturns(1) }}

                >
                  Download
                </Button>
              </div>
            )}
            </div>
            
            <div className="view-page">
            <Grid container rowSpacing={2} columnSpacing={1.5}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
              <label htmlFor="pcnDate">Start Due Date:</label>
              <DatePicker
                   format={'YYYY-MM-DD'}
                   style={{ width: '100%'}}
                   size="large"
                   value={startDate ? dayjs(startDate) : null}
                   onChange={(_, dateStr) => {
                    setStartDate(dateStr);
                    setPageChange(false)
                    setPage(1)
                  }}
                  placeholder="Select Date" 
                   />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
              <label htmlFor="pcnDate">End Due Date:</label>
              <DatePicker
                   format={'YYYY-MM-DD'}
                   style={{ width: '100%'}}
                   size="large"
                   value={endDate ? dayjs(endDate) : null}
                   onChange={(_, dateStr) => {
                    setEndDate(dateStr);
                    setPageChange(false)
                    setPage(1)
                  }}
                  placeholder="Select Date" 
                   />
            </Grid>
          </Grid>
              <Table
                style={{ overflowX: "auto", marginTop: "10px" }}
                columns={detailsCol}
                dataSource={returns}
                pagination={false}
                footer={() => {
                    const startRecord = (page - 1) * limit + 1;
                    const endRecord = Math.min(page * limit, returns?.length ? totalCount : 0); 
                    return `Showing ${startRecord}-${endRecord} of ${returns?.length ? totalCount : "0"} Records`;
                  }}
              />
              <div style={{ float: "right", margin: "10px" }}>
                      <Pagination
                        page={page}
                        count={totalPages}
                        variant="outlined"
                        color="primary"
                        onChange={handleChange}
                      />
                    </div>
            </div>
          
          <Message type={type} msg={loadMessage} />
        </div>
  )
}

export default AllReturnsNew
