import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Menu,
  Modal,
  Row,
  // Select,
  message,
  Tooltip,
  Alert,
  Tag,
  Table,
} from "antd";
import CloseIcon from '@mui/icons-material/Close';
import AuthService from "../auth/AuthService";
import config from "../config";
import { driverIssuesActions, driverIssuesTypes, vehicleServiceTasks } from "../common/constants";
import dayjs from "dayjs";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, TextField, Button as UploadButton } from "@mui/material";
import { CheckCircleOutlined, CloseCircleOutlined, CloseOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import qs from "query-string";
import { hideLoading, showLoading, useLoadingDispatch } from "../../context/loadingContext";
import moment from "moment";
import { Modal as MUIModal, Typography, Button as MUIButton} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import qs from "query-string";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { domain, env, tms_uploads_bucket, tms_uploads_cdn } from "../../Components/config";



const Auth = new AuthService();

const DriverHistoryModal = ({ isModalOpen, setIsModalOpen, history, setHistory, selectedDriverID, getData }) => {
  const { Option } = Select;
  const { TextArea } = Input;
  var layoutDispatch = useLoadingDispatch();
  const [filesOpen, setFilesOpen] = React.useState(false);
  const [selectedRecordId, setSelectedRecordId] = React.useState(0);
  const [selectedFiles, setSelectedFiles] = React.useState([]);

  const [files, setFiles] = React.useState([]);

  const [issueType, setIssueType] = React.useState("");
  const [action, setAction] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [cost, setCost] = React.useState("");


  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);


  

  const [screenWidth, setScreenWidth] = React.useState("");
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const handleFilesOpen = (row) => {
    if (row?.files) {
      setSelectedRecordId(row.id);
      setSelectedFiles(JSON.parse(row?.files));
    }
    setFilesOpen(true);
  };

  const handleFilesClose = () => setFilesOpen(false);

  const driverColumns = [
    {
      title: 'Type',
      render: (record) => {
        return record.issue_type
        ? driverIssuesTypes.filter(
          (obj) => obj.id == record.issue_type
        )[0].title
        : ""
      },
      width: 175
    },
    {
      title: "Action",
      render: (record) => {
        return record.action
        ? driverIssuesActions.filter(
          (obj) => obj.id == record.action
        )[0].title
        : ""
      }
    },
    {
      title: 'Cost',
      width: 75,
      render: (record) => {
        return record?.cost
          ? "£" +
              Number(record?.cost).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })
          : 0;
      },
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: 'Updated By',
      dataIndex: 'user_name',
      key: 'user_name',
      width: 100
    },
    {
      title: 'Updated At',
      render: (record) => {
        return record.createdAt
        ? moment(record.createdAt).format("YYYY-MM-DD H:mm:ss")
        : ""
      }
    },
    {
      title: 'Files',
      render: (record) => {
        return JSON.parse(record.files).length > 0 ? (
          <Button
            onClick={() => {
              handleFilesOpen(record);
            }}
          >
            {JSON.parse(record.files).length + "-Files"}
          </Button>
        ) : (
          <Button
            disabled
          >
            {JSON.parse(record.files).length + "-Files"}
          </Button>
        )
      },
    },
  ];
  

  const handleCancel = () => {
    setIsModalOpen(false);
    // resetState();
  };

  const resetState = () => {
    setHistory([])
  };

  const handleClickOpen = () => {
    setOpen(true);
    setFiles([]);
    setSelectedFiles([]);
    setIssueType("");
    setAction("");
    setNotes("");
    setCost("");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitHistory = async () => {

    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }

    if (notes === "") {
      message.error("Please add notes" + str);
      return;
    } 

    if (issueType === "") {
      message.error("Please select type" + str);
      return;
    }

    setLoading(true);
    showLoading(layoutDispatch);
    let reqBody = {
      driver_id: selectedDriverID,
      notes: notes,
      issue_type: issueType,
      action: action,
      cost: cost,
      files: files,
    };

    try {
      const res = await Auth.fetch("/maintenance/maintenance-driver", {
        method: "POST",
        body: JSON.stringify(reqBody),
      })
      if (res.ack === true) {
        
        message.success(res.message);
        setOpen(false)
        setLoading(false);
        setTimeout(() => {  
          setIsModalOpen(false)
        }, 1000);
        getData()
      } else {
        message.error(res.message);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      
    } finally {
      hideLoading(layoutDispatch);
    }
  };

  console.log('close', open);
  

  const handleSubmitImage = (e) => {
    e.preventDefault();

    let acceptedFiles = e.target.files;
    var i = 0;
    for (let file of acceptedFiles) {
        const randomString = Math.random().toString(36).substring(2, 10);
        const newFileName = `${file.name.split('.')[0]}-${randomString}.${file.name.split('.')[1]}`;

      let params = {
        bucket: tms_uploads_bucket,
        ContentType: file.type,
        // Key: file_name + "." + fileExt,
        Key: env + "/drivers/" + newFileName,
      };
      fetch(`${domain}/generate-put-url?${qs.stringify(params)}`, {
        headers: {
          "Content-Type": params.ContentType,
        },
      })
        .then((response) => response.json())
        // eslint-disable-next-line no-loop-func
        .then((res) => {
          fetch(res.putURL, {
            method: "put",
            body: file,
            headers: {
              "Content-Type": params.ContentType,
            },
          })
            .then((response) => response)
            .then((data) => {
              if (data.status === 200) {
                console.log("uploaded !!");
              }
            });
          let files1 = [...files];
          files1.push(newFileName);
          setFiles(files1);
          message.success("File Uploaded !!");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <Modal
        width={800}
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        title={"Driver History "}
        footer={[
          <>
            <Button onClick={handleCancel}>{"Close"}</Button>
            
          </>,
        ]}
      >
        <>
          <Divider style={{ margin: '5px' }} />
          <div style={{ display: 'flex', justifyContent: 'end', margin: '5px'}}>
            <Button type="primary" icon={<PlusOutlined />} onClick={handleClickOpen}>Add Driver Action</Button>
          </div>
          <div >
          <Table 
            columns={driverColumns}
            style={{ overflowX: "auto" }}
            dataSource={history}
            footer={()=>`List of History - Total Records: ${history.length === 0 ? "0" : history.length  }`}
            pagination={false}
          />
      </div>
          {/* <Table dataSource={history} columns={historyCol} style={{ marginTop: "4px"}} pagination={false} /> */}

        </>
        {/* ==================popup for Showing uploaded Image==================== */}
      <MUIModal
          open={filesOpen}
          onClose={handleFilesClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              overflowY: "auto",
              maxHeight: "90vh",
              transform: "translate(-50%, -50%)",
              width: screenWidth < 610 ? "90%" : 600,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <IconButton
            edge="end"
            color="inherit"
            onClick={handleFilesClose}
            aria-label="close"
            sx={{ position: 'absolute', top: 0, right: 0, marginRight: '5px'}}
        >
          <CloseIcon />
        </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Download the files below
            </Typography>
            {selectedFiles.length > 0 && (
              <p style={{ padding: "0" }}>Files</p>
            )}
            {selectedFiles.length > 0 &&
              selectedFiles.map((file, idx) => {
              const lastHyphenIndex = file.lastIndexOf('-')
              const dotBeforeExtensionIndex = file.lastIndexOf('.');
              const newFileName = file.slice(0, lastHyphenIndex) + file.slice(dotBeforeExtensionIndex);
                return (
                  <li key={idx}>
                    <a
                      href={`${config.tms_uploads_cdn}/${config.env}/drivers/${file}`}
                      target="_blank"
                    >
                      {newFileName}
                    </a>
                  </li>
                );
              })}
          </Box>
        </MUIModal>
        <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Maintenance Update</DialogTitle>
                <DialogContent>
                  <DialogContentText>Please fill below form</DialogContentText>
                  <br />
                  <FormControl fullWidth>
                    <InputLabel id="type-label">Type</InputLabel>
                    <Select
                      labelId="type-label"
                      id="type"
                      value={issueType}
                      label="type"
                      onChange={(e) => {
                        setIssueType(e.target.value);
                      }}
                    >
                      {driverIssuesTypes.map((ss) => {
                        return <MenuItem value={ss.id}>{ss.title}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>

                  <br />
                  <br />
                  <FormControl fullWidth>
                    <InputLabel id="action-label">Action</InputLabel>
                    <Select
                      labelId="action-label"
                      id="action"
                      value={action}
                      label="status"
                      onChange={(e) => {
                        setAction(e.target.value);
                      }}
                    >
                      {driverIssuesActions.map((s) => {
                        return <MenuItem value={s.id}>{s.title}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  <TextField
                    value={cost}
                    type={"number"}
                    onChange={(event) => {
                      setCost(event.target.value);
                    }}
                    label="Cost"
                    id="cost"
                  />
                  <br />
                  <br />
                  <TextField
                    id="outlined-multiline-static"
                    label="Notes"
                    multiline
                    rows={4}
                    value={notes}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setNotes(e.target.value);
                    }}
                  />
                  <br />
                  <br />
                  <MUIButton variant="contained" component="label" sx={{':hover': { background: "#4096ff "}, background: '#1677FF'}}>
                    Upload File
                    <input
                      type="file"
                      multiple
                      hidden
                      // onChange={handleSubmitImage}
                      onChange={(e) => {
                      const myfiles = e.target.files;
                      if (myfiles.length > 10) {
                        message.error("A maximum of 10 images can be uploaded at a time.");
                        e.target.value = "";
                      } else {
                        handleSubmitImage(e);
                        }
                      }}
                    />
                  </MUIButton>
                  <ul>
                    {files.length > 0 &&
                      files.map((file, idx) => {
                        const lastHyphenIndex = file.lastIndexOf('-')
                        const dotBeforeExtensionIndex = file.lastIndexOf('.');
                        const newFileName = file.slice(0, lastHyphenIndex) + file.slice(dotBeforeExtensionIndex);
                        return (
                          <li key={idx}>
                            <a
                              href={`${tms_uploads_cdn}/${env}/drivers/${file}`}
                              target="_blank"
                            >
                              {newFileName}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </DialogContent>
                <DialogActions>
                  <MUIButton onClick={handleClose}>Cancel</MUIButton>
                  <MUIButton onClick={handleSubmitHistory}>Save</MUIButton>
                </DialogActions>
              </Dialog>
      </Modal>
    </>
  );
};

export default DriverHistoryModal;
