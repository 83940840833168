import React, { useEffect, useState } from "react";
import { Button, Card, Col, Descriptions, Drawer, Result, Row, Space, Statistic, Tabs, Tag } from "antd";
import DriverManifestTab from "../ReportsSections/DriverManifestTab";
import AuthService from "../../Components/auth/AuthService";
import moment from "moment";
import { showLoading, hideLoading, useLoadingDispatch } from "../../context/loadingContext";
import CashupTab from "../ReportsSections/CashupTab";
import DriverSheetTab from "../ReportsSections/DriverSheetTab";
import { cardTerminals, deliveryStatuses, userRolesTypes } from "../../Components/common/constants";
import GoodsReturnTab from "../ReportsSections/GoodsReturnTab";
import StockDiscrepanciesTab from "../ReportsSections/StockDiscrepanciesTab";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import FinancialSummary from "../ReportsSections/FinancialSummary";
import AmountDiscrepancy from "../ReportsSections/AmountDiscrepancy";
import DailyReturns from "../ReportsSections/DailyReturns";

const useStyles = makeStyles((theme) => ({
  dataSheet: {
      // padding: '5px',
    "& .ant-card .ant-card-body": {
      padding: "4px",
      // backgroundColor: 'rgb(240, 240, 240)',
    },
    
    "& .ant-statistic .ant-statistic-content": {
      fontSize: "12px !important",
      fontWeight: "bold",
    },
    "& .ant-statistic .ant-statistic-title": {
      fontSize: "12px",
    },
    "& .ant-input[disabled]": {
      color: 'black !important',
      fontWeight: 500
    },
    "& .ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-row >.ant-descriptions-item-content": {
      padding: "0px 2px !important"
    },
    "& .ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-row >.ant-descriptions-item-label":{
      padding: "4px 8px !important"

    },
    "& .ant-drawer .ant-drawer-body" : {
      padding: '15px !important',
      background: 'lightgrey !important'
    },
    "& .ant-tabs" : {
      height: '100%',
      background: 'white'
    },
    "& .ant-drawer .ant-drawer-header-title": {
      paddingRight: '5px'
    }
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
    "& .ant-table-footer": {
      padding: '16px 0px !important'
    }
  },
}));

const DailyReturnDrawer = ({ drawerOpen, setDrawerOpen, dailyReturns, setDailyReturns ,deliveryDate, searchText, handleSearch, filteredData, setFilteredData, loadRoutes  }) => {

  const Auth = new AuthService();
  const layoutDispatch = useLoadingDispatch()
  const classes = useStyles()
  const { roles } = useSelector((state) => state.AppState?.userTypes);
  const UserType = useSelector((state) => state.AppState?.userTypes?.type);


  const matchedRoles = roles
  .map((role) => {
    return userRolesTypes.find((userRole) => userRole.id === role.role_id);
  })
  .filter(Boolean);
const includesFinance = matchedRoles.some(item => item.title === "Finance")
const includesRouting = matchedRoles.some(item => item.title === "Routing")
const includesWareHouse = matchedRoles.some(item => item.title === "Warehouse User")
const includesWareHouseManager = matchedRoles.some(item => item.title === "Warehouse Manager")
const includesSalesManager = matchedRoles.some(item => item.title === "Sales Manager")
  
  
  const onClose = () => {
    setDrawerOpen(false);
    setDailyReturns([]);
    setFilteredData([])
    loadRoutes();

  };

  return (
    <div className={classes.dataSheet}>
      <Drawer
        title="Daily Returns"
        extra={
          <Descriptions bordered size={"small"}
              style={{ marginTop: '5px' }}
              column={{ xs: 1, sm: 2, md: 2, lg: 5 }}
            >
              <Descriptions.Item
                label={'Date'}
                labelStyle={{ width: "auto" }}
                contentStyle={{ width: 'auto' }}
              >
                <Tag style={{ fontSize: '15px' }} color="blue">{deliveryDate ? moment(deliveryDate).format('YYYY-MM-DD') : ''}</Tag>
              </Descriptions.Item>
            </Descriptions>
        }
        placement={"bottom"}
        bodyStyle={{ background: '#f5f5f5', padding: '10px', }}

        footer={
          <>
          <div style={{ display: "flex", justifyContent: "center", padding: '5px 5px'}}>
        
        Prefectos ©{new Date().getFullYear()}
        </div>
          </>
        }
        onClose={onClose}
        height="100vh"
        open={drawerOpen}
        
      >
        <DailyReturns dailyReturns={dailyReturns} deliveryDate={deliveryDate} searchText={searchText} handleSearch={handleSearch} filteredData={filteredData} />
      </Drawer>
    </div>
  );
};
export default DailyReturnDrawer;
