import React, { useEffect, useState } from "react";
import { Card, Col, Divider, Row, Statistic, Table, Switch, Button, Tag, Tooltip, Input, message, Modal } from "antd";
import { makeStyles } from "@mui/styles";
import { CheckOutlined, CloseOutlined, DeleteFilled, EditOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import moment from "moment";
import { hideLoading, showLoading, useLoadingDispatch } from '../../context/loadingContext';
import { Pagination } from "@mui/material";
import { issuePriorities } from "../../Components/common/constants";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../../Components/common/CommonFun";

const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: "5px",
    
  },
}));

const InventoryList = () => {
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const navigate = useNavigate();
  const [loadMessage, setLoadMessage] = useState("");
  const [type, setType] = useState("");
  const [allInventory, setAllInventory] = useState([]);
  const [page, setPage] = React.useState(1);
  const [pageChange, setPageChange] = React.useState(false);
  const [limit] = React.useState(50);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);
  

  const handleChange = (event, value) => {
    setPage(value);
    setPageChange(true)
  };

  const getAllItems = async (download) => {
    let search = {};
    if (page && pageChange) search.page = page;

   if (download) search.download = 1;

    showLoading(layoutDispatch);
    try {
    const res = await Auth.fetch("/inventory/inventory-items", {
        method: "POST",
        body: JSON.stringify(search),
      });
      if (res?.ack) {
       if (download == 1) {
           window.open(res.file_url, "_blank", "noreferrer");
         } else {
        setAllInventory(res?.inventory_items);
        setTotalPages(Math.ceil(res?.total_count / limit));
        setTotalCount(res.total_count);
         }
      } else {
        setAllInventory([])
        message.error(res?.message)
      }
    } catch (err){
        console.log(err);
        message.error(err?.message)
    } finally {
        hideLoading(layoutDispatch);
    }
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => getAllItems(), 1000);
    return () => clearTimeout(timeOutId);
  }, [
    page
  ]);

   useEffect(() => {
          message.config({
            top: window.innerHeight - 100, 
            duration: 3, 
          });
        }, []);

    const deleteItem = async (id) => {
        showLoading(layoutDispatch);
        try {
          const res = await Auth.fetch(`/inventory/inventory-item`, {
            method: "DELETE",
            body: JSON.stringify({ id: id })
    
          });
          if (res?.ack) {
            getAllItems();
            message.success(res?.message);
          } else {
            message.error(res?.message);
          }
        } catch (err) {
          console.log(err);
          message.error(err?.message);
        } finally {
          hideLoading(layoutDispatch);
        }
      };

  const inventoryColumns = [
    {
        title: 'Code',
        dataIndex: 'item_code',
        key: 'item_code',
    },
    {
        title: 'Name',
        dataIndex: 'item_name',
        key: 'item_name',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    
  {
          title: 'Unit Price',
          render: (record)=> {
            return (
              <>
              <span>{ formatCurrency(record.unit_price) || ''}</span>
            </>
            )
          }
        },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <div>
              <EditOutlined 
        style={{
          fontSize: "25px",
          cursor: "pointer",
          marginRight: "5px",
          color: "#006cb8",
        }} onClick={() => navigate(`/workshop_inventory_items/${record.id}`)}/>
           

        
            <DeleteFilled style={
              {
                fontSize: "25px",
                cursor: "pointer",
                color: "#ff0000",
            }} onClick={() => {
              Modal.confirm({
                title: 'Delete Inventory Item',
                centered: true,
                content: `Are you sure you want to delete this inventory item?`,
                onOk:()=> deleteItem(record.id),
                okText: 'Delete',
                cancelText: 'Cancel',
                width: 600,
                okButtonProps: { size: "large"  },
                cancelButtonProps: { size: "large" },
              });
            }} 
            />
        
        </div>
        )
    },
    
  ];

  return (
    <div className={classes.dataSheet}>
        <div className="page-header">
        <h2>Inventory Item List</h2>
        <Button onClick={()=> navigate('/workshop_inventory_items/new')} type="primary" icon={<PlusOutlined />}>Add Inventory Item</Button>
        </div>
        <div className={classes.antTable}>
                <Table
                  columns={inventoryColumns}
                  style={{ overflowX: "auto", marginTop: '10px' }}
                  pagination={false}
                  dataSource={allInventory?.filter((item) => item.status == 1)}
                  footer={() => {
                    const startRecord = (page - 1) * limit + 1;
                    const endRecord = Math.min(page * limit, allInventory?.length ? totalCount : 0); 
                    return `Showing ${startRecord}-${endRecord} of ${allInventory?.length ? totalCount  : "0"} Records`;
                  }}
                />
              </div>
        
              <div style={{ float: "right", margin: "10px" }}>
                <Pagination
                  page={page}
                  count={totalPages}
                  variant="outlined"
                  color="primary"
                  onChange={handleChange}
                />
              </div>
      <Message type={type} msg={loadMessage} />
    </div>
  );
};

export default InventoryList;
