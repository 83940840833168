import React, { useEffect, useState } from 'react'
import { Card, Col, Divider, Row, Select, Table, Switch, Button, Tag, Tooltip, Input, DatePicker, message } from "antd";
import { makeStyles } from "@mui/styles";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import moment from "moment";
import { hideLoading, showLoading, useLoadingDispatch } from '../../context/loadingContext';
import { Pagination } from "@mui/material";
import { issuePriorities } from "../../Components/common/constants";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from 'dayjs';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button as UploadButton, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { extractOriginalFileName, getNewFileName } from '../../Components/common/CommonFun';
import { domain, env, tms_uploads_bucket, tms_uploads_cdn } from '../../Components/config';
import qs from 'query-string';

const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: "5px",
    "& .ant-input[disabled]": {
      background: "none",
      color: "black",
    },
  },
}));

const Inventory = () => {
const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { Option } = Select;
  const { TextArea } = Input;
  const [id, setId] = React.useState(params.id ? params.id : 0);
  
  
  const [loadMessage, setLoadMessage] = useState("");
  const [type, setType] = useState("");
  const [itemDetail, setItemDetail] = useState({
    item_name: "",
    item_code: "To be generated",
    unit_price: "",
    description: "",
    notes: "",
  });
  const [files, setFiles] = useState([]);
  const [currentItemFiles, setCurrentItemFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = React.useState([]);
  

  const handleSubmitImage = (e) => {
    e.preventDefault();
    let acceptedFiles = e.target.files;

    for (let file of acceptedFiles) {
      const randomString = Math.random().toString(36).substring(2, 10);
      const newFileName = getNewFileName(file.name)
      setFiles((prevFiles) => [
        ...prevFiles,
        {
          name: newFileName,
          display_name: newFileName,
          file: file,
          ContentType: file.type,
        },
      ]);
      setCurrentItemFiles((prevFiles) => [...prevFiles, newFileName]);
    }
  };

  const handleDeleteFile = (fileName) => {
    const updatedFiles = files?.filter((file) => file.name !== fileName);
    setFiles(updatedFiles);
    setCurrentItemFiles(currentItemFiles?.filter((item) => item !== fileName));
  };

  const handleDeleteCurrentFile = (itemToDelete) => {
    setCurrentItemFiles(
      currentItemFiles.filter((item) => item !== itemToDelete)
    );
    if (!deletedFiles.includes(itemToDelete)) {
      setDeletedFiles((prevDeletedFiles) => [
        ...prevDeletedFiles,
        itemToDelete,
      ]);
    }
  };

  const getUploadUrl = async (file, recordID) => {
    const params = {
      bucket: tms_uploads_bucket,
      ContentType: file.file.type,
      Key: env + "/inventory_item/" + recordID + "/" + file.name,
    };
    const response = await fetch(
      `${domain}/generate-put-url?${qs.stringify(params)}`,
      {
        headers: {
          "Content-Type": params.ContentType,
        },
      }
    );
    return response.json();
  };

  const uploadFileToS3 = async (uploadUrl, file, contentType) => {
    const response = await fetch(uploadUrl.putURL, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": contentType,
      },
    });
    if (response.status !== 200) {
      console.log('response', response);
      message.error("File upload failed.")
      throw new Error("File upload failed.");
    }
  };


   const handleSubmitInventoryItem = async () => {
      const isEmptyOrUndefined = (value) => value === '' || value === null || value === undefined;
      const validations = [
        { key: 'item_name', message: 'Please Add item Name' },
        { key: 'description', message: 'Please Add item Description' },
        { key: 'unit_price', message: 'Please Add item unit prices' },
      ];
      
      for (const { key, message: errorMessage } of validations) {
        if (isEmptyOrUndefined(itemDetail?.[key])) {
          message.error(errorMessage);
          return;
        }
      }
      
      showLoading(layoutDispatch);
      const isUpdate = id > 0;
      const dataToSend = {
        id: isUpdate ? id : undefined,
        item_name: itemDetail?.item_name,
        description: itemDetail?.description,
        notes: itemDetail.notes,
        files: currentItemFiles ? currentItemFiles : [],
        unit_price: itemDetail?.unit_price
      }
      try {
        const method = isUpdate ? "PUT" : "POST";
        const res = await Auth.fetch("/inventory/inventory-item", {
          method: method,
          body: JSON.stringify(dataToSend),
        })
        if(res?.ack) {
          const record = isUpdate ? id : res?.record_id;
          console.log(res);
          const uploadPromises = []
          if (files.length > 0) {
            files.forEach((f) => {
              uploadPromises.push(
                getUploadUrl(f, record)
                  .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                  .catch(error => console.error("Error uploading file:", error))
              );
            });
          }
          await Promise.all(uploadPromises);
          message.success(res?.message)
          navigate('/workshop_inventory_items')
        } else {
          message.error(res?.message)
        }
  
      } catch (err) {
        console.log(err);
      } 
      finally {
      hideLoading(layoutDispatch);
      }
    };

    const getDataByID = async () => {
        showLoading(layoutDispatch)
        try {
            const res = await Auth.fetch(`/inventory/inventory-item?id=${id}`)
            if(res?.ack) {
                setCurrentItemFiles(res?.inventory.files ? JSON.parse(res?.inventory.files) : [])
                setItemDetail((prevDetails) => ({
                  ...prevDetails,
                  item_name: res?.inventory.item_name,
                  item_code: res?.inventory.item_code,
                  description: res?.inventory.description,
                  notes: res?.inventory.notes,
                  unit_price: res?.inventory.unit_price
              }))
            }
        } catch (err) {
            console.log(err);
        } finally {
            hideLoading(layoutDispatch)
        }
      }
    
    
    
      useEffect(()=>{
        if(id > 0) {
            getDataByID()
        }
      },[])

    useEffect(() => {
        message.config({
          top: window.innerHeight - 100, 
          duration: 3, 
        });
      }, []);

  return (
        <div className={classes.dataSheet}>
        <div>
            <h3>Inventory Item</h3>
        </div>
        <>
          <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Code<span style={{ color: "red" }}>*</span>
            </span>
                <Input 
                value={itemDetail?.item_code}
                disabled
                // placeholder='Please enter item code.'
                 />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Name<span style={{ color: "red" }}>*</span>
            </span>
                <Input 
                value={itemDetail?.item_name}
                onChange={(e) => {
                    setItemDetail((prevDetails) => ({
                        ...prevDetails,
                        item_name: e.target.value,
                    }))
                }}
                placeholder='Please enter item name.'
                 />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Unit Price
            </span>
                <Input
                  name="unit_price"
                  type="number"
                  value={itemDetail?.unit_price}
                onChange={(e) => {
                    setItemDetail((prevDetails) => ({
                        ...prevDetails,
                        unit_price: e.target.value,
                    }))
                }}
                  placeholder="Unit Price"
                  step="0.01"
                  max={20000}
                  min={0}

                />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Description<span style={{ color: "red" }}>*</span>
            </span>
                <Input 
                value={itemDetail?.description}
                onChange={(e) => {
                    setItemDetail((prevDetails) => ({
                        ...prevDetails,
                        description: e.target.value,
                    }))
                }}
                placeholder='Please enter item description.'
                 />
            </Col>
            
          </Row>
          <Row style={{marginTop: '5px'}} gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <span>
              Notes:
            </span>
                <TextArea 
                value={itemDetail?.notes}
                onChange={(e) => {
                    setItemDetail((prevDetails) => ({
                        ...prevDetails,
                        notes: e.target.value,
                    }))
                }}
                placeholder='Please enter any notes.'
                autoSize={{ minRows: 3, maxRows: 6 }} />
            </Col>
            </Row>
          <Divider />

          <>
          <div>
        <UploadButton
          component="label"
          variant="contained"
          sx={{ marginTop: "10px" }}
          startIcon={<CloudUploadIcon />}
        >
          Add files
          <input
            type="file"
            hidden
            multiple
            capture
            onChange={(e) => {
                const myfiles = e.target.files;
            
                if (myfiles.length > 10) {
                  message.error("A maximum of 10 images can be uploaded at a time.");
                  e.target.value = "";
                } else {
                  handleSubmitImage(e);
                }
              }}
          />
        </UploadButton>
      </div>
      {currentItemFiles?.length > 0 && <h4>Current Files:</h4>}
      <ul>
                  {currentItemFiles?.length > 0 &&
                    currentItemFiles
                      .filter(
                        (item) =>
                          !files.some((file) => file.name === item)
                      )
                      .map((item) => {
                        const encodedFileName = item.replace(/\+/g, '%2B');
                        return (
                          <li>
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={() => handleDeleteCurrentFile(item)}
                                >
                                  <DeleteIcon />
                                </IconButton>

                            <a
                              href={`${tms_uploads_cdn}/${env}/inventory_item/${id}/${encodedFileName}`}
                              target="_blank"
                            >
                              {extractOriginalFileName(encodedFileName)}
                            </a>
                          </li>
                        );
                      })}
                </ul>
        <Divider />
      {files?.length > 0 && <h4>Files:</h4>}
                <ul>
                  {files.length > 0 &&
                    files.map((files) => {
                      const encodedFileName = files?.name.replace(/\+/g, '%2B');
                      return (
                        <li>
                          {" "}
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => handleDeleteFile(files.name)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {extractOriginalFileName(encodedFileName)}
                        </li>
                      );
                    })}
                </ul>
      <Divider />
       
        </>
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
        >
          <Button
            onClick={() => navigate("/workshop_inventory_items")}
            danger
            size="large"
            style={{ marginRight: "10px" }}
          >
            Cancel
          </Button>
            <Button
              type="primary"
              size="large"
              onClick={handleSubmitInventoryItem}
            >
              Save
            </Button>
        </div>
        </>
          <Message type={type} msg={loadMessage} />
      </div>
      
  )
}

export default Inventory
