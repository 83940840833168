import React, { useEffect, useState } from "react";
import { Button, Card, Col, Descriptions, Drawer, Result, Row, Space, Statistic, Tabs, Tag } from "antd";
import DriverManifestTab from "../ReportsSections/DriverManifestTab";
import AuthService from "../../Components/auth/AuthService";
import moment from "moment";
import { showLoading, hideLoading, useLoadingDispatch } from "../../context/loadingContext";
import CashupTab from "../ReportsSections/CashupTab";
import DriverSheetTab from "../ReportsSections/DriverSheetTab";
import { cardTerminals, deliveryStatuses, userRolesTypes } from "../../Components/common/constants";
import GoodsReturnTab from "../ReportsSections/GoodsReturnTab";
import StockDiscrepanciesTab from "../ReportsSections/StockDiscrepanciesTab";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: '5px',
    "& .ant-card .ant-card-body": {
      padding: "4px",
      // backgroundColor: 'rgb(240, 240, 240)',
    },


    "& .ant-statistic .ant-statistic-content": {
      fontSize: "12px !important",
      fontWeight: "bold",
    },
    "& .ant-statistic .ant-statistic-title": {
      fontSize: "12px",
    },
    "& .ant-input[disabled]": {
      color: 'black !important',
      fontWeight: 500
    },
    "& .ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-row >.ant-descriptions-item-content": {
      padding: "0px 2px !important"
    },
    "& .ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-row >.ant-descriptions-item-label": {
      padding: "4px 8px !important"

    },
    "& .ant-drawer .ant-drawer-body": {
      padding: '10px !important',
      background: 'lightgrey !important'
    },
    "& .ant-tabs": {
      height: '100%',
      background: 'white'
    },
    "& .ant-drawer .ant-drawer-header-title": {
      paddingRight: '5px'
    }
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
    "& .ant-table-footer": {
      padding: '16px 0px !important'
    }
  },
}));

const ViewReportsDrawer = ({ drawerOpen, setDrawerOpen, selectedRoute, setSelectedRoute, deliveryDate, routes, handleCloseDrawer }) => {

  const Auth = new AuthService();
  const layoutDispatch = useLoadingDispatch()
  const classes = useStyles()
  const { roles } = useSelector((state) => state.AppState?.userTypes);
  const UserType = useSelector((state) => state.AppState?.userTypes?.type);

  const [activeTab, setActiveTab] = useState("1")
  const [driverManifestData, setDriverManifestData] = React.useState("");
  const [driverSheetData, setDriverSheetData] = React.useState([]);
  const [cashUpData, setCashUpData] = React.useState("");
  const [goodsReturnData, setGoodsReturnData] = React.useState([]);
  const [stockDiscrepanciesData, setStockDiscrepanciesData] = React.useState("");


  const [deliveryQuantities, setDeliveryQuantities] = React.useState({});


  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [routePallets, setRoutePallets] = React.useState([]);
  const [paymentAuthorizer, setPaymentAuthorizer] = React.useState([]);
  const [paymnetDataPost, setPaymnetDataPost] = React.useState({});


  const matchedRoles = roles
    .map((role) => {
      return userRolesTypes.find((userRole) => userRole.id === role.role_id);
    })
    .filter(Boolean);
  const includesFinance = matchedRoles.some(item => item.title === "Finance")
  const includesRouting = matchedRoles.some(item => item.title === "Routing")
  const includesWareHouse = matchedRoles.some(item => item.title === "Warehouse User")
  const includesWareHouseManager = matchedRoles.some(item => item.title === "Warehouse Manager")
  const includesSalesManager = matchedRoles.some(item => item.title === "Sales Manager")


  const getPaymentAuthorizer = async () => {

    try {
      const res = await Auth.fetch("/driverapp/get-payment-authorizers");
      if (res?.ack) {
        setPaymentAuthorizer(res?.payment_authorizers)
      } else {
        setType('error')
        setLoadMessage(res?.message)
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPaymentAuthorizer()
  }, [])

  useEffect(() => {
    console.log('routepallet updated')
    console.log(routePallets)
  }, routePallets)

  const onClose = () => {
    setDrawerOpen(false);
    setDriverManifestData([]);
    setCashUpData([]);
    setDriverManifestData([]);
    setDriverSheetData([])
    setGoodsReturnData([]);
    setStockDiscrepanciesData([]);
    // setSelectedRoute('')
  };

  const onTabChange = (key) => {
    setActiveTab(key);
    // if (key) {
    //   getData(key);
    // }
    if (key === "1") {
      printDriverManifest(routePallets)
    }
    if (key === "2") {
      printCashUp(routePallets)
    }
    if (key === "3") {
      printDriverSheet(routePallets)
    }
    if (key === "4") {
      printGoodsReturn(routePallets)
    }
    if (key === "5") {
      printStockDiscrepancies(routePallets)
    }
  };

  useEffect(() => {
    if (drawerOpen) {
      setActiveTab("1");
      getData("1")
      getPaymentAuthorizer()
    }
  }, [drawerOpen]);

  const getData = async (key) => {
    setPaymnetDataPost({})
    showLoading(layoutDispatch)
    try {
      const res = await Auth.fetch(
        `/route/load-daily-route?delivery_date=${moment(deliveryDate).format("YYYY-MM-DD")}&route_status_id=${selectedRoute?.id}`
      );

      if (!res.ack) {
        setType("error");
        setLoadMessage("Pallet builder not saved for selected route ....");
        return;
      }

      setLoadMessage("Reports data loaded!");
      const routePalletsMap = {};

      res.route_pallets.forEach((pallet, idx) => {
        const routeKey = pallet.route_status_id;
        const palletKey = pallet.pallet_builder_id;

        if (!routePalletsMap[routeKey]) {
          routePalletsMap[routeKey] = {
            route_status_id: routeKey,
            pallets: []
          };
        }

        const route = routePalletsMap[routeKey];
        let palletObj = route.pallets.find(p => p.id === palletKey);

        if (!palletObj) {
          palletObj = {
            id: palletKey,
            title: pallet?.title,
            pallet_id: pallet.pallet_id,
            weight: 0,
            items: []
          };
          route.pallets.push(palletObj);
        }

        if (pallet.internal_id) {
          let order = palletObj.items.find(o => o.internalId === pallet.internal_id);

          if (!order) {
            order = {
              id: `${idx}1`,
              rank: pallet.rank,
              customerInternalId: pallet.customer_internal_id,
              customerName: pallet.customer_name,
              notes: pallet.notes,
              received_by: pallet.received_by,
              delivery_discount_req_status: pallet.delivery_discount_req_status,
              delivery_discount_req_notes: pallet.delivery_discount_req_notes,
              delivery_discount_auth_by: pallet.delivery_discount_auth_by,
              payment_req_status: pallet.payment_req_status,
              payment_req_notes: pallet.payment_req_notes,
              payment_auth_by: pallet.payment_auth_by,
              postponed_status: pallet.postponed_status,
              postponed_files: pallet.postponed_files,
              postponed_notes: pallet.postponed_notes,
              signature: pallet.signature,
              invoiceInternalId: pallet.inv_internal_id,
              palletOrderID: pallet.pallet_order_id,
              customerProjectId: pallet.customer_project_id,
              shipZip: pallet.ship_zip,
              customerOpeningTime: pallet.customer_opening_time,
              terms: pallet.terms,
              shipAddress1: pallet.ship_address1,
              internalId: pallet.internal_id,
              invInternalId: pallet.inv_internal_id,
              invoiceNo: pallet.invoice_no,
              saleOrderNo: pallet.sale_order_no,
              orderWeight: 0,
              orderAmount: 0,
              amountReceivedCash: pallet.amount_received_cash,
              amountReceivedCard: pallet.amount_received_card,
              amountReceivedAccount: pallet.amount_received_account,
              amountReceivedCashPost: pallet.amount_received_cash_post,
              amountReceivedCardPost: pallet.amount_received_card_post,
              amountReceivedAccountPost: pallet.amount_received_account_post,
              delivered: pallet.delivered,
              delivered_at: pallet.delivered_at,
              deliveryInstructions: pallet.delivery_instructions,
              files: pallet.files,
              items: []
            };
            palletObj.items.push(order);
          }

          const item = {
            itemPalletId: pallet.item_pallet_id,
            item_internal_id: pallet?.item_internal_id,
            inv_internal_id: pallet?.inv_internal_id,
            internal_id: pallet?.internal_id,
            itemId: pallet.item_id,
            itemName: pallet.item_name,
            itemTotalWeight: pallet.item_total_weight,
            itemWeight: pallet.item_weight,
            quantity: pallet.quantity,
            deliveredQuantity: pallet.delivered_quantity,
            returnedQuantity: pallet.returned_quantity,
            returnedQuantityToStock: pallet.returned_quantity_to_stock,
            returnedQuantityToStockStatus: pallet.returned_quantity_to_stock_status,
            returnedQuantityApprovedRec: pallet.returned_quantity_approved_rec,
            returnedQuantityApprovedSup: pallet.returned_quantity_approved_sup,
            deliveryStatus: pallet.delivery_status,
            unitPrice: pallet.unit_price,
            grossAmountIncludingTax: pallet.gross_amount_incl_tax,
            frozenSheetItem: pallet.frozen_sheet_item
          };

          order.items.push(item);
          order.orderWeight += Number(pallet.item_total_weight);
          order.orderAmount += Number(pallet.gross_amount_incl_tax);
          palletObj.weight += Number(pallet.item_total_weight);
        }
      });

      const routePalletsArray = Object.values(routePalletsMap);
      setRoutePallets(routePalletsArray);      

      if (key === "1") {
        printDriverManifest(routePalletsArray)
      }
      // if(key === "2") {
      //   printCashUp(routePalletsArray)
      // }
      // if(key === "3") {
      //   printDriverSheet(routePalletsArray)
      // }
      // if(key === "4") {
      //   printGoodsReturn(routePalletsArray)
      // }
      // if(key === "5") {
      //   printStockDiscrepancies(routePalletsArray)
      // }
    } catch (err) {
      console.error(err);
    } finally {
      hideLoading(layoutDispatch)
    }
  };

  const printStockDiscrepancies = (routePallets) => {
    var routeNo = 1;


    let stockDiscrepanciesData1 = [];
    routePallets.map((routePallet) => {
      let route = routes.filter((r) => r.id == routePallet.route_status_id);

      if (route.length == 0) {
        return;
      } else {
        routePallet.selectedRoute = route[0];
        routePallet.driver_name = route[0].driver_name;
        routePallet.registration_number = route[0].registration_number;
      }
    });

    routePallets.sort(function (a, b) {
      return a.driver_name?.localeCompare(b.driver_name);
    });

    const initialDeliveryQuantities = {};

    routePallets.map((routePallet) => {
      let stockDiscrepanciesDataTemp = {};
      let rows = [];
      let return_rows_count = 1;
      routePallet.pallets.map((rp, idx) => {

        rp.items.map((order, idx1) => {
          order.items.map((item, idx2) => {
            if (item.itemId != 'Delivery Charge' && item.deliveredQuantity != item.quantity)// && Number(item.quantity) - Number(item.deliveredQuantity) !== item.returnedQuantity) // item.deliveredQuantity != null && 
            {
              let row1 = {};
              row1.rank = return_rows_count++;
              row1.customerName = order.customerName;
              row1.customerInternalId = order.customerInternalId;
              row1.invoiceInternalId = order.invoiceInternalId;
              row1.customerProjectId = order.customerProjectId;
              row1.internalId = order.internalId;
              row1.item_internal_id = item.item_internal_id;
              row1.inv_internal_id = item.inv_internal_id;
              row1.internal_id = item.internal_id;
              row1.invoice_no = order.invoiceNo;
              row1.saleOrderNo = order.saleOrderNo;
              row1.itemPalletId = item.itemPalletId;
              row1.returnedQuantity = item.returnedQuantity; // Actual quantity that is returned to stock
              row1.returnedQuantityApprovedRec = item.returnedQuantityApprovedRec;
              row1.itemId = item.itemId;
              row1.itemName = item.itemName;
              row1.quantity = Number(item.quantity);
              row1.returnedQuantityToStockStatus = item.returnedQuantityToStockStatus;
              row1.returnedQuantityToStock = item.returnedQuantityToStock;
              // row1.quantityToBeReceived = Number(item.quantity) - Number(item.deliveredQuantity);

              row1.orgReturnedQuantity = Number(item.quantity) - Number(item.deliveredQuantity);

              if (item.deliveryStatus)
                row1.status = deliveryStatuses.find(obj => obj.id == item.deliveryStatus)?.title;
              else
                row1.status = "";

              initialDeliveryQuantities[item.itemPalletId] = {
                item_pallet_id: item.itemPalletId,
                item_internal_id: item.item_internal_id,
                inv_internal_id: item.inv_internal_id,
                internal_id: item.internal_id,
                returned_quantity: item.returnedQuantity || 0,
                returned_quantity_to_stock: item.returnedQuantityToStock || 0,
                returned_quantity_to_stock_status: item.returnedQuantityToStockStatus,
                _quantity_status: item.returnedQuantityStatus,
              };

              rows.push(row1);
            }
          })
        })
      })

      let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");

      // console.log("routePallet", routePallet);
      var driver = routePallet.driver_name;
      var truck = routePallet.registration_number;
      var routeStatusID = routePallet.route_status_id;
      var driverRoute =
        routePallet.selectedRoute.title +
        " (" +
        routePallet.selectedRoute.code +
        ")";
      const postingDateInitial = routePallet.selectedRoute.credit_notes_posting_date ? moment(routePallet.selectedRoute.credit_notes_posting_date).format('YYYY-MM-DD') : deliveryDate
      var credit_notes_posted_by = routePallet.selectedRoute.credit_notes_posted_by
      var stock_discrepancies_updated_by = routePallet.selectedRoute.stock_discrepancies_updated_by
      var start_status = routePallet.selectedRoute.start_status
      var driver_returns_notes = routePallet.selectedRoute.driver_returns_notes == null ? '' : routePallet.selectedRoute.driver_returns_notes;
      var driver_returns_files = routePallet.selectedRoute.driver_returns_files == null ? [] : JSON.parse(routePallet.selectedRoute.driver_returns_files);
      var files = routePallet.selectedRoute.files == null ? [] : JSON.parse(routePallet.selectedRoute.files);
      var notes = routePallet.selectedRoute.notes == null ? '' : routePallet.selectedRoute.notes;
      var warehouse_received_by = routePallet.selectedRoute.warehouse_received_by == null ? '' : routePallet.selectedRoute.warehouse_received_by;
      var warehouse_signature = routePallet.selectedRoute.warehouse_signature == null ? '' : routePallet.selectedRoute.warehouse_signature;


      let data = {
        formatted_date: formatted_date,
        driver: driver,
        truck: truck,
        tableData: rows,
        route: routeNo,
        driverRoute: driverRoute,
        routeStatusID: routeStatusID,
        postingDateInitial: postingDateInitial,
        credit_notes_posted_by: credit_notes_posted_by,
        stock_discrepancies_updated_by: stock_discrepancies_updated_by,
        start_status: start_status,
        driver_returns_files: driver_returns_files,
        driver_returns_notes: driver_returns_notes,
        files: files,
        notes: notes,
        warehouse_received_by: warehouse_received_by,
        warehouse_signature: warehouse_signature,
      };

      // if (rows.length > 0) 
      {

        stockDiscrepanciesData1.push(data);
        routeNo = routeNo + 1;
      }
    });


    setDeliveryQuantities(initialDeliveryQuantities);


    setStockDiscrepanciesData(stockDiscrepanciesData1);
  };

  const printGoodsReturn = (routePallets) => {
    var routeNo = 1;

    let goodsReturnData1 = [];
    routePallets.map((routePallet) => {
      let route = routes.filter((r) => r.id == routePallet.route_status_id);

      if (route.length == 0) {
        return;
      } else {
        routePallet.selectedRoute = route[0];
        routePallet.driver_name = route[0].driver_name;
        routePallet.registration_number = route[0].registration_number;
      }
    });

    routePallets.sort(function (a, b) {
      return a.driver_name?.localeCompare(b.driver_name);
    });

    const initialDeliveryQuantities = {};

    routePallets.map((routePallet) => {
      let goodsReturnDataTemp = {};
      let rows = [];
      let return_rows_count = 1;
      routePallet.pallets.map((rp, idx) => {

        rp.items.map((order, idx1) => {
          order.items.map((item, idx2) => {
            if (item.itemId != 'Delivery Charge' && item.deliveredQuantity != item.quantity) // item.deliveredQuantity != null && 
            {
              let row1 = {};
              row1.rank = return_rows_count++;
              row1.customerName = order.customerName;
              row1.customerProjectId = order.customerProjectId;

              row1.invoiceInternalId = order.invoiceInternalId;
              row1.customerInternalId = order.customerInternalId;
              row1.internalId = order.internalId;
              row1.invoice_no = order.invoiceNo
              row1.saleOrderNo = order.saleOrderNo;
              row1.itemPalletId = item.itemPalletId;
              row1.returnedQuantity = item.returnedQuantity;
              row1.returnedQuantityToStock = item.returnedQuantityToStock;
              row1.returnedQuantityToStockStatus = item.returnedQuantityToStockStatus;
              row1.returnedQuantityApprovedRec = item.returnedQuantityApprovedRec;
              row1.returnedQuantityApprovedSup = item.returnedQuantityApprovedSup;
              row1.itemId = item.itemId;
              row1.itemName = item.itemName;
              row1.quantity = Number(item.quantity);
              row1.orgReturnedQuantity = Number(item.quantity) - Number(item.deliveredQuantity);
              if (item.deliveryStatus)
                row1.status = deliveryStatuses.find(obj => obj.id == item.deliveryStatus)?.title;
              else
                row1.status = "";

              initialDeliveryQuantities[item.itemPalletId] = {
                item_pallet_id: item.itemPalletId,
                returned_quantity: item.returnedQuantity || 0,
                returned_quantity_to_stock: item.returnedQuantityToStock || 0,
                returned_quantity_to_stock_status: item.returnedQuantityToStockStatus,
                _quantity_status: item.returnedQuantityStatus,
              };

              rows.push(row1);
            }
          })
        })
      })

      let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");

      // console.log("routePallet", routePallet);
      var driver = routePallet.driver_name;
      var truck = routePallet.registration_number;
      var routeStatusID = routePallet.route_status_id;
      var files = routePallet.selectedRoute.files == null ? [] : JSON.parse(routePallet.selectedRoute.files);
      // var notes = routePallet.selectedRoute.notes == null ? '' : routePallet.selectedRoute.notes;
      var warehouse_received_by = routePallet.selectedRoute.warehouse_received_by == null ? '' : routePallet.selectedRoute.warehouse_received_by;
      var warehouse_signature = routePallet.selectedRoute.warehouse_signature == null ? '' : routePallet.selectedRoute.warehouse_signature;
      var notes = routePallet.selectedRoute.notes == null ? '' : routePallet.selectedRoute.notes;
      var driverID = routePallet.selectedRoute.driver_id == null ? 0 : routePallet.selectedRoute.driver_id;
      var fuelCardReturn = routePallet.selectedRoute.fuel_card_returned == null ? 0 : routePallet.selectedRoute.fuel_card_returned;
      var driverRoute =
        routePallet.selectedRoute.title +
        " (" +
        routePallet.selectedRoute.code +
        ")";
      var driver_returns_notes = routePallet.selectedRoute.driver_returns_notes == null ? '' : routePallet.selectedRoute.driver_returns_notes;
      var driver_returns_files = routePallet.selectedRoute.driver_returns_files == null ? [] : JSON.parse(routePallet.selectedRoute.driver_returns_files);
      var goods_return_updated_by = routePallet.selectedRoute.goods_return_updated_by;
      var start_status = routePallet.selectedRoute.start_status


      let data = {
        formatted_date: formatted_date,
        driver: driver,
        truck: truck,
        tableData: rows,
        route: routeNo,
        notes: notes,
        files: files,
        newFiles: [],
        driverRoute: driverRoute,
        routeStatusID: routeStatusID,
        driverID: driverID,
        fuelCardReturn: fuelCardReturn,
        driver_returns_notes: driver_returns_notes,
        driver_returns_files: driver_returns_files,
        goods_return_updated_by: goods_return_updated_by,
        start_status: start_status,
        warehouse_received_by: warehouse_received_by,
        warehouse_signature: warehouse_signature,
      };

      // if (rows.length > 0) 
      {

        goodsReturnData1.push(data);
        routeNo = routeNo + 1;
      }
    });


    setDeliveryQuantities(initialDeliveryQuantities);


    setGoodsReturnData(goodsReturnData1);
  };

  const printCashUp = (routePallets) => {
    var routeNo = 1;

    let cashUpData1 = [];
    routePallets.map((routePallet) => {
      let route = routes.filter((r) => r.id == routePallet.route_status_id);
      console.log('routes', routes);

      if (route.length == 0) {
        return;
      } else {
        routePallet.selectedRoute = route[0];
        routePallet.driver_name = route[0].driver_name;
        routePallet.registration_number = route[0].registration_number;
      }
    });

    routePallets.sort(function (a, b) {
      return a.driver_name?.localeCompare(b.driver_name);
    });

    routePallets.map((routePallet) => {
      let cashUpDataTemp = {};

      let rows = [];
      let totalCash = 0;
      let totalCard = 0;
      let totalAccount = 0;

      let pallets1 = JSON.parse(JSON.stringify(routePallet.pallets));
      pallets1.sort(function (a, b) {
        return b.pallet_id - a.pallet_id;
      });

      // console.log(pallets1)
      let all_orders = [];

      pallets1.map((pallet) => {
        if (pallet.items.length > 0)
          all_orders = all_orders.concat(pallet.items);
      });

      // sort by rank
      all_orders.sort(function (a, b) {
        return a.rank - b.rank;
      });

      all_orders.map((order) => {
        // let internalId = rows[rows.length - 1] && rows[rows.length - 1][0] == order.internalId ? true : false;
        // To check if the ranks are in reverse order within the orders items, need to check the whole list everytime
        let internalId = 0;
        let idx = -1;
        rows.map((r, i) => {
          if (r.internalId == order.internalId) {
            internalId = order.internalId;
            idx = i;
          }
        });

        if (internalId > 0) {
          rows[rows.length - 1]["orderAmount"] = (
            Number(rows[rows.length - 1]["orderAmount"]) + Number(order.orderAmount)
          ).toFixed(2);
          rows[idx]['items'] = rows[idx]['items'].concat(order.items);
        } else {
          let row1 = {};
          row1.items = order.items;
          row1.rank = order.rank;
          row1.customerName = order.customerName;
          row1.customerInternalId = order.customerInternalId;
          row1.internalId = order.internalId;
          row1.invoiceInternalId = order.invoiceInternalId;
          row1.customerProjectId = order.customerProjectId;
          row1.palletOrderID = order.palletOrderID;
          row1.delivered = order.delivered;
          row1.delivered_at = order.delivered_at;
          row1.postponed_status = order.postponed_status;
          row1.postponed_notes = order.postponed_notes;
          row1.postponed_files = (order.postponed_files === null || order.postponed_files === undefined) ? [] : JSON.parse(order.postponed_files);
          row1.signature = order.signature;
          row1.delivery_instructions = order.deliveryInstructions;
          row1.payment_req_notes = order.payment_req_notes;
          row1.delivery_discount_req_notes = order.delivery_discount_req_notes;
          row1.notes = order.notes;
          row1.received_by = order.received_by;

          // row1.cashApproved = order.cashApproved;
          // row1.invoice_no = order.invoiceNo
          //   ? order.invoiceNo
          //   : order.saleOrderNo;
          row1.invoice_no = order.invoiceNo
          row1.saleOrderNo = order.saleOrderNo;
          row1.orderAmount = order.orderAmount.toFixed(2);
          row1.amountReceivedCash = order.amountReceivedCash
            ? order.amountReceivedCash.toFixed(2)
            : "    ";
          row1.amountReceivedCard = order.amountReceivedCard
            ? order.amountReceivedCard.toFixed(2)
            : "    ";
          row1.amountReceivedAccount = order.amountReceivedAccount
            ? order.amountReceivedAccount.toFixed(2)
            : "    ";
          rows.push(row1);
          totalCash += Number(order.amountReceivedCash || 0);
          totalCard += Number(order.amountReceivedCard || 0);
          totalAccount += Number(order.amountReceivedAccount || 0);
        }
      });
      // });

      let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");

      var driver = routePallet.driver_name;
      var truck = routePallet.registration_number;
      var routeStatusID = routePallet.route_status_id;
      var driverRoute =
        routePallet.selectedRoute.title +
        " (" +
        routePallet.selectedRoute.code +
        ")";
      var cashReceived = routePallet.selectedRoute.cash_received;
      var cardReceived = routePallet.selectedRoute.card_received;
      var terminal = routePallet.selectedRoute.terminal_id;
      var dc_notes = routePallet.selectedRoute.dc_notes == null ? '' : routePallet.selectedRoute.dc_notes;
      var dc_fifty_notes = routePallet.selectedRoute.dc_fifty_notes == null ? '' : routePallet.selectedRoute.dc_fifty_notes;
      var dc_twenty_notes = routePallet.selectedRoute.dc_twenty_notes == null ? '' : routePallet.selectedRoute.dc_twenty_notes;
      var dc_ten_notes = routePallet.selectedRoute.dc_ten_notes == null ? '' : routePallet.selectedRoute.dc_ten_notes;
      var dc_five_notes = routePallet.selectedRoute.dc_five_notes == null ? '' : routePallet.selectedRoute.dc_five_notes;
      var dc_files = routePallet.selectedRoute.dc_files == null ? '' : JSON.parse(routePallet.selectedRoute.dc_files);
      var dc_change_total = routePallet.selectedRoute.dc_change_total == null ? '' : routePallet.selectedRoute.dc_change_total;


      var dc_one_pennies_coins = routePallet.selectedRoute.dc_one_pennies_coins == null ? '' : routePallet.selectedRoute.dc_one_pennies_coins;
      var dc_two_pennies_coins = routePallet.selectedRoute.dc_two_pennies_coins == null ? '' : routePallet.selectedRoute.dc_two_pennies_coins;
      var dc_five_pennies_coins = routePallet.selectedRoute.dc_five_pennies_coins == null ? '' : routePallet.selectedRoute.dc_five_pennies_coins;
      var dc_ten_pennies_coins = routePallet.selectedRoute.dc_ten_pennies_coins == null ? '' : routePallet.selectedRoute.dc_ten_pennies_coins;
      var dc_twenty_pennies_coins = routePallet.selectedRoute.dc_twenty_pennies_coins == null ? '' : routePallet.selectedRoute.dc_twenty_pennies_coins;
      var dc_fifty_pennies_coins = routePallet.selectedRoute.dc_fifty_pennies_coins == null ? '' : routePallet.selectedRoute.dc_fifty_pennies_coins;
      var dc_one_pound_coins = routePallet.selectedRoute.dc_one_pound_coins == null ? '' : routePallet.selectedRoute.dc_one_pound_coins;
      var dc_two_pound_coins = routePallet.selectedRoute.dc_two_pound_coins == null ? '' : routePallet.selectedRoute.dc_two_pound_coins;
      var cashup_updated_by = routePallet.selectedRoute.cashup_updated_by;
      var start_status = routePallet.selectedRoute.start_status;
      var cashup_signature = routePallet.selectedRoute.cashup_signature;


      let data = {
        formatted_date: formatted_date,
        driver: driver,
        truck: truck,
        tableData: rows,
        route: routeNo,
        terminal: terminal,
        driverRoute: driverRoute,
        totalCash: totalCash,
        totalCard: totalCard,
        totalAccount: totalAccount,
        routeStatusID: routeStatusID,
        cashReceived: cashReceived,
        cardReceived: cardReceived,
        dc_notes: dc_notes,
        dc_fifty_notes: dc_fifty_notes,
        dc_twenty_notes: dc_twenty_notes,
        dc_ten_notes: dc_ten_notes,
        dc_five_notes: dc_five_notes,
        dc_files: dc_files,
        dc_change_total: dc_change_total,
        dc_one_pennies_coins: dc_one_pennies_coins,
        dc_two_pennies_coins: dc_two_pennies_coins,
        dc_five_pennies_coins: dc_five_pennies_coins,
        dc_ten_pennies_coins: dc_ten_pennies_coins,
        dc_twenty_pennies_coins: dc_twenty_pennies_coins,
        dc_fifty_pennies_coins: dc_fifty_pennies_coins,
        dc_two_pound_coins: dc_two_pound_coins,
        dc_one_pound_coins: dc_one_pound_coins,
        cashup_updated_by: cashup_updated_by,
        start_status: start_status,
        cashup_signature: cashup_signature
      };

      cashUpData1.push(data);
      routeNo = routeNo + 1;
    });


    setCashUpData(cashUpData1);
  };


  const printDriverManifest = (routePallets) => {
    var routeNo = 1;
    let driverManifestData1 = [];
    routePallets.map((routePallet) => {
      let route = routes.filter((r) => r.id == routePallet.route_status_id);

      if (route.length == 0) {
        return;
      } else {
        routePallet.selectedRoute = route[0];
        routePallet.driver_name = route[0].driver_name;
        routePallet.registration_number = route[0].registration_number;
      }
    });

    routePallets.sort(function (a, b) {
      return a.driver_name?.localeCompare(b.driver_name);
    });

    routePallets.map((routePallet) => {

      let rows = [];
      let totalCash = 0;
      let totalCard = 0;
      let totalAccount = 0;

      let pallets1 = JSON.parse(JSON.stringify(routePallet.pallets));
      pallets1.sort(function (a, b) {
        return b.pallet_id - a.pallet_id;
      });

      // console.log(pallets1)
      let all_orders = [];

      pallets1.map((pallet) => {
        if (pallet.items.length > 0)
          all_orders = all_orders.concat(pallet.items);
      });

      // sort by rank
      all_orders.sort(function (a, b) {
        return a.rank - b.rank;
      });

      all_orders.map((order) => {
        // let internalId = rows[rows.length - 1] && rows[rows.length - 1][0] == order.internalId ? true : false;
        // To check if the ranks are in reverse order within the orders items, need to check the whole list everytime
        let internalId = 0;
        let idx = -1;
        rows.map((r, i) => {
          if (r.internalId == order.internalId) {
            internalId = order.internalId;
            idx = i;
          }
        });

        if (internalId > 0) {
          rows[rows.length - 1]["orderAmount"] = (
            Number(rows[rows.length - 1]["orderAmount"]) + Number(order.orderAmount)
          ).toFixed(2);
          rows[idx]['items'] = rows[idx]['items'].concat(order.items);
        } else {
          let row1 = {};
          row1.items = order.items;
          row1.rank = order.rank;
          row1.customerName = order.customerName;
          row1.customerInternalId = order.customerInternalId;
          row1.internalId = order.internalId;
          row1.invoiceInternalId = order.invoiceInternalId;
          row1.customerProjectId = order.customerProjectId;
          row1.ship_address1 = order.shipAddress1;
          row1.ship_zip = order.shipZip;
          row1.terms = order.terms;
          row1.palletOrderID = order.palletOrderID;
          row1.signature = order.signature;
          row1.received_by = order.received_by;
          row1.notes = order.notes;
          row1.delivered = order.delivered;
          row1.delivered_at = order.delivered_at;
          row1.delivery_instructions = order.deliveryInstructions;
          row1.routeStatusID = routePallet.route_status_id;
          row1.postponed_status = order.postponed_status;
          row1.postponed_files = (order.postponed_files === null || order.postponed_files === undefined) ? [] : JSON.parse(order.postponed_files);
          row1.postponed_notes = order.postponed_notes;
          row1.delivery_discount_req_status = order.delivery_discount_req_status;
          row1.delivery_discount_auth_by = order.delivery_discount_auth_by;
          row1.payment_req_status = order.payment_req_status;
          row1.payment_req_notes = order.payment_req_notes;
          row1.delivery_discount_req_notes = order.delivery_discount_req_notes;
          row1.payment_auth_by = order.payment_auth_by;
          // row1.cashApproved = order.cashApproved;
          row1.invoice_no = order.invoiceNo
          row1.saleOrderNo = order.saleOrderNo;
          row1.orderAmount = order.orderAmount.toFixed(2);
          row1.amountReceivedCash = order.amountReceivedCash
            ? order.amountReceivedCash.toFixed(2)
            : "    ";
          row1.amountReceivedCard = order.amountReceivedCard
            ? order.amountReceivedCard.toFixed(2)
            : "    ";
          row1.amountReceivedAccount = order.amountReceivedAccount
            ? order.amountReceivedAccount.toFixed(2)
            : "    ";
          rows.push(row1);
          totalCash += Number(order.amountReceivedCash || 0);
          totalCard += Number(order.amountReceivedCard || 0);
          totalAccount += Number(order.amountReceivedAccount || 0);
        }
      });
      // });

      let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");

      var driver = routePallet.driver_name;
      var truck = routePallet.registration_number;
      var routeStatusID = routePallet.route_status_id;
      var driverRoute =
        routePallet.selectedRoute.title +
        " (" +
        routePallet.selectedRoute.code +
        ")";
      var cashReceived = routePallet.selectedRoute.cash_received;
      var cardReceived = routePallet.selectedRoute.card_received;
      var terminal = routePallet.selectedRoute.terminal_id;
      var cashup_updated_by = routePallet.selectedRoute.cashup_updated_by;
      var start_status = routePallet.selectedRoute.start_status;
      var cashup_signature = routePallet.selectedRoute.cashup_signature;


      let data = {
        formatted_date: formatted_date,
        driver: driver,
        truck: truck,
        tableData: rows,
        route: routeNo,
        terminal: terminal,
        driverRoute: driverRoute,
        totalCash: totalCash,
        totalCard: totalCard,
        totalAccount: totalAccount,
        routeStatusID: routeStatusID,
        cashReceived: cashReceived,
        cardReceived: cardReceived,
        cashup_updated_by: cashup_updated_by,
        start_status: start_status,
        cashup_signature: cashup_signature
      };

      driverManifestData1.push(data);
      routeNo = routeNo + 1;
    });


    setDriverManifestData(driverManifestData1);
  };

  const printDriverSheet = (routePallets) => {
    var routeNo = 1;


    let driverSheetData1 = [];
    const initialPaymentDataPost = {};

    routePallets.map((routePallet) => {
      let route = routes.filter((r) => r.id == routePallet.route_status_id);

      if (route.length == 0) {
        return;
      } else {
        routePallet.selectedRoute = route[0];
        routePallet.driver_name = route[0].driver_name;
        routePallet.registration_number = route[0].registration_number;
        routePallet.netsuite_route_id = route[0].netsuite_route_id;
      }
    });

    routePallets.sort(function (a, b) {
      return a.driver_name?.localeCompare(b.driver_name);
    });

    routePallets.map((routePallet) => {

      let rows = [];
      let totalCash = 0;
      let totalCard = 0;
      let totalAccount = 0;

      let pallets1 = JSON.parse(JSON.stringify(routePallet.pallets));
      pallets1.sort(function (a, b) {
        return b.pallet_id - a.pallet_id;
      });

      // console.log(pallets1)
      let all_orders = [];

      pallets1.map((pallet) => {
        if (pallet.items.length > 0)
          all_orders = all_orders.concat(pallet.items);
        console.log('all_orders', all_orders.concat(pallet.items));

      });

      // sort by rank
      all_orders.sort(function (a, b) {
        return a.rank - b.rank;
      });
      all_orders.map((order) => {
        // let internalId = rows[rows.length - 1] && rows[rows.length - 1][0] == order.internalId ? true : false;
        // To check if the ranks are in reverse order within the orders items, need to check the whole list everytime
        let internalId = 0;
        let idx = -1;
        rows.map((r, i) => {
          if (r.internalId == order.internalId) {
            internalId = order.internalId;
            idx = i;
          }
        });

        if (internalId > 0) {
          rows[rows.length - 1]['orderAmount'] = (
            Number(rows[rows.length - 1]['orderAmount']) + Number(order.orderAmount)
          ).toFixed(2);
          rows[idx]['items'] = rows[idx]['items'].concat(order.items);
        } else {
          let row1 = {};

          row1.items = order.items;
          row1.rank = order.rank;
          row1.customerName = order.customerName;
          row1.customerProjectId = order.customerProjectId;
          row1.palletOrderID = order.palletOrderID;
          row1.delivered = order.delivered;
          row1.delivered_at = order.delivered_at;
          row1.delivery_instructions = order.deliveryInstructions;
          row1.notes = order.notes;
          row1.received_by = order.received_by;
          row1.delivery_discount_req_status = order.delivery_discount_req_status;
          row1.delivery_discount_auth_by = order.delivery_discount_auth_by;
          row1.payment_req_status = order.payment_req_status;

          row1.payment_req_notes = order.payment_req_notes;
          row1.delivery_discount_req_notes = order.delivery_discount_req_notes;
          row1.payment_auth_by = order.payment_auth_by;
          row1.postponed_status = order.postponed_status;
          row1.postponed_files = (order.postponed_files === null || order.postponed_files === undefined) ? [] : JSON.parse(order.postponed_files);
          row1.postponed_notes = order.postponed_notes;
          row1.signature = order.signature;
          row1.files = (order.files === null || order.files === undefined) ? [] : JSON.parse(order.files);

          // row1.files = order.files;
          row1.routeStatusID = routePallet.route_status_id;

          row1.customerInternalId = order.customerInternalId;
          row1.internalId = order.internalId;
          row1.invoiceInternalId = order.invoiceInternalId;
          // row1.cashApproved = order.cashApproved;
          row1.invoice_no = order.invoiceNo
          row1.saleOrderNo = order.saleOrderNo;
          row1.orderAmount = order.orderAmount.toFixed(2);
          row1.amountReceivedCash = order.amountReceivedCash
            ? order.amountReceivedCash.toFixed(2)
            : 0;

          row1.amountReceivedCashPost = routePallet.selectedRoute.payments_posted_by > 0
            ? Number(order.amountReceivedCashPost).toFixed(2)
            : Number(order.amountReceivedCash).toFixed(2);

          row1.amountReceivedCard = order.amountReceivedCard
            ? order.amountReceivedCard.toFixed(2)
            : 0;

          row1.amountReceivedCardPost = routePallet.selectedRoute.payments_posted_by > 0
            ? Number(order.amountReceivedCardPost).toFixed(2)
            : Number(order.amountReceivedCard).toFixed(2);

          // row1.amountReceivedCardPost = order.amountReceivedCardPost
          //   ? order.amountReceivedCardPost.toFixed(2)
          //   : null;
          row1.amountReceivedAccount = order.amountReceivedAccount
            ? order.amountReceivedAccount.toFixed(2)
            : 0;

          row1.amountReceivedAccountPost = order.amountReceivedAccountPost !== null && order.amountReceivedAccountPost !== undefined
            ? Number(order.amountReceivedAccountPost).toFixed(2)
            : null;
          // row1.amountReceivedAccountPost = order.amountReceivedAccountPost
          //   ? order.amountReceivedAccountPost.toFixed(2)
          //   : null;


          initialPaymentDataPost[order.palletOrderID] = {
            order_pallet_id: order.palletOrderID,
            amount_received_cash_post: routePallet.selectedRoute.payments_posted_by > 0
              ? Number(order.amountReceivedCashPost).toFixed(2)
              : Number(order.amountReceivedCash).toFixed(2),
            amount_received_card_post: routePallet.selectedRoute.payments_posted_by > 0
              ? Number(order.amountReceivedCardPost).toFixed(2)
              : Number(order.amountReceivedCard).toFixed(2),
            amount_received_account_post: order.amountReceivedAccountPost,

          };
          rows.push(row1);
          totalCash += Number(order.amountReceivedCash || 0);
          totalCard += Number(order.amountReceivedCard || 0);
          totalAccount += Number(order.amountReceivedAccount || 0);
        }
      });
      // });

      let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");

      // console.log("routePallet", routePallet);
      var driver = routePallet.driver_name;
      var truck = routePallet.registration_number;
      var routeStatusID = routePallet.route_status_id;
      var netsuiteRouteId = routePallet.netsuite_route_id;
      var driverRoute =
        routePallet.selectedRoute.title +
        " (" +
        routePallet.selectedRoute.code +
        ")";
      var terminal = routePallet.selectedRoute.terminal_id;
      var payments_posted_by = routePallet.selectedRoute.payments_posted_by;
      var start_status = routePallet.selectedRoute.start_status;
      const postingDateInitial = routePallet.selectedRoute.posting_date ? moment(routePallet.selectedRoute.posting_date).format('YYYY-MM-DD') : deliveryDate
      var cashReceived = routePallet.selectedRoute.cash_received;
      var cardReceived = routePallet.selectedRoute.card_received;
      let data = {
        formatted_date: formatted_date,
        driver: driver,
        truck: truck,
        tableData: rows,
        route: routeNo,
        terminal: terminal,
        driverRoute: driverRoute,
        totalCash: totalCash,
        totalCard: totalCard,
        totalAccount: totalAccount,
        routeStatusID: routeStatusID,
        netsuiteRouteId: netsuiteRouteId,
        payments_posted_by: payments_posted_by,
        postingDateInitial: postingDateInitial,
        start_status: start_status,
        cashReceived: cashReceived,
        cardReceived: cardReceived,
      };

      driverSheetData1.push(data);
      routeNo = routeNo + 1;
    });

    setPaymnetDataPost(initialPaymentDataPost)


    setDriverSheetData(driverSheetData1);
  };

  const updateCashup = (cash_received, card_received) => {
    let routePallets1 = routePallets;
    if (routePallets1.length) {
      routePallets1[0].selectedRoute.card_received = card_received;
      routePallets1[0].selectedRoute.cash_received = cash_received;
      setRoutePallets(routePallets1);
    }
  }

  const updateGoodsReturn = (warehouse_received_by, warehouse_signature) => {
    let routePallets1 = routePallets;
    if (routePallets1.length) {
      routePallets1[0].selectedRoute.warehouse_received_by = warehouse_received_by;
      routePallets1[0].selectedRoute.warehouse_signature = warehouse_signature;
      setRoutePallets(routePallets1);
    }
  }

  const updateReturnedQuantity = (firstArray, secondArray) => {
    const secondArrayMap = secondArray.reduce((acc, entry) => {
      acc[entry.item_pallet_id] = entry.returned_quantity;
      return acc;
    }, {});
  
    return firstArray.map((pallet) => ({
      ...pallet,
      items: pallet.items.map((item) => ({
        ...item,
        items: item.items.map((subItem) => ({
          ...subItem,
          returnedQuantity: secondArrayMap[subItem.itemPalletId] || subItem.returnedQuantity,
        })),
      })),
    }));
  };
  
  
  
  const updateGoodsReturnQuantity = (goods_return_quantity) => {
    let routePallets1 = routePallets;
    const updatedArray = updateReturnedQuantity(routePallets1[0].pallets, goods_return_quantity);
    console.log('updatedArray', updatedArray);
    if (routePallets1.length) {
      routePallets1[0].pallets = updatedArray;
      setRoutePallets(routePallets1);
    }
    
    
  }

  const items = [
    {
      key: '1',
      label: 'Driver Manifest',
      children: (
        // driverManifestData.length > 0 ? (
        //   <DriverManifestTab 
        //   selectedRoute={selectedRoute}
        //   deliveryDate={deliveryDate}
        //   driverManifestData={driverManifestData} 
        //   paymentAuthorizer={paymentAuthorizer} 
        //   />
        // ) : (
        //    !driverManifestData.length ? (
        //     <Result 
        //       status="404" 
        //       subTitle="Sorry, no data exists." 
        //     />
        //   ) : (
        //     <></>
        //   )
        // )
        <DriverManifestTab
          selectedRoute={selectedRoute}
          deliveryDate={deliveryDate}
          driverManifestData={driverManifestData}
          paymentAuthorizer={paymentAuthorizer}
        />
      ),

    },
    (UserType == 1 || includesFinance ) && {
      key: '2',
      label: 'Cash Up',
      children: cashUpData.length > 0
        ? (
          <CashupTab
            cashUpData={cashUpData}
            setCashUpData={setCashUpData}
            delivery_date={deliveryDate}
            paymentAuthorizer={paymentAuthorizer}
            updateCashup={updateCashup}
          />
        )
        : (
          <Result status="404" subTitle="Sorry, no data exists." />
        ),
    },
      (UserType == 1 || includesFinance ) && {
      key: '3',
      label: 'Driver Sheet',
      children: driverSheetData.length > 0
        ? (
          <DriverSheetTab
            driverSheetData={driverSheetData}
            setDriverSheetData={setDriverSheetData}
            postData={paymnetDataPost}
            setPostData={setPaymnetDataPost}
            paymentAuthorizer={paymentAuthorizer}
          />
        )
        : (
          <Result status="404" subTitle="Sorry, no data exists." />
        ),
    },
    (UserType == 1 || roles[0].role_id == 4 || includesFinance || includesSalesManager) && {
      key: '4',
      label: 'Goods Return',
      children: goodsReturnData.length > 0
        ? (
          <GoodsReturnTab
            goodsReturnData={goodsReturnData}
            setGoodsReturnData={setGoodsReturnData}
            deliveryQuantities={deliveryQuantities}
            setDeliveryQuantities={setDeliveryQuantities}
            updateGoodsReturn={updateGoodsReturn}
            updateGoodsReturnQuantity={updateGoodsReturnQuantity}
          />
        )
        : (
          <Result status="404" subTitle="Sorry, no data exists." />
        ),
    },
    (UserType == 1 || roles[0].role_id == 4 || includesFinance || includesSalesManager) && {
      key: '5',
      label: 'Stock Discrepancies',
      children: stockDiscrepanciesData.length > 0
        ? (
          <StockDiscrepanciesTab
            stockDiscrepanciesData={stockDiscrepanciesData}
            setStockDiscrepanciesData={setStockDiscrepanciesData}
            deliveryQuantities1={deliveryQuantities}
          />
        )
        : (
          <Result status="404" subTitle="Sorry, no data exists." />
        ),
    },
  ];
  return (
    <div className={classes.dataSheet}>
      <Drawer
        title={
          <>

            <Descriptions bordered size={"small"}
              style={{ marginTop: '5px' }}
              column={{ xs: 1, sm: 2, md: 2, lg: 5 }}
            >
              <Descriptions.Item
                label={'Date'}
                labelStyle={{ width: "auto" }}
                contentStyle={{ width: 'auto' }}
              >
                <Tag style={{ fontSize: '15px' }} color="blue">{deliveryDate ? moment(deliveryDate).format('DD-MM-YYYY') : ''}</Tag>
              </Descriptions.Item>
              <Descriptions.Item
                label={'Route'}
                labelStyle={{ width: "auto" }}
                contentStyle={{ width: 'auto' }}
              >
                <Tag style={{ fontSize: '15px' }} color="blue">{`${selectedRoute?.title} (${selectedRoute?.code})`}</Tag>
              </Descriptions.Item>
              <Descriptions.Item
                label={'Vehicle'}
                labelStyle={{ width: "auto" }}
                contentStyle={{ width: 'auto' }}
              >
                <Tag style={{ fontSize: '15px' }} color="blue">{`${selectedRoute?.registration_number}`}</Tag>
              </Descriptions.Item>
              <Descriptions.Item
                label={'Driver'}
                labelStyle={{ width: "auto" }}
                contentStyle={{ width: 'auto' }}
              >
                <Tag style={{ fontSize: '15px' }} color="blue">{`${selectedRoute?.driver_name ? selectedRoute?.driver_name : ''}`}</Tag>
              </Descriptions.Item>

              <Descriptions.Item
                label={'Terminal'}
                labelStyle={{ width: "auto" }}
                contentStyle={{ width: 'auto' }}
              >
                <Tag style={{ fontSize: '15px' }} color="blue">{selectedRoute?.terminal_id
                  ? cardTerminals.filter((obj) => obj.id == selectedRoute?.terminal_id)[0]?.title
                  : "N/A"}
                </Tag>
              </Descriptions.Item>
            </Descriptions>
          </>
        }
        extra={
          <Button
            style={{
              color: selectedRoute?.start_status === 2 ? 'green' : 'red',
              cursor: 'alias',
              pointerEvents: "none",
              padding: '3px 4px',
              marginTop: '2px',
              marginLeft: '5px'
            }}
            // size="large"
            icon={selectedRoute?.start_status === 2 ? <CheckOutlined /> : <CloseOutlined />}
          >
            Route Completed
          </Button>

        }
        placement={"bottom"}
        footer={
          <>
            <div style={{ display: "flex", justifyContent: "center", padding: '5px 5px' }}>

              Prefectos ©{new Date().getFullYear()}
            </div>
          </>
        }
        onClose={() => {
          handleCloseDrawer()
          setPaymnetDataPost({})
          onClose()


        }}
        height="100vh"
        open={drawerOpen}
        bodyStyle={{ background: '#f5f5f5', padding: '2px', }}
        headerStyle={{ padding: '10px', alignItems: 'center' }}
      >

        <Tabs type="card" style={{ background: 'white', height: '100%', overflowY: 'scroll' }} activeKey={activeTab} onChange={onTabChange} centered
          items={items}
        />
        {/* <Button
          danger
          onClick={() => {
            handleCloseDrawer()
            setPaymnetDataPost({})
  
  
          }}
          style={{
            position: 'absolute',
            bottom: '20px',
            right: '20px',
          }}
        >
          Close
        </Button> */}
        {/* </div> */}

      </Drawer>
    </div>
  );
};
export default ViewReportsDrawer;
