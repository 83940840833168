import React, { useEffect, useState } from "react";
import { Card, Col, Divider, Row, Statistic, Table, Switch, Button, Tag, Tooltip, Input, Modal, message } from "antd";
import { makeStyles } from "@mui/styles";
import { CheckOutlined, CloseOutlined, DeleteFilled, EditOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import moment from "moment";
import { hideLoading, showLoading, useLoadingDispatch } from '../../context/loadingContext';
import { Pagination } from "@mui/material";
import { issuePriorities } from "../../Components/common/constants";
import { useNavigate } from "react-router-dom";

const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: "5px",

  },
}));

const AssetTypes = () => {
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const navigate = useNavigate();
  const [loadMessage, setLoadMessage] = useState("");
  const [type, setType] = useState("");
  const [allAssetTypes, setAllAssetTypes] = useState([]);
  console.log(allAssetTypes);
  
  const [page, setPage] = React.useState(1);
  const [pageChange, setPageChange] = React.useState(false);
  const [limit] = React.useState(50);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);

  const [ModalOpen, setModalOpen] = React.useState(false);
  const [ModalOpenEdit, setModalOpenEdit] = React.useState(false);
  const [newAssetType, setNewAssetType] = React.useState("");

  const [selectedAssetType, setSelectedAssetType] = React.useState(null);

  const handleModalClose = () => {
    setModalOpen(false)
    setNewAssetType("")
  };
  const handleModalCloseEdit = () => {
    setModalOpenEdit(false)
    setSelectedAssetType(null)
  };

  const handleModalOpen = () => setModalOpen(true);
  const handleModalOpenEdit = () => setModalOpenEdit(true);

  const handleAssetTypeSubmit = async () => {
    if (newAssetType.length < 3) {
      message.error('Asset type must be three letter long')
      return;
    }
    showLoading(layoutDispatch);
    const data = {
      title: newAssetType
    }
    try {
      const res = await Auth.fetch("/asset/asset-type", {
        method: "POST",
        body: JSON.stringify(data)
      });
      if (res?.ack) {
        message.success(res?.message)
        setModalOpen(false)
        getAllAssetTypes();
      } else {
        message.error(res?.message)
      }
    } catch (err) {
      console.log(err);
      message.error(err?.message)
    } finally {
      hideLoading(layoutDispatch);
    }

  }

  const handleEditAssetTypeSubmit = async () => {
    if (newAssetType.length < 3) {
      message.error('Asset type must be three letter long')
      return;
    }
    showLoading(layoutDispatch);
    const data = {
      title: newAssetType,
      id: selectedAssetType.id
    }
    try {
      const res = await Auth.fetch("/asset/asset-type", {
        method: "PUT",
        body: JSON.stringify(data)
      });
      if (res?.ack) {
        message.success(res?.message)
        setModalOpenEdit(false)
        getAllAssetTypes();
      } else {
        message.error(res?.message)
      }
    } catch (err) {
      console.log(err);
      message.error(err?.message)
    } finally {
      hideLoading(layoutDispatch);
    }

  }

  const getAllAssetTypes = async () => {
    showLoading(layoutDispatch);
    try {
      const res = await Auth.fetch("/asset/assets-type");
      if (res?.ack) {
        setAllAssetTypes(res?.asset_types)
        message.success(res?.message)
      } else {
        setAllAssetTypes([])
        message.error(res?.message)
      }
    } catch (err) {
      console.log(err);
      message.error(err?.message)
    } finally {
      hideLoading(layoutDispatch);
    }
  };

  useEffect(() => {
    getAllAssetTypes();
  }, []);

  const deleteAsset = async (id) => {
    showLoading(layoutDispatch);
    try {
      const res = await Auth.fetch(`/asset/asset-type`, {
        method: "DELETE",
        body: JSON.stringify({ id: id })

      });
      if (res?.ack) {
        getAllAssetTypes();
        message.success(res?.message);
      } else {
        message.error(res?.message);
      }
    } catch (err) {
      console.log(err);
      message.error(err?.message);
    } finally {
      hideLoading(layoutDispatch);
    }
  };

  const assetTypeColumns = [
    {
      title: 'Asset Type',
      dataIndex: 'title',
      key: 'title',
    },

    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <div>
          <EditOutlined
            style={{
              fontSize: "25px",
              cursor: "pointer",
              marginRight: "5px",
              color: "#006cb8",
            }} onClick={() => {
              setSelectedAssetType(record)
              setNewAssetType(record?.title)
              handleModalOpenEdit()
            }} />
          <DeleteFilled style={
            {
              fontSize: "25px",
              cursor: "pointer",
              color: "#ff0000",
            }} onClick={() => {
              Modal.confirm({
                title: 'Delete Asset Type',
                centered: true,
                content: `Are you sure you want to delete this asset type?`,
                onOk: () => deleteAsset(record.id),
                okText: 'Delete',
                cancelText: 'Cancel',
                width: 600,
                okButtonProps: { size: "large" },
                cancelButtonProps: { size: "large" },
              });
            }}
          />

        </div>
      )
    },

  ];

  useEffect(() => {
    message.config({
      top: window.innerHeight - 100,
      duration: 5,
    });
  }, [])

  return (
    <div className={classes.dataSheet}>
      <div className="page-header">
        <h2>Asset Types</h2>
        <Button onClick={handleModalOpen} type="primary" icon={<PlusOutlined />}>Add Asset Type</Button>
      </div>
      <div className={classes.antTable}>
        <Table
          columns={assetTypeColumns}
          style={{ overflowX: "auto", marginTop: '10px' }}
          pagination={false}
          dataSource={allAssetTypes}
        // footer={() => {
        //   const startRecord = (page - 1) * limit + 1;
        //   const endRecord = Math.min(page * limit, allAssetTypes.length ? totalCount : 0); 
        //   return `Showing ${startRecord}-${endRecord} of ${allAssetTypes.length ? totalCount : "0"} Records`;
        // }}
        />
      </div>

      {/* <div style={{ float: "right", margin: "10px" }}>
                <Pagination
                  page={page}
                  count={totalPages}
                  variant="outlined"
                  color="primary"
                  onChange={handleChange}
                />
              </div> */}
      <Modal
        open={ModalOpen}
        onCancel={handleModalClose}
        title="Add Asset Type"
        cancelText="Close"
        okText="Save"
        centered
        onOk={handleAssetTypeSubmit}

      >
        <>
          <Divider />
          <Row>
            <Col span={12}>
              <Input
                placeholder="Asset Title"
                value={newAssetType}
                onChange={(e) => setNewAssetType(e.target.value)}
              />
            </Col>

          </Row>
        </>
      </Modal>
      <Modal
        open={ModalOpenEdit}
        onCancel={handleModalCloseEdit}
        title="Edit Asset Type"
        cancelText="Close"
        okText="Save"
        centered
        onOk={handleEditAssetTypeSubmit}

      >
        <>
          <Divider />
          <Row>
            <Col span={12}>
              <Input
                placeholder="Asset Title"
                value={newAssetType}
                onChange={(e) => setNewAssetType(e.target.value)}
              />
            </Col>

          </Row>
        </>
      </Modal>
      <Message type={type} msg={loadMessage} />
    </div>
  );
};

export default AssetTypes;
