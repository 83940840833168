import moment from 'moment'
import React, { useEffect, useState } from 'react'
import AuthService from '../../Components/auth/AuthService';
import { Descriptions, Modal, Result, Table, Tag, Image, Divider, Statistic, Col, Card, Row, Input, Collapse, Button, Tooltip, Alert, Typography, Space, Badge } from 'antd';
import { netsuite_url, tms_uploads_cdn, env, tms_uploads_bucket, domain } from '../../Components/config';
import { CheckOutlined, CloseCircleOutlined, CloseOutlined, CloudDownloadOutlined, EyeFilled, InfoCircleOutlined } from '@ant-design/icons';
import { makeStyles } from "@mui/styles";
import { cardTerminals, deliveryStatuses, PostponeReasons } from '../../Components/common/constants';
import { hideLoading, showLoading, useLoadingDispatch } from '../../context/loadingContext';
import { Button as UploadButton } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import qs from "query-string";
import Message from '../../Components/common/Message';
import { formatCurrency } from '../../Components/common/CommonFun';
import DetailsModal from './DetailsModal';

const useStyles = makeStyles((theme) => ({
    dataSheet: {
        padding: '10px',
      "& .ant-card .ant-card-body": {
        padding: "4px",
        // backgroundColor: 'rgb(240, 240, 240)',
      },
      
      "& .ant-statistic .ant-statistic-content": {
        fontSize: "12px",
        fontWeight: "bold",
      },
      "& .ant-statistic .ant-statistic-title": {
        fontSize: "12px",
      },
      "& .ant-input[disabled]": {
        color: 'black !important',
        fontWeight: 500
      },
      "& .ant-card-bordered": {
      border: '1px solid #b1aeae'
    }
      
    },
    antTable: {
      "& .ant-table-tbody > tr > td": {
        borderBottom: "1px solid #b7b9bd",
      },
      "& .ant-table-footer": {
        padding: '16px 0px !important'
      }
    },
  }));

const CashupTab = ({ cashUpData, setCashUpData, delivery_date, paymentAuthorizer, updateCashup }) => {
    console.log('cashUpData', cashUpData);
    
    const Auth = new AuthService();
    const classes = useStyles();
    const { TextArea } = Input
    const { Panel } = Collapse;
    const { Text } = Typography;

    var layoutDispatch = useLoadingDispatch();
    
    const [loadMessage, setLoadMessage] = React.useState("");
    const [type, setType] = React.useState("");

    const [filesOpen, setFilesOpen] = React.useState(false);
    const [selectedRecord, setSelectedRecord] = React.useState("");


  const handleFilesOpen = (row) => {
    console.log("row", row);
    if (row) {
      setSelectedRecord(row);
    }
    setFilesOpen(true);
  };
  const handleFilesClose = () => setFilesOpen(false);

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return <Tag color="warning">Pending</Tag>;
      case 2:
        return <Tag color="success">Approved</Tag>;
      case 3:
        return <Tag color="error">Rejected</Tag>;
      default:
        return "";
    }
  };

    const [fiftyPoundBillCount, setFiftyPoundBillCount] = useState("");
    const [twentyPoundBillCount, setTwentyPoundBillCount] = useState("");
    const [tenPoundBillCount, setTenPoundBillCount] = useState("");
    const [fivePoundBillCount, setFivePoundBillCount] = useState("");
    const [coinCount, setCoinCount] = useState({
    oneP: '',
    twoP: '',
    fiveP: '',
    tenP: '',
    TwentyP: '',
    fiftyP: '',
    onePound: '',
    twoPound: ''
  });
  const [frontdeskCashupID, setFrontdeskCashupID] = React.useState(null);
  const [notes, setNotes] = useState("");
  const [files, setFiles] = useState([]);
  const [currentItemFiles, setCurrentItemFiles] = useState([]);
  const [totalCoinAmount, setTotalCoinAmount] = useState(0);
  const [totalNotesAmount, setTotalNotesAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [deletedFiles, setDeletedFiles] = React.useState([]);


//Front desk Cash Up 


  // const genExtraFrontCashUp = () => {
    
  //   const completed = frontdeskCashupID > 0 ? true : false;
  //   return (
  //     <div style={{ display: 'flex', alignItems: 'center'}}>
  //       <Button
  //             style={{
  //               marginRight: '5px',
  //               color: completed  ? 'green' : 'red',
  //               cursor: 'alias',
  //               pointerEvents: "none",
  //               padding: '4px 4px'
  //             }}
  //             icon={completed ? <CheckOutlined/> : <CloseOutlined />}
  //               >
  //                Front Desk Cashup
  //           </Button>
  //         </div>
  //   )
  // };

  //   const fetchData = async () => {
      
  //     showLoading(layoutDispatch);
  //     try {
  //       const res = await Auth.fetch(`/route/frontdesk-cashup?delivery_date=${delivery_date}`);
  //       if (res?.ack) {
  //         setFiftyPoundBillCount(res?.frontdesk_cashup?.fifty_notes || "")
  //         setTwentyPoundBillCount(res?.frontdesk_cashup?.twenty_notes || "")
  //         setTenPoundBillCount(res?.frontdesk_cashup?.ten_notes || "")
  //         setFivePoundBillCount(res?.frontdesk_cashup?.five_notes || "")
  //         setNotes(res?.frontdesk_cashup?.notes || "")
  //         setCurrentItemFiles(res?.frontdesk_cashup?.files ? JSON.parse(res?.frontdesk_cashup?.files) : [])
  //         setCoinCount((prevCoins) => ({
  //           ...prevCoins,
  //           oneP: res?.frontdesk_cashup?.one_pennies_coins,
  //           twoP: res?.frontdesk_cashup?.two_pennies_coins,
  //           fiveP: res?.frontdesk_cashup?.five_pennies_coins,
  //           tenP: res?.frontdesk_cashup?.ten_pennies_coins,
  //           TwentyP: res?.frontdesk_cashup?.twenty_pennies_coins,
  //           fiftyP: res?.frontdesk_cashup?.fifty_pennies_coins,
  //           onePound: res?.frontdesk_cashup?.one_pound_coins,
  //           twoPound: res?.frontdesk_cashup?.two_pound_coins,
  //         }));
  //       }
  //       setFrontdeskCashupID(res?.frontdesk_cashup?.id > 0 ? res?.frontdesk_cashup?.id : null)
  //     } catch (err) {
  //       console.log(err)
  //     } finally {
  //       hideLoading(layoutDispatch);
  //     }
  // }
  
  //   useEffect(() => {
  //     fetchData()
  //   }, [])

  //   const calculateTotalCoinAmount = () => {
  //     const totalCoin =
  //       (coinCount.oneP ? parseInt(coinCount.oneP) : 0) * 0.01 +
  //       (coinCount.twoP ? parseInt(coinCount.twoP) : 0) * 0.02 +
  //       (coinCount.fiveP ? parseInt(coinCount.fiveP) : 0) * 0.05 +
  //       (coinCount.tenP ? parseInt(coinCount.tenP) : 0) * 0.10 +
  //       (coinCount.TwentyP ? parseInt(coinCount.TwentyP) : 0) * 0.20 +
  //       (coinCount.fiftyP ? parseInt(coinCount.fiftyP) : 0) * 0.50 +
  //       (coinCount.onePound ? parseInt(coinCount.onePound) : 0) * 1.00 +
  //       (coinCount.twoPound ? parseInt(coinCount.twoPound) : 0) * 2.00;
  
  //     setTotalCoinAmount(totalCoin.toFixed(2));
  
  //   }
  
  //   useEffect(() => {
  //     calculateTotalCoinAmount();
  //   }, [coinCount]);
  
  //   const calculateTotalNoteAmount = () => {
  //     const totalNote =
  //       (fiftyPoundBillCount ? parseInt(fiftyPoundBillCount) : 0) * 50.00 +
  //       (twentyPoundBillCount ? parseInt(twentyPoundBillCount) : 0) * 20.00 +
  //       (tenPoundBillCount ? parseInt(tenPoundBillCount) : 0) * 10.00 +
  //       (fivePoundBillCount ? parseInt(fivePoundBillCount) : 0) * 5.00;
  
  //     setTotalNotesAmount(totalNote.toFixed(2));
  //   }
  
  //   useEffect(() => {
  //     calculateTotalNoteAmount();
  //   }, [fiftyPoundBillCount, twentyPoundBillCount, tenPoundBillCount, fivePoundBillCount]);
  
  //   const calculateTotalAmount = () => {
  //     const total =
  //       (totalCoinAmount ? parseFloat(totalCoinAmount) : 0) + (totalNotesAmount ? parseFloat(totalNotesAmount) : 0)
  
  
  //     setTotalAmount(total);
  //   }
  
  //   useEffect(() => {
  //     calculateTotalAmount();
  //   }, [totalCoinAmount, totalNotesAmount]);
  
  //   const handleSaveCashUpFrontdesk = async () => {
  //     let rand = Math.random() * 50;
  //     let str = " ";
  //     for (let i = 0; i < rand; i++) {
  //       str += " ";
  //     }
     
  //     if(currentItemFiles?.length < 1) {
  //       setType('error')
  //       setLoadMessage('At least add one file to continue' + str)
  //       return
  //     }
  //     const cashUpData = {
  //       fifty_notes: fiftyPoundBillCount,
  //       twenty_notes: twentyPoundBillCount,
  //       ten_notes: tenPoundBillCount,
  //       five_notes: fivePoundBillCount,
  //       notes: notes,
  //       files: currentItemFiles,
  //       fifty_pennies_coins: coinCount.fiftyP,
  //       twenty_pennies_coins: coinCount.TwentyP,
  //       ten_pennies_coins: coinCount.tenP,
  //       five_pennies_coins: coinCount.fiveP,
  //       two_pennies_coins: coinCount.twoP,
  //       one_pennies_coins: coinCount.oneP,
  //       one_pound_coins: coinCount.onePound,
  //       two_pound_coins: coinCount.twoPound,
  //       delivery_date: delivery_date,
  //     };
  //     if(frontdeskCashupID) {
  //       cashUpData.id = frontdeskCashupID
  //     }
  //     showLoading(layoutDispatch);
  //     try {
  //       const res = await Auth.fetch("/route/frontdesk-cashup", {
  //         method: "POST",
  //         body: JSON.stringify(cashUpData),
  //       });
  //       if (res.ack) {
  //         const uploadPromises = files?.map(async (f, idx) => {
  //           const recordID = frontdeskCashupID ? frontdeskCashupID : res?.record_id
  //           try {
  //             const uploadUrl = await getUploadUrl(f, recordID);
  //             await uploadFileToS3(uploadUrl, f.file, f.file.type);
  //           } catch (error) {
  //             console.error("Error uploading file:", error);
  //           }
  //         });
  
  //         Promise.all(uploadPromises);
  //         setLoadMessage(res.message)
  //         setType('success')
  //         fetchData()
  //       } else {
  //         setLoadMessage(res.message)
  //         setType('error')
  //       }
  //     } catch (err) {
  //       console.log(err)
  //     } finally {
  //       hideLoading(layoutDispatch);
  //     }
  
  //   };
  
    
  //   const handleSubmitImage = (e) => {
  //     e.preventDefault();
  //     let acceptedFiles = e.target.files;
  
  //     for (let file of acceptedFiles) {
  //       const randomString = Math.random().toString(36).substring(2, 10);
  //       const newFileName = `${file.name.split(".")[0]}-${randomString}.${file.name.split(".")[1]
  //         }`;
  //       setLoadMessage("");
  //       setFiles((prevFiles) => [
  //         ...prevFiles,
  //         {
  //           name: newFileName,
  //           display_name: newFileName,
  //           file: file,
  //           ContentType: file.type,
  //         },
  //       ]);
  //       setCurrentItemFiles((prevFiles) => [...prevFiles, newFileName]);
  //     }
  //   };
  
  //   const getUploadUrl = async (file, recordID) => {
  //     const params = {
  //       bucket: tms_uploads_bucket,
  //       ContentType: file.file.type,
  //       Key: env + "/frontdesk_cashup/" + recordID + "/" + file.name,
  //     };
  //     const response = await fetch(
  //       `${domain}/generate-put-url?${qs.stringify(params)}`,
  //       {
  //         headers: {
  //           "Content-Type": params.ContentType,
  //         },
  //       }
  //     );
  //     return response.json();
  //   };
  
  //   const uploadFileToS3 = async (uploadUrl, file, contentType) => {
  //     const response = await fetch(uploadUrl.putURL, {
  //       method: "PUT",
  //       body: file,
  //       headers: {
  //         "Content-Type": contentType,
  //       },
  //     });
  //     if (response.status !== 200) {
  //       throw new Error("File upload failed.");
  //     }
  //   };
  
  //   const handleDeleteFile = (fileName) => {
  //     const updatedFiles = files?.filter((file) => file.name !== fileName);
  //     setFiles(updatedFiles);
  //     setCurrentItemFiles(currentItemFiles?.filter((item) => item !== fileName));
  //   };
  
  //   const handleDeleteCurrentFile = (itemToDelete) => {
  //     setCurrentItemFiles(
  //       currentItemFiles.filter((item) => item !== itemToDelete)
  //     );
  //     if (!deletedFiles.includes(itemToDelete)) {
  //       setDeletedFiles((prevDeletedFiles) => [
  //         ...prevDeletedFiles,
  //         itemToDelete,
  //       ]);
  //     }
  //   };




    // Simple Cash Up report part

    const [disabledRoutes, setDisabledRoutes] = React.useState([]);


useEffect(() => {
  localStorage.setItem("disabledCashupRoutes", JSON.stringify(disabledRoutes));
}, [disabledRoutes]);

useEffect(() => {
  // Retrieve previously stored disabledRoutes from localStorage
  if(cashUpData?.length) {
    const storedDisabledRoutes = JSON.parse(localStorage.getItem("disabledCashupRoutes")) || [];

  const newDisabledRoutes = cashUpData?.filter(stock => stock.credit_notes_posted_by > 0)
    .map(stock => stock.routeStatusID);

  // Merge stored disabledRoutes with new ones, avoiding duplicates
  const updatedDisabledRoutes = [...new Set([...storedDisabledRoutes, ...newDisabledRoutes])];

  // Update the state and localStorage
  setDisabledRoutes(updatedDisabledRoutes);
  localStorage.setItem("disabledCashupRoutes", JSON.stringify(updatedDisabledRoutes));
  }
  
}, [cashUpData]);

  const handleInputChange = (index, fieldName, value) => {
    const updatedCashUpData = [...cashUpData];
    updatedCashUpData[index][fieldName] = value;
    setCashUpData(updatedCashUpData);
  };

  const onFinish = async (dataIndex) => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    showLoading(layoutDispatch);
    const routeStatusID = cashUpData[dataIndex].routeStatusID;
    const cash = cashUpData[dataIndex].cashReceived;
    const card = cashUpData[dataIndex].cardReceived;
    const dataToSend = {
      route_status_id: routeStatusID,
      cash_received: cash,
      card_received: card,
    };
    try {
      const response = await Auth.fetch("/route/update-amount-received-route", {
        method: "POST",
        body: JSON.stringify(dataToSend),
      });
      if (response.ack) {
        setType('success')
        setLoadMessage(response?.message +str)
        const updatedDisabledRoutes = [...disabledRoutes, routeStatusID];
            setDisabledRoutes(updatedDisabledRoutes);
            localStorage.setItem(
              "disabledCashupRoutes",
              JSON.stringify(updatedDisabledRoutes)
            );

            updateCashup(cash, card)
      } else {
        setType('error')
        setLoadMessage(response?.message + str)
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading(layoutDispatch);
    }
  };

  const itemColumns = [
    {
      title: "S No.",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (text, record, index) => index + 1,
      width: 40,
    },
    {
      title: "Code",
      dataIndex: "itemId",
      key: "itemId",
      width: 40,
    },
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      width: 250,
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      width: 75,
      align: 'center',
    },
    {
      title: "Delivered QTY",
      dataIndex: "deliveredQuantity",
      key: "deliveredQuantity",
      width: 75,
      align: 'center',
    },
    {
      title: "Status",
      align: 'center',
      render: (record) => {
        const status = deliveryStatuses.find(item => item.id === record?.deliveryStatus)
        return (
           <span>{status ? status.title : ''}</span>
        );
      },
      width: 75,
    },
  ];

  const dataCol = [
    {
      title: "S.No.",
      dataIndex: "rank",
      key: "rank",
      width: 55
    }, 
    // {
    //   title: "Shop Code",
    //   dataIndex: "customerName",
    //   key: "customerName",
    //   width: 100
    // },
    {
      title: "Shop Code",
      // dataIndex: "customerName",
      // key: "customerName",
      render: (text,record) => {
        return <span><a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${record.customerInternalId}`} target="_blank">{record.customerName}</a></span>;
      },
      width: 100
    },
    {
      title: "Shop Name",
      dataIndex: "customerProjectId",
      key: "customerProjectId",
      width: 200
    },
    // {
    //   title: "Invoice",
    //   dataIndex: "invoice_no",
    //   key: "invoice_no",
    //   width: 100
    // },
    {
      title: 'Order No.',
      render: (text, record) => {
          return (
              <span>
                  {record.invoice_no != null && record?.invoice_no != "" ? (
                      <a 
                          href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${record.invoiceInternalId}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                      >
                          {record.invoice_no}
                      </a>
                  ) : (
                      <a 
                          href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${record.internalId}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                      >
                          {record.saleOrderNo}
                      </a>
                  )}
              </span>
          );
      },
      width: 100
    },
    {
      title: "Total (£)",
      dataIndex: "orderAmount",
      key: "orderAmount",
      // render: (text, record) => {
      //   const orderAmount = Number(record.orderAmount).toLocaleString("en-GB");
      //   return `${orderAmount}`;
      // },
      render: (text, record) => Number(record.orderAmount).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) || '0.00',
      width: 100
    },
    {
      title: "Cash",
      dataIndex: "amountReceivedCash",
      key: "amountReceivedCash",
      render: (text, record) => Number(record.amountReceivedCash).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) || '0.00',
      width: 100
    },
    {
      title: "Card",
      dataIndex: "amountReceivedCard",
      key: "amountReceivedCard",
      render: (text, record) => Number(record.amountReceivedCard).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) || '0.00',
      width: 100
    },
    // {
    //   title: "Bank",
    //   dataIndex: "amountReceivedAccount",
    //   key: "amountReceivedAccount",
    //   render: (text, record) => Number(record.amountReceivedAccount).toLocaleString(undefined, {
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2
    //   }) || '0.00',
    //   width: 100
    // },
    {
      title: "Status",
      render(record) {
        let statusText;
        let backgroundColor;
        // let delivered_at = record?.delivered_at || "N/A";
        const delivered_at = record?.delivered_at && moment(record.delivered_at).isValid()
              ? moment(record.delivered_at).format('YYYY-MM-DD HH:mm:ss')
              : "N/A";
    
        switch (record?.delivered) {
          case 1:
            statusText = "Delivered";
            backgroundColor = "#80cb80";
            break;
          case 2:
            statusText = "Postponed";
            backgroundColor = "#f0d787";
            break;
            case -1:
              statusText = "Submitted";
              backgroundColor = "#1890FF";
              break;
          default:
            statusText = "Not Delivered";
            backgroundColor = "#edb1a7";
            break;
        }
    
        return {
          props: {
            style: {
              background: backgroundColor,
              textAlign: 'center',

            },
          },
          children: (
            <>
            {statusText}
            {record?.delivered === 1 && (
              <Tooltip title={`Delivered at: ${delivered_at}`}>
                <InfoCircleOutlined style={{ marginLeft: 8, color: "#000" }} />
              </Tooltip>
            )}
          </>
          ),
        };
      },
      width: 100
    },  
    {
      title: "Action",
      align: "center",
      width: 75,
      render(record) {
        return (
          // <EyeFilled
          //   style={{ cursor: "pointer", fontSize: "25px" }}
          //   onClick={() => {
          //     handleFilesOpen(record);
          //   }}
          // />
          <Badge
                  color="#1890ff"
                  dot={record?.delivery_instructions || record?.notes || record?.payment_req_notes || record?.delivery_discout_notes || record?.postponed_notes }
                >
              <EyeFilled
                style={{ cursor: "pointer", fontSize: "25px" }}
                onClick={() => {
                  handleFilesOpen(record);
                }}
              />
              </Badge>
        );
      },
    }, 
  ];

  const genExtraContent = (updatedCashUpData1) => (
    <>
    {(updatedCashUpData1[0]?.totalCash > 0 && updatedCashUpData1[0]?.dc_grand_total == 0) && (
      <div style={{ display: 'flex', alignItems: 'center'}}>
      <CloseCircleOutlined style={{ color: 'red', fontSize: '18px', marginRight: '4px'}} /><span style={{ color: 'red', fontSize: '15px'}}>Cash Up Not Found </span>
      </div>
    )
    }
    </>
  );

  // Define the values for coins and notes
const coinValues = {
  dc_one_pennies_coins: 0.01,
  dc_two_pennies_coins: 0.02,
  dc_five_pennies_coins: 0.05,
  dc_ten_pennies_coins: 0.10,
  dc_twenty_pennies_coins: 0.20,
  dc_fifty_pennies_coins: 0.50,
  dc_one_pound_coins: 1.00,
  dc_two_pound_coins: 2.00,
};

const noteValues = {
  dc_five_notes: 5,
  dc_ten_notes: 10,
  dc_twenty_notes: 20,
  dc_fifty_notes: 50,
};

// Function to calculate total coins and notes
const calculateTotals = (data) => {
  let totalCoins = 0;
  let totalNotes = 0;

  Object.keys(coinValues).forEach((key) => {
    totalCoins += (data[key] || 0) * coinValues[key];
  });

  Object.keys(noteValues).forEach((key) => {
    totalNotes += (data[key] || 0) * noteValues[key];
  });

  return {
    ...data,
    dc_change_total: totalCoins,
    dc_notes_total: totalNotes,
    dc_grand_total: totalCoins + totalNotes,
  };
};

const updatedCashUpData = cashUpData?.map(calculateTotals)

const downloadCSVFile = (id) => {
  const dataObject = cashUpData.find((item, idx) => idx === id);
  console.log('dataObject', dataObject);

  // Extract formatted_date and driver fields
  const formattedDate = dataObject.formatted_date.replace(/ /g, '-'); // Replace spaces with dashes
  const driverName = dataObject.driver.replace(/\s+/g, ''); // Remove spaces

  // Define the filename
  const filename = `${formattedDate}_${dataObject.driverRoute}_cashUp.csv`;

  // Define fields to exclude
  const excludedFields = ['expanded', 'formatted_date', 'routeStatusID'];

  // Define a mapping object to rename tableData headers
  const tableDataHeaderMapping = {
      rank: 'S No.',
      customerName: 'Shop Code',
      customerProjectId: 'Shop Name',
      invoice_no: 'Invoice No',
      orderAmount: 'Total',
      amountReceivedCash: 'Cash',
      amountReceivedCard: 'Card',
      amountReceivedAccount: 'Bank'
  };

  // Define fields to exclude from tableData
  const excludedTableDataFields = ['invoiceInternalId', 'palletOrderID', 'customerInternalId'];

  // Define the desired order for simple fields and table fields
  const simpleFieldsOrder = [
    'route', 'driver', 'truck', 'driverRoute', 'terminal', 
    'totalCash', 'totalCard', 'totalAccount', 'cardReceived', 'cashReceived'
  ];
  const tableFieldsOrder = [
    'rank', 'customerName', 'customerProjectId', 'invoice_no', 
    'orderAmount', 'amountReceivedCash', 'amountReceivedCard', 'amountReceivedAccount'
  ];

  // Filter and map the simple fields according to the desired order
  const simpleFields = simpleFieldsOrder.filter(field => dataObject.hasOwnProperty(field) && !excludedFields.includes(field));

  // Format simple fields data into CSV string
  let csvData = '';

  simpleFields.forEach(field => {
      let fieldName = field;
      let fieldValue = dataObject[field];

      if (field === 'route') fieldName = 'Route No';
      if (field === 'driver') fieldName = 'Driver';
      if (field === 'truck') fieldName = 'Truck Reg.';
      if (field === 'driverRoute') fieldName = 'Route';
      if (field === 'terminal'){
        fieldName = 'CC Terminal No.';
        // fieldValue = `C${fieldValue}`;
        fieldValue = fieldValue ? `C${fieldValue}` : ''
      } 
      if (field === 'totalCash') fieldName = 'Total Cash';
      if (field === 'totalCard') fieldName = 'Total Card';
      if (field === 'totalAccount') fieldName = 'Total Account';
      if (field === 'cardReceived') fieldName = 'Total Card Received';
      if (field === 'cashReceived') fieldName = 'Total Cash Received';

      csvData += `${fieldName},${fieldValue}\n`;
  });

  // Process tableData if it exists
  if (dataObject.tableData) {
      // Extract tableData headers
      const tableHeaders = Object.keys(dataObject.tableData[0]);
      // Map headers to their renamed counterparts and exclude unwanted fields
      const renamedHeaders = tableFieldsOrder
          .filter(header => tableHeaders.includes(header) && !excludedTableDataFields.includes(header))
          .map(header => tableDataHeaderMapping[header] || header);
      // Add tableData headers to CSV
      csvData += `${renamedHeaders.join(',')}\n`;

      // Loop through each row in tableData and add to CSV
      dataObject.tableData.forEach(row => {
          const rowData = tableFieldsOrder
              .filter(key => !excludedTableDataFields.includes(key))
              .map(key => row[key]);
          csvData += `${rowData.join(',')}\n`;
      });
  }

  // Create a Blob object from the CSV data
  const blob = new Blob([csvData], { type: 'text/csv' });

  // Create a temporary URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element to trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);

  // Append the link to the body and click it to trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up: remove the link and revoke the URL
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

const genExtra = (idx, data) => {
    
  const posted = updatedCashUpData[0]?.cashup_updated_by > 0 ? true : false;
  const completed = updatedCashUpData[0]?.start_status === 2 ? true : false;
  return (
    <div style={{ display: 'flex', alignItems: 'center'}}>
      {/* <Button
            style={{
              marginRight: '5px',
              color: completed  ? 'green' : 'red',
              cursor: 'alias',
              pointerEvents: "none",
              padding: '4px 4px'
            }}
            icon={completed ? <CheckOutlined/> : <CloseOutlined />}
              >
               Route Completed
          </Button> */}
          <Button
            style={{
              marginRight: '5px',
              color: posted || disabledRoutes.includes(updatedCashUpData[0].routeStatusID) ? 'green' : 'red',
              cursor: 'alias',
              pointerEvents: "none",
              padding: '4px 4px'
            }}
            icon={posted || disabledRoutes.includes(updatedCashUpData[0].routeStatusID) ? <CheckOutlined/> : <CloseOutlined />}
              >
                Warehouse Cashup
          </Button>
        
          <Button
            style={{
              marginRight: '5px',
              color: updatedCashUpData[0]?.cashup_signature ? 'green' : 'red',
              cursor: 'alias',
              pointerEvents: "none",
              padding: '4px 4px'
            }}
            icon={updatedCashUpData[0]?.cashup_signature ? <CheckOutlined/> : <CloseOutlined />}
              >
                Driver Cashup
          </Button>
        
  <Tooltip title="Download">
    <CloudDownloadOutlined
    style={{ fontSize: '25px', color: '#1677FF'}}
    onClick={(e) => {
      // e.stopPropagation();
      downloadCSVFile(0);
    }}
     />
     </Tooltip>
  </div>
  )
};

const rowClassName = (record) => {
  const status = deliveryStatuses.find(item => item.id === record?.deliveryStatus);
  if (status?.title !== 'Delivered') {
    return 'highlight-row';
  }
  return '';
};
      
    
  return (
    <>
    <div className={classes.dataSheet}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
        <div style={{padding: '5px'}}>{genExtra()}</div>
      </div>
      
      {/* <Collapse style={{ margin: "10px 0px" }} >
      <Panel
          header={
            <>
              <span style={{ fontWeight: "bold" }}>
                Front Desk Cash Up
              </span>
            </>
          }
       extra={genExtraFrontCashUp()}>
                      
      <div style={{ display: "flex", justifyContent: "space-between" }}>

<h2 ></h2>
<div>
  <Row gutter={[10, 16]}>
    <Card style={{ backgroundColor: "#f0f0f0", width: '150px', marginRight: '10px' }}>
      <Statistic
        title="Total Notes Amount"
        value={"£" + totalNotesAmount}
        precision={2}
        valueStyle={{
          color: "#3f8600",
        }}
      // prefix="£"
      />
    </Card>
    <Card style={{ backgroundColor: "#f0f0f0", width: '150px', marginRight: '10px' }}>
      <Statistic
        title="Total Coin Amount"
        value={"£" + totalCoinAmount}
        precision={2}
        valueStyle={{
          color: "#3f8600",
        }}
      // prefix="£"
      />
    </Card>

    <Card style={{ backgroundColor: "#f0f0f0", width: '150px' }}>
      <Statistic
        style={{ "&.ant-statistic .ant-statistic-title": { fontSize: '16px !important' } }}
        title="Total Amount"
        value={"£" + totalAmount.toFixed(2)}
        precision={2}
        valueStyle={{
          color: "#3f8600",
        }}
      // prefix="£"
      />
    </Card>

  </Row>
  </div>
  </div>

  <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            £50 Notes
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter £50 Count"
              type="number"
              onChange={(e) => setFiftyPoundBillCount(e.target.value)}
              value={fiftyPoundBillCount}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total £50 Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="£50 Amount"
              disabled
              // type="number"
              // value={(fiftyPoundBillCount ? parseInt(fiftyPoundBillCount) * 50 : 0).toFixed(2)}
              value={"£" + (fiftyPoundBillCount ? parseInt(fiftyPoundBillCount) * 50.00 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            £20 Notes
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter £20 Count"
              type="number"
              onChange={(e) => setTwentyPoundBillCount(e.target.value)}
              value={twentyPoundBillCount}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total £20 Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="£20 Amount"
              disabled
              // type="text"
              value={"£" + (twentyPoundBillCount ? parseInt(twentyPoundBillCount) * 20.00 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            £10 Notes
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter £10 Count"
              type="number"
              onChange={(e) => setTenPoundBillCount(e.target.value)}
              value={tenPoundBillCount}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total £10 Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="£10 Amount"
              disabled
              // type="number"
              value={"£" + (tenPoundBillCount ? parseInt(tenPoundBillCount) * 10.00 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            £5 Notes
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter £5 Count"
              type="number"
              onChange={(e) => setFivePoundBillCount(e.target.value)}
              value={fivePoundBillCount}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total £5 Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="£5 Amount"
              disabled
              // type="number"
              value={"£" + (fivePoundBillCount ? parseInt(fivePoundBillCount) * 5.00 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            £2 Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter £2 Count"
              type="number"
              value={coinCount.twoPound}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  twoPound: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total £2 Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="£2 Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.twoPound ? parseInt(coinCount.twoPound) * 2.00 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            £1 Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter £1 Count"
              type="number"
              value={coinCount.onePound}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  onePound: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total £1 Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="£1 Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.onePound ? parseInt(coinCount.onePound) * 1.00 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            50p Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter 50p Count"
              type="number"
              value={coinCount.fiftyP}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  fiftyP: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total 50p Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="50p Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.fiftyP ? parseInt(coinCount.fiftyP) * 0.50 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            20p Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter 20p Count"
              type="number"
              value={coinCount.TwentyP}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  TwentyP: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total 20p Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="20p Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.TwentyP ? parseInt(coinCount.TwentyP) * 0.20 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            10p Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter 10p Count"
              type="number"
              value={coinCount.tenP}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  tenP: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total 10p Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="10p Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.tenP ? parseInt(coinCount.tenP) * 0.10 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            5p Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter 5p Count"
              type="number"
              value={coinCount.fiveP}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  fiveP: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total 5p Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="5p Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.fiveP ? parseInt(coinCount.fiveP) * 0.05 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            2p Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter 2p Count"
              type="number"
              value={coinCount.twoP}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  twoP: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total 2p Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="2p Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.twoP ? parseInt(coinCount.twoP) * 0.02 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            1p Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter 1p Count"
              type="number"
              value={coinCount.oneP}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  oneP: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total 1p Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="1p Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.oneP ? parseInt(coinCount.oneP) * 0.01 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>

      <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>Notes</h4>
      <div style={{ marginBottom: "10px" }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <TextArea
              autoSize={{ minRows: 6, maxRows: 12 }}
              label="Notes"
              placeholder="Enter Any Specific Notes"
              onChange={(e) => setNotes(e.target.value)}
              value={notes}
            />
          </Col>
        </Row>
      </div>
      <Divider />
     
      
      <Divider />
      <div style={{ display: 'flex'}}>
        <UploadButton
          component="label"
          variant="contained"
          sx={{ marginTop: "10px" }}
          startIcon={<CloudUploadIcon />}
        >
          Add files
          <input
            type="file"
            hidden
            multiple
            capture
            accept="image/*, video/*"
            onChange={(e) => {
              handleSubmitImage(e);
            }}
          />
        </UploadButton>
        <Alert style={{ width: '180px', marginTop: "10px", marginLeft: "5px"}} message={'Images are required'} type="info" showIcon/>
      </div>
      {currentItemFiles?.length > 0 && <h4>Current Files:</h4>}
      <Image.PreviewGroup>
        {currentItemFiles?.length > 0 &&
          currentItemFiles
            .filter((item) => !files?.some((file) => file.name === item))
            .map((item) => {
              const imageUrl = `${tms_uploads_cdn}/${env}/frontdesk_cashup/${frontdeskCashupID}/${item}`;
              return (
                <div
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    display: "inline-block",
                  }}
                >
                  <Image
                    width={200}
                    height={150}
                    style={{ padding: "5px", border: "1px solid lightgrey" }}
                    src={imageUrl}
                  />
                  <Tooltip title="Delete">
                    <CloseOutlined
                      style={{
                        fontSize: "30px",
                        position: "absolute",
                        right: 5,
                        top: 5,
                        color: "#c12828",
                      }}
                      onClick={() => handleDeleteCurrentFile(item)}
                    />
                  </Tooltip>
                </div>
              );
            })}
      </Image.PreviewGroup>
      <Divider />
      {files?.length > 0 && <h4>New Files:</h4>}
      <Image.PreviewGroup>
        {files?.length > 0 &&
          files?.map((files) => {
            return (
              <div
                style={{
                  position: "relative",
                  marginRight: "10px",
                  display: "inline-block",
                }}
              >
                <Image
                  width={200}
                  height={150}
                  style={{ padding: "5px", border: "1px solid lightgrey" }}
                  src={URL.createObjectURL(files.file)}
                />
                <Tooltip title="Delete">
                  <CloseOutlined
                    style={{
                      fontSize: "30px",
                      position: "absolute",
                      right: 5,
                      top: 5,
                      color: "#c12828",
                    }}
                    onClick={() => handleDeleteFile(files.name)}
                  />
                </Tooltip>
              </div>
            );
          })}
      </Image.PreviewGroup>
      {files?.length > 0 && <Divider />}

      <div
        style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
      >
        
        <Button
          type="primary"
          size="large"
          onClick={handleSaveCashUpFrontdesk}
        >
          Save
        </Button>
      </div>


                      
                    </Panel>
        </Collapse> */}

<Row gutter={[6, 16]} style={{ marginTop: "10px" }}>
                    <Col span={12}>
                    <Row
    gutter={[4, 4]}
    style={{
      background: "#f0f2f5",
      padding: "4px",
      borderRadius: "8px",
    }}
  >
    {/* Cash Section */}
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
      <div
        style={{
          background: "#ffffff",
          padding: "4px",
          borderRadius: "8px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
          border: '2px solid #b1aeae'
        }}
      >
        <Row gutter={[6, 6]}>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Total Cash"
                value={formatCurrency(updatedCashUpData[0]?.totalCash)}
                valueStyle={{
                  color: "#1677FF",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Cash Received"
                value={formatCurrency(updatedCashUpData[0]?.cashReceived)}

                valueStyle={{
                  color: "#1677FF"
                  
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Difference"
                value={formatCurrency(updatedCashUpData[0]?.cashReceived - updatedCashUpData[0]?.totalCash)}
                valueStyle={{
                  color: updatedCashUpData[0]?.cashReceived - updatedCashUpData[0]?.totalCash > 0 
                ? "green" 
                : updatedCashUpData[0]?.cashReceived - updatedCashUpData[0]?.totalCash === 0 
                ? "blue" 
                : "red"

                  
                }}
              />
            </Card>
          </Col>
          
        </Row>
      </div>
    </Col>

    {/* Card Section */}
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
      <div
        style={{
          background: "#ffffff",
          padding: "4px",
          borderRadius: "8px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
          border: '2px solid #b1aeae'

        }}
      >
        <Row gutter={[6, 6]}>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Total Card"
                value={formatCurrency(updatedCashUpData[0]?.totalCard)}
                valueStyle={{
                  color: "#1677FF",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Card Received"
                value={formatCurrency(updatedCashUpData[0]?.cardReceived)}

                valueStyle={{
                  color: "#1677FF"
                  
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Difference"
                value={formatCurrency(updatedCashUpData[0]?.cardReceived - updatedCashUpData[0]?.totalCard)}
                valueStyle={{
                  color: updatedCashUpData[0]?.cardReceived - updatedCashUpData[0]?.totalCard > 0 
                ? "green" 
                : updatedCashUpData[0]?.cardReceived - updatedCashUpData[0]?.totalCard === 0 
                ? "#1677FF" 
                : "red"
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Col>
                   </Row>
                    </Col> 
                <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'end'}} span={12}>
                <div   >
                <Space gutter={16}>
                  {/* <Col span={8}> */}
                    <Text>Cash Received</Text>
                    <Input
                      type="number"
                      name="cash"
                      style={{
                        width: "100%",
                      }}
                      placeholder="Cash received"
                      value={updatedCashUpData[0]?.cashReceived ? updatedCashUpData[0]?.cashReceived : null}
                      onChange={(e) =>
                        handleInputChange(0, "cashReceived", e.target.value)
                      }
                    />
                  {/* </Col> */}
                  {/* <Col span={8}> */}
                    <Text>Card Received</Text>
                    <Input
                      name="card"
                      type="number"
                      style={{
                        width: "100%",
                      }}
                      placeholder="Card received"
                      value={updatedCashUpData[0]?.cardReceived ? updatedCashUpData[0]?.cardReceived : null}
                      onChange={(e) =>
                        handleInputChange(0, "cardReceived", e.target.value)
                      }
                    />
                  {/* </Col> */}
                  {/* <Col span={8}> */}
                    <Button  type="primary" onClick={() => onFinish(0)}>
                      Save
                    </Button>
                  {/* </Col> */}
                </Space>
              </div>
                </Col>
                  </Row>
              
              <Collapse style={{ margin: "10px 0px" }} >
                    <Panel
                      header={
                        <>
                          <span style={{ fontWeight: "bold" }}>
                            Cash Up Details
                          </span>
                        </>
                      }
                      extra={genExtraContent(updatedCashUpData)}
                    >
                      <Descriptions bordered size={'small'} >
                      <Descriptions.Item label={`£50`}> Count: {updatedCashUpData[0]?.dc_fifty_notes || 0} 
                      <br/>
                      Total: {"£" +(updatedCashUpData[0]?.dc_fifty_notes ? parseInt(updatedCashUpData[0]?.dc_fifty_notes) * 50.00 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£20`}> Count: {updatedCashUpData[0]?.dc_twenty_notes || 0} 
                      <br/>
                      Total: {"£" +(updatedCashUpData[0]?.dc_twenty_notes ? parseInt(updatedCashUpData[0]?.dc_twenty_notes) * 20.00 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£10`}> Count: {updatedCashUpData[0]?.dc_ten_notes || 0} 
                      <br/>
                      Total: {"£" +(updatedCashUpData[0]?.dc_ten_notes ? parseInt(updatedCashUpData[0]?.dc_ten_notes) * 10.00 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£5`}> Count: {updatedCashUpData[0]?.dc_five_notes || 0} 
                      <br/>
                      Total: {"£" +(updatedCashUpData[0]?.dc_five_notes ? parseInt(updatedCashUpData[0]?.dc_five_notes) * 5.00 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£2`}> Count: {updatedCashUpData[0]?.dc_two_pound_coins || 0} 
                      <br/>
                      Total: {"£" +(updatedCashUpData[0]?.dc_two_pound_coins ? parseInt(updatedCashUpData[0]?.dc_two_pound_coins) * 2.00 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£1`}> Count: {updatedCashUpData[0]?.dc_one_pound_coins || 0} 
                      <br/>
                      Total: {"£" +(updatedCashUpData[0]?.dc_one_pound_coins ? parseInt(updatedCashUpData[0]?.dc_one_pound_coins) * 1.00 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£50p`}> Count: {updatedCashUpData[0]?.dc_fifty_pennies_coins || 0} 
                      <br/>
                      Total: {"£" +(updatedCashUpData[0]?.dc_fifty_pennies_coins ? parseInt(updatedCashUpData[0]?.dc_fifty_pennies_coins) * 0.50 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£20p`}> Count: {updatedCashUpData[0]?.dc_twenty_pennies_coins || 0} 
                      <br/>
                      Total: {"£" +(updatedCashUpData[0]?.dc_twenty_pennies_coins ? parseInt(updatedCashUpData[0]?.dc_twenty_pennies_coins) * 0.20 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£10p`}> Count: {updatedCashUpData[0]?.dc_ten_pennies_coins || 0} 
                      <br/>
                      Total: {"£" +(updatedCashUpData[0]?.dc_ten_pennies_coins ? parseInt(updatedCashUpData[0]?.dc_ten_pennies_coins) * 0.10 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£5p`}> Count: {updatedCashUpData[0]?.dc_five_pennies_coins || 0} 
                      <br/>
                      Total: {"£" +(updatedCashUpData[0]?.dc_five_pennies_coins ? parseInt(updatedCashUpData[0]?.dc_five_pennies_coins) * 0.05 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£2p`}> Count: {updatedCashUpData[0]?.dc_two_pennies_coins || 0} 
                      <br/>
                      Total: {"£" +(updatedCashUpData[0]?.dc_two_pennies_coins ? parseInt(updatedCashUpData[0]?.dc_two_pennies_coins) * 0.02 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£1p`}> Count: {updatedCashUpData[0]?.dc_one_pennies_coins || 0} 
                      <br/>
                      Total: {"£" +(updatedCashUpData[0]?.dc_one_pennies_coins ? parseInt(updatedCashUpData[0]?.dc_one_pennies_coins) * 0.01 : 0).toFixed(2)}</Descriptions.Item>

                      </Descriptions>

                      <Row gutter={[6, 16]}>
                       <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
                       Total Coin
                        </h4>
                       <Input
                        placeholder=" Total Coins Amount"
                        disabled
                        type="number"
                        value={ updatedCashUpData[0]?.dc_change_total ? updatedCashUpData[0]?.dc_change_total.toFixed(2) : 0}
                        />
                       </Col>
                       <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
                       Total Notes
                        </h4>
                       <Input
                        placeholder=" Total Notes Amount"
                        disabled
                        type="number"
                        value={updatedCashUpData[0]?.dc_notes_total ? updatedCashUpData[0]?.dc_notes_total.toFixed(2) : 0}
                        />
                       </Col>
                       <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
                       Grand Total
                        </h4>
                       <Input
                        placeholder=" Total Amount"
                        disabled
                        type="number"
                        value={updatedCashUpData[0]?.dc_grand_total ? updatedCashUpData[0]?.dc_grand_total.toFixed(2) : 0}
                        />
                       </Col>
                      </Row>
                      <Row>
                      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
                       Notes
                        </h4>
                       <TextArea
                       autoSize
                        placeholder="Notes"
                        disabled
                        // type="number"
                        value={updatedCashUpData[0]?.dc_notes}
                        />
                       </Col>
                      </Row>
                      {updatedCashUpData[0]?.dc_files?.length > 0 && <h4>Current Files:</h4>}
                      <br />
                      <Image.PreviewGroup>
                        {updatedCashUpData[0]?.dc_files?.length > 0 &&
                          updatedCashUpData[0]?.dc_files
                            .map((item) => {
                              const imageUrl = `${tms_uploads_cdn}/${
                                env
                              }/cashup/${updatedCashUpData[0]?.routeStatusID}/${item}`;
                              return (
                                <div
                                  style={{
                                    position: "relative",
                                    marginRight: "10px",
                                    display: "inline-block",
                                  }}
                                >
                                  <Image
                                    width={200}
                                    height={150}
                                    style={{
                                      padding: "5px",
                                      border: "1px solid lightgrey",
                                    }}
                                    src={imageUrl}
                                  />
                                </div>
                              );
                            })}
                      </Image.PreviewGroup>
                      <Divider />
                      
                    </Panel>
                  </Collapse>

                  <>
                <Table
                  style={{ overflowX: "auto", marginTop: "10px" }}
                  columns={dataCol}
                  bordered
                  dataSource={updatedCashUpData[0]?.tableData}
                  pagination={false}
                  // scroll={{
                  //   y: 300,
                  // }}
                />
              </>
      {/* <Table
                  style={{ overflowX: "auto", marginTop: "10px" }}
                  columns={dataCol}
                  bordered
                  dataSource={cashUpData[0]?.tableData}
                  pagination={false}
                  scroll={{
                      y: 400,
                    }}
                    /> */}
    </div>
    <DetailsModal filesOpen={filesOpen} handleFilesClose={handleFilesClose} selectedRecord={selectedRecord} itemColumns={itemColumns} getStatusText={getStatusText} paymentAuthorizer={paymentAuthorizer} classes={classes} rowClassName={rowClassName}/>

    {/* <Modal
        open={filesOpen}
        onCancel={handleFilesClose}
        okButtonProps={{ style: { display: "none" } }}
        title="Order Details"
        cancelText="Close"
        width={'90vw'}
        style={{
          top: 20,
          height: '90vh',
          overflowY: 'auto'
        }}
      >
        <>
  {selectedRecord &&
  (
    selectedRecord.postponed_status ||
    (selectedRecord.postponed_files && selectedRecord.postponed_files.length > 0) ||
    selectedRecord.postponed_notes ||
    selectedRecord.received_by ||
    selectedRecord.notes ||
    selectedRecord.delivery_discount_req_status ||
    selectedRecord.delivery_discount_req_notes ||
    selectedRecord.delivery_discount_auth_by ||
    selectedRecord.payment_req_status ||
    selectedRecord.payment_req_notes ||
    selectedRecord.payment_auth_by ||
    selectedRecord.signature ||
    (selectedRecord.files && selectedRecord.files.length > 0) ||
    selectedRecord?.items ||
    selectedRecord?.delivery_Instructions
  ) ? (
    <>
    {selectedRecord?.customerProjectId ? (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Shop Name"
            labelStyle={{ width: "200px" }}
          >
            {selectedRecord?.customerProjectId || ""}
          </Descriptions.Item>
        </Descriptions>
       ) : null}
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Order No."
            labelStyle={{ width: "200px" }}
          >
            <span>
                    {selectedRecord?.invoice_no != null && selectedRecord?.invoice_no != "" ? (
                        <a 
                            href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${selectedRecord?.invoiceInternalId}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            {selectedRecord?.invoice_no}
                        </a>
                    ) : (
                        <a 
                            href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${selectedRecord?.internalId}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            {selectedRecord?.saleOrderNo}
                        </a>
                    )}
                </span>
          </Descriptions.Item>
        </Descriptions>
       {selectedRecord?.customerName ? (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Customer Code"
            labelStyle={{ width: "200px" }}
          >
            <span><a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${selectedRecord.customerInternalId}`} target="_blank">{selectedRecord.customerName}</a></span>
          </Descriptions.Item>
        </Descriptions>
       ) : null}
       {selectedRecord?.amountReceivedCash > 0 ? (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Amount Received Cash"
            labelStyle={{ width: "200px" }}
          >
            {formatCurrency(selectedRecord?.amountReceivedCash)}
          </Descriptions.Item>
        </Descriptions>
       ) : null}
       {selectedRecord?.amountReceivedCard > 0 ? (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Amount Received Cash"
            labelStyle={{ width: "200px" }}
          >
            {formatCurrency(selectedRecord?.amountReceivedCard)}
          </Descriptions.Item>
        </Descriptions>
       ) : null}
      {selectedRecord?.postponed_status ? (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Postponement Status"
            labelStyle={{ width: "200px" }}
          >
            {PostponeReasons.find((obj) => obj.id === selectedRecord.postponed_status)?.title || ""}
          </Descriptions.Item>
        </Descriptions>
      ) : null}

      {selectedRecord?.postponed_notes && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item label="Postpone Notes" labelStyle={{ width: "200px" }}>
            {selectedRecord.postponed_notes || ""}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.notes && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item label="Driver Notes" labelStyle={{ width: "200px" }}>
            {selectedRecord.notes || ""}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.delivery_instructions && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item label="Delivery Notes" labelStyle={{ width: "200px" }}>
            {selectedRecord.delivery_instructions || ""}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.delivery_discount_req_status && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Delivery Discount"
            labelStyle={{ width: "200px" }}
          >
            {getStatusText(selectedRecord.delivery_discount_req_status)}
          </Descriptions.Item>
        </Descriptions>
      )}

    {selectedRecord?.delivery_discount_req_notes && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Delivery Discount Notes"
            labelStyle={{ width: "200px" }}
          >
            {selectedRecord.delivery_discount_req_notes}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.delivery_discount_auth_by && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Delivery Auth By"
            labelStyle={{ width: "200px" }}
          >
            {(() => {
              const authorizer = paymentAuthorizer?.find(
                (obj) =>
                  obj?.id?.toString() ===
                  selectedRecord?.delivery_discount_auth_by?.toString()
              );
              return `${authorizer?.first_name || ""} ${authorizer?.last_name || ""}`;
            })()}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.payment_req_status && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Payment Discount"
            labelStyle={{ width: "200px" }}
          >
            {getStatusText(selectedRecord.payment_req_status)}
          </Descriptions.Item>
        </Descriptions>
      )}
      {selectedRecord?.payment_req_notes && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Payment Discount Notes"
            labelStyle={{ width: "200px" }}
          >
            {selectedRecord.payment_req_notes}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.payment_auth_by > 0 && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Payment Auth By"
            labelStyle={{ width: "200px" }}
          >
            {(() => {
              const authorizer = paymentAuthorizer?.find(
                (obj) => obj?.id === selectedRecord?.payment_auth_by
              );
              return `${authorizer?.first_name || ""} ${authorizer?.last_name || ""}`;
            })()}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.received_by && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Receiver Name"
            labelStyle={{ width: "200px" }}
          >
            {selectedRecord.received_by || ""}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.signature != null && (
        <>
          <h4>Signature:</h4>
          <div
            style={{
              position: "relative",
              marginRight: "10px",
              display: "inline-block",
            }}
          >
            <Image
              width={150}
              height={100}
              style={{ padding: "5px", border: "1px solid lightgrey" }}
              src={selectedRecord?.signature}
            />
          </div>
        </>
      )}

      <Divider />

      {selectedRecord?.files?.length > 0 && (
        <>
          <h4>Files</h4>
          <Image.PreviewGroup>
            {selectedRecord.files.map((item) => {
              const imageUrl = `${tms_uploads_cdn}/${env}/route_orders/${selectedRecord?.routeStatusID}/${item}`;
              return (
                <div
                  key={item}
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    display: "inline-block",
                  }}
                >
                  <Image
                    width={200}
                    height={150}
                    style={{ padding: "5px", border: "1px solid lightgrey" }}
                    src={imageUrl}
                  />
                </div>
              );
            })}
          </Image.PreviewGroup>
        </>
      )}
      {selectedRecord?.postponed_files?.length > 0 && (
        <>
          <h4>Postpone Files</h4>
          <Image.PreviewGroup>
            {selectedRecord.postponed_files.map((item) => {
              const imageUrl = `${tms_uploads_cdn}/${env}/postpone_order/${selectedRecord?.routeStatusID}/${item}`;
              return (
                <div
                  key={item}
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    display: "inline-block",
                  }}
                >
                  <Image
                    width={200}
                    height={150}
                    style={{ padding: "5px", border: "1px solid lightgrey" }}
                    src={imageUrl}
                  />
                </div>
              );
            })}
          </Image.PreviewGroup>
        </>
      )}
      <h2>Items</h2>
      <div className={classes.antTable}>
          <Table
          columns={itemColumns}
          dataSource={selectedRecord?.items?.filter(
            (item) => item.itemId !== "Delivery Charge"
          )}
          pagination={false}
          style={{ overflowX: "auto", marginTop: "10px", marginBottom: "20px" }}
          scroll={{
            y: 250,
          }}
          rowClassName={rowClassName}
          footer={() => {
            const deliveryChargeItem = selectedRecord?.items?.find(
              (item) => item.itemId === "Delivery Charge"
            );
            return deliveryChargeItem
              ? (
                <div>
                  {
                    deliveryChargeItem.grossAmountIncludingTax > 0 && (
                <Tag style={{  height: '40px', alignContent: 'center', fontWeight: 400 }} color="grey">
                      <span style={{fontSize: '18px'}}>{`Delivery Charges: ${'£' + deliveryChargeItem.grossAmountIncludingTax}`}
                    <span style={{fontSize: '14px', fontWeight: 'bolder'}}> Incl. VAT</span>
                  </span>
                  </Tag>
                    )
                  }
                </div>
              ) : (
                <Tag color="grey" style={{ fontSize: '18px', height: '40px', alignContent: 'center', fontWeight: 400 }}>No Delivery Charges</Tag>
              )
          }}
        />
        </div>
    </>
  ) : (
    <Result status="404" subTitle="Sorry, no data data to show." />
  )}
</>
      </Modal> */}
    <Message type={type} msg={loadMessage} />

                    </>
  )
}

export default CashupTab
