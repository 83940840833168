import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Card, Col, Divider, Row, Select, Table, Switch, Button, Tag, Tooltip, Input, DatePicker, message, Popconfirm, List, Statistic, Modal } from "antd";
import { makeStyles } from "@mui/styles";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import moment from "moment";
import { hideLoading, showLoading, useLoadingDispatch } from '../../context/loadingContext';
import { Pagination } from "@mui/material";
import { InventoryServices, issuePriorities, VATPercentages } from "../../Components/common/constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from 'dayjs';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button as UploadButton, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { extractOriginalFileName, formatCurrency, getNewFileName } from '../../Components/common/CommonFun';
import { CloudDownloadOutlined, DeleteFilled, DeleteOutlined, ExportOutlined, LinkOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { domain, env, tms_uploads_bucket, tms_uploads_cdn } from '../../Components/config';
import qs from 'query-string';
import jsPDF from "jspdf";
import { set } from 'lodash';


const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: "5px",
    "& .ant-input[disabled]": {
      background: "none",
      color: "black",
    },
    "& .ant-card .ant-card-body": {
      padding: "4px",
      backgroundColor: "rgb(240, 240, 240)",
    },
    "& .ant-card .ant-card-head": {
      padding: "0px 4px",
      // backgroundColor: "rgb(240, 240, 240)",
      minHeight: '40px'
    },
  },
}));

const WorkOrder = () => {
const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const navigate = useNavigate();
  const params = useParams();
  
  const location = useLocation();
  const issueConversionData = location?.state?.conversionData;
  console.log('issueConversionData', issueConversionData);
  const { Option } = Select;
  const { TextArea } = Input;
  const [id, setId] = React.useState(params.id ? params.id : 0);
  
  
  const [loadMessage, setLoadMessage] = useState("");
  const [type, setType] = useState("");
  const [workOrderDetail, setWorkOrderDetail] = useState({
    work_order_code: "",
    date: new Date(),
    due_date: null,
    notes: "",
    priority: null,
    vehicle: null,
    description: "",
    assignedTo: null,
    purchase_order_id: null,
    issue_id: null,
  });
  const [files, setFiles] = useState([]);
  const [currentItemFiles, setCurrentItemFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = React.useState([]);

  const [orderItems, setOrderItems] = useState([]);
  const [serviceUsed, setServiceUsed] = useState([]);
  const [users, setUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [allPurchaseOrders, setAllPurchaseOrders] = useState([]);
  const [allIssues, setAllIssues] = useState([]);

  const getAllIssues = async () => {
    showLoading(layoutDispatch);
    try {
      const response = await Auth.fetch(`/issue/issues`);
      if (response) {
        hideLoading(layoutDispatch);
        if (response?.ack) {
          setAllIssues(response?.issues);
        } else {
          setAllIssues([])
        }
      }
    } catch (err) {
      console.log(err);
    } finally{
      hideLoading(layoutDispatch)
    }
  }

  const getAllPurchaseOrders = async () => {
    showLoading(layoutDispatch);
    try {
      const response = await Auth.fetch(`/inventory/purchase-orders`);
      if (response) {
        hideLoading(layoutDispatch);
        if (response?.ack) {
          setAllPurchaseOrders(response?.purchase_orders);
        } else {
          setAllPurchaseOrders([])
        }
      }
    } catch (err) {
      console.log(err);
    } finally{
      hideLoading(layoutDispatch)
    }
  }

  const getAllItems = async () => {
    showLoading(layoutDispatch);
    try {
      const response = await Auth.fetch(`/inventory/inventory-items`);
      if (response) {
        hideLoading(layoutDispatch);
        if (response?.ack) {
          setAllItems(response?.inventory_items);
        } else {
          setAllItems([])
        }
      }
    } catch (err) {
      console.log(err);
    } finally{
      hideLoading(layoutDispatch)
    }
  }

    const getAssignees = async () => {
      showLoading(layoutDispatch);
      try {
        const response = await Auth.fetch(`/issue/issues-assignees`);
        if (response) {
          hideLoading(layoutDispatch);
          if (response?.ack) {
            setUsers(response.issue_assignees);
          } else {
            setUsers([])
          }
        }
      } catch (err) {
        console.log(err);
      } finally{
        hideLoading(layoutDispatch)
      }
    }
    const getAllVehicles = async () => {
      showLoading(layoutDispatch);
      try {
        const response = await Auth.fetch(`/vehicle/vehicles`);
        if (response) {
          hideLoading(layoutDispatch);
          if (response?.ack) {
            setVehicles(response.vehicles);
          } else {
            setVehicles([])
          }
        }
      } catch (err) {
        console.log(err);
      } finally{
        hideLoading(layoutDispatch)
      }
    }

    useLayoutEffect(() => {
      getAssignees();
      getAllVehicles();
      getAllItems();
      getAllPurchaseOrders();
      getAllIssues();
    }, [])
  

  const handleSubmitImage = (e) => {
    e.preventDefault();
    let acceptedFiles = e.target.files;

    for (let file of acceptedFiles) {
      const randomString = Math.random().toString(36).substring(2, 10);
      const newFileName = getNewFileName(file.name)
      setFiles((prevFiles) => [
        ...prevFiles,
        {
          name: newFileName,
          display_name: newFileName,
          file: file,
          ContentType: file.type,
        },
      ]);
      setCurrentItemFiles((prevFiles) => [...prevFiles, newFileName]);
    }
  };

  const handleDeleteFile = (fileName) => {
    const updatedFiles = files?.filter((file) => file.name !== fileName);
    setFiles(updatedFiles);
    setCurrentItemFiles(currentItemFiles?.filter((item) => item !== fileName));
  };

  const handleDeleteCurrentFile = (itemToDelete) => {
    setCurrentItemFiles(
      currentItemFiles.filter((item) => item !== itemToDelete)
    );
    if (!deletedFiles.includes(itemToDelete)) {
      setDeletedFiles((prevDeletedFiles) => [
        ...prevDeletedFiles,
        itemToDelete,
      ]);
    }
  };

  const handleAddItem = () => {
    const newItem = {
      work_order_item: null,
      vat_percentage: 2,
      order_item_quantity: "",
      order_item_unit_price: "",
      order_item_vat: "",
      order_item_total: "",
    };
    setOrderItems([...orderItems, newItem]);
  };

  const handleAddService = () => {
    const newService = {
      name: null,
      hoursWorked: "",
      hourlyRate: "",
      totalServiceCost: "",
      vat_percentage: 2,
      service_vat: "",

    };
    setServiceUsed([...serviceUsed, newService]);
  };

  const handleRemoveItem = async (index, item) => {
      const list = [...orderItems];
      list.splice(index, 1);
      setOrderItems(list);
    };

    const handleRemoveService = async (index, item) => {
      const list = [...serviceUsed];
      list.splice(index, 1);
      setServiceUsed(list);
    };

    const handleInputChange = (index, event) => {
      const { name, value } = event.target;
      let newValue = value;
    
      // Update the order item based on user input
      const list = [...orderItems];
      list[index][name] = newValue;
    
      // Calculate VAT and total if the changed field is related to quantity or unit price
      const item = list[index];
      const quantity = parseFloat(item.order_item_quantity || 0);
      const unitPrice = parseFloat(item.order_item_unit_price || 0);
    
      // Calculate VAT amount (quantity * unit price)
      const vatAmount = quantity * unitPrice;
    
      // Calculate VAT based on the selected VAT percentage
      const vat_percentageItem = VATPercentages.find((vatItem) => vatItem.id == item.vat_percentage)
      console.log('vat_percentageItem', vat_percentageItem, item.vat_percentage);
      
      const vatPercentage = Number(vat_percentageItem?.percent || 0) / 100;
      const calculatedVat = vatAmount * vatPercentage;
    
      // Calculate the total (quantity * unit price) + VAT
      const total = vatAmount + calculatedVat;
    
      // Update the list with the calculated values
      list[index].order_item_vat = calculatedVat.toFixed(2); // store VAT value
      list[index].order_item_total = total.toFixed(2); // store total value
    
      // Update the state with the new list
      setOrderItems(list);
    };

    const handleInputChangeLabour = (index, event) => {
      const { name, value } = event.target;
      let newValue = value;
    
      // Update the order item based on user input
      const list = [...serviceUsed];
      list[index][name] = newValue;
    
      // Calculate VAT and total if the changed field is related to quantity or unit price
      const item = list[index];
      const quantity = parseFloat(item.hoursWorked || 0);
      const unitPrice = parseFloat(item.hourlyRate || 0);
    
      // Calculate VAT amount (quantity * unit price)
      const vatAmount = quantity * unitPrice;
    
      // Calculate VAT based on the selected VAT percentage
      const vat_percentageItem = VATPercentages.find((vatItem) => vatItem.id == item.vat_percentage)
      console.log('vat_percentageItem', vat_percentageItem, item.vat_percentage);
      
      const vatPercentage = Number(vat_percentageItem?.percent || 0) / 100;
      const calculatedVat = vatAmount * vatPercentage;
    
      // Calculate the total (quantity * unit price) + VAT
      const total = vatAmount + calculatedVat;
    
      // Update the list with the calculated values
      list[index].service_vat = calculatedVat.toFixed(2); // store VAT value
      list[index].totalServiceCost = total.toFixed(2); // store total value
    
      // Update the state with the new list
      setServiceUsed(list);
    };

  

    console.log('name order Items', orderItems);

    const getUploadUrl = async (file, recordID) => {
      const params = {
        bucket: tms_uploads_bucket,
        ContentType: file.file.type,
        Key: env + "/work-orders/" + recordID + "/" + file.name,
      };
      const response = await fetch(
        `${domain}/generate-put-url?${qs.stringify(params)}`,
        {
          headers: {
            "Content-Type": params.ContentType,
          },
        }
      );
      return response.json();
    };
  
    const uploadFileToS3 = async (uploadUrl, file, contentType) => {
      const response = await fetch(uploadUrl.putURL, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": contentType,
        },
      });
      if (response.status !== 200) {
        console.log('response', response);
        message.error("File upload failed.")
        throw new Error("File upload failed.");
      }
    };

    
      const handleSubmitWorkOrder = async () => {
          const isEmptyOrUndefined = (value) => value === '' || value === null || value === undefined;
          const validations = [
            { key: 'date', message: 'Please add order date' },
            { key: 'priority', message: 'Please add priority' },
            { key: 'assignedTo', message: 'Please add assignee' },
          ];
      
          for (const { key, message: errorMessage } of validations) {
            if (isEmptyOrUndefined(workOrderDetail?.[key])) {
              message.error(errorMessage);
              return;
            }
          }

          const assignee = users?.find((user) => user.id == workOrderDetail?.assignedTo)
          const emailPayload = {
            type: id > 0 ? 1 : 2,
            assignee: assignee,
          }
      
          showLoading(layoutDispatch);
          const isUpdate = id > 0;
          
          const dataToSend = {
            id: isUpdate ? id : undefined,
            work_order_date: moment(workOrderDetail.date).format("YYYY-MM-DD"),
            due_date: moment(workOrderDetail.due_date).format("YYYY-MM-DD"),
            notes: workOrderDetail.notes,
            files: currentItemFiles ? currentItemFiles : [],
            priority: workOrderDetail.priority,
            assigned_to: workOrderDetail.assignedTo,
            vehicle_id: workOrderDetail.vehicle,
            description: workOrderDetail?.description,
            purchase_order_id: workOrderDetail?.purchase_order_id,
            issue_id: workOrderDetail?.issue_id,
            items: [
              ...orderItems.map((item) => ({
                inventory_item_id: item.work_order_item,
                quantity: item.order_item_quantity,
                unit_price: item.order_item_unit_price,
                vat_percentage: VATPercentages.find((vatItem) => vatItem.id == item.vat_percentage).percent,
                type: 1,
              })),
              ...serviceUsed.map((item) => ({
                service_id: item.name,
                quantity: item.hoursWorked,
                unit_price: item.hourlyRate,
                vat_percentage: VATPercentages.find((vatItem) => vatItem.id == item.vat_percentage).percent,
                type: 2,
              })),
            ],
          }
          console.log('dataToSend', dataToSend);
          
          try {
            const method = isUpdate ? "PUT" : "POST";
            const res = await Auth.fetch("/inventory/work-order", {
              method: method,
              body: JSON.stringify(dataToSend),
            })
            if (res?.ack) {
              const record = isUpdate ? id : res?.record_id;
              console.log(res);
              const uploadPromises = []
              if (files.length > 0) {
                files.forEach((f) => {
                  uploadPromises.push(
                    getUploadUrl(f, record)
                      .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                      .catch(error => console.error("Error uploading file:", error))
                  );
                });
              }
              await Promise.all(uploadPromises);
              message.success(res?.message)
              if (env === "prod") {
                fetch("https://tmsemail.api-magnafoodservice.co.uk/api/email/work-order", {
                  method: "POST",
                  headers: {
                    Authorization: "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Im5vb3JAZ21haWwuY29tIiwidXNlcl9pZCI6MSwiaWF0IjoxNzI4NDE0NjIzLCJhdWQiOiJodHRwczovL3d3dy5tYWduYWZvb2RzZXJ2aWNlLmNvLnVrLyIsImlzcyI6Ik1hZ25hIEZvb2QgU2VydmljZSIsInN1YiI6Im5vb3JAZ21haWwuY29tIn0.fb--ERLcBgLhrec6Ed_rAR_uGiBWyFdLN-4fYjjdhjw411DODxyg058A7VVQuLAgD5A8OEa-9jOar0eOTqZk3WGnP8mhh_Tx0smCmiVNY73Odn0x0KZgVyx1yf4W6nEzAHJImmXwzbnPBTIVww7CG2a47GZ8M_XQxFJNhovOnIAEoIZdFz989QdyS_8UpTVIr1UV6nbpZ01ZNuHlU-ZBxJ9zPez1bD7Q9U8Gg59AeJsr3vp6XPDuWmW0C2G3Z3YO-5jT7e1S-lFNg7_8YTH5ju7RWlvdjOJ8lGIiQAVF4aRMXIJh3HHPYBdUWugj7Zb0ZF8F4rz8sR4zNgqEUXJtPQ",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(emailPayload),
                }).catch((err) => console.error("Error sending email:", err)); 
              } else {
                fetch("http://localhost:7420/api/email/work-order", {
                  method: "POST",
                  headers: {
                    Authorization: "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Im5vb3JAZ21haWwuY29tIiwidXNlcl9pZCI6MSwiaWF0IjoxNzI3ODgwNDE0LCJleHAiOjE3NTk0MTY0MTQsImF1ZCI6Imh0dHBzOi8vd3d3Lm1hZ25hZm9vZHNlcnZpY2UuY28udWsvIiwiaXNzIjoiTWFnbmEgRm9vZCBTZXJ2aWNlIiwic3ViIjoibm9vckBnbWFpbC5jb20ifQ.Bidb4jyOqnI4JdF9o0WpcEqiGQDpxv47KZ2mFyhFhLwj0LSui0ukFFH889J3tdVs3A-8ah-rk5026bfhEpneyUzkPl0IEE1iTBt4rdmXnUytXQRY38AsSiMv_v0S1gFL0JJtMh2Odzh3JpPbALaqEhCX0P2Kvhy9jNeczzWL8-rYw9b9WVgVN_mBd8AcdzgbzbeVOPd9hDL24im5QXwd6wIT9hme3e-lyBvW24bEp-ozxwaujVAZrtdh8BoSTTD2lyn0nKHc7iErGFjhFvtIw2pzvtnorNzfWJ2hB2BABQ0JOruxnxVks7NbNAMyBhchQhGV5af6ssDAZ4YaKUeNpw",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(emailPayload),
                }).catch((err) => console.error("Error sending email:", err));
              }
              navigate('/workshop_work_order')
            } else {
              message.error(res?.message)
            }
      
          } catch (err) {
            console.log(err);
          }
          finally {
            hideLoading(layoutDispatch);
            
          }
        };
      
        const handlePostPurchaseOrder = async () => {
          const isEmptyOrUndefined = (value) => value === '' || value === null || value === undefined;
          const validations = [
            { key: 'date', message: 'Please add order date' },
            { key: 'supplier', message: 'Please add supplier' },
          ];
      
          for (const { key, message: errorMessage } of validations) {
            if (isEmptyOrUndefined(workOrderDetail?.[key])) {
              message.error(errorMessage);
              return;
            }
          }

          const requiredFields = [
            "order_item",
            "order_item_quantity",
            "order_item_unit_price",
          ];
          const isEmpty = orderItems.some((item) =>
            requiredFields.some((field) => !item[field])
          );
      
          if (isEmpty) {
            message.error("Please fill in all required fields for items.");
            setType("error");
            return;
          }

          if(orderItems.length < 1) {
            message.error("Please add at least one item to close the purchase order.");
            return;
          }
      
          showLoading(layoutDispatch);
          const dataToSend = {
            id: id,
            order_date: moment(workOrderDetail.date).format("YYYY-MM-DD"),
            notes: workOrderDetail.notes,
            files: currentItemFiles ? currentItemFiles : [],
            priority: workOrderDetail.priority,
            description: workOrderDetail?.description,
            items: orderItems.map((item) => ({
              inventory_item_id: item.work_order_item,
              quantity: item.order_item_quantity,
              unit_price: item.order_item_unit_price,
              vat_percentage: VATPercentages.find((vatItem) => vatItem.id == item.vat_percentage).percent,
            })),
          }
          const postDataToSend = {
            id: id,
          }
          try {
            const res = await Auth.fetch("/inventory/work-order", {
              method: "PUT",
              body: JSON.stringify(dataToSend),
            })
            if (res?.ack) {
              console.log(res);
              const uploadPromises = []
              if (files.length > 0) {
                files.forEach((f) => {
                  uploadPromises.push(
                    getUploadUrl(f, id)
                      .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                      .catch(error => console.error("Error uploading file:", error))
                  );
                });
              }
              await Promise.all(uploadPromises);
              const PostRes = await Auth.fetch("/inventory/close-work-order", {
                method: "POST",
                body: JSON.stringify(postDataToSend),
              })
              if (PostRes?.ack) {
                message.success(PostRes?.message)
                navigate('/workshop_work_order')
              } else {
                message.error(PostRes?.message)
              }
            } else {
              message.error(res?.message)
            }
      
          } catch (err) {
            console.log(err);
          }
          finally {
            hideLoading(layoutDispatch);
          }
        };


      const getDataByID = async () => {
          showLoading(layoutDispatch)
          try {
            const res = await Auth.fetch(`/inventory/work-order?id=${id}`)
            if (res?.ack) {
              setCurrentItemFiles(res?.work_order.files ? JSON.parse(res?.work_order.files) : [])
              setWorkOrderDetail((prevDetails) => ({
                ...prevDetails,
                work_order_code: res?.work_order.work_order_code,
                description: res?.work_order.description,
                notes: res?.work_order.notes,
                vehicle: res?.work_order.vehicle_id,
                assignedTo: res?.work_order.assigned_to,
                due_date: res?.work_order.due_date ? moment(res?.work_order.due_date).format("YYYY-MM-DD") : null,
                date: res?.work_order.work_order_date ? moment(res?.work_order.work_order_date).format("YYYY-MM-DD") : null,
                priority: res?.work_order.priority,
                purchase_order_id: res?.work_order?.purchase_order_id,
                issue_id: res?.work_order?.issue_id,
              }))
              
              // setOrderItems(
              //   res?.work_order_items?.filter(
              //     (item) =>
              //       item?.type === 1)
              //   ).map((item) => ({
              //     ...item,
              //     work_order_item: item.inventory_item_id,
              //     vat_percentage: VATPercentages.find((vatItem) => vatItem.percent == item.vat_percentage).id,
              //     order_item_quantity: item.quantity,
              //     order_item_unit_price: item.unit_price,
              //     order_item_vat: item.quantity * item.unit_price * (item.vat_percentage / 100),
              //     order_item_total: item.quantity * item.unit_price * (1 + item.vat_percentage / 100),
              //   }))
              setOrderItems(
                res?.work_order_items
                  ?.filter((item) => item?.type === 1)
                  .map((item) => ({
                    ...item,
                    work_order_item: item.inventory_item_id,
                    vat_percentage: VATPercentages.find((vatItem) => vatItem.percent == item.vat_percentage)?.id || null,
                    order_item_quantity: item.quantity,
                    order_item_unit_price: item.unit_price,
                    order_item_vat: item.quantity * item.unit_price * (item.vat_percentage / 100),
                    order_item_total: item.quantity * item.unit_price * (1 + item.vat_percentage / 100),
                  }))
              );
              setServiceUsed(
                res?.work_order_items
                  ?.filter((item) => item?.type === 2)
                  .map((item) => ({
                    ...item,
                    name: item.service_id,
                    vat_percentage: VATPercentages.find((vatItem) => vatItem.percent == item.vat_percentage)?.id || null,
                    hoursWorked: item.quantity,
                    hourlyRate: item.unit_price,
                    service_vat: item.quantity * item.unit_price * (item.vat_percentage / 100),
                    totalServiceCost: item.quantity * item.unit_price * (1 + item.vat_percentage / 100),
                  }))
              );
             
              
            }
          } catch (err) {
            console.log(err);
          } finally {
            hideLoading(layoutDispatch)
          }
        }
    
        console.log('orderItems', orderItems);
        

    useEffect(() => {
      if (id > 0) {
        getDataByID();
        
      } else if (id == 'new' && issueConversionData != undefined) {
        setWorkOrderDetail((prevDetails) => ({
          ...prevDetails,
          issue_id: Number(issueConversionData?.issue_id),
          due_date: issueConversionData?.due_date ? moment(issueConversionData?.due_date).format('YYYY-MM-DD') : null,
          priority: issueConversionData?.priority,
          vehicle: issueConversionData?.vehicle ? issueConversionData?.vehicle : null,
          assignedTo: issueConversionData?.assignee,
        }))
        setOrderItems([
          {
            work_order_item: null,
            vat_percentage: 2,
            order_item_quantity: "",
            order_item_unit_price: "",
            order_item_vat: "",
            order_item_total: "",
          },
        ]);
        setServiceUsed([
          {
            name: null,
            hoursWorked: "",
            hourlyRate: "",
            totalServiceCost: "",
            vat_percentage: 2,
            service_vat: "",
          },
        ]);
      } else {
        setOrderItems([
          {
            work_order_item: null,
            vat_percentage: 2,
            order_item_quantity: "",
            order_item_unit_price: "",
            order_item_vat: "",
            order_item_total: "",
          },
        ]);
        setServiceUsed([
          {
            name: null,
            hoursWorked: "",
            hourlyRate: "",
            totalServiceCost: "",
            vat_percentage: 2,
            service_vat: "",
          },
        ]);
      }
      }, [id]);

      console.log('serviceUsed', serviceUsed);
      

     
      
      // Usage example in your component

      const order = {
        orderCode: workOrderDetail.work_order_code,
        date: workOrderDetail.date,
        priority: issuePriorities.find(item => item.id == workOrderDetail.priority)?.title,
        notes: workOrderDetail.notes,
        items: orderItems.map(item => ({
          name: issuePriorities.find(issue => issue.id == item.order_item)?.title,
          quantity: item.order_item_quantity,
          unitPrice: item.order_item_unit_price,
          vatPercent: VATPercentages.find(vatItem => vatItem.id == item.vat_percentage)?.percent,
          vat: item.order_item_vat,
          total: item.order_item_total
        }))
      };
      
      const generateWorkOrderPDF = () => {
        const doc = new jsPDF();
      
        // Get page dimensions
        const pageWidth = doc.internal.pageSize.getWidth();
        const boxHeight = 10; // Height of each box
        const fieldSpacing = 12; // Spacing between fields
      
        // Add centered "Purchase Order" heading
        doc.setFontSize(18);
        doc.text('Purchase Order', pageWidth / 2, 20, { align: 'center' });
      
        // Add labeled boxes for order details
        const fields = [
          { label: 'Order Code', value: order.orderCode },
          { label: 'Date', value: moment(order.date).format("YYYY-MM-DD") },
          { label: 'Supplier', value: order.supplier },
          { label: 'Notes', value: order.notes },
          { label: 'VAT', value: formatCurrency(totalVat) },
          { label: 'Total', value: formatCurrency(totalPrice) },
          { label: 'Grand Total', value: formatCurrency(totalVat + totalPrice) },
        ];
      
        const boxStartX = 14; // Padding from the left
        fields.forEach((field, index) => {
          const y = 30 + index * fieldSpacing;
      
          // Draw full-width box
          doc.rect(boxStartX, y, pageWidth - 2 * boxStartX, boxHeight);
      
          // Add label and value
          doc.setFontSize(10);
          doc.text(`${field.label}:`, boxStartX + 2, y + 6); // Label on the left
          doc.text(field.value.toString(), pageWidth - boxStartX - 2, y + 6, { align: 'right' }); // Value on the right
        });
      
        // Add order items table
        const tableColumn = ["Item Name", "Quantity", "Unit Price", "VAT%", "VAT", "Total"];
        const tableRows = [];
      
        order.items.forEach(item => {
          const itemData = [
            item.name,
            item.quantity,
            item.unitPrice.toFixed(2),
            item.vatPercent,
            item.vat.toFixed(2),
            item.total.toFixed(2),
          ];
          tableRows.push(itemData);
        });
      
        // Start the table below the last field
        const tableStartY = 30 + fields.length * fieldSpacing + 10;
        doc.autoTable(tableColumn, tableRows, { startY: tableStartY });
      
        // Save the PDF
        doc.save(`work_order_${order.orderCode}.pdf`);
      };

      
      const calculateSums = () => {
              const totalVat = orderItems.reduce((sum, item) => {
                return sum + parseFloat(item?.order_item_vat || 0); 
              }, 0);
            
              const totalPrice = orderItems.reduce((sum, item) => {
                const quantity = parseFloat(item.order_item_quantity || 0);
                const unitPrice = parseFloat(item.order_item_unit_price || 0);
                return sum + (quantity * unitPrice); 
              }, 0);

              const totalLabourVat = serviceUsed.reduce((sum, item) => {
                return sum + parseFloat(item?.service_vat || 0); 
              }, 0);

              const totalLabourPrice = serviceUsed.reduce((sum, item) => {
                const quantity = parseFloat(item.hoursWorked || 0);
                const unitPrice = parseFloat(item.hourlyRate || 0);
                return sum + (quantity * unitPrice); 
              }, 0);
            
              return { totalVat, totalPrice, totalLabourVat, totalLabourPrice };
            };
            
            // Usage example in your component
            const { totalVat, totalPrice, totalLabourVat, totalLabourPrice } = calculateSums();
      
            const data = [
              {
                title: 'Total',
                value: <span style={{ color: 'blue', fontWeight: "bold"}}>{formatCurrency(totalPrice + totalLabourPrice)}</span>
              },
              {
                title: 'VAT',
                value: <span style={{ color: 'blue', fontWeight: "bold"}}>{formatCurrency(totalVat + totalLabourVat)}</span>
              },
              
              {
                title: 'Grand Total',
                value: <span style={{ color: 'red', fontWeight: "bold"}}>{formatCurrency(totalVat + totalPrice + totalLabourVat + totalLabourPrice)}</span>
              },
            ];
      
  return (
        <div className={classes.dataSheet}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>Work Order</h3>
            {/* {
              id > 0 && (
                  <Button onClick={generateWorkOrderPDF} type="primary">Download PDF</Button>
              )
            } */}
            {
              id > 0 && (
                <div style={{ display: 'flex', alignItems: 'center'}}>
                  
                {
                  !workOrderDetail?.purchase_order_id > 0 && (

                    <Button style={{ marginRight: '10px'}} type="primary"
                    onClick={() => {
                      const conversionData = {
                        work_order_id: id, 
                        order_items: orderItems,
                      }
                      navigate(`/workshop_purchase_order/new`, {
                      state: { conversionData: conversionData }
                    }
                  )}
                  }
                     >
                      Convert To Purchase Order
                      </Button>
                  )
                }
                <>
                <Tooltip title="Download PDF">
                <CloudDownloadOutlined
                style={{ fontSize: '25px', color: '#1677FF'}}
                onClick={generateWorkOrderPDF}
                 />
                 </Tooltip>
                 </>
                 </div>
              )
            }
        </div>
        <>
          <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Code
            </span>
                <Input 
                value={workOrderDetail?.work_order_code}
                disabled
                placeholder='To be generated'
                 />
            </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Vehicle<span style={{ color: "red" }}>*</span>
            </span>
            <Select
              style={{
                width: "100%",
              }}
              showSearch
              value={workOrderDetail.vehicle}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                setWorkOrderDetail((prevDetails) => ({
                  ...prevDetails,
                  vehicle: value,
                }))
              }}
            >
              {vehicles?.map((item) => (
                <Option key={item?.id} value={item?.id}>
                  {item?.registration_number}
                </Option>
              ))}
            </Select>
            </Col>
          
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <span>
                Date<span style={{ color: "red", fontSize: "15px" }}>*</span>:
              </span>
              <DatePicker
                format={"YYYY-MM-DD"}
                value={workOrderDetail.date ? dayjs(workOrderDetail.date) : null}
                onChange={(_, dateStr) => {
                  setWorkOrderDetail((prevDetails) => ({
                      ...prevDetails,
                      date: dateStr,
                    }))
                }}
                // showTime
                style={{
                  width: "100%",
                }}
                placeholder="Select work order date"
              />
              </Col>
               <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                          <span>
                            Description
                          </span>
                              <Input 
                              value={workOrderDetail?.description}
                              onChange={(e) => {
                                setWorkOrderDetail((prevDetails) => ({
                                      ...prevDetails,
                                      description: e.target.value,
                                  }))
                              }}
                              placeholder='Please enter description.'
                               />
                          </Col>
            
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
            Priority<span style={{ color: "red" }}>*</span>
            </span>
            <Select
              style={{
                width: "100%",
              }}
              showSearch
              value={workOrderDetail.priority}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                setWorkOrderDetail((prevDetails) => ({
                  ...prevDetails,
                  priority: value,
                }))
              }}
            >
              {issuePriorities.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <span>
                          Assigned To<span style={{ color: "red" }}>*</span>
                        </span>
                        <Select
              style={{
                width: "100%",
              }}
              showSearch
              value={workOrderDetail.assignedTo}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input?.toLowerCase())
              }
              onChange={(value) => {
                setWorkOrderDetail((prevDetails) => ({
                  ...prevDetails,
                  assignedTo: value,
                }))
              }}
              
            >
              {users?.map((item) => (
                <Option key={item?.id} value={item?.id}>
                  {`${item?.first_name} ${item?.last_name}`}
                </Option>
              ))}
            </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <span>
                          Linked Issue
                        </span>
                        <Select
              style={{
                width: "100%",
              }}
              showSearch
              suffixIcon={
                <>
                {id > 0 && workOrderDetail?.issue_id  && (
                  <Tooltip title="Go to Linked issue Order">
                  <ExportOutlined  onClick={()=> navigate(`/workshop_issues/${workOrderDetail?.issue_id}`)} style={{color: '#5b5858', fontSize: '25px' }} />
                  </Tooltip>
                )}
                </>
            }
              value={workOrderDetail.issue_id}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input?.toLowerCase())
              }
              onChange={(value) => {
                setWorkOrderDetail((prevDetails) => ({
                  ...prevDetails,
                  issue_id: value,
                }))
              }}
              
            >
              {allIssues?.map((item) => (
                <Option key={item?.id} value={item?.id}>
                  {`${item?.issue_code}`}
                </Option>
              ))}
            </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <span>
                          Linked Purchase Order
                        </span>
                        <Select
              style={{
                width: "100%",
              }}
              showSearch
              suffixIcon={
                <>
                {id > 0 && workOrderDetail?.purchase_order_id  && (
                  <Tooltip title="Go to Linked Purchase Order">
                  <ExportOutlined  onClick={()=> navigate(`/workshop_purchase_order/${workOrderDetail?.purchase_order_id}`)} style={{color: '#5b5858', fontSize: '25px' }} />
                  </Tooltip>
                )}
                </>
            }
              value={workOrderDetail.purchase_order_id}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input?.toLowerCase())
              }
              onChange={(value) => {
                setWorkOrderDetail((prevDetails) => ({
                  ...prevDetails,
                  purchase_order_id: value,
                }))
              }}
              
            >
              {allPurchaseOrders?.map((item) => (
                <Option key={item?.id} value={item?.id}>
                  {`${item?.order_code}`}
                </Option>
              ))}
            </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <span>
                Due Date<span style={{ color: "red", fontSize: "15px" }}>*</span>:
              </span>
              <DatePicker
                format={"YYYY-MM-DD"}
                value={workOrderDetail.due_date ? dayjs(workOrderDetail.due_date) : null}
                onChange={(_, dateStr) => {
                  setWorkOrderDetail((prevDetails) => ({
                      ...prevDetails,
                      due_date: dateStr,
                    }))
                }}
                // showTime
                style={{
                  width: "100%",
                }}
                placeholder="Select Due date"
              />
              </Col>
            
          </Row>
          <Row style={{marginTop: '5px'}} gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <span>
              Notes:
            </span>
                <TextArea 
                value={workOrderDetail?.notes}
                onChange={(e) => {
                    setWorkOrderDetail((prevDetails) => ({
                        ...prevDetails,
                        notes: e.target.value,
                    }))
                }}
                placeholder='Please enter any notes.'
                autoSize={{ minRows: 3, maxRows: 6 }} />
            </Col>
            </Row>
          <Divider />

          <>
          <div>
        <UploadButton
          component="label"
          variant="contained"
          sx={{ marginTop: "10px" }}
          startIcon={<CloudUploadIcon />}
        >
          Add files
          <input
            type="file"
            hidden
            multiple
            capture
            onChange={(e) => {
                const myfiles = e.target.files;
            
                if (myfiles.length > 10) {
                  message.error("A maximum of 10 images can be uploaded at a time.");
                  e.target.value = "";
                } else {
                  handleSubmitImage(e);
                }
              }}
          />
        </UploadButton>
      </div>

      {currentItemFiles?.length > 0 && <h4>Current Files:</h4>}
      <ul>
                  {currentItemFiles?.length > 0 &&
                    currentItemFiles
                      .filter(
                        (item) =>
                          !files.some((file) => file.name === item)
                      )
                      .map((item) => {
                        const encodedFileName = item.replace(/\+/g, '%2B');
                        return (
                          <li>
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={() => handleDeleteCurrentFile(item)}
                                >
                                  <DeleteIcon />
                                </IconButton>

                            <a
                              href={`${tms_uploads_cdn}/${env}/work-orders/${id}/${encodedFileName}`}
                              target="_blank"
                            >
                              {extractOriginalFileName(encodedFileName)}
                            </a>
                          </li>
                        );
                      })}
                </ul>
        
      {files?.length > 0 && <Divider />}

      {files?.length > 0 && <h4>Files:</h4>}
                <ul>
                  {files.length > 0 &&
                    files.map((files) => {
                      const encodedFileName = files?.name.replace(/\+/g, '%2B');
                      return (
                        <li>
                          {" "}
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => handleDeleteFile(files.name)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {extractOriginalFileName(encodedFileName)}
                        </li>
                      );
                    })}
                </ul>
      {files?.length > 0 && <Divider />}

          <div>
          
            <Row  style={{display: 'flex', justifyContent: 'end', marginBottom: '15px'}} gutter={[16, 16]}>
              {
                data.map((item, index) => (
                  <Col xs={24} sm={12} md={8} lg={8} xl={4} key={index}>
                    <Card title={item?.title}>{item?.value}</Card>
                  </Col>
                ))
              }
            </Row>
  </div>

         <>
         <h3>Items</h3>
              {orderItems.map((orderItem, index) => (
                <div key={index}>
                  <Row gutter={[6, 4]}>
                  <Col xs={24} sm={12} md={4} lg={6} xl={6}>
                  <h5 style={{ margin: "1px 0px" }}>
                        Name
                      </h5>
                  <Select
              style={{
                width: "100%",
              }}
              showSearch
              name="work_order_item"
              value={orderItem?.work_order_item}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                const list = [...orderItems];
                list[index].work_order_item = value;
                setOrderItems(list);
              }}
              
            >
              {allItems.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.item_name}
                </Option>
              ))}
            </Select>
                    </Col>
                    
                    
                    <Col xs={24} sm={12} md={4} lg={3} xl={3}>
                      <h5 style={{ margin: "1px 0px" }}>
                        Quantity
                        <span style={{ color: "red", fontSize: "10px" }}>
                          *
                        </span>
                      </h5>
                      <Input
                        name="order_item_quantity"
                        type="number"
                        value={orderItem?.order_item_quantity}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="Quantity"
                        // step="0.01"
                        max={20000}
                        min={0}

                      />
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={3} xl={3}>
                      <h5 style={{ margin: "1px 0px" }}>
                        Unit Price
                        <span style={{ color: "red", fontSize: "10px" }}>
                          *
                        </span>
                      </h5>
                      <Input
                        name="order_item_unit_price"
                        type="number"
                        value={orderItem?.order_item_unit_price}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="Unit Price"
                        step="0.01"
                        max={20000}
                        min={0}

                      />
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={3} xl={3}>
                  <h5 style={{ margin: "1px 0px" }}>
                        VAT %
                      </h5>
                  <Select
              style={{
                width: "100%",
              }}
              showSearch
              name="vat_percentage"
              value={orderItem?.vat_percentage}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                const list = [...orderItems];
                list[index].vat_percentage = value;
                const item = list[index];
                const quantity = parseFloat(item.order_item_quantity || 0);
                const unitPrice = parseFloat(item.order_item_unit_price || 0);
              
                const vatAmount = quantity * unitPrice;
              
                const vat_percentageItem = VATPercentages.find((vatItem) => vatItem.id == item.vat_percentage)
                
                const vatPercentage = Number(vat_percentageItem?.percent || 0) / 100;
                const calculatedVat = vatAmount * vatPercentage;
              
                const total = vatAmount + calculatedVat;
              
                list[index].order_item_vat = calculatedVat.toFixed(2); 
                list[index].order_item_total = total.toFixed(2); 
              
                setOrderItems(list);
              }}
              
            >
              {VATPercentages.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={3} xl={3}>
                      <h5 style={{ margin: "1px 0px" }}>
                        VAT
                        <span style={{ color: "red", fontSize: "10px" }}>
                          *
                        </span>
                      </h5>
                      <Input
                        name="order_item_vat"
                        type="number"
                        value={orderItem?.order_item_vat}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="VAT"
                        step="0.01"
                        max={20000}
                        min={0}

                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={3} xl={3}>
                      <h5 style={{ margin: "1px 0px" }}>
                        Total
                        <span style={{ color: "red", fontSize: "10px" }}>
                          *
                        </span>
                      </h5>
                      <Input
                        name="order_item_total"
                        type="number"
                        value={orderItem?.order_item_total}
                        placeholder="Total"
                        step="0.01"
                        disabled
                      />
                    </Col>
                    {(orderItems.length > 1) && (
                    <>
                        <Col xs={24} sm={12} md={8} lg={3} xl={3}>
                          {orderItem?.id > 0 ? (
                            <Popconfirm
                              title="Remove Item"
                              description="Are you sure you want to remove this Item?"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => handleRemoveItem(index, orderItem)}
                            >
                              {/* <Button
                                type="dashed"
                                block
                                danger
                                icon={<MinusOutlined />}
                                style={{ margin: "19px 0px" }}
                              >
                                Delete
                              </Button> */}
                              <DeleteFilled style={{fontSize: '25px', color: 'red', marginTop: "20px"}}/>

                            </Popconfirm>
                          ) : (
                            // <Button
                            //   type="dashed"
                            //   onClick={() => handleRemoveItem(index, orderItem)}
                            //   block
                            //   danger
                            //   icon={<MinusOutlined />}
                            //   style={{ margin: "19px 0px" }}
                            // >
                            //   Delete
                            // </Button>
                            <DeleteFilled onClick={() => handleRemoveItem(index, orderItem)} style={{fontSize: '25px', color: 'red', marginTop: "20px"}}/>

                          )}
                        </Col>
                    </>
                  )}
                  </Row>
                  {/* <Divider style={{ height: "2px", background: "#006cb8" }} /> */}

                </div>
              ))}
              <Row>
            <Col>
              <Button
                type="dashed"
                onClick={handleAddItem}
                block
                icon={<PlusOutlined />}
                style={{ marginTop: "10px" }}
              >
                Add Another Item
              </Button>
            </Col>
          </Row>
            </>
            <Divider />
            <>
            <h3>Services</h3>
              {serviceUsed.map((serviceItem, index) => (
                <div key={index}>
                  <Row gutter={[6, 4]}>
                  <Col xs={24} sm={12} md={4} lg={6} xl={6}>
                  <h5 style={{ margin: "1px 0px" }}>
                        Name
                      </h5>
                  <Select
              style={{
                width: "100%",
              }}
              showSearch
              name="name"
              value={serviceItem?.name}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                const list = [...serviceUsed];
                list[index].name = value;
                setServiceUsed(list);
              }}
              
            >
              {InventoryServices.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
                    </Col>
                    
                    
                    <Col xs={24} sm={12} md={4} lg={3} xl={3}>
                      <h5 style={{ margin: "1px 0px" }}>
                        Hours
                        <span style={{ color: "red", fontSize: "10px" }}>
                          *
                        </span>
                      </h5>
                      <Input
                        name="hoursWorked"
                        type="number"
                        value={serviceItem?.hoursWorked}
                        onChange={(e) => handleInputChangeLabour(index, e)}
                        placeholder="Number of hours"
                        // step="0.01"
                        max={20000}
                        min={0}

                      />
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={3} xl={3}>
                      <h5 style={{ margin: "1px 0px" }}>
                        Hourly Rate
                        <span style={{ color: "red", fontSize: "10px" }}>
                          *
                        </span>
                      </h5>
                      <Input
                        name="hourlyRate"
                        type="number"
                        value={serviceItem?.hourlyRate}
                        onChange={(e) => handleInputChangeLabour(index, e)}
                        placeholder="Hourly Price"
                        step="0.01"
                        max={20000}
                        min={0}

                      />
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={3} xl={3}>
                  <h5 style={{ margin: "1px 0px" }}>
                        VAT %
                      </h5>
                  <Select
              style={{
                width: "100%",
              }}
              showSearch
              name="vat_percentage"
              value={serviceItem?.vat_percentage}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                const list = [...serviceUsed];
                list[index].vat_percentage = value;
                const item = list[index];
                const quantity = parseFloat(item.hoursWorked || 0);
                const unitPrice = parseFloat(item.hourlyRate || 0);
              
                const vatAmount = quantity * unitPrice;
              
                const vat_percentageItem = VATPercentages.find((vatItem) => vatItem.id == item.vat_percentage)
                
                const vatPercentage = Number(vat_percentageItem?.percent || 0) / 100;
                const calculatedVat = vatAmount * vatPercentage;
              
                const total = vatAmount + calculatedVat;
              
                list[index].service_vat = calculatedVat.toFixed(2); 
                list[index].totalServiceCost = total.toFixed(2); 
              
                setServiceUsed(list);
              }}
              
            >
              {VATPercentages.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={3} xl={3}>
                      <h5 style={{ margin: "1px 0px" }}>
                        VAT
                        <span style={{ color: "red", fontSize: "10px" }}>
                          *
                        </span>
                      </h5>
                      <Input
                        name="service_vat"
                        type="number"
                        value={serviceItem?.service_vat}
                        // onChange={(e) => handleInputChange(index, e)}
                        placeholder="VAT"
                        disabled
                        step="0.01"
                        max={20000}
                        min={0}

                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={3} xl={3}>
                      <h5 style={{ margin: "1px 0px" }}>
                        Total
                        <span style={{ color: "red", fontSize: "10px" }}>
                          *
                        </span>
                      </h5>
                      <Input
                        name="totalServiceCost"
                        type="number"
                        value={serviceItem?.totalServiceCost}
                        placeholder="Total"
                        step="0.01"
                        disabled
                      />
                    </Col>
                    {(serviceUsed.length > 1) && (
                    <>
                        <Col xs={24} sm={12} md={8} lg={3} xl={3}>
                          {serviceItem?.id > 0 ? (
                            <Popconfirm
                              title="Remove Item"
                              description="Are you sure you want to remove this ?"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => handleRemoveService(index, serviceItem)}
                            >
                              <DeleteFilled style={{fontSize: '25px', color: 'red', marginTop: "20px"}}/>
                              {/* <Button
                                type="dashed"
                                block
                                danger
                                icon={<MinusOutlined />}
                                style={{ margin: "19px 0px" }}
                              >
                                Delete
                              </Button> */}
                            </Popconfirm>
                          ) : (
                            // <Button
                            //   type="dashed"
                            //   onClick={() => handleRemoveService(index, serviceItem)}
                            //   block
                            //   danger
                            //   icon={<MinusOutlined />}
                            //   style={{ margin: "19px 0px" }}
                            // >
                            //   Delete
                            // </Button>
                            <DeleteFilled onClick={() => handleRemoveService(index, serviceItem)} style={{fontSize: '25px', color: 'red', marginTop: "20px"}}/>

                          )}
                        </Col>
                    </>
                  )}
                  </Row>
                  {/* <Divider style={{ height: "2px", background: "#006cb8" }} /> */}

                </div>
              ))}
              <Row>
            <Col>
              <Button
                type="dashed"
                onClick={handleAddService}
                block
                icon={<PlusOutlined />}
                style={{ marginTop: "10px" }}
              >
                Add Another Sevice
              </Button>
            </Col>
          </Row>
            </>
       
        </>
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
        >
          <Button
            onClick={() => navigate("/workshop_work_order")}
            danger
            size="large"
            style={{ marginRight: "10px" }}
          >
            Cancel
          </Button>
            <Button
              type="primary"
              size="large"
              style={{ marginRight: "10px" }}
              onClick={handleSubmitWorkOrder}
            >
              Save
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={()=>{
                Modal.confirm({
                  title: 'Close Work Order',
                  centered: true,
                  content: `Are you sure you want to close this work order?`,
                  onOk: handlePostPurchaseOrder,
                  okText: 'Close',
                  cancelText: 'Cancel',
                  width: 600,
                  okButtonProps: { size: "large"  },
                  cancelButtonProps: { size: "large" },
                });
              }}
            >
              Close 
            </Button>
        </div>
        </>
          <Message type={type} msg={loadMessage} />
      </div>
      
  )
}

export default WorkOrder
