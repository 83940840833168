import React, { useState } from "react";
import { Card, Col, Divider, Row, Statistic, Table, Switch, Button, Tag, Tooltip, Input } from "antd";
import "./reports.css";
import { makeStyles } from "@mui/styles";
import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import { formatCurrency } from "../../Components/common/CommonFun";
import { netsuite_url } from "../../Components/config";
import { deliveryStatuses, StockChangeReasons } from "../../Components/common/constants";
import moment from "moment";

const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: "15px",
    background: "white",
    "& .ant-card .ant-card-body": {
      padding: "4px",
      backgroundColor: "rgb(240, 240, 240)",
    },
    "& .ant-statistic .ant-statistic-content": {
      fontSize: "12px",
      fontWeight: "bold",
    },
    "& .ant-statistic .ant-statistic-title": {
      fontSize: "12px",
    },
    "& .example-custom-input": {
      width: "100% !important",
    },
    "& .react-datepicker-wrapper": {
      width: "100% !important",
    },
  },
}));

const DailyReturns = ({ dailyReturns, deliveryDate, searchText, handleSearch, filteredData }) => {
  const classes = useStyles();
  const [loadMessage, setLoadMessage] = useState("");
  const [type, setType] = useState("");
  // const [filteredData, setFilteredData] = useState([...dailyReturns]); 
  // const [searchText, setSearchText] = useState(""); 




  const detailsCol = [
    {
      title: "Route",
      dataIndex: "title",
      key: "title",
      width: 80,
    },
    {
      title: "Driver",
      dataIndex: "driver_name",
      key: "driver_name",
      width: 120,
    },
    {
      title: "Vehicle",
      dataIndex: "registration_number",
      key: "registration_number",
      width: 80,
    },
    {
      title: "Customer",
      align: 'center',
      render(record) {
        return (
          <span><a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${record.customer_internal_id}`} target="_blank">{record.customer_name}</a></span>
        );
      },
      width: 90,
    },
    {
      title: "Invoice",
      render(record) {
        return (
          <span>
                <a 
                  href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${record?.inv_internal_id}`} 
                  target="_blank" 
                  rel="noopener noreferrer"
              >
                  {record?.invoice_no}
              </a>
            </span>
        );
      },
      width: 90,
    },
    {
      title: "Order",
      render(record) {
        return (
          <span>
                <a 
                  href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${record?.internal_id}`} 
                  target="_blank" 
                  rel="noopener noreferrer"
              >
                  {record?.sale_order_no}
              </a>
            </span>
        );
      },
      width: 90,
    },
    {
      title: "Delivery Status",
      render(record) {
        let statusText;
        let backgroundColor;
        // let delivered_at = record?.delivered_at || "N/A";

        const delivered_at = record?.delivered_at && moment(record.delivered_at).isValid()
      ? moment(record.delivered_at).format('YYYY-MM-DD HH:mm:ss')
      : "N/A";


        switch (record?.delivered) {
          case 1:
            statusText = "Delivered";
            backgroundColor = "#80cb80";
            break;
          case 2:
            statusText = "Postponed";
            backgroundColor = "#f0d787";
            break;
            case -1:
            statusText = "Submitted";
            backgroundColor = "#1890FF";
            break;
          default:
            statusText = "Not Delivered";
            backgroundColor = "#edb1a7";
            break;
        }

        return {
          props: {
            style: {
              background: backgroundColor,
              textAlign: 'center',
              width: "300px",


            },
          },
          children: (
            <>
            {statusText}
            {record?.delivered === 1 && (
              <Tooltip title={`Delivered at: ${delivered_at}`}>
                <InfoCircleOutlined style={{ marginLeft: 8, color: "#000" }} />
              </Tooltip>
            )}
          </>
          ),
        };
      },
    },
    {
      title: "Item Code",
      dataIndex: "item_id",
      key: "item_id",
      width: 80,
    },
    {
      title: "Item Name",
      dataIndex: "item_name",
      key: "item_name",
      width: 250,
    },
    
    
    
    {
      title: "Ordered QTY",
      dataIndex: "quantity",
      key: "quantity",
      width: 80,
      align: 'center'
    },
    {
      title: "Delivered QTY",
      dataIndex: "delivered_quantity",
      key: "delivered_quantity",
      width: 80,
      align: 'center'

    },
    {
      title: "Returned QTY",
      render: (text, record) => {
        return (
          <span>
            
              <>{Number(record?.quantity) - Number(record?.delivered_quantity)}</>
          </span>
        );
      },
      width: 80,
      align: 'center'

    },
    {
      title: "Warehouse Received QTY (Quarantine)",
      dataIndex: "returned_quantity",
      key: "returned_quantity",
      width: 80,
      align: 'center'

    },
    {
      title: "Returned To Stock QTY",
      dataIndex: "returned_quantity_to_stock",
      key: "returned_quantity_to_stock",
      width: 80,
      align: 'center'

    },
    {
          title: "Damages",
          render: (text, record) => {
            const value = record?.returned_quantity_to_damages || 0; 
            return (
              <span>
                {value > 0 ? (
                  <Tag color="red">{value}</Tag>
                ) : (
                  <span>{value}</span> 
                )}
              </span>
            );
          },
          width: 100,
          align: "center",
        },
    
    {
      title: "Customer Delivery Status",
      render: (record) => {
              const status = deliveryStatuses.find(item => item.id === record?.delivery_status)
              return (
                 <span>{status ? status.title : ''}</span>
              );
            },
      width: 120,
      align: 'center'

    },
    {
      title: "Warehouse Delivery Status",
      render: (record) => {
              const status = StockChangeReasons.find(item => item.id === record?.returned_quantity_to_stock_status)
              return (
                 <span>{status ? status.title : ''}</span>
              );
            },
      width: 120,
      align: 'center'

    },
  ];

  const downloadCSV = () => {
    // Prepare data for CSV
    const csvData = filteredData.map((record) => ({
      Route: record.title || "",
      Driver: record.driver_name || "",
      Vehicle: record.registration_number || "",
      Customer: record.customer_name || "",
      Invoice: record.invoice_no || "",
      Order: record.sale_order_no || "",
      "Delivery Status": (() => {
        switch (record.delivered) {
          case 1:
            return "Delivered";
          case 2:
            return "Postponed";
          case -1:
            return "Submitted";
          default:
            return "Not Delivered";
        }
      })(),
      // "Delivered At":
      //   record.delivered_at && moment(record.delivered_at).isValid()
      //     ? moment(record.delivered_at).format("YYYY-MM-DD HH:mm:ss")
      //     : "N/A",
      "Item Code": record.item_id || "",
      "Item Name": record.item_name || "",
      "Ordered QTY": record.quantity || 0,
      "Delivered QTY": record.delivered_quantity || 0,
      "Returned QTY": record.quantity - record.delivered_quantity || 0,
      "Warehouse Received QTY (Quarantine)": record.returned_quantity || 0,
      "Returned To Stock QTY": record.returned_quantity_to_stock || 0,
      Damages: record.returned_quantity_to_damages || 0,
      "Customer Delivery Status": deliveryStatuses.find((item) => item.id === record.delivery_status)?.title || "",
      "Warehouse Delivery Status": StockChangeReasons.find((item) => item.id === record.returned_quantity_to_stock_status)?.title || "",
    }));


  const csvString =
      "data:text/csv;charset=utf-8," +
      [
        Object.keys(csvData[0]).join(","), // Header row
        ...csvData.map((row) => Object.values(row).join(",")), // Data rows
      ].join("\n");

    // Create a link and trigger download
    const link = document.createElement("a");
    link.href = encodeURI(csvString);
    link.download = `DailyReturns_${moment().format("YYYY-MM-DD")}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <div className={classes.dataSheet}>
      <div style={{ marginBottom: "10px",display: "flex", justifyContent: "space-between" }}>
        <Input
          placeholder="Search by Route"
          value={searchText}
          onChange={handleSearch}
          allowClear
          style={{ width: '250px'}}
        />
        <Button type="primary" onClick={downloadCSV}>
          Download
        </Button>
      </div>
      {filteredData.length > 0 ? (
        <div className="view-page">
          <Table
            style={{ overflowX: "auto", marginTop: "10px" }}
            columns={detailsCol}
            dataSource={filteredData}
            pagination={false}
          />
        </div>
      ) : (
        <div>No results found.</div>
      )}
      <Message type={type} msg={loadMessage} />
    </div>
  );
};

export default DailyReturns;
