import Vehicle from "../../pages/Vehicles/Vehicle";

export const availableDays = [
    { id: "1", title: "Monday" },
    { id: "2", title: "Tuesday" },
    { id: "3", title: "Wednesday" },
    { id: "4", title: "Thursday" },
    { id: "5", title: "Friday" },
    { id: "6", title: "Saturday" },
    { id: "7", title: "Sunday" },
];

export const vehicleTypes = [
    { id: "1", title: "3.5 Tonnes", maxWeight: 900, maxPallets: 4, maxPalletWeight: 450, weightThreshold: 50 },
    { id: "2", title: "7.5 Tonnes", maxWeight: 3000, maxPallets: 7, maxPalletWeight: 650, weightThreshold: 50 },
    { id: "3", title: "16 Tonnes", maxWeight: 6000, maxPallets: 12, maxPalletWeight: 650, weightThreshold: 50 },
    { id: "4", title: "18 Tonnes", maxWeight: 7500, maxPallets: 14, maxPalletWeight: 650, weightThreshold: 50 }
];

export const driverTypes = [    
    { id: "1", title: "Full-time" },
    { id: "2", title: "Agency" },
];

export const userRoles = [    
    { id: "1", title: "Admin" },
    { id: "2", title: "User" },
];

export const statuses = [    
    { id: "1", title: "Active" },
    { id: "0", title: "Inactive" },
];

export const colors = [
    { id: "#105ecd", title: "Blue" },
    { id: "#5f3511", title: "Brown" },
    { id: "#f29b57", title: "BrownSandy" },
    { id: "#3b121e", title: "Chocolate" },
    { id: "#06f2e8", title: "Cyan" },
    { id: "#000080", title: "DarkBlue" },
    { id: "#807974", title: "Gray" },
    { id: "#29403c", title: "GrayDarkSlate" },
    { id: "#289b2b", title: "Green" },
    { id: "#025e00", title: "GreenDark" },
    { id: "#90b180", title: "GreenDarkSea" },
    { id: "#7c0000", title: "Maroon" },
    { id: "#807700", title: "Olive" },
    { id: "#e84819", title: "Orange" },
    { id: "#fdc479", title: "Peach" },
    { id: "#e207d3", title: "Pink" },
    { id: "#7d0071", title: "Purple" },
    { id: "#f70000", title: "Red" },
    { id: "#047770", title: "Teal" },
    { id: "#f3cd11", title: "Yellow" }
];


export const schedules = [
    { id: "1", title: "06:00" },
    { id: "2", title: "06:30" },
    { id: "3", title: "07:00" },
    { id: "4", title: "07:30" },
    { id: "5", title: "08:00" },
    { id: "6", title: "08:30" },
    { id: "7", title: "09:00" },
    { id: "8", title: "09:30" },
    { id: "9", title: "10:00" },
    { id: "10", title: "10:30" },
    { id: "11", title: "11:00" },
    { id: "12", title: "11:30" },
    { id: "13", title: "12:00" },
    { id: "14", title: "12:30" },
    { id: "15", title: "13:00" },
    { id: "16", title: "13:30" },
    { id: "17", title: "14:00" },
    { id: "18", title: "14:30" },
    { id: "19", title: "15:00" },
    { id: "20", title: "15:30" },
];

export const maintenanceSuppliers = [
    { id: "1", title: "Chiltern Cooling" },
    { id: "2", title: "LV Motors" },
    { id: "3", title: "RS Refrigeration" },
    { id: "4", title: "Mechanic" },
    { id: "5", title: "Morning Checker" },
    { id: "6", title: "Evening Checker" },
    { id: "7", title: "Vehicle Cleaning Team" },
    { id: "8", title: "Carcomm" },
    { id: "9", title: "SZL Tyres" },
    { id: "10", title: "Feltham Tyres" },
    { id: "11", title: "Body Shop" },
    { id: "12", title: "Other" },
    { id: "13", title: "Cordwallis" },
    { id: "14", title: "Phoenix" },
    { id: "15", title: "TLS" },
    { id: "16", title: "RGVA" },
    { id: "17", title: "RYGOR" },
    { id: "18", title: "DAF" },
    { id: "19", title: "Teletrac" },
];

export const maintenanceIssues = [
    { id: "1", title: "Mechanical Issue" },
    { id: "2", title: "Electrical Issue" },
    { id: "3", title: "Refrigeration Issue" },
    { id: "4", title: "Cabin Issue" },
    { id: "5", title: "Tail lift Issue" },
    { id: "6", title: "Mechanical Service" },
    { id: "7", title: "Refrigeration Service" },
    { id: "8", title: "Error on Dashboard" },
    { id: "9", title: "Body work issue" },
    { id: "10", title: "Tyre issue" },
    { id: "11", title: "Accessories / Fuel Caps issue" },
    { id: "12", title: "shutter Issue" },
    { id: "13", title: "Lamps/Lights Issue" },
];

export const maintenanceStatuses = [
    { id: "1", title: "Available (With Advisories)" },
    { id: "2", title: "Available (Short Route)" },
    { id: "3", title: "Available (Long Route)" },
    { id: "4", title: "Off Road" },
];

export const maintenanceReportedBy = [
    { id: "1", title: "Driver" },
    { id: "2", title: "Helper" },
    { id: "3", title: "Routing" },
    { id: "4", title: "Warehouse" },
    { id: "5", title: "Management" },
    { id: "6", title: "Mechanic" },
    { id: "7", title: "Public" },
    { id: "8", title: "Inspector" },
];

export const driverIssuesTypes = [
    { id: "1", title: "Accident" },
    { id: "2", title: "Insubordination" },
    { id: "3", title: "Damage/Loss" },
    { id: "4", title: "Holiday" },
    { id: "5", title: "General Log/Info" }
];

export const driverIssuesActions = [
    { id: "1", title: "Record" },
    { id: "2", title: "Train" },
    { id: "3", title: "Escalate" }
];

export const truckSide = [
    { id: "f", title: "Front" },
    { id: "r", title: "Right" },
    { id: "l", title: "Left" },
    { id: "t", title: "Tail" }
];

export const equipmentTypes = [
    { id: "1", title: "Forklift" },
    { id: "2", title: "Electric Pallet Truck" },
    { id: "3", title: "Manual Pallet Trucks" },
    { id: "4", title: "Sweepers" },
    { id: "5", title: "Temperature Monitors" },
    { id: "6", title: "Warehouse Trolleys" },
    { id: "7", title: "Hand-Ball Trolleys" },
    { id: "8", title: "Electric Pallet Truck (Stand On)" },
    { id: "9", title: "Electric Pallet Truck Battery" },
];

export const equipmentMakes = [
    { id: "1", title: "Toyota" },
    { id: "2", title: "Linde" },
    { id: "3", title: "EP Equipment" },
    { id: "4", title: "Mitsubishi"},
];

export const equipmentModels = [
    { id: "1", title: "LWE130" },
    { id: "2", title: "LWE200" },
    { id: "3", title: "BT Counterbalance" },
    { id: "4", title: "BT Reach" },
    { id: "5", title: "LHE130" },
    { id: "6", title: "E14" },
    { id: "7", title: "E20" },
    { id: "8", title: "F4" },
    { id: "9", title: "Reach" },
    { id: "10", title: "Counterbalance" },
];

export const equipmentOwnership = [
    { id: "1", title: "Fully owned" },
    { id: "2", title: "Leased" }
];

export const equipmentStatus = [
    { id: "1", title: "Available" },
    { id: "2", title: "Available (With advisories)" },
    { id: "3", title: "Unavailable" }
];

export const equipmentMaintenanceTypes = [
    { id: "1", title: "Battery"},
    { id: "2", title: "Bodyword (Chassis)"},
    { id: "3", title: "Electric"},
    { id: "4", title: "Forks"},
    { id: "5", title: "Horn"},
    { id: "6", title: "Lights"},
    { id: "7", title: "Loler"},
    { id: "8", title: "Roof"},
    { id: "9", title: "Tyres"}
];

export const equipmentMaintenanceSupplier = [
    { id: "1", title: "N A Services"},
    { id: "2", title: "Toyota"}
];

export const locationTypes = [
    { id: "1", title: "Picking"},
    { id: "2", title: "Binning"}
];

export const yesNo = [
    { id: "1", title: "Yes"},
    { id: "0", title: "No"}
];

export const pcnContraventionTypes = [
    { id: "1", title: "Parked or driving through bus lane", fault: "2", action: "1" },
    { id: "2", title: "Parked or driving in restricted (Area/Hours)", fault: "1", action: "1" },
    { id: "3", title: "Vehicle restriction (Weight/Height)", fault: "1", action: "2" },
    { id: "4", title: "Parked with wheel on curb", fault: "2", action: "2" },
    { id: "5", title: "Parked or driving through Red Route", fault: "2", action: "1" },
    { id: "6", title: "Stopped at box junction", fault: "2", action: "2" },
    { id: "7", title: "Performing prohibities (left/right turn)", fault: "2", action: "2" },
    { id: '8', title: "Congestion Charge", fault: "1", action: "1" },
    { id: '9', title: "Drive without HGV safety permit", fault: "1", action: "1" } 
]

export const pcnFaultTypes = [
    { id: "1", title: "Company" },
    { id: "2", title: "Driver"}
]

export const PcnStatusTypes = [
    { id: "1", title: "Appeal Pending", background: "#FEE12B"   },
    { id: "2", title: "Appeal Rejected", background: "#FF0000"},
    { id: "3", title: "Appeal Accepted", background: "#0080FE" },
    { id: "4", title: "Payment Pending", background: "#FFA500" },
    { id: "5", title: "Paid", background: "#3BB143" }
]

export const pcnActionTypes = [
    { id: "1", title: "Appeal" },
    { id: "2", title: "Send For Payment"}
]
export const pcnTypes = [
    { id: "1", title: "Route" },
    { id: "2", title: "Non-Route"}
]
export const fileAttachments = [
    { id: "1", title: "Yes" },
    { id: "2", title: "No"}
]


export const deliveryStatuses = [
    { id: 1, title: "Customer Over Ordered" },
    { id: 2, title: "Customer Returned" },
    { id: 3, title: "Damaged In Transit" },
    { id: 4, title: "Delivery Fee Discount" },
    { id: 5, title: "Full Delivery Return" },
    { id: 6, title: "Invoice Error" },
    { id: 7, title: "Item Discount" },
    { id: 8, title: "Loading Error" },
    { id: 9, title: "Pricing Error" },
    { id: 10, title: "Short Dated" },
    { id: 11, title: "Shortage" },
    { id: 12, title: "Delivered" },
  ];

export const userTypes = [
    { id: 1, title: 'Admin' },
    { id: 2, title: 'User' },
];

export const userRolesTypes = [
    { id: 1, title: 'Routing', modules: ['Routing',"Routes", "Drivers", 'Helpers', 'Vehicles', 'Rosters', 'Reports', 'Maintenance'] },
    { id: 2, title: 'Finance', modules: ['Finance Reports'] },
    { id: 4, title: 'Warehouse Manager', modules: ['Warehouse Reports'] },
    { id: 5, title: 'Warehouse User', modules: ['Warehouse Reports'] },
    { id: 6, title: 'Sales Manager', modules: ['Sales Reports'] },
    // { id: 7, title: 'Sales executive', modules: ['Sales Reports'] },

];

export const modulesPermissions = [
    { id: 1, title: 'Routing', routes: ['/home', '/dashboard'] },
    { id: 2, title: 'Routes', routes:  ['/routes', '/routes/:id'] },
    { id: 3, title: 'Drivers', routes: ['/drivers', '/drivers/:id'] },
    { id: 4, title: 'Helpers', routes: ['/helpers', '/helpers/:id'] },
    { id: 5, title: 'Vehicles', routes: ['/vehicles', '/vehicles/:id'] },
    { id: 6, title: 'Rosters', routes: ['/rosters', '/rosters/:id'] },
    { id: 7, title: 'Finance Reports', routes: ['/dashboard','/print_reports', '/all_returns', '/view_reports', '/discounts'] },
    { id: 8, title: 'Reports', routes: ['/dashboard','/pallets_report' ,'/print_reports', '/all_returns', '/view_reports'] },
    { id: 9, title: 'Warehouse Reports', routes: ['/dashboard','/print_reports', '/all_returns', '/view_reports'] },
    { id: 10, title: 'Maintenance', routes: ['/dashboard','/maintenance', '/equipments'] },
    { id: 11, title: 'Sales Reports', routes: ['/dashboard','/print_reports', '/all_returns', '/view_reports', '/location_report'] },

];

export const PostponeReasons = [
    { id: 1, title: "Customer Refusal" },
    { id: 2, title: "No Access to shop" },
    { id: 3, title: "Non-Payment" },
    { id: 4, title: "Out of Hours" },
    { id: 5, title: "Traffic Incident" },
    { id: 6, title: "Customer Unavailable" },
    { id: 7, title: "Vehicle Breakdown" },
  ];


export const cardTerminals = [
    { id: -1, title: "No Terminal" },
    { id: 1, title: "C1" },
    { id: 2, title: "C2" },
    { id: 3, title: "C3" },
    { id: 4, title: "C4" },
    { id: 5, title: "C5" },
    { id: 6, title: "C6" },
    { id: 7, title: "C7" },
    { id: 8, title: "C8" },
    { id: 9, title: "C9" },
    { id: 10, title: "C10" },
    { id: 11, title: "C11" },
    { id: 12, title: "C12" },
    { id: 13, title: "C13" },
    { id: 14, title: "C14" },
    { id: 15, title: "C15" },
    { id: 16, title: "C16" },
    { id: 17, title: "C17" },
    { id: 18, title: "C18" },
    { id: 19, title: "C19" },
    { id: 20, title: "C20" },
    { id: 21, title: "C21" },
    { id: 22, title: "C22" },
    { id: 23, title: "C23" },
    { id: 24, title: "C24" },
    { id: 25, title: "C25" },
    { id: 26, title: "C26" },
    { id: 27, title: "C27" },
    { id: 28, title: "C28" },
    { id: 29, title: "C29" },
    { id: 30, title: "C30" },
  ];

  export const accidentStatus = [
    { id: 1, title: 'Open'},
    { id: 0, title: 'Closed' },
];

export const discountStatus = [
    { id: 1, title: 'Pending' },
    { id: 2, title: 'Approved' },
    { id: 3, title: 'Rejected' },
];
export const PaidBy = [
    { id: 1, title: 'Company' },
    { id: 2, title: 'Insurance' },
];

export const accidentTypes = [
    { id: "1", title: "Route" },
    { id: "2", title: "Non-Route"},
    { id: "3", title: "Warehouse"}
]

export const ExcessPaidBy = [
    { id: 1, title: 'Company' },
    { id: 2, title: 'Driver' },
];

export const StockChangeReasons = [
    { id: 1, title: "Returned to Stock" },
    { id: 2, title: "Written Off" },
    { id: 3, title: "Missing" },
  ];

  export const DiscountStatuses = [
    { id: 1, title: "Pending", color: 'yellow' },
    { id: 2, title: "Accepted", color: 'green' },
    { id: 3, title: "Rejected", color: 'red' },
  ];

  export const DriverTypesCheck = [
    { id: 1, title: "Yes" },
    { id: 2, title: "No" },
  ]

  export const accidentFaults = [
    { id: 1, title: "Self" },
    { id: 2, title: "Other Party" },
  ]

  export const completedOptions = [
    { id: 1, title: "Yes"},
    { id: 2, title: "No"}
];

export const reportTypes = [
    { id: 1, title: "Fuel"},
    { id: 2, title: "Service"}
];

export const vehicleServiceTasks = [
    { id: 1, title: "MOT", interval: 1, intervalType: 'year', thresholdDays: 60, message: 'every year'},
    { id: 2, title: "Loler", interval: 6, intervalType: 'month', thresholdDays: 30, message: 'every six months'},
    { id: 3, title: "PMI", interval: 42, intervalType: 'day',thresholdDays: 7, message: 'every six weeks'},
    { id: 4, title: "Fridge Service", interval: 6, intervalType: 'month', thresholdDays: 14, message: 'every six months'},
    { id: 5, title: "Tacho Calibration", interval: 2, intervalType: 'year', thresholdDays: 60, message: 'every two years'},
    { id: 6, title: "Engine Servicing", interval: 6, intervalType: 'month', thresholdDays: 14, message: 'every six months'},
    { id: 7, title: "Road tax", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 8, title: "Insurance", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
];

export const DriverReminderTasks = [
    { id: 1, title: "Height Restriction Training", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 2, title: "Manual Handling", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 3, title: "Banksman", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 4, title: "Electric pump truck", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 5, title: "Work at height", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 6, title: "Tail lift operation & safety", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 7, title: "Trolley safe systems of work", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 8, title: "TMS Software", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 9, title: "Cash Up", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 10, title: "Navigation", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 11, title: "Customer Service", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 12, title: "Accident Reporting Procedure", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 13, title: "Injuries & First Aid", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 14, title: "Return Defect Reporting", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 15, title: "Product off-loading & hand balling", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 16, title: "Card Machines", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 17, title: "Rolls for card machines", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 18, title: "Spare tacho rolls", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 19, title: "Cash counter rolls", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 20, title: "Pallet wrap roll", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 21, title: "Fridge & Freezer Training", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 22, title: "Spare key for Regeneration", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 23, title: "Locating parking", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 24, title: "Dealing with restricted parking areas", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 25, title: "End of day product returns & logging", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 26, title: "Endorsements", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 27, title: "Tachograph Training", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 28, title: "PPE Training", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 29, title: "Weight Restriction Training", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
];

export const HelperReminderTasks = [
    { id: 1, title: "Temperature Control", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 2, title: "Manual Handling", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 3, title: "Banksman", interval: 1, intervalType: 'year',thresholdDays: 30, message: 'every year'},
    { id: 4, title: "Electric pump truck", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 5, title: "Work at height", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 6, title: "PPE", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 7, title: "Trolley safe systems of work", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 8, title: "TMS Software", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 9, title: "Tail lift operation & safety", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 10, title: "Fridge & Freezer Training", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 12, title: "End of day product returns & logging", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 13, title: "Driver Mates Training", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 14, title: "Three strike rule", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 15, title: "PPE Agreement", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 16, title: "Equipment Authorisation", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 17, title: "Card Spot Agreement", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 18, title: "Card Spot Form", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 19, title: "Breathalyser Agreement", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 20, title: "Breathalyser Form", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 21, title: "Endorsements Agreement", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
    { id: 22, title: "Endorsements Form", interval: 1, intervalType: 'year', thresholdDays: 30, message: 'every year'},
];

export const completeStatuses = [
    { id: 1, title: "Yes"},
    { id: 2, title: "No"}
];

export const vehicleTypeOptions = [
    { id: 1, title: "Truck"},
    { id: 2, title: "Van"},
    { id: 3, title: "Company Car"}
];

export const vehicleMakeOptions = [
    { id: 1, title: "DAF"},
    { id: 2, title: "Mercedes - Benz"},
    { id: 3, title: "Mercedes"},
    { id: 4, title: "MAN"},
];

export const vehicleModelOptions = [
    { id: 1, title: "LF 230"},
    { id: 2, title: "LF"},
    { id: 3, title: "Sprinter"},
    { id: 4, title: "Antos"},
    { id: 5, title: "CF 230"},
    { id: 6, title: "TGM"},
];

export const vehicleStatusOptions = [
    { id: 1, title: "Active"},
    { id: 2, title: "Inactive"},
];

export const vehicleColors = [
    { id: 1, title: "Black" },
    { id: 2, title: "White" },
    { id: 3, title: "Silver" },
    { id: 4, title: "Gray" },
    { id: 5, title: "Red" },
    { id: 6, title: "Blue" },
    { id: 7, title: "Green" },
    { id: 8, title: "Yellow" },
    { id: 9, title: "Orange" },
    { id: 10,title:  "Purple" },
    { id: 11,title:  "Brown" },
    { id: 12,title:  "Beige" },
    { id: 13,title:  "Maroon" },
    { id: 14,title:  "Turquoise" },
    { id: 15,title:  "Teal" },
    { id: 16,title:  "Pink" },
    { id: 17,title:  "Gold" },
    { id: 18,title:  "Copper" },
    { id: 19,title:  "Bronze" },
    { id: 20,title:  "Champagne" },
    { id: 21,title:  "Lime" },
    { id: 22,title:  "Ivory" },
    { id: 23,title:  "Seafoam Green" },
    { id: 24,title:  "Electric Blue" },
    { id: 25,title:  "Sky Blue" },
    { id: 26,title:  "Slate Gray" },
    { id: 27,title:  "Forest Green" },
    { id: 28,title:  "Sand" },
    { id: 29,title:  "Charcoal" },
    { id: 30,title:  "Gunmetal" },
    { id: 31,title:  "Aqua" },
    { id: 32,title:  "Cerulean" },
    { id: 33,title:  "Ruby Red" },
    { id: 34,title:  "Burnt Orange" },
    { id: 35,title:  "Copper Red" },
    { id: 36,title:  "Rose Gold" },
    { id: 37,title:  "Plum" },
    { id: 38,title:  "Wine" },
    { id: 39,title:  "Sea Blue" },
    { id: 40,title:  "Mint Green" }
]

export const vehicleDefects = [
    { required: true, key: "battery_security", id: "1", title: "Battery Security", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "spray_suppression", id: "2", title: "Spray Suppression", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "registration_plates", id: "3", title: "Registration Plates", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "seat_belt", id: "4", title: "Seat Belt", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "warning_lights", id: "5", title: "Warning Lights", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "wipers", id: "6", title: "Wipers", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "exhaust_smoke", id: "7", title: "Exhaust Smoke", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "reflectors", id: "8", title: "Reflectors", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "indicators", id: "9", title: "Indicators", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "adblue", id: "10", title: "Adblue", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "cab_steps", id: "11", title: "Cab Steps", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "fuel_leaks", id: "12", title: "Fuel Leaks", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "wheels_tyres_fixing", id: "13", title: "Wheels Tyres Fixing", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "steering", id: "14", title: "Steering", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "brakes", id: "15", title: "Brakes", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "air_build", id: "16", title: "Air Build", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "odometer", id: "17", title: "Odometer", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "tachograph", id: "18", title: "Tachograph", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "security_load", id: "19", title: "Security Load", status: false, images: [], notes: '', not_road_worthy: false },
    { required: false, key: "brake_lines", id: "20", title: "Brake Lines", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "mirrors", id: "21", title: "Mirrors", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "fit_to_drive", id: "22", title: "Fit to Drive", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "licence_visible", id: "23", title: "Licence Visible", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "glass_visibility", id: "24", title: "Glass Visibility", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "doors", id: "25", title: "Doors", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "first_aid_kit", id: "26", title: "First Aid Kit", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "passenger_seat_belt", id: "27", title: "Passenger Seat Belt", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "fire_extinguisher", id: "28", title: "Fire Extinguisher", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "tail_lift", id: "29", title: "Tail Lift", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "alternative_fuels", id: "30", title: "Alternative Fuels", status: false, images: [], notes: '', not_road_worthy: false },
  
  
    { required: false, key: "body_condition", id: "31", title: "Body Condition", status: false, images: [], notes: '', not_road_worthy: false },
    { required: false, key: "coupling_security", id: "32", title: "Coupling Security", status: false, images: [], notes: '', not_road_worthy: false },
    { required: false, key: "electrical_connections", id: "33", title: "Electrical Connections", status: false, images: [], notes: '', not_road_worthy: false },
    { required: false, key: "saloon_lighting", id: "34", title: "Saloon Lighting", status: false, images: [], notes: '', not_road_worthy: false },
    { required: false, key: "heating", id: "35", title: "Heating", status: false, images: [], notes: '', not_road_worthy: false },
    { required: false, key: "wheelchair", id: "36", title: "Wheelchair", status: false, images: [], notes: '', not_road_worthy: false },
    { required: false, key: "communication_devices", id: "37", title: "Communication Devices", status: false, images: [], notes: '', not_road_worthy: false },
    { required: false, key: "prominent_warning_signage", id: "38", title: "Prominent Warning Signage", status: false, images: [], notes: '', not_road_worthy: false },
    { required: false, key: "side_under_run_protection", id: "39", title: "Side Under Run Protection", status: false, images: [], notes: '', not_road_worthy: false },
    { required: false, key: "camera_system", id: "40", title: "Camera System", status: false, images: [], notes: '', not_road_worthy: false },
    { required: false, key: "fresnel_lenses", id: "41", title: "Fresnel Lenses", status: false, images: [], notes: '', not_road_worthy: false },
    { required: false, key: "high_voltage_emergency_cut_off_switch", id: "42", title: "High Voltage Emergency Cut Off Switch", status: false, images: [], notes: '', not_road_worthy: false },
    { required: false, key: "adas", id: "43", title: "Adas", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "horn", id: "44", title: "Horn", status: false, images: [], notes: '', not_road_worthy: false },
    { required: true, key: "pumptruck", id: "45", title: "Pumptruck Working", status: false, images: [], notes: '', not_road_worthy: false },

  ];

  export const vehicleDueOptions = [
    { id: 1, title: "Overdue"},
    { id: 2, title: "Due Soon"},
    { id: 3, title: "Upcoming"},
];

export const selectYesNo = [
    { id: 1, title: "Yes" },
    { id: 2, title: "No" },
]

export const Ratings = [
    { id: 0, title: "0" },
    { id: 1, title: "1" },
    { id: 2, title: "2" },
    { id: 3, title: "3" },
    { id: 4, title: "4" },
    { id: 5, title: "5" },
  ]

  export const LevelReading = [
    { id: 1, title: "Full" },
    { id: 2, title: "Three Quarters" },
    { id: 3, title: "Half" },
    { id: 4, title: "One Quarter" },
  ];

  export const AuthReqTypes = [
    { id: 1, title: "Delivery" },
    { id: 2, title: "Online" },
    { id: 3, title: "Non Payment" },
    { id: 4, title: "Partial Payment" },
  ];

  export const shortDays = [
  { id: 0, name: "Mon" },
  { id: 1, name: "Tue" },
  { id: 2, name: "Wed" },
  { id: 3, name: "Thu" },
  { id: 4, name: "Fri" },
  { id: 5, name: "Sat" },
  { id: 6, name: "Sun" },
];

export const orderStatuses = [
    { id: 0, title: "Not Submitted" },
    { id: 1, title: "Submitted" }
  ];

export const dashboardColors = {
  Revenue: "#16A34A",
  Orders: "#2563EB",
  Routes: "#7C3AED", 
  Vehicles: "#14B8A6",
  Weight: "#F97316",
  Delivered: "#10B981", 
  NotDelivered: "#DC2626",
  Postponed: "#FACC15",
  Pending: "#9333EA",
  Active: "#3B82F6",
  Inactive: "#6B7280", 
  Critical: "#BE123C",
};

export const issuePriorities = [
    { id: 1, title: "High"},
    { id: 2, title: "Medium"},
    { id: 3, title: "Low"}
];

export const VATPercentages = [
    { id: 1, title: "0%", percent: 0},
    { id: 2, title: "20%", percent: 20},
];

export const InventorySuppliers = [
    { id: 1, title: "Chiltern Cooling" },
    { id: 2, title: "LV Motors" },
    { id: 3, title: "RS Refrigeration" },
    { id: 4, title: "Mechanic" },
    { id: 5, title: "Morning Checker" },
    { id: 6, title: "Evening Checker" },
    { id: 7, title: "Vehicle Cleaning Team" },
    { id: 8, title: "Carcomm" },
    { id: 9, title: "SZL Tyres" },
    { id: 10, title: "Feltham Tyres" },
    { id: 11, title: "Body Shop" },
    { id: 12, title: "Other" },
    { id: 13, title: "Cordwallis" },
    { id: 14, title: "Phoenix" },
    { id: 15, title: "TLS" },
    { id: 16, title: "RGVA" },
    { id: 17, title: "RYGOR" },
    { id: 18, title: "DAF" },
    { id: 19, title: "Teletrac" },
];

export const InventoryServices = [
    { id: 1, title: "Labour" },
];

