import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Divider,
  Row,
  Statistic,
  Table,
  Switch,
  Button,
  Typography,
  Collapse,
  Popconfirm,
  Descriptions,
  Image,
  Tag,
  Input,
  Modal,
  DatePicker,
  message,
  Tooltip,
  Result,
  Badge,
} from "antd";
import "./reports.css";
import { makeStyles } from "@mui/styles";
import { CheckCircleFilled, CheckOutlined, CloseCircleFilled, CloseOutlined, CloudDownloadOutlined, ExpandAltOutlined, EyeFilled, InfoCircleOutlined } from "@ant-design/icons";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import {
  cardTerminals,
  deliveryStatuses,
  PostponeReasons,
  userRolesTypes,
} from "../../Components/common/constants";
import { useSelector } from "react-redux";
import { netsuite_url, tms_uploads_cdn, env } from "../../Components/config";
import { ActionDialog } from "../../Components/actionDialog/ActionDialog";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import { formatCurrency } from "../../Components/common/CommonFun";
import DetailsModal from "./DetailsModal";
// import { config } from "../../Components/config/";

const colors = ["#f0f0f0", "#e6f7ff", "#faf0e6", "#fcf8e3"];
const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: '15px',
    "& .ant-card .ant-card-body": {
      padding: "4px",
      // backgroundColor: 'rgb(240, 240, 240)',
    },
    "& .ant-table-thead>tr>th": {
      padding: "0px 4px !important"
    },
    "& .ant-statistic .ant-statistic-content": {
      fontSize: "12px",
      fontWeight: "bold",
    },
    "& .ant-statistic .ant-statistic-title": {
      fontSize: "12px",
    },
    "& .ant-input[disabled]": {
      background: "none",
      color: "black",
      fontWeight: "400",
    },
    "& .ant-card-bordered": {
      border: '1px solid #b1aeae'
    }
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
    "& .ant-table-footer": {
      padding: '16px 0px !important'
    }
  },
}));
const { Text } = Typography;

const DriverSheetTab = ({
  driverSheetData,
  setDriverSheetData,
  postData,
  setPostData,
  paymentAuthorizer,
}) => {
  const { roles } = useSelector((state) => state.AppState?.userTypes);
  const UserType = useSelector((state) => state.AppState?.userTypes?.type);
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const [filesOpen, setFilesOpen] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState("");
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  // const [disabledPost, setDisabledPost] = React.useState(false);
  const [disabledRoutes, setDisabledRoutes] = React.useState([]);
  const [screenWidth, setScreenWidth] = React.useState("");

  // const [postData, setPostData] = React.useState(paymentDataPost1);
  console.log('postData', postData);
  

  const [actionDialogProps, setActionDialogProps] = useState({
    message: "",
    type: "",
    isOpen: false,
  });

  const ShowActionDialog = (
    message,
    type,
    isOpen,
    OnOkCallback,
    OnCancellCallback
  ) => {
    setActionDialogProps((prevState) => ({
      ...prevState,
      message: message,
      type: type,
      isOpen: isOpen,
      onOk: OnOkCallback,
      onCancel: OnCancellCallback,
    }));
  };


  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  React.useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const generateColor = (index) => {
    const colors = ["#f0f0f0"];
    return colors[0];
  };
  const { Panel } = Collapse;

  const handleFilesOpen = (row) => {
    console.log("row", row);
    if (row) {
      setSelectedRecord(row);
    }
    setFilesOpen(true);
  };
  const handleFilesClose = () => setFilesOpen(false);

  const matchedRoles = roles
    .map((role) => {
      return userRolesTypes.find((userRole) => userRole.id === role.role_id);
    })
    .filter(Boolean);
  const includesFinance = matchedRoles.some((item) => item.title === "Finance");

  console.log('post', disabledRoutes);
  

  const dataCol = [
    {
      title: "S.No.",
      dataIndex: "rank",
      key: "rank",
      width: 55,
    },

    // {
    //   title: "Shop Code",
    //   dataIndex: "customerName",
    //   key: "customerName",
    //   width: 100
    // },
    {
      title: "Shop Code",
      dataIndex: "customerName",
      // key: "customerName",
      render: (text, record) => {
        return (
          <span>
            <a
              href={`${netsuite_url}/app/common/entity/custjob.nl?id=${record.customerInternalId}`}
              target="_blank"
            >
              {record.customerName}
            </a>
          </span>
        );
      },
      width: 100,
    },
    {
      title: "Shop Name",
      dataIndex: "customerProjectId",
      key: "customerProjectId",
      width: 200,
    },
    // {
    //   title: "Invoice",
    //   dataIndex: "invoice_no",
    //   key: "invoice_no",
    //   width: 100
    // },
    {
      title: 'Order No.',
      render: (text, record) => {
          return (
              <span>
                  {record.invoice_no != null && record?.invoice_no != "" ? (
                      <a 
                          href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${record.invoiceInternalId}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                      >
                          {record.invoice_no}
                      </a>
                  ) : (
                      <a 
                          href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${record.internalId}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                      >
                          {record.saleOrderNo}
                      </a>
                  )}
              </span>
          );
      },
      width: 100
    },
    {
      title: "Total (£)",
      dataIndex: "orderAmount",
      key: "orderAmount",
      render: (text, record) =>
        Number(record.orderAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) || "0.00",
      width: 100,
    },
    {
      title: "Cash",
      children: [
        {
          title: "Driver",
          align: "center",
          key: "amountReceivedCash",
          render: (text, record) => ({
            props: {
              style: { background: "rgb(240, 240, 240)" },
            },
            children:
              Number(record.amountReceivedCash).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || "0.00",
          }),
          width: 75,
        },
        {
          title: "Post",
          align: "center",
          key: "amountReceivedCashInput",
          render: (text, record) => ({
            props: {
              style: { background: "" },
            },
            children: (
              <Input
                type="number"
                disabled={disabledRoutes.includes(record?.routeStatusID) || record?.delivered != 1}
                placeholder="Post"
              style={{ background: record?.delivered != 1 ? '#f0f0f0' : '', color: record?.delivered != 1 ? '#6d6a6a' : ''  }}
                
                value={
                  // postData[record.palletOrderID]?.amount_received_cash_post !== null &&
                  // postData[record.palletOrderID]?.amount_received_cash_post !== undefined
                  //   ?
                  record?.delivered == 1 ? postData[record.palletOrderID]?.amount_received_cash_post : '0.00'
                    // : record.amountReceivedCash
                }
                onChange={(e) => {
                  const newValue = Math.max(0, e.target.value);
                  handlePostCashDataChange(e.target.value, record);
                }}
              />
            ),
          }),
          width: 100,
        },
      ],
      width: 300,
    },
    {
      title: "Card",
      children: [
        {
          title: "Driver",
          align: "center",
          key: "amountReceivedCard",
          render: (text, record) => ({
            props: {
              style: { background: "rgb(240, 240, 240)" },
            },
            children:
              Number(record.amountReceivedCard).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || "0.00",
          }),
          width: 75,
        },
        {
          title: "Post",
          align: "center",
          key: "amountReceivedCardInput",
          render: (text, record) => (
            <Input
              type="number"
              disabled={disabledRoutes.includes(record?.routeStatusID) || record?.delivered != 1}
              placeholder="Post"
              value={
                // postData[record.palletOrderID]?.amount_received_card_post !== null &&
                // postData[record.palletOrderID]?.amount_received_card_post !== undefined
                  // ? 
                  record?.delivered == 1 ? postData[record.palletOrderID]?.amount_received_card_post : '0.00'
                  // : record.amountReceivedCard
              }
              onChange={(e) => {
                const newValue = Math.max(0, e.target.value);
                handlePostCardDataChange(e.target.value, record);
              }}
              style={{ background: record?.delivered != 1 ? '#f0f0f0' : '', color: record?.delivered != 1 ? '#6d6a6a' : ''  }}
            />
          ),
          width: 100,
        },
      ],
      width: 300,
    },
    // {
    //   title: "Bank",
    //   children: [
    //     {
    //       title: "Driver",
    //       align: "center",
    //       key: "amountReceivedAccount",
    //       render: (text, record) => ({
    //         props: {
    //           style: { background: "rgb(240, 240, 240)" },
    //         },
    //         children:
    //           Number(record.amountReceivedAccount).toLocaleString(undefined, {
    //             minimumFractionDigits: 2,
    //             maximumFractionDigits: 2,
    //           }) || "0.00",
    //       }),
    //       width: 75,
    //     },
    //     {
    //       title: "Post",
    //       align: "center",
    //       key: "amountReceivedAccountInput",
    //       render: (text, record) => (
    //         <Input
    //           type="number"
    //           disabled={disabledRoutes.includes(record?.routeStatusID)}
    //           value={
    //             postData[record.palletOrderID]?.amount_received_account_post !== null &&
    //             postData[record.palletOrderID]?.amount_received_account_post !== undefined
    //               ? postData[record.palletOrderID]?.amount_received_account_post
    //               : record.amountReceivedAccount
    //           }
    //           onChange={(e) => {
    //             const newValue = Math.max(0, e.target.value);
    //             handlePostAccountDataChange(e.target.value, record);
    //           }}
    //         />
    //       ),
    //       width: 100,
    //     },
    //   ],
    //   width: 300,
    // },
    {
      title: "Status",
      render(record) {
        let statusText;
        let backgroundColor;
        // let delivered_at = record?.delivered_at || "N/A";
        const delivered_at = record?.delivered_at && moment(record.delivered_at).isValid()
              ? moment(record.delivered_at).format('YYYY-MM-DD HH:mm:ss')
              : "N/A";



        switch (record?.delivered) {
          case 1:
            statusText = "Delivered";
            backgroundColor = "#80cb80";
            break;
          case 2:
            statusText = "Postponed";
            backgroundColor = "#f0d787";
            break;
            case -1:
            statusText = "Submitted";
            backgroundColor = "#1890FF";
            break;
          default:
            statusText = "Not Delivered";
            backgroundColor = "#edb1a7";
            break;
        }

        return {
          props: {
            style: {
              background: backgroundColor,
              textAlign: 'center',

            },
          },
          children: (
            <>
            {statusText}
            {record?.delivered === 1 && (
              <Tooltip title={`Delivered at: ${delivered_at}`}>
                <InfoCircleOutlined style={{ marginLeft: 8, color: "#000" }} />
              </Tooltip>
            )}
          </>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Action",
      align: "center",
      width: 75,
      render(record) {
        return (
          // <EyeFilled
          //   style={{ cursor: "pointer", fontSize: "25px" }}
          //   onClick={() => {
          //     handleFilesOpen(record);
          //   }}
          // />
          <Badge
                  color="#1890ff"
                  dot={record?.delivery_instructions || record?.notes || record?.payment_req_notes || record?.delivery_discout_notes || record?.postponed_notes }

                >
              <EyeFilled
                style={{ cursor: "pointer", fontSize: "25px" }}
                onClick={() => {
                  handleFilesOpen(record);
                }}
              />
              </Badge>
        );
      },
    },
  ];

  const [expandAll, setExpandAll] = useState(false);

  const onClickExpand = () => {
    let driverSheetData1 = [...driverSheetData];

    if (expandAll) {
      driverSheetData1.map((route, rIdx) => {
        route.expanded = false;
      });
    } else {
      driverSheetData1.map((route, rIdx) => {
        route.expanded = true;
      });
    }
    setDriverSheetData(driverSheetData1);
    setExpandAll(!expandAll);
  };

  const handleRouteExpand = (route_idx) => {
    const updatedData = [...driverSheetData];
    updatedData[route_idx].expanded = !updatedData[route_idx].expanded;
    setDriverSheetData(updatedData);
  };

  useEffect(() => {
    if (driverSheetData.length === 1) {
      setDriverSheetData((prevData) => {
        return prevData.map((item) => ({
          ...item,
          expanded: true,
        }));
      });
    }
  }, []);

  const downloadCSVFile = (id) => {
    const dataObject = driverSheetData.find((item, idx) => idx === id);
    console.log("dataObject", dataObject);

    // Extract formatted_date and driver fields
    const formattedDate = dataObject.formatted_date.replace(/ /g, "-"); // Replace spaces with dashes
    const driverName = dataObject.driver.replace(/\s+/g, ""); // Remove spaces

    // Define the filename
    const filename = `${formattedDate}_${dataObject.driverRoute}_DriverSheet.csv`;

    // Define fields to exclude from tableData
    const excludedTableDataFields = [
      "invoiceInternalId",
      "palletOrderID",
      "customerInternalId",
    ];

    // Define a mapping object to rename tableData headers
    const tableDataHeaderMapping = {
      rank: "S No.",
      customerName: "Shop Code",
      customerProjectId: "Shop Name",
      invoice_no: "Invoice No",
      orderAmount: "Total",
      amountReceivedCash: "Cash",
      amountReceivedCard: "Card",
      amountReceivedAccount: "Bank",
    };

    // Define simple fields mapping with the desired sequence
    const simpleFieldsMapping = {
      route: "Route No",
      driver: "Driver",
      truck: "Truck Reg.",
      driverRoute: "Route",
      terminal: "CC Terminal No.",
      totalCash: "Total Cash",
      totalCard: "Total Card",
      totalAccount: "Total Account",
    };

    // Define the desired sequence of simple fields
    const simpleFieldsSequence = [
      "route",
      "driver",
      "truck",
      "driverRoute",
      "terminal",
      "totalCash",
      "totalCard",
      "totalAccount",
    ];

    // Define the desired sequence of table fields
    const tableFieldsSequence = [
      "rank",
      "customerName",
      "customerProjectId",
      "invoice_no",
      "orderAmount",
      "amountReceivedCash",
      "amountReceivedCard",
      "amountReceivedAccount",
    ];

    // Extract all fields from the dataObject, excluding 'expanded' and 'formatted_date'
    const fields = Object.keys(dataObject).filter(
      (field) =>
        field !== "expanded" &&
        field !== "formatted_date" &&
        field !== "routeStatusID"
    );

    // Separate simple fields and tableData
    const tableDataField = fields.find((field) => field === "tableData");

    // Format data into CSV string
    let csvData = "";

    // Loop through each simple field in the desired sequence
    simpleFieldsSequence.forEach((field) => {
      if (dataObject[field] !== undefined) {
        let fieldName = simpleFieldsMapping[field] || field;
        let fieldValue = dataObject[field];

        // Concatenate 'C' to the terminal field value
        if (field === "terminal") {
          fieldValue = `C${fieldValue}`;
        }

        csvData += `${fieldName},${fieldValue}\n`;
      }
    });

    // Process tableData if it exists
    if (tableDataField) {
      // Add tableData headers to CSV in the desired sequence
      const renamedHeaders = tableFieldsSequence
        .filter((header) => !excludedTableDataFields.includes(header))
        .map((header) => tableDataHeaderMapping[header] || header);
      csvData += `${renamedHeaders.join(",")}\n`;

      // Loop through each row in tableData and add to CSV
      dataObject[tableDataField].forEach((row) => {
        // Add row data in the desired sequence
        const rowData = tableFieldsSequence
          .filter(
            (key) =>
              !excludedTableDataFields.includes(key) && row[key] !== undefined
          )
          .map((key) => row[key]);
        csvData += `${rowData.join(",")}\n`;
      });
    }

    // Create a Blob object from the CSV data
    const blob = new Blob([csvData], { type: "text/csv" });

    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);

    // Append the link to the body and click it to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up: remove the link and revoke the URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const genExtra = () => {
    
    const posted = driverSheetData[0]?.payments_posted_by > 0 ? true : false;
  const completed = driverSheetData[0]?.start_status === 2 ? true : false;

    return (
      <div style={{ display: 'flex', alignItems: 'center'}}>
        {/* <Button
            style={{
              marginRight: '5px',
              color: completed  ? 'green' : 'red',
              cursor: 'alias',
              pointerEvents: "none",
              padding: '4px 4px'
            }}
            icon={completed ? <CheckOutlined/> : <CloseOutlined />}
              >
                Route Completed
          </Button> */}
            <Button
              style={{
                marginRight: '5px',
                color: posted || disabledRoutes.includes(driverSheetData[0].routeStatusID) ? 'green' : 'red',
                cursor: 'alias',
                pointerEvents: "none",
                padding: '4px 4px'
              }}
              icon={posted || disabledRoutes.includes(driverSheetData[0].routeStatusID) ? <CheckOutlined/> : <CloseOutlined />}
                >
                  Payment Posted
            </Button>
                 
    <Tooltip title="Download">
    <CloudDownloadOutlined
    style={{ fontSize: '25px', color: '#1677FF'}}
    onClick={(e) => {
      e.stopPropagation();
      downloadCSVFile(0);
    }}
     />
     </Tooltip>
    </div>
    )
};

  // const genExtra = (id) => (
  //   <Button
  //     type="primary"
  //     size="large"
  //     onClick={(e) => {
  //       e.stopPropagation();
  //       downloadCSVFile(id);
  //     }}
  //   >
  //     Download
  //   </Button>
  // );

  // useEffect(() => {
  //   const storedDisabledRoutes =
  //     JSON.parse(localStorage.getItem("disabledRoutes")) || [];
  //   setDisabledRoutes(storedDisabledRoutes);
  // }, []);

  useEffect(() => {
    localStorage.setItem("disabledRoutes", JSON.stringify(disabledRoutes));
  }, [disabledRoutes]);

  useEffect(() => {
    // Retrieve previously stored disabledRoutes from localStorage
    const storedDisabledRoutes = JSON.parse(localStorage.getItem("disabledRoutes")) || [];
  
    // Check driverData and add routeStatusID to disabledRoutes if payments_posted_by > 1
    const newDisabledRoutes = driverSheetData
      .filter(driver => driver.payments_posted_by > 0)
      .map(driver => driver.routeStatusID);
  
    // Merge stored disabledRoutes with new ones, avoiding duplicates
    const updatedDisabledRoutes = [...new Set([...storedDisabledRoutes, ...newDisabledRoutes])];
  
    // Update the state and localStorage
    setDisabledRoutes(updatedDisabledRoutes);
    localStorage.setItem("disabledRoutes", JSON.stringify(updatedDisabledRoutes));
  }, [driverSheetData]);
  

  const handlePostCashDataChange = (value, record) => {
    const updatedDeliveryQuantities = {
      ...postData,
      [record.palletOrderID]: {
        ...postData[record.palletOrderID],
        amount_received_cash_post: value,
      },
    };
    setPostData(updatedDeliveryQuantities);
  };

  const handlePostCardDataChange = (value, record) => {
    const updatedDeliveryQuantities = {
      ...postData,
      [record.palletOrderID]: {
        ...postData[record.palletOrderID],
        amount_received_card_post: value,
      },
    };
    setPostData(updatedDeliveryQuantities);
  };

  const handlePostAccountDataChange = (value, record) => {
    const updatedDeliveryQuantities = {
      ...postData,
      [record.palletOrderID]: {
        ...postData[record.palletOrderID],
        amount_received_account_post: value,
      },
    };
    setPostData(updatedDeliveryQuantities);
  };

  const [selectedDates, setSelectedDates] = useState({});

  useEffect(() => {
    const initialDates = driverSheetData.reduce((acc, data, index) => {
      acc[index] = data?.postingDateInitial
        ? dayjs(data.postingDateInitial)
        : null;
      return acc;
    }, {});

    setSelectedDates(initialDates);
  }, [driverSheetData]);

  const handleDateChange = (date, index) => {
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [index]: date,
    }));
  };

  console.log("selected Date", selectedDates);

  const postPayemnts = async (routeStatusID, index) => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    const selectedDate = selectedDates[index];
    const filterData = driverSheetData.filter(
      (obj) => obj.routeStatusID == routeStatusID
    );

    // const payload = filterData.flatMap((route) =>
    //   route.tableData.map((item) => ({
    //     amount_received_cash_post:
    //       postData[item.palletOrderID]?.amount_received_cash_post ??
    //       item?.amountReceivedCash,
    //     amount_received_card_post:
    //       postData[item.palletOrderID]?.amount_received_card_post ??
    //       item?.amountReceivedCard,
    //     // amount_received_account_post:
    //     //   postData[item.palletOrderID]?.amount_received_account_post ??
    //     //   item?.amountReceivedAccount,
    //     amount_received_cash: item?.amountReceivedCash,
    //     amount_received_card: item.amountReceivedCard,
    //     // amount_received_account: item.amountReceivedAccount,
    //     inv_internal_id: item.invoiceInternalId,
    //   }))
    // );

    const payload = filterData.flatMap((route) =>
      route.tableData
        .filter((item) => item.delivered === 1 && item.invoiceInternalId > 0) 
        .map((item) => ({
          amount_received_cash_post:
            postData[item.palletOrderID]?.amount_received_cash_post ??
            item?.amountReceivedCash,
          amount_received_card_post:
            postData[item.palletOrderID]?.amount_received_card_post ??
            item?.amountReceivedCard,
          // amount_received_account_post:
          //   postData[item.palletOrderID]?.amount_received_account_post ??
          //   item?.amountReceivedAccount,
          amount_received_cash: item?.amountReceivedCash,
          amount_received_card: item.amountReceivedCard,
          // amount_received_account: item.amountReceivedAccount,
          inv_internal_id: item.invoiceInternalId,
        }))
    );
    
    const dataToSend = {
      route_status_id: routeStatusID,
      post_payments: payload,
      posting_date_ns: moment(new Date(selectedDate)).format("DD/MM/YYYY"),
      posting_date: moment(new Date(selectedDate)).format("YYYY-MM-DD"),
    };

    ShowActionDialog(
      "Are you sure to Post payments?",
      "confirm",
      true,
      async function () {
      showLoading(layoutDispatch);

        try {
          const response = await Auth.fetch("/route/update-payments-to-netsuite", {
            method: "POST",
            body: JSON.stringify(dataToSend),
          });
          if (response.ack) {
            setType("success");
            setLoadMessage(response.message + str);
            const updatedDisabledRoutes = [...disabledRoutes, routeStatusID];
            setDisabledRoutes(updatedDisabledRoutes);
            localStorage.setItem(
              "disabledRoutes",
              JSON.stringify(updatedDisabledRoutes)
            );
          } else {
            setType("error");
            setLoadMessage(response.message + str);
          }
        } catch (err) {
          console.log(err);
          message.error("Something went wrong, try again!");
        } finally {
          hideLoading(layoutDispatch)
        }
      
      }
    );
    
    
    console.log("payload", dataToSend);
    
  };

  console.log("payload", driverSheetData);


  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return <Tag color="warning">Pending</Tag>;
      case 2:
        return <Tag color="success">Approved</Tag>;
      case 3:
        return <Tag color="error">Rejected</Tag>;
      default:
        return "";
    }
  };

  useEffect(() => {
    message.config({
      top: window.innerHeight - 100,
      duration: 3,
    });
  }, []);


  const itemColumns = [
    {
      title: "S No.",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (text, record, index) => index + 1,
      width: 40,
    },
    {
      title: "Code",
      dataIndex: "itemId",
      key: "itemId",
      width: 40,
    },
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      width: 250,
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      width: 75,
      align: 'center',
    },
    {
      title: "Delivered QTY",
      dataIndex: "deliveredQuantity",
      key: "deliveredQuantity",
      width: 75,
      align: 'center',
    },
    {
      title: "Status",
      align: 'center',
      render: (record) => {
        const status = deliveryStatuses.find(item => item.id === record?.deliveryStatus)
        return (
           <span>{status ? status.title : ''}</span>
        );
      },
      width: 75,
    },
  ];

  const rowClassName = (record) => {
    const status = deliveryStatuses.find(item => item.id === record?.deliveryStatus);
    if (status?.title !== 'Delivered') {
      return 'highlight-row';
    }
    return '';
  };


  const sumCash = Object.values(postData).reduce((acc, item) => 
    acc + parseFloat(item.amount_received_cash_post), 0);

  const sumCard = Object.values(postData).reduce((acc, item) => 
    acc + parseFloat(item.amount_received_card_post), 0);
  
  console.log('Sum', sumCard, sumCash);
  
  

  return (
    <div className={classes.dataSheet}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <div>{genExtra()}</div>
      </div>
      {driverSheetData.length > 0 &&
        driverSheetData?.sort((a, b) => a.driverRoute.localeCompare(b.driverRoute)).map((data, index) => (
          <>
            <div className="view-page">
              
                  
                  <Row gutter={[6, 16]} style={{ marginTop: "10px" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row
    gutter={[4, 4]}
    style={{
      background: "#f0f2f5",
      padding: "4px",
      borderRadius: "8px",
    }}
  >
    {/* Cash Section */}
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
      <div
        style={{
          background: "#ffffff",
          padding: "4px",
          borderRadius: "8px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
          border: '2px solid #b1aeae'
        }}
      >
        <Row gutter={[6, 6]}>
          <Col xs={24} sm={24} md={6}>
            <Card>
              <Statistic
                title="Expected Cash"
                value={formatCurrency(data.totalCash)}
                valueStyle={{
                  color: "#1677FF",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Card>
              <Statistic
                title="Cash Received"
                value={formatCurrency(data?.cashReceived)}

                valueStyle={{
                  color: "#1677FF"
                  
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Card>
              <Statistic
                title="Difference"
                value={formatCurrency(data?.cashReceived - data.totalCash)}
                valueStyle={{
                  color: data?.cashReceived - data.totalCash > 0 
                ? "green" 
                : data?.cashReceived - data.totalCash === 0 
                ? "blue" 
                : "red"

                  
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Card>
              <Statistic
                title="Cash Posted"
                value={driverSheetData[0]?.payments_posted_by > 0 || disabledRoutes.includes(driverSheetData[0]?.routeStatusID) ? formatCurrency(sumCash) : formatCurrency(0)}
                valueStyle={{
                  color: "#1677FF",
                }}
              />
            </Card>
          </Col>
          
        </Row>
      </div>
    </Col>

    {/* Card Section */}
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
      <div
        style={{
          background: "#ffffff",
          padding: "4px",
          borderRadius: "8px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
          border: '2px solid #b1aeae'

        }}
      >
        <Row gutter={[6, 6]}>
          <Col xs={24} sm={24} md={6}>
            <Card>
              <Statistic
                title="Expected Card"
                value={formatCurrency(data?.totalCard)}
                valueStyle={{
                  color: "#1677FF",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Card>
              <Statistic
                title="Card Received"
                value={formatCurrency(data.cardReceived)}

                valueStyle={{
                  color: "#1677FF"
                  
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Card>
              <Statistic
                title="Difference"
                value={formatCurrency(data?.cardReceived - data.totalCard)}
                valueStyle={{
                  color: data?.cardReceived - data.totalCard > 0 
                ? "green" 
                : data?.cardReceived - data.totalCard === 0 
                ? "#1677FF" 
                : "red"
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Card>
              <Statistic
                title="Card Posted"
                value={driverSheetData[0]?.payments_posted_by > 0 || disabledRoutes.includes(driverSheetData[0]?.routeStatusID) ? formatCurrency(sumCard) : formatCurrency(0)}
                valueStyle={{
                  color: "#1677FF",
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Col>
                   </Row>
                    </Col> 

                  </Row>

                  <>
                  <div>
               <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', marginTop: '5px'}}>
                    {(UserType == 1 || includesFinance) && (
                      <>
                        <DatePicker
                          format={"YYYY-MM-DD"}
                          style={{ width: 160, marginRight: "10px" }}
                          //  value={data?.postingDateInitial ? dayjs(data?.postingDateInitial) : null}
                          value={
                            selectedDates[index]
                              ? dayjs(selectedDates[index])
                              : null
                          }
                          onChange={(_, dateStr) => {
                            handleDateChange(dateStr, index);
                          }}
                          placeholder="Select Date"
                          disabled={
                            data.payments_posted_by > 0 ||
                            disabledRoutes.includes(data.routeStatusID)
                          }
                        />
                      </>
                    )}
                    {!disabledRoutes.includes(data.routeStatusID) && (
                      <>
                        {(UserType == 1 || includesFinance) && (
                          <>
                              <Button
                                disabled={
                                  data.payments_posted_by > 0 ||
                                  disabledRoutes.includes(data.routeStatusID)
                                }
                                onClick={() => postPayemnts(data.routeStatusID, index)}
                                type="primary"
                                style={{ margin: '5px 0px'}}
                              >
                                Post payments to Netsuite
                              </Button>
                          </>
                        )}
                      </>
                    )}

                    {(data.payments_posted_by > 0 ||
                      disabledRoutes.includes(data.routeStatusID)) && (
                      <Link
                        to={
                          env === "uat"
                            ? `https://5615243-sb1.app.netsuite.com/app/common/search/searchresults.nl?searchtype=Transaction&CUSTBODY_TMS_BATCH_ID=${data.routeStatusID}&style=NORMAL&CUSTBODY_TMS_BATCH_IDtype=STARTSWITH&report=&grid=&searchid=2707&dle=F&sortcol=Transction_ORDTYPE9_raw&sortdir=ASC&csv=HTML&OfficeXML=F&pdf=&size=1000`
                            : `https://5615243.app.netsuite.com/app/common/search/searchresults.nl?searchid=2760&whence=&&CUSTBODY_TMS_BATCH_ID=${data.routeStatusID}&style=NORMAL&CUSTBODY_TMS_BATCH_IDtype=STARTSWITH&report=&grid=&searchid=2707&dle=F&sortcol=Transction_ORDTYPE9_raw&sortdir=ASC&csv=HTML&OfficeXML=F&pdf=&size=1000`
                        }
                        target="_blank"
                        style={{ marginLeft: "5px" }}
                      >
                        View Netsuite Payments
                      </Link>
                    )}
                  </div>
              </div>
                  </>
                 
                  
                  
                  <>
                    <div className={classes.antTable}>
                      <Table
                        style={{ overflowX: "auto", marginTop: "10px" }}
                        columns={dataCol}
                        bordered
                        dataSource={data.tableData}
                        pagination={false}
                        // scroll={{
                        //   y: 400,
                        // }}
                      />
                    </div>
                  </>
            </div>
          </>
        ))}
      <Message type={type} msg={loadMessage} />
    <DetailsModal filesOpen={filesOpen} handleFilesClose={handleFilesClose} selectedRecord={selectedRecord} itemColumns={itemColumns} getStatusText={getStatusText} paymentAuthorizer={paymentAuthorizer} classes={classes} rowClassName={rowClassName}/>

      {/* <Modal
        open={filesOpen}
        onCancel={handleFilesClose}
        okButtonProps={{ style: { display: "none" } }}
        title="Order Details"
        cancelText="Close"
        width={'90vw'}
        style={{
          top: 20,
          height: '90vh',
          overflowY: 'auto'
        }}
      >
        <>
  {selectedRecord &&
  (
    selectedRecord.postponed_status ||
    (selectedRecord.postponed_files && selectedRecord.postponed_files.length > 0) ||
    selectedRecord.postponed_notes ||
    selectedRecord.received_by ||
    selectedRecord.notes ||
    selectedRecord.delivery_discount_req_status ||
    selectedRecord.delivery_discount_req_notes ||
    selectedRecord.delivery_discount_auth_by ||
    selectedRecord.payment_req_status ||
    selectedRecord.payment_req_notes ||
    selectedRecord.payment_auth_by ||
    selectedRecord.signature ||
    (selectedRecord.files && selectedRecord.files.length > 0) ||
    selectedRecord?.items ||
    selectedRecord?.delivery_Instructions
  ) ? (
    <>
    {selectedRecord?.customerProjectId ? (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Shop Name"
            labelStyle={{ width: "200px" }}
          >
            {selectedRecord?.customerProjectId || ""}
          </Descriptions.Item>
        </Descriptions>
       ) : null}
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Order No."
            labelStyle={{ width: "200px" }}
          >
            <span>
                    {selectedRecord?.invoice_no != null && selectedRecord?.invoice_no != "" ? (
                        <a 
                            href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${selectedRecord?.invoiceInternalId}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            {selectedRecord?.invoice_no}
                        </a>
                    ) : (
                        <a 
                            href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${selectedRecord?.internalId}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            {selectedRecord?.saleOrderNo}
                        </a>
                    )}
                </span>
          </Descriptions.Item>
        </Descriptions>
       {selectedRecord?.customerName ? (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Customer Code"
            labelStyle={{ width: "200px" }}
          >
            <span><a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${selectedRecord.customerInternalId}`} target="_blank">{selectedRecord.customerName}</a></span>
          </Descriptions.Item>
        </Descriptions>
       ) : null}
       {selectedRecord?.amountReceivedCash ? (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Amount Received Cash"
            labelStyle={{ width: "200px" }}
          >
            {formatCurrency(selectedRecord?.amountReceivedCash)}
          </Descriptions.Item>
        </Descriptions>
       ) : null}
       {selectedRecord?.amountReceivedCard ? (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Amount Received Cash"
            labelStyle={{ width: "200px" }}
          >
            {formatCurrency(selectedRecord?.amountReceivedCard)}
          </Descriptions.Item>
        </Descriptions>
       ) : null}
      {selectedRecord?.postponed_status ? (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Postponement Status"
            labelStyle={{ width: "200px" }}
          >
            {PostponeReasons.find((obj) => obj.id === selectedRecord.postponed_status)?.title || ""}
          </Descriptions.Item>
        </Descriptions>
      ) : null}

      {selectedRecord?.postponed_notes && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item label="Postpone Notes" labelStyle={{ width: "200px" }}>
            {selectedRecord.postponed_notes || ""}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.notes && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item label="Driver Notes" labelStyle={{ width: "200px" }}>
            {selectedRecord.notes || ""}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.delivery_instructions && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item label="Delivery Notes" labelStyle={{ width: "200px" }}>
            {selectedRecord.delivery_instructions || ""}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.delivery_discount_req_status && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Delivery Discount"
            labelStyle={{ width: "200px" }}
          >
            {getStatusText(selectedRecord.delivery_discount_req_status)}
          </Descriptions.Item>
        </Descriptions>
      )}

    {selectedRecord?.delivery_discount_req_notes && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Delivery Discount Notes"
            labelStyle={{ width: "200px" }}
          >
            {selectedRecord.delivery_discount_req_notes}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.delivery_discount_auth_by && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Delivery Auth By"
            labelStyle={{ width: "200px" }}
          >
            {(() => {
              const authorizer = paymentAuthorizer?.find(
                (obj) =>
                  obj?.id?.toString() ===
                  selectedRecord?.delivery_discount_auth_by?.toString()
              );
              return `${authorizer?.first_name || ""} ${authorizer?.last_name || ""}`;
            })()}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.payment_req_status && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Payment Discount"
            labelStyle={{ width: "200px" }}
          >
            {getStatusText(selectedRecord.payment_req_status)}
          </Descriptions.Item>
        </Descriptions>
      )}
      {selectedRecord?.payment_req_notes && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Payment Discount Notes"
            labelStyle={{ width: "200px" }}
          >
            {selectedRecord.payment_req_notes}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.payment_auth_by > 0 && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Payment Auth By"
            labelStyle={{ width: "200px" }}
          >
            {(() => {
              const authorizer = paymentAuthorizer?.find(
                (obj) => obj?.id === selectedRecord?.payment_auth_by
              );
              return `${authorizer?.first_name || ""} ${authorizer?.last_name || ""}`;
            })()}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.received_by && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Receiver Name"
            labelStyle={{ width: "200px" }}
          >
            {selectedRecord.received_by || ""}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.signature != null && (
        <>
          <h4>Signature:</h4>
          <div
            style={{
              position: "relative",
              marginRight: "10px",
              display: "inline-block",
            }}
          >
            <Image
              width={150}
              height={100}
              style={{ padding: "5px", border: "1px solid lightgrey" }}
              src={selectedRecord?.signature}
            />
          </div>
        </>
      )}

      <Divider />

      {selectedRecord?.files?.length > 0 && (
        <>
          <h4>Files</h4>
          <Image.PreviewGroup>
            {selectedRecord.files.map((item) => {
              const imageUrl = `${tms_uploads_cdn}/${env}/route_orders/${selectedRecord?.routeStatusID}/${item}`;
              return (
                <div
                  key={item}
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    display: "inline-block",
                  }}
                >
                  <Image
                    width={200}
                    height={150}
                    style={{ padding: "5px", border: "1px solid lightgrey" }}
                    src={imageUrl}
                  />
                </div>
              );
            })}
          </Image.PreviewGroup>
        </>
      )}
      {selectedRecord?.postponed_files?.length > 0 && (
        <>
          <h4>Postpone Files</h4>
          <Image.PreviewGroup>
            {selectedRecord.postponed_files.map((item) => {
              const imageUrl = `${tms_uploads_cdn}/${env}/postpone_order/${selectedRecord?.routeStatusID}/${item}`;
              return (
                <div
                  key={item}
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    display: "inline-block",
                  }}
                >
                  <Image
                    width={200}
                    height={150}
                    style={{ padding: "5px", border: "1px solid lightgrey" }}
                    src={imageUrl}
                  />
                </div>
              );
            })}
          </Image.PreviewGroup>
        </>
      )}
      <h2>Items</h2>
      <div className={classes.antTable}>
          <Table
          columns={itemColumns}
          dataSource={selectedRecord?.items?.filter(
            (item) => item.itemId !== "Delivery Charge"
          )}
          pagination={false}
          style={{ overflowX: "auto", marginTop: "10px", marginBottom: "20px" }}
          scroll={{
            y: 250,
          }}
          rowClassName={rowClassName}
          footer={() => {
            const deliveryChargeItem = selectedRecord?.items?.find(
              (item) => item.itemId === "Delivery Charge"
            );
            return deliveryChargeItem
              ? (
                <div>
                  {
                    deliveryChargeItem.grossAmountIncludingTax > 0 && (
                <Tag style={{  height: '40px', alignContent: 'center', fontWeight: 400 }} color="grey">
                      <span style={{fontSize: '18px'}}>{`Delivery Charges: ${'£' + deliveryChargeItem.grossAmountIncludingTax}`}
                    <span style={{fontSize: '14px', fontWeight: 'bolder'}}> Incl. VAT</span>
                  </span>
                  </Tag>
                    )
                  }
                </div>
              ) : (
                <Tag color="grey" style={{ fontSize: '18px', height: '40px', alignContent: 'center', fontWeight: 400 }}>No Delivery Charges</Tag>
              )
          }}
        />
        </div>
    </>
  ) : (
    <Result status="404" subTitle="Sorry, no data data to show." />
  )}
</>
      </Modal> */}
      <ActionDialog
        message={actionDialogProps.message}
        type={actionDialogProps.type}
        isOpen={actionDialogProps.isOpen}
        onClose={actionDialogProps.onClose}
        onSubmit={() => {
          actionDialogProps.onOk();
        }}
        onCancel={() =>
          setActionDialogProps((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
      />
    </div>
  );
};

export default DriverSheetTab;
