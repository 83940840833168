import React, { useEffect, useState } from 'react'
import { Card, Col, Divider, Row, Select, Table, Switch, Button, Tag, Tooltip, Input, DatePicker, message, Popconfirm, List } from "antd";
import { makeStyles } from "@mui/styles";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import moment from "moment";
import { hideLoading, showLoading, useLoadingDispatch } from '../../context/loadingContext';
import { Pagination } from "@mui/material";
import { issuePriorities, VATPercentages } from "../../Components/common/constants";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from 'dayjs';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button as UploadButton, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { extractOriginalFileName, formatCurrency, getNewFileName } from '../../Components/common/CommonFun';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { domain, env, tms_uploads_bucket, tms_uploads_cdn } from '../../Components/config';
import qs from 'query-string';

const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: "5px",
    "& .ant-input[disabled]": {
      background: "none",
      color: "black",
    },
    "& .ant-card .ant-card-body": {
      padding: "4px",
      backgroundColor: "rgb(240, 240, 240)",
    },
    "& .ant-card .ant-card-head": {
      padding: "0px 4px",
      // backgroundColor: "rgb(240, 240, 240)",
      minHeight: '40px'
    },
  },
}));

const SingleAsset = () => {
const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { Option } = Select;
  const { TextArea } = Input;
  const [id, setId] = React.useState(params.id ? params.id : 0);
  
  
  const [loadMessage, setLoadMessage] = useState("");
  const [type, setType] = useState("");
  const [assetDetails, setAssetDetails] = useState({
    name: "",
    assetType: null,
    description: "",
    notes: "",
    serialNumber: "",
    make: null,
    model: null,
  });
  const [files, setFiles] = useState([]);
  const [currentItemFiles, setCurrentItemFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = React.useState([]);

  

  const handleSubmitImage = (e) => {
    e.preventDefault();
    let acceptedFiles = e.target.files;

    for (let file of acceptedFiles) {
      const randomString = Math.random().toString(36).substring(2, 10);
      const newFileName = getNewFileName(file.name)
      setFiles((prevFiles) => [
        ...prevFiles,
        {
          name: newFileName,
          display_name: newFileName,
          file: file,
          ContentType: file.type,
        },
      ]);
      setCurrentItemFiles((prevFiles) => [...prevFiles, newFileName]);
    }
  };

  const handleDeleteFile = (fileName) => {
    const updatedFiles = files?.filter((file) => file.name !== fileName);
    setFiles(updatedFiles);
    setCurrentItemFiles(currentItemFiles?.filter((item) => item !== fileName));
  };

  const handleDeleteCurrentFile = (itemToDelete) => {
    setCurrentItemFiles(
      currentItemFiles.filter((item) => item !== itemToDelete)
    );
    if (!deletedFiles.includes(itemToDelete)) {
      setDeletedFiles((prevDeletedFiles) => [
        ...prevDeletedFiles,
        itemToDelete,
      ]);
    }
  };

  const getUploadUrl = async (file, recordID) => {
    const params = {
      bucket: tms_uploads_bucket,
      ContentType: file.file.type,
      Key: env + "/assets/" + recordID + "/" + file.name,
    };
    const response = await fetch(
      `${domain}/generate-put-url?${qs.stringify(params)}`,
      {
        headers: {
          "Content-Type": params.ContentType,
        },
      }
    );
    return response.json();
  };

  const uploadFileToS3 = async (uploadUrl, file, contentType) => {
    const response = await fetch(uploadUrl.putURL, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": contentType,
      },
    });
    if (response.status !== 200) {
      console.log('response', response);
      message.error("File upload failed.")
      throw new Error("File upload failed.");
    }
  };


   const handleSubmitAsset = async () => {
      const isEmptyOrUndefined = (value) => value === '' || value === null || value === undefined;
      const validations = [
        { key: 'name', message: 'Please Add Asset Name' },
        { key: 'assetType', message: 'Please select asset Type' },
        { key: 'description', message: 'Please Add Asset Description' },
        { key: 'serialNumber', message: 'Please add serial No.' },
        { key: 'make', message: 'Please Select Make' },
        { key: 'model', message: 'Please Select Model' },
      ];
      
      for (const { key, message: errorMessage } of validations) {
        if (isEmptyOrUndefined(assetDetails?.[key])) {
          message.error(errorMessage);
          return;
        }
      }
      
      showLoading(layoutDispatch);
      const isUpdate = id > 0;
      const dataToSend = {
        id: isUpdate ? id : undefined,
        asset_type: assetDetails?.assetType,
        title: assetDetails?.name,
        description: assetDetails?.description,
        serial_number: assetDetails?.serialNumber,
        make: assetDetails?.make,
        model: assetDetails?.model,
        notes: assetDetails.notes,
        files: currentItemFiles ? currentItemFiles : []
      }
      try {
        const method = isUpdate ? "PUT" : "POST";
        const res = await Auth.fetch("/asset/asset", {
          method: method,
          body: JSON.stringify(dataToSend),
        })
        if(res?.ack) {
          const record = isUpdate ? id : res?.record_id;
          console.log(res);
          const uploadPromises = []
          if (files.length > 0) {
            files.forEach((f) => {
              uploadPromises.push(
                getUploadUrl(f, record)
                  .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                  .catch(error => console.error("Error uploading file:", error))
              );
            });
          }
          await Promise.all(uploadPromises);
          message.success(res?.message)
          navigate('/assets')
        } else {
          message.error(res?.message)
        }
  
      } catch (err) {
        console.log(err);
      } 
      finally {
      hideLoading(layoutDispatch);
      }
    };

    const getDataByID = async () => {
        showLoading(layoutDispatch)
        try {
            const res = await Auth.fetch(`/asset/asset?id=${id}`)
            if(res?.ack) {
                setCurrentItemFiles(res?.asset.files ? JSON.parse(res?.asset.files) : [])
                setAssetDetails((prevDetails) => ({
                  ...prevDetails,
                  name: res?.asset.title,
                  assetType: res?.asset.asset_type,
                  description: res?.asset.description,
                  notes: res?.asset.notes,
                  serialNumber: res?.asset.serial_number,
                  make: res?.asset.make,
                  model: res?.asset.model

              }))
            }
        } catch (err) {
            console.log(err);
        } finally {
            hideLoading(layoutDispatch)
        }
      }
    
    
    
      useEffect(()=>{
        if(id > 0) {
            getDataByID()
        }
      },[])

    useEffect(() => {
        message.config({
          top: window.innerHeight - 100, 
          duration: 3, 
        });
      }, [])

  return (
        <div className={classes.dataSheet}>
        <div>
            <h3>Asset</h3>
        </div>
        <>
          <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Name<span style={{ color: "red" }}>*</span>
            </span>
                <Input 
                value={assetDetails?.name}
                onChange={(e) => {
                    setAssetDetails((prevDetails) => ({
                        ...prevDetails,
                        name: e.target.value,
                    }))
                  
                }}
                placeholder='Add Name'
                 />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Type<span style={{ color: "red" }}>*</span>
            </span>
            <Select
              style={{
                width: "100%",
              }}
              showSearch
              value={assetDetails.assetType}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                setAssetDetails((prevDetails) => ({
                  ...prevDetails,
                  assetType: value,
                }))
              }}
            >
              {issuePriorities.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Description<span style={{ color: "red" }}>*</span>
            </span>
                <Input 
                value={assetDetails?.description}
                onChange={(e) => {
                    setAssetDetails((prevDetails) => ({
                        ...prevDetails,
                        description: e.target.value,
                    }))
                  
                }}
                placeholder='Add Description'
                 />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Serial No.<span style={{ color: "red" }}>*</span>
            </span>
                <Input 
                value={assetDetails?.serialNumber}
                onChange={(e) => {
                    setAssetDetails((prevDetails) => ({
                        ...prevDetails,
                        serialNumber: e.target.value,
                    }))
                  
                }}
                placeholder='Add Serial Number'
                 />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Make<span style={{ color: "red" }}>*</span>
            </span>
            <Select
              style={{
                width: "100%",
              }}
              showSearch
              value={assetDetails.make}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                setAssetDetails((prevDetails) => ({
                  ...prevDetails,
                  make: value,
                }))
              }}
            >
              {issuePriorities.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Model<span style={{ color: "red" }}>*</span>
            </span>
            <Select
              style={{
                width: "100%",
              }}
              showSearch
              value={assetDetails.model}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                setAssetDetails((prevDetails) => ({
                  ...prevDetails,
                  model: value,
                }))
              }}
            >
              {issuePriorities.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
            </Col>
          </Row>
          <Row style={{marginTop: '5px'}} gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <span>
              Notes:
            </span>
                <TextArea 
                value={assetDetails?.notes}
                onChange={(e) => {
                    setAssetDetails((prevDetails) => ({
                        ...prevDetails,
                        notes: e.target.value,
                    }))
                }}
                placeholder='Please enter any notes.'
                autoSize={{ minRows: 3, maxRows: 6 }} />
            </Col>
            </Row>
          <Divider />

          <>
          <div>
        <UploadButton
          component="label"
          variant="contained"
          sx={{ marginTop: "10px" }}
          startIcon={<CloudUploadIcon />}
        >
          Add files
          <input
            type="file"
            hidden
            multiple
            capture
            onChange={(e) => {
                const myfiles = e.target.files;
            
                if (myfiles.length > 10) {
                  message.error("A maximum of 10 images can be uploaded at a time.");
                  e.target.value = "";
                } else {
                  handleSubmitImage(e);
                }
              }}
          />
        </UploadButton>
      </div>

      {currentItemFiles?.length > 0 && <h4>Current Files:</h4>}
      <ul>
                  {currentItemFiles?.length > 0 &&
                    currentItemFiles
                      .filter(
                        (item) =>
                          !files.some((file) => file.name === item)
                      )
                      .map((item) => {
                        const encodedFileName = item.replace(/\+/g, '%2B');
                        return (
                          <li>
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={() => handleDeleteCurrentFile(item)}
                                >
                                  <DeleteIcon />
                                </IconButton>

                            <a
                              href={`${tms_uploads_cdn}/${env}/assets/${id}/${encodedFileName}`}
                              target="_blank"
                            >
                              {extractOriginalFileName(encodedFileName)}
                            </a>
                          </li>
                        );
                      })}
                </ul>
        
      {files?.length > 0 && <Divider />}

      {files?.length > 0 && <h4>Files:</h4>}
                <ul>
                  {files.length > 0 &&
                    files.map((files) => {
                      const encodedFileName = files?.name.replace(/\+/g, '%2B');
                      return (
                        <li>
                          {" "}
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => handleDeleteFile(files.name)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {extractOriginalFileName(encodedFileName)}
                        </li>
                      );
                    })}
                </ul>
      {files?.length > 0 && <Divider />}
       
        </>
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
        >
          <Button
            onClick={() => navigate("/assets")}
            danger
            size="large"
            style={{ marginRight: "10px" }}
          >
            Cancel
          </Button>
            <Button
              type="primary"
              size="large"
              onClick={handleSubmitAsset}
            >
              Save
            </Button>
        </div>
        </>
          <Message type={type} msg={loadMessage} />
      </div>
      
  )
}

export default SingleAsset
