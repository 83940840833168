import React, { useEffect, useState } from "react";
import { Button, Col, Descriptions, Input, Modal, Row, Select } from "antd";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import { AuthReqTypes, DiscountStatuses } from "../../Components/common/constants";
import { useNavigate, useParams } from "react-router-dom";
import {
    hideLoading,
    showLoading,
    useLoadingDispatch,
  } from "../../context/loadingContext";
import { netsuite_url } from "../../Components/config";
import moment from "moment";

const Auth = new AuthService();

const SingleDiscountItem = () => {

    const navigate = useNavigate()
    const params = useParams();
    const { internal_id, discount_type } = useParams(); 
    console.log('internal_id', internal_id, discount_type);
    
    var layoutDispatch = useLoadingDispatch();

    // const [id, setId] = React.useState(params.id ? params.id : 0);
    const [notes, setNotes] = React.useState('');
    const [authStatus, setAuthStatus] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [loadMessage, setLoadMessage] = React.useState("");
    const [type, setType] = React.useState("");

    const [selectedRecord, setSelectedRecord] = useState('');


    const { TextArea } = Input;
    const { Option } = Select;

    const handleCancel = () => {
        setAuthStatus(null);
        setNotes('')
        navigate("/discounts");
    };

    const handleOk = async () => {
        let rand = Math.random() * 50;
        let str = " ";
        for (let i = 0; i < rand; i++) {
            str += " ";
        }
        if (!authStatus) {
            setLoadMessage('Select status first to continue' + str)
            setType('error')
            return;
        }
        if (!notes) {
            setType('error')
            setLoadMessage('Enter notes to continue' + str)
            return;
        }
        setIsLoading(true)
        const dataToSend = {
            internal_id: internal_id,
            status: authStatus,
            notes: notes,
            type: discount_type
        }
        try {
            const res = await Auth.fetch("/route/update-payment-authorization-status", {
                method: "PUT",
                body: JSON.stringify(dataToSend),
            });
            if (res?.ack) {
                setType('success')
                setLoadMessage(res?.message + str)
                setAuthStatus(null);
                setNotes('')
                navigate('/discounts')

            } else {
                setType('error')
                setLoadMessage(res?.message + str)
            }

        } catch (err) {
            console.log(err);
            setType('Error')
            setLoadMessage("Something went wrong try again!")
        }
    };

    const getData = async() => {
        showLoading(layoutDispatch);
        try {
          const res = await Auth.fetch(`/route/get-payment-authorization-status-by-id?internal_id=${internal_id}`)
          if (res?.ack) {
            setSelectedRecord(res?.payment_auth_status);
            if(discount_type == 1) {
                setAuthStatus(res?.payment_auth_status[0]?.delivery_discount_req_status)
                setNotes(res?.payment_auth_status[0]?.delivery_discount_req_notes)
            }
            if(discount_type == 2) {
                setAuthStatus(res?.payment_auth_status[0]?.payment_req_status)
                setNotes(res?.payment_auth_status[0]?.payment_req_notes)
            }
          } else {
            setLoadMessage(res?.message)
            setType('error')
          }
        } catch (err) {
          console.log(err);
          setLoadMessage(err?.message)
          setType('error')
        } finally {
          hideLoading(layoutDispatch)
        }
    
      }
    

    React.useEffect(() => {
        if (internal_id > 0) {
          getData()
        }
      }, []);

    return (
        <>
         <h2>{discount_type == 1 ? "Delivery Discount Request" : "Payment Authrorization Request"}</h2>
         <Descriptions style={{ marginBottom: "5px" }} bordered size={"small"}>
            <Descriptions.Item
              label={`Customer Name`}
              labelStyle={{ width: "200px" }}
            >
              <span>{selectedRecord[0]?.customer_project_id || ""} </span><br/>
              
            </Descriptions.Item>
          </Descriptions>
          <Descriptions style={{ marginBottom: "5px" }} bordered size={"small"}>
            <Descriptions.Item
              label={`Customer Code`}
              labelStyle={{ width: "200px" }}
            >
              <span>
              <a
              href={`${netsuite_url}/app/common/entity/custjob.nl?id=${selectedRecord[0]?.customer_internal_id}`}
              target="_blank"
            >
               {selectedRecord[0]?.customer_name || ""}
            </a>
              </span><br/>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions style={{ marginBottom: "5px" }} bordered size={"small"}>
            <Descriptions.Item
              label={`Order No.`}
              labelStyle={{ width: "200px" }}
            >
              <span>
              {selectedRecord[0]?.invoice_no != null && selectedRecord[0]?.invoice_no != "" ? (
                      <a 
                          href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${selectedRecord[0]?.inv_internal_id}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                      >
                          {selectedRecord[0]?.invoice_no}
                      </a>
                  ) : (
                      <a 
                          href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${selectedRecord[0]?.internal_id}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                      >
                          {selectedRecord[0]?.sale_order_no}
                      </a>
                  )}
            </span>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions style={{ marginBottom: "5px" }} bordered size={"small"}>
            <Descriptions.Item
              label={`Request Type`}
              labelStyle={{ width: "200px" }}
            >
              {/* <span>{selectedRecord[0]?.approval_request_type || ""}</span><br/> */}
              {AuthReqTypes.find(reading => reading.id === selectedRecord[0]?.approval_request_type)?.title || ""}

            </Descriptions.Item>
          </Descriptions>
          <Descriptions style={{ marginBottom: "5px" }} bordered size={"small"}>
            <Descriptions.Item
              label={`Driver`}
              labelStyle={{ width: "200px" }}
            >
              <span>{selectedRecord[0]?.driver || ""}</span><br/>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions style={{ marginBottom: "5px" }} bordered size={"small"}>
            <Descriptions.Item
              label={`Driver Notes`}
              labelStyle={{ width: "200px" }}
            >
              <span>{selectedRecord[0]?.notes || ""}</span><br/>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions style={{ marginBottom: "5px" }} bordered size={"small"}>
            <Descriptions.Item
              label={`Sales Person`}
              labelStyle={{ width: "200px" }}
            >
              <span>{selectedRecord[0]?.customer_fields?.split("######")?.[0] || ""}</span><br/>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions style={{ marginBottom: "5px" }} bordered size={"small"}>
            <Descriptions.Item
              label={`Credit Controller`}
              labelStyle={{ width: "200px" }}
            >
              <span>{selectedRecord[0]?.customer_fields?.split("######")?.[1] || ""}</span><br/>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions style={{ marginBottom: "5px" }} bordered size={"small"}>
            <Descriptions.Item
              label={`Request Time`}
              labelStyle={{ width: "200px" }}
            >
              <span><span style={{ fontWeight: "bold"}}>
              {discount_type == 1 ? moment(selectedRecord[0]?.delivery_discount_req_sent).format("YYYY-MM-DD HH:mm:ss") : moment(selectedRecord[0]?.payment_req_sent).format("YYYY-MM-DD HH:mm:ss") }

              </span>
              </span><br/>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions style={{ marginBottom: "5px" }} bordered size={"small"}>
            <Descriptions.Item
              label={`Response Time`}
              labelStyle={{ width: "200px" }}
            >
              <span><span style={{ fontWeight: "bold"}}>
              {discount_type == 1 ? (
               selectedRecord[0]?.delivery_discount_req_responded ? moment(selectedRecord[0]?.delivery_discount_req_responded).format("YYYY-MM-DD HH:mm:ss") : ''
              )  : (
                selectedRecord[0]?.payment_req_responded ? moment(selectedRecord[0]?.payment_req_responded).format("YYYY-MM-DD HH:mm:ss") : ""
              )}

              </span>
              </span><br/>
            </Descriptions.Item>
          </Descriptions>

            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
                        Status
                    </h4>
                    <Select
                        style={{
                            width: "100%",
                        }}
                        value={authStatus}
                        size="large"
                        placeholder="Select Status"
                        onChange={(value) => {
                            setAuthStatus(value);
                        }}
                        disabled={ discount_type == 1 && selectedRecord[0]?.delivery_discount_req_status != 1 || discount_type == 2 && selectedRecord[0]?.payment_req_status != 1 }

                    >
                        {DiscountStatuses.map((item) => (
                            <Option key={item.id} value={item.id}>
                                {item.title}
                            </Option>
                        ))}
                    </Select>
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
                        Notes
                    </h4>
                    <TextArea
                        autoSize={{ minRows: 6, maxRows: 12 }}
                        label="Notes"
                        placeholder="Enter Any Specific Notes"
                        onChange={(e) => setNotes(e.target.value)}
                        value={notes}
                        disabled={ discount_type == 1 && selectedRecord[0]?.delivery_discount_req_status != 1 && selectedRecord[0]?.delivery_discount_req_status != null || discount_type == 2 && selectedRecord?.payment_req_status != 1 && selectedRecord?.payment_req_status != null }

                    />
                </Col>
            </Row>
            <div
                style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
            >
                <Button
                    onClick={handleCancel}
                    danger
                    size="large"
                    style={{ marginRight: "10px" }}
                >
                    Cancel
                </Button>
                {
                    (discount_type == 1 && selectedRecord[0]?.delivery_discount_req_status == 1 || discount_type == 2 && selectedRecord[0]?.payment_req_status == 1 ) && (
                        <Button
                        onClick={handleOk}
                        type="primary"
                        size="large"
                        disabled={ discount_type == 1 && selectedRecord[0]?.delivery_discount_req_status != 1 || discount_type == 2 && selectedRecord[0]?.payment_req_status != 1 }
                    >
                        Save
                    </Button>
                    )
                }
               
            </div>
            <Message type={type} msg={loadMessage} />
        </>
    );
};

export default SingleDiscountItem;
