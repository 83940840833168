import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Card, Col, Divider, Row, Select, Table, Switch, Button, Tag, Tooltip, Input, DatePicker, message, Popconfirm, List, Statistic, Modal } from "antd";
import { makeStyles } from "@mui/styles";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import moment from "moment";
import { hideLoading, showLoading, useLoadingDispatch } from '../../context/loadingContext';
import { Pagination } from "@mui/material";
import { InventorySuppliers, issuePriorities, maintenanceSuppliers, VATPercentages } from "../../Components/common/constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from 'dayjs';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button as UploadButton, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { extractOriginalFileName, formatCurrency, getNewFileName } from '../../Components/common/CommonFun';
import { CloudDownloadOutlined, DeleteFilled, ExportOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { domain, env, tms_uploads_bucket, tms_uploads_cdn } from '../../Components/config';
import qs from 'query-string';
import jsPDF from "jspdf";


const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: "5px",
    "& .ant-input[disabled]": {
      background: "none",
      color: "black",
    },
    "& .ant-card .ant-card-body": {
      padding: "4px",
      backgroundColor: "rgb(240, 240, 240)",
    },
    "& .ant-card .ant-card-head": {
      padding: "0px 4px",
      // backgroundColor: "rgb(240, 240, 240)",
      minHeight: '40px'
    },
  },
}));

const PurchaseOrder = () => {
const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const conversionData = location?.state?.conversionData;
  console.log('conversionData', conversionData);
  
  const { Option } = Select;
  const { TextArea } = Input;
  const [id, setId] = React.useState(params.id ? params.id : 0);
  
  
  const [loadMessage, setLoadMessage] = useState("");
  const [type, setType] = useState("");
  const [purchaseOrderDetail, setPurchaseOrderDetail] = useState({
    order_code: "",
    date: new Date(),
    notes: "",
    supplier: null,
    work_order_id: null,
  });
  const [files, setFiles] = useState([]);
  const [currentItemFiles, setCurrentItemFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = React.useState([]);

  const [orderItems, setOrderItems] = useState([]);

  const [allItems, setAllItems] = useState([]);
  const [allWorkOrders, setAllWorkOrders] = useState([]);
  
    const getAllWorkOrders = async () => {
      showLoading(layoutDispatch);
      try {
        const response = await Auth.fetch(`/inventory/work-orders`);
        if (response) {
          hideLoading(layoutDispatch);
          if (response?.ack) {
            setAllWorkOrders(response?.work_orders);
          } else {
            setAllWorkOrders([])
          }
        }
      } catch (err) {
        console.log(err);
      } finally{
        hideLoading(layoutDispatch)
      }
    }
  
    const getAllItems = async () => {
      showLoading(layoutDispatch);
      try {
        const response = await Auth.fetch(`/inventory/inventory-items`);
        if (response) {
          hideLoading(layoutDispatch);
          if (response?.ack) {
            setAllItems(response?.inventory_items);
          } else {
            setAllItems([])
          }
        }
      } catch (err) {
        console.log(err);
      } finally{
        hideLoading(layoutDispatch)
      }
    }

    useLayoutEffect(() => {
      getAllItems();
      getAllWorkOrders();
    }, [])
  

  const handleSubmitImage = (e) => {
    e.preventDefault();
    let acceptedFiles = e.target.files;

    for (let file of acceptedFiles) {
      const randomString = Math.random().toString(36).substring(2, 10);
      const newFileName = getNewFileName(file.name)
      setFiles((prevFiles) => [
        ...prevFiles,
        {
          name: newFileName,
          display_name: newFileName,
          file: file,
          ContentType: file.type,
        },
      ]);
      setCurrentItemFiles((prevFiles) => [...prevFiles, newFileName]);
    }
  };

  const handleDeleteFile = (fileName) => {
    const updatedFiles = files?.filter((file) => file.name !== fileName);
    setFiles(updatedFiles);
    setCurrentItemFiles(currentItemFiles?.filter((item) => item !== fileName));
  };

  const handleDeleteCurrentFile = (itemToDelete) => {
    setCurrentItemFiles(
      currentItemFiles.filter((item) => item !== itemToDelete)
    );
    if (!deletedFiles.includes(itemToDelete)) {
      setDeletedFiles((prevDeletedFiles) => [
        ...prevDeletedFiles,
        itemToDelete,
      ]);
    }
  };

  const handleAddItem = () => {
    const newItem = {
      order_item: null,
      vat_percentage: 2,
      order_item_quantity: "",
      order_item_unit_price: "",
      order_item_vat: "",
      order_item_total: "",
    };
    setOrderItems([...orderItems, newItem]);
  };

  const handleRemoveItem = async (index, item) => {
      const list = [...orderItems];
      list.splice(index, 1);
      setOrderItems(list);
    };

    const handleInputChange = (index, event) => {
      const { name, value } = event.target;
      let newValue = value;
    
      // Update the order item based on user input
      const list = [...orderItems];
      list[index][name] = newValue;
    
      // Calculate VAT and total if the changed field is related to quantity or unit price
      const item = list[index];
      const quantity = parseFloat(item.order_item_quantity || 0);
      const unitPrice = parseFloat(item.order_item_unit_price || 0);
    
      // Calculate VAT amount (quantity * unit price)
      const vatAmount = quantity * unitPrice;
    
      // Calculate VAT based on the selected VAT percentage
      const vat_percentageItem = VATPercentages.find((vatItem) => vatItem.id == item.vat_percentage)
      console.log('vat_percentageItem', vat_percentageItem, item.vat_percentage);
      
      const vatPercentage = Number(vat_percentageItem?.percent || 0) / 100;
      const calculatedVat = vatAmount * vatPercentage;
    
      // Calculate the total (quantity * unit price) + VAT
      const total = vatAmount + calculatedVat;
    
      // Update the list with the calculated values
      list[index].order_item_vat = calculatedVat.toFixed(2); // store VAT value
      list[index].order_item_total = total.toFixed(2); // store total value
    
      // Update the state with the new list
      setOrderItems(list);
    };

  

    console.log('name order Items', orderItems);

    const getUploadUrl = async (file, recordID) => {
      const params = {
        bucket: tms_uploads_bucket,
        ContentType: file.file.type,
        Key: env + "/purchase-orders/" + recordID + "/" + file.name,
      };
      const response = await fetch(
        `${domain}/generate-put-url?${qs.stringify(params)}`,
        {
          headers: {
            "Content-Type": params.ContentType,
          },
        }
      );
      return response.json();
    };
  
    const uploadFileToS3 = async (uploadUrl, file, contentType) => {
      const response = await fetch(uploadUrl.putURL, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": contentType,
        },
      });
      if (response.status !== 200) {
        console.log('response', response);
        message.error("File upload failed.")
        throw new Error("File upload failed.");
      }
    };

    
      const handleSubmitPurchaseOrder = async () => {
          const isEmptyOrUndefined = (value) => value === '' || value === null || value === undefined;
          const validations = [
            { key: 'date', message: 'Please add order date' },
            { key: 'supplier', message: 'Please add supplier' },
          ];
      
          for (const { key, message: errorMessage } of validations) {
            if (isEmptyOrUndefined(purchaseOrderDetail?.[key])) {
              message.error(errorMessage);
              return;
            }
          }

          const requiredFields = [
            "order_item",
            "order_item_quantity",
            "order_item_unit_price",
          ];
          const isEmpty = orderItems.some((item) =>
            requiredFields.some((field) => !item[field])
          );
      
          if (isEmpty) {
            message.error("Please fill in all required fields for items.");
            setType("error");
            return;
          }
      
          showLoading(layoutDispatch);
          const isUpdate = id > 0;
          const dataToSend = {
            id: isUpdate ? id : undefined,
            order_date: moment(purchaseOrderDetail.date).format("YYYY-MM-DD"),
            notes: purchaseOrderDetail.notes,
            files: currentItemFiles ? currentItemFiles : [],
            supplier_id: purchaseOrderDetail.supplier,
            work_order_id: purchaseOrderDetail?.work_order_id,
            items: orderItems.map((item) => ({
              inventory_item_id: item.order_item,
              quantity: item.order_item_quantity,
              unit_price: item.order_item_unit_price,
              vat_percentage: VATPercentages.find((vatItem) => vatItem.id == item.vat_percentage).percent,
            })),
          }
          try {
            const method = isUpdate ? "PUT" : "POST";
            const res = await Auth.fetch("/inventory/purchase-order", {
              method: method,
              body: JSON.stringify(dataToSend),
            })
            if (res?.ack) {
              const record = isUpdate ? id : res?.record_id;
              console.log(res);
              const uploadPromises = []
              if (files.length > 0) {
                files.forEach((f) => {
                  uploadPromises.push(
                    getUploadUrl(f, record)
                      .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                      .catch(error => console.error("Error uploading file:", error))
                  );
                });
              }
              await Promise.all(uploadPromises);
              message.success(res?.message)
              navigate('/workshop_purchase_order')
            } else {
              message.error(res?.message)
            }
      
          } catch (err) {
            console.log(err);
          }
          finally {
            hideLoading(layoutDispatch);
          }
        };
      
        const handlePostPurchaseOrder = async () => {
          const isEmptyOrUndefined = (value) => value === '' || value === null || value === undefined;
          const validations = [
            { key: 'date', message: 'Please add order date' },
            { key: 'supplier', message: 'Please add supplier' },
          ];
      
          for (const { key, message: errorMessage } of validations) {
            if (isEmptyOrUndefined(purchaseOrderDetail?.[key])) {
              message.error(errorMessage);
              return;
            }
          }

          const requiredFields = [
            "order_item",
            "order_item_quantity",
            "order_item_unit_price",
          ];
          const isEmpty = orderItems.some((item) =>
            requiredFields.some((field) => !item[field])
          );
      
          if (isEmpty) {
            message.error("Please fill in all required fields for items.");
            setType("error");
            return;
          }

          if(orderItems.length < 1) {
            message.error("Please add at least one item to close the purchase order.");
            return;
          }
      
          showLoading(layoutDispatch);
          const dataToSend = {
            id: id,
            order_date: moment(purchaseOrderDetail.date).format("YYYY-MM-DD"),
            notes: purchaseOrderDetail.notes,
            files: currentItemFiles ? currentItemFiles : [],
            supplier_id: purchaseOrderDetail.supplier,
            items: orderItems.map((item) => ({
              inventory_item_id: item.order_item,
              quantity: item.order_item_quantity,
              unit_price: item.order_item_unit_price,
              vat_percentage: VATPercentages.find((vatItem) => vatItem.id == item.vat_percentage).percent,
            })),
          }
          const postDataToSend = {
            id: id,
          }
          try {
            const res = await Auth.fetch("/inventory/purchase-order", {
              method: "PUT",
              body: JSON.stringify(dataToSend),
            })
            if (res?.ack) {
              console.log(res);
              const uploadPromises = []
              if (files.length > 0) {
                files.forEach((f) => {
                  uploadPromises.push(
                    getUploadUrl(f, id)
                      .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                      .catch(error => console.error("Error uploading file:", error))
                  );
                });
              }
              await Promise.all(uploadPromises);
              const PostRes = await Auth.fetch("/inventory/post-purchase-order", {
                method: "POST",
                body: JSON.stringify(postDataToSend),
              })
              if (PostRes?.ack) {
                message.success(PostRes?.message)
                navigate('/workshop_purchase_order')
              } else {
                message.error(PostRes?.message)
              }
            } else {
              message.error(res?.message)
            }
      
          } catch (err) {
            console.log(err);
          }
          finally {
            hideLoading(layoutDispatch);
          }
        };


      const getDataByID = async () => {
          showLoading(layoutDispatch)
          try {
            const res = await Auth.fetch(`/inventory/purchase-order?id=${id}`)
            if (res?.ack) {
              setCurrentItemFiles(res?.purchase_order.files ? JSON.parse(res?.purchase_order.files) : [])
              setPurchaseOrderDetail((prevDetails) => ({
                ...prevDetails,
                order_code: res?.purchase_order.order_code,
                date: res?.purchase_order.order_date,
                notes: res?.purchase_order.notes,
                supplier: res?.purchase_order.supplier_id,
                work_order_id: res?.purchase_order?.work_order_id
              }))
              
              setOrderItems(
                res?.purchase_order_items.map((item) => ({
                  ...item,
                  order_item: item.inventory_item_id,
                  vat_percentage: VATPercentages.find((vatItem) => vatItem.percent == item.vat_percentage).id,
                  order_item_quantity: item.quantity,
                  order_item_unit_price: item.unit_price,
                  // order_item_vat: item.quantity * item.unit_price * (item.vat_percentage / 100),
                  order_item_vat:  Number(
                    (item.quantity * item.unit_price * (item.vat_percentage / 100)).toFixed(2)
                  ),
                  // order_item_total: item.quantity * item.unit_price * (1 + item.vat_percentage / 100),
                  order_item_total: Number(
                    (item.quantity * item.unit_price * (1 + item.vat_percentage / 100)).toFixed(2)
                  ),
                }))
              );
            }
          } catch (err) {
            console.log(err);
          } finally {
            hideLoading(layoutDispatch)
          }
        }
    
        console.log('details', orderItems, purchaseOrderDetail, conversionData);
        

    useEffect(() => {
      if (id > 0) {
        getDataByID();
        
      } else if (id == 'new' && conversionData != undefined) {
        setPurchaseOrderDetail((prevDetails) => ({
          ...prevDetails,
          work_order_id: Number(conversionData?.work_order_id)
        }))
        
        setOrderItems(
          conversionData?.order_items?.map((item) => ({
            ...item,
            order_item: item.inventory_item_id,
            vat_percentage: VATPercentages?.find((vatItem) => vatItem.id == item.vat_percentage)?.id,
            order_item_quantity: item.quantity,
            order_item_unit_price: item.unit_price,
            order_item_vat:  Number(
              (item.quantity * item.unit_price * (VATPercentages?.find((vatItem) => vatItem.id == item.vat_percentage)?.percent / 100)).toFixed(2)
            ),
            order_item_total: Number(
              (item.quantity * item.unit_price * (1 + VATPercentages?.find((vatItem) => vatItem.id == item.vat_percentage)?.percent / 100)).toFixed(2)
            ),
          }))
        );
      } else {
        setOrderItems([
          {
            order_item: null,
            vat_percentage: 2,
            order_item_quantity: "",
            order_item_unit_price: "",
            order_item_vat: "",
            order_item_total: "",
          },
        ]);
      }
      }, [id]);

      const calculateSums = () => {
        const totalVat = orderItems.reduce((sum, item) => {
          return sum + parseFloat(item?.order_item_vat || 0); 
        }, 0);
      
        const totalPrice = orderItems.reduce((sum, item) => {
          const quantity = parseFloat(item.order_item_quantity || 0);
          const unitPrice = parseFloat(item.order_item_unit_price || 0);
          return sum + (quantity * unitPrice); 
        }, 0);
      
        return { totalVat, totalPrice };
      };
      
      // Usage example in your component
      const { totalVat, totalPrice } = calculateSums();

      const data = [
        {
          title: 'Total',
          value: <span style={{ color: 'blue', fontWeight: "bold"}}>{formatCurrency(totalPrice)}</span>
        },
        {
          title: 'VAT',
          value: <span style={{ color: 'blue', fontWeight: "bold"}}>{formatCurrency(totalVat)}</span>
        },
        
        {
          title: 'Grand Total',
          value: <span style={{ color: 'red', fontWeight: "bold"}}>{formatCurrency(totalVat + totalPrice)}</span>
        },
      ];

      const order = {
        orderCode: purchaseOrderDetail.order_code,
        date: purchaseOrderDetail.date,
        supplier: issuePriorities.find(item => item.id == purchaseOrderDetail.supplier)?.title,
        notes: purchaseOrderDetail.notes,
        items: orderItems.map(item => ({
          name: issuePriorities.find(issue => issue.id == item.order_item)?.title,
          quantity: item.order_item_quantity,
          unitPrice: item.order_item_unit_price,
          vatPercent: VATPercentages.find(vatItem => vatItem.id == item.vat_percentage)?.percent,
          vat: item.order_item_vat,
          total: item.order_item_total
        }))
      };
      
      const generatePurchaseOrderPDF = () => {
        const doc = new jsPDF();
      
        // Get page dimensions
        const pageWidth = doc.internal.pageSize.getWidth();
        const boxHeight = 10; // Height of each box
        const fieldSpacing = 12; // Spacing between fields
      
        // Add centered "Purchase Order" heading
        doc.setFontSize(18);
        doc.text('Purchase Order', pageWidth / 2, 20, { align: 'center' });
      
        // Add labeled boxes for order details
        const fields = [
          { label: 'Order Code', value: order.orderCode },
          { label: 'Date', value: moment(order.date).format("YYYY-MM-DD") },
          { label: 'Supplier', value: order.supplier },
          { label: 'Notes', value: order.notes },
          { label: 'VAT', value: formatCurrency(totalVat) },
          { label: 'Total', value: formatCurrency(totalPrice) },
          { label: 'Grand Total', value: formatCurrency(totalVat + totalPrice) },
        ];
      
        const boxStartX = 14; // Padding from the left
        fields.forEach((field, index) => {
          const y = 30 + index * fieldSpacing;
      
          // Draw full-width box
          doc.rect(boxStartX, y, pageWidth - 2 * boxStartX, boxHeight);
      
          // Add label and value
          doc.setFontSize(10);
          doc.text(`${field.label}:`, boxStartX + 2, y + 6); // Label on the left
          doc.text(field.value.toString(), pageWidth - boxStartX - 2, y + 6, { align: 'right' }); // Value on the right
        });
      
        // Add order items table
        const tableColumn = ["Item Name", "Quantity", "Unit Price", "VAT%", "VAT", "Total"];
        const tableRows = [];
      
        order.items.forEach(item => {
          const itemData = [
            item.name,
            item.quantity,
            item.unitPrice.toFixed(2),
            item.vatPercent,
            item.vat.toFixed(2),
            item.total.toFixed(2),
          ];
          tableRows.push(itemData);
        });
      
        // Start the table below the last field
        const tableStartY = 30 + fields.length * fieldSpacing + 10;
        doc.autoTable(tableColumn, tableRows, { startY: tableStartY });
      
        // Save the PDF
        doc.save(`purchase_order_${order.orderCode}.pdf`);
      };

      useEffect(() => {
                  message.config({
                    top: window.innerHeight - 100, 
                    duration: 3, 
                  });
        }, []);
      
      
  return (
        <div className={classes.dataSheet}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>Purchase Order</h3>
            {
              id > 0 && (
                <div style={{ display: 'flex', alignItems: 'center'}}>
                  <>
                <Tooltip title="Download PDF">
                <CloudDownloadOutlined
                style={{ fontSize: '25px', color: '#1677FF'}}
                onClick={generatePurchaseOrderPDF}
                 />
                 </Tooltip>
                 </>
                {/* {
                  purchaseOrderDetail?.work_order_id > 0 && (

                    <Button type="link" onClick={()=> navigate(`/workshop_work_order/${purchaseOrderDetail?.work_order_id}`)}>View Work Order</Button>
                  )
                } */}
                 </div>
              )
            }
        </div>
        <>
          <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Code
            </span>
                <Input 
                value={purchaseOrderDetail?.order_code}
                disabled
                placeholder='To be generated'
                 />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <span>
                Date<span style={{ color: "red", fontSize: "15px" }}>*</span>:
              </span>
              <DatePicker
                format={"YYYY-MM-DD"}
                value={purchaseOrderDetail.date ? dayjs(purchaseOrderDetail.date) : null}
                onChange={(_, dateStr) => {
                  setPurchaseOrderDetail((prevDetails) => ({
                      ...prevDetails,
                      date: dateStr,
                    }))
                }}
                // showTime
                style={{
                  width: "100%",
                }}
                placeholder="Select issue date"
              />
              </Col>
            
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Supplier<span style={{ color: "red" }}>*</span>
            </span>
            <Select
              style={{
                width: "100%",
              }}
              showSearch
              value={purchaseOrderDetail.supplier}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                setPurchaseOrderDetail((prevDetails) => ({
                  ...prevDetails,
                  supplier: value,
                }))
              }}
            >
              {InventorySuppliers.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item?.title}
                </Option>
              ))}
            </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                    <span>
                                      Work Order
                                    </span>
                                    <Select
                          style={{
                            width: "100%",
                          }}
                          showSearch
                          suffixIcon={
                            <>
                            {id > 0 && purchaseOrderDetail?.work_order_id  && (
                              <Tooltip title="Go to Linked Work Order">
                              <ExportOutlined  onClick={()=> navigate(`/workshop_work_order/${purchaseOrderDetail?.work_order_id}`)} style={{color: '#5b5858', fontSize: '25px' }} />
                              </Tooltip>
                            )}
                            </>
                        }
                          value={purchaseOrderDetail.work_order_id}
                          placeholder="Please Select"
                          filterOption={(input, option) =>
                            option?.children?.toLowerCase().includes(input?.toLowerCase())
                          }
                          onChange={(value) => {
                            setPurchaseOrderDetail((prevDetails) => ({
                              ...prevDetails,
                              work_order_id: value,
                            }))
                          }}
                          
                        >
                          {allWorkOrders?.map((item) => (
                            <Option key={item?.id} value={item?.id}>
                              {`${item?.work_order_code}`}
                            </Option>
                          ))}
                        </Select>
                        </Col>
            
          </Row>
          <Row style={{marginTop: '5px'}} gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <span>
              Notes:
            </span>
                <TextArea 
                value={purchaseOrderDetail?.notes}
                onChange={(e) => {
                    setPurchaseOrderDetail((prevDetails) => ({
                        ...prevDetails,
                        notes: e.target.value,
                    }))
                }}
                placeholder='Please enter any notes.'
                autoSize={{ minRows: 3, maxRows: 6 }} />
            </Col>
            </Row>
          <Divider />

          <>
          <div>
        <UploadButton
          component="label"
          variant="contained"
          sx={{ marginTop: "10px" }}
          startIcon={<CloudUploadIcon />}
        >
          Add files
          <input
            type="file"
            hidden
            multiple
            capture
            onChange={(e) => {
                const myfiles = e.target.files;
            
                if (myfiles.length > 10) {
                  message.error("A maximum of 10 images can be uploaded at a time.");
                  e.target.value = "";
                } else {
                  handleSubmitImage(e);
                }
              }}
          />
        </UploadButton>
      </div>

      {currentItemFiles?.length > 0 && <h4>Current Files:</h4>}
      <ul>
                  {currentItemFiles?.length > 0 &&
                    currentItemFiles
                      .filter(
                        (item) =>
                          !files.some((file) => file.name === item)
                      )
                      .map((item) => {
                        const encodedFileName = item.replace(/\+/g, '%2B');
                        return (
                          <li>
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={() => handleDeleteCurrentFile(item)}
                                >
                                  <DeleteIcon />
                                </IconButton>

                            <a
                              href={`${tms_uploads_cdn}/${env}/purchase-orders/${id}/${encodedFileName}`}
                              target="_blank"
                            >
                              {extractOriginalFileName(encodedFileName)}
                            </a>
                          </li>
                        );
                      })}
                </ul>
        
      {files?.length > 0 && <Divider />}

      {files?.length > 0 && <h4>Files:</h4>}
                <ul>
                  {files.length > 0 &&
                    files.map((files) => {
                      const encodedFileName = files?.name.replace(/\+/g, '%2B');
                      return (
                        <li>
                          {" "}
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => handleDeleteFile(files.name)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {extractOriginalFileName(encodedFileName)}
                        </li>
                      );
                    })}
                </ul>
      {files?.length > 0 && <Divider />}

          <div>
          
            <Row  style={{display: 'flex', justifyContent: 'end', marginBottom: '15px'}} gutter={[16, 16]}>
              {
                data.map((item, index) => (
                  <Col xs={24} sm={12} md={8} lg={8} xl={4} key={index}>
                    <Card title={item?.title}>{item?.value}</Card>
                  </Col>
                ))
              }
            </Row>
  </div>
         <>
              {orderItems.map((orderItem, index) => (
                <div key={index}>
                  <Row gutter={[6, 4]}>
                  <Col xs={24} sm={12} md={4} lg={6} xl={6}>
                  <h5 style={{ margin: "1px 0px" }}>
                        Item
                      </h5>
                  <Select
              style={{
                width: "100%",
              }}
              showSearch
              name="order_item"
              value={orderItem?.order_item}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                const list = [...orderItems];
                list[index].order_item = value;
                setOrderItems(list);
              }}
              
            >
              {allItems.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.item_name}
                </Option>
              ))}
            </Select>
                    </Col>
                    
                    
                    <Col xs={24} sm={12} md={4} lg={3} xl={3}>
                      <h5 style={{ margin: "1px 0px" }}>
                        Quantity
                        <span style={{ color: "red", fontSize: "10px" }}>
                          *
                        </span>
                      </h5>
                      <Input
                        name="order_item_quantity"
                        type="number"
                        value={orderItem?.order_item_quantity}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="Quantity"
                        // step="0.01"
                        max={20000}
                        min={0}

                      />
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={3} xl={3}>
                      <h5 style={{ margin: "1px 0px" }}>
                        Unit Price
                        <span style={{ color: "red", fontSize: "10px" }}>
                          *
                        </span>
                      </h5>
                      <Input
                        name="order_item_unit_price"
                        type="number"
                        value={orderItem?.order_item_unit_price}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="Unit Price"
                        step="0.01"
                        max={20000}
                        min={0}

                      />
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={3} xl={3}>
                  <h5 style={{ margin: "1px 0px" }}>
                        VAT %
                      </h5>
                  <Select
              style={{
                width: "100%",
              }}
              showSearch
              name="vat_percentage"
              value={orderItem?.vat_percentage}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                const list = [...orderItems];
                list[index].vat_percentage = value;
                const item = list[index];
                const quantity = parseFloat(item.order_item_quantity || 0);
                const unitPrice = parseFloat(item.order_item_unit_price || 0);
              
                const vatAmount = quantity * unitPrice;
              
                const vat_percentageItem = VATPercentages.find((vatItem) => vatItem.id == item.vat_percentage)
                
                const vatPercentage = Number(vat_percentageItem?.percent || 0) / 100;
                const calculatedVat = vatAmount * vatPercentage;
              
                const total = vatAmount + calculatedVat;
              
                list[index].order_item_vat = calculatedVat.toFixed(2); 
                list[index].order_item_total = total.toFixed(2); 
              
                setOrderItems(list);
              }}
              
            >
              {VATPercentages.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={3} xl={3}>
                      <h5 style={{ margin: "1px 0px" }}>
                        VAT
                        <span style={{ color: "red", fontSize: "10px" }}>
                          *
                        </span>
                      </h5>
                      <Input
                        name="order_item_vat"
                        type="number"
                        value={orderItem?.order_item_vat}
                        // onChange={(e) => handleInputChange(index, e)}
                        disabled
                        placeholder="VAT"
                        step="0.01"
                        max={20000}
                        min={0}
                        

                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={3} xl={3}>
                      <h5 style={{ margin: "1px 0px" }}>
                        Total
                        <span style={{ color: "red", fontSize: "10px" }}>
                          *
                        </span>
                      </h5>
                      <Input
                        name="order_item_total"
                        type="number"
                        value={orderItem?.order_item_total}
                        placeholder="Total"
                        step="0.01"
                        disabled
                        
                      />
                    </Col>
                    {(orderItems.length > 1) && (
                    <>
                        <Col xs={24} sm={12} md={8} lg={3} xl={3}>
                          {orderItem?.id > 0 ? (
                            <Popconfirm
                              title="Remove Item"
                              description="Are you sure you want to remove this Item?"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => handleRemoveItem(index, orderItem)}
                            >
                              {/* <Button
                                type="dashed"
                                block
                                danger
                                icon={<MinusOutlined />}
                                style={{ margin: "19px 0px" }}
                              >
                                Delete
                              </Button> */}
                              <DeleteFilled style={{fontSize: '25px', color: 'red', marginTop: "20px"}}/>
                            </Popconfirm>
                          ) : (
                            // <Button
                            //   type="dashed"
                            //   onClick={() => handleRemoveItem(index, orderItem)}
                            //   block
                            //   danger
                            //   icon={<MinusOutlined />}
                            //   style={{ margin: "19px 0px" }}
                            // >
                            //   Delete
                            // </Button>
                              <DeleteFilled onClick={() => handleRemoveItem(index, orderItem)} style={{fontSize: '25px', color: 'red', marginTop: "20px"}}/>
                            
                          )}
                        </Col>
                    </>
                  )}
                  </Row>
                  {/* <Divider style={{ height: "2px", background: "#006cb8" }} /> */}

                </div>
              ))}
              <Row>
            <Col>
              <Button
                type="dashed"
                onClick={handleAddItem}
                block
                icon={<PlusOutlined />}
                style={{ marginTop: "10px" }}
              >
                Add Another Item
              </Button>
            </Col>
          </Row>
            </>
       
        </>
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
        >
          <Button
            onClick={() => navigate("/workshop_purchase_order")}
            danger
            size="large"
            style={{ marginRight: "10px" }}
          >
            Cancel
          </Button>
            <Button
              type="primary"
              size="large"
              style={{ marginRight: "10px" }}
              onClick={handleSubmitPurchaseOrder}
            >
              Save
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={()=>{
                Modal.confirm({
                  title: 'Post Purchase Order',
                  centered: true,
                  content: `Are you sure you want to post this purchase order?`,
                  onOk: handlePostPurchaseOrder,
                  okText: 'Post',
                  cancelText: 'Cancel',
                  width: 600,
                  okButtonProps: { size: "large"  },
                  cancelButtonProps: { size: "large" },
                });
              }}
            >
              Post
            </Button>
        </div>
        </>
          <Message type={type} msg={loadMessage} />
      </div>
      
  )
}

export default PurchaseOrder
