import React from "react";
import { Card, Col, Divider, Row, Statistic, Table, Switch, Button } from "antd";
import "./reports.css";
import { makeStyles } from "@mui/styles";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import { formatCurrency } from "../../Components/common/CommonFun";

const colors = ["#f0f0f0", "#e6f7ff", "#faf0e6", "#fcf8e3"];
const Auth = new AuthService();


const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: '15px',
    background: 'white',
    "& .ant-card .ant-card-body": {
      padding: "4px",
      backgroundColor: "rgb(240, 240, 240)",
    },
    "& .ant-statistic .ant-statistic-content": {
      fontSize: "12px",
      fontWeight: "bold",
    },
    "& .ant-statistic .ant-statistic-title": {
      fontSize: "12px",
    },
    "& .example-custom-input": {
      width: "100% !important",
    },
    "& .react-datepicker-wrapper": {
      width: "100% !important",
    },
  },
}));

const AmountDiscrepancy = ({ amountDiscrepanciesData, deliveryDate }) => {
  const classes = useStyles();
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");

  const generateColor = (index) => {
    const colors = ["#f0f0f0", "#e6f7ff", "#faf0e6", "#fcf8e3"];
    return colors[index % colors.length];
  };

  const handleSwitchToggle = async (orderPalletID, checked) => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    const dataToSend = {
      pallet_order_id: orderPalletID,
      cash_approved: checked ? 1 : 0
    }
    console.log('dataToSend', dataToSend)
    try {
      const response = await Auth.fetch("/route/change-cash-approved-status", {
        method: "POST",
        body: JSON.stringify(dataToSend)
      });
      if (response.ack) {
        setType('success')
        setLoadMessage(response.message + str)
      }
    } catch (err) {
      console.log(err)
    }
  }

  console.log("amountDiscrepanciesData", amountDiscrepanciesData);
  // const dataCol = [
    
  //   {
  //     title: "Driver Name",
  //     dataIndex: "driver_name",
  //     key: "driver_name",
  //   },
  //   {
  //     title: "Truck Reg",
  //     dataIndex: "registration_number",
  //     key: "registration_number",
  //   },
  //   {
  //     title: "Route Name",
  //     dataIndex: "title",
  //     key: "title",
  //   },
  //   {
  //     title: "Cash",
  //     children: [
  //       {
  //         title: "Cash Expected",
  //         align: "center",
  //         key: "amount_received_cash",
  //         render: (text, record) => ({
  //           props: {
  //             // style: { background: "rgb(240, 240, 240)" },
  //           },
  //           children:
  //             formatCurrency(record?.amount_received_cash)
  //         }),
  //         width: 150,
  //       },
  //       {
  //         title: "Cash Counted",
  //         align: "center",
  //         key: "cash_received",
  //         render: (text, record) => {
  //           const value = Number(record.cash_received) || 0;
  //           const expected = Number(record.amount_received_cash) || 0;
        
  //           const isGreen = value > expected && value !== 0;
  //           const isRed = value < expected && value !== 0;
  //           const style = {
  //             color: isGreen ? "green" : isRed ? "red" : "black",
  //             fontWeight: (isGreen || isRed) && value !== 0 ? "bold" : "normal",
  //           };
        
  //           return (
  //             <span style={style}>
  //               {formatCurrency(value)}
  //             </span>
  //           );
  //         },
  //         width: 150,
  //       },
  //     ],
  //     width: 300,
  //   },  
  //   {
  //     title: "Card",
  //     children: [
  //       {
  //         title: "Card Expected",
  //         align: "center",
  //         key: "amount_received_card",
  //         render: (text, record) => ({
  //           props: {
  //             // style: { background: "rgb(240, 240, 240)" },
  //           },
  //           children:
  //             formatCurrency(record?.amount_received_card)
  //         }),
  //         width: 150,
  //       },
  //       {
  //         title: "Card Counted",
  //         align: "center",
  //         key: "card_received",
  //         render: (text, record) => {
  //           const value = Number(record.card_received) || 0;
  //           const expected = Number(record.amount_received_card) || 0;
        
  //           const isGreen = value > expected && value !== 0;
  //           const isRed = value < expected && value !== 0;
  //           const style = {
  //             color: isGreen ? "green" : isRed ? "red" : "black",
  //             fontWeight: (isGreen || isRed) && value !== 0 ? "bold" : "normal",
  //           };
        
  //           return (
  //             <span style={style}>
  //               {formatCurrency(value)}
  //             </span>
  //           );
  //         },
  //         width: 150,
  //       },
  //     ],
  //     width: 300,
  //   }, 
  // ];


  const dataCol = [
    {
      title: "Route Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Driver Name",
      dataIndex: "driver_name",
      key: "driver_name",
    },
    {
      title: "Truck Reg",
      dataIndex: "registration_number",
      key: "registration_number",
    },
    
    {
      title: "Cash",
      children: [
        {
          title: "Expected",
          align: "left",
          key: "amount_received_cash",
          render: (text, record) => ({
            children: formatCurrency(record?.amount_received_cash),
          }),
          width: 150,
        },
        {
          title: "Counted",
          align: "left",
          key: "cash_received",
          render: (text, record) => {
            const value = Number(record.cash_received) || 0;
            const expected = Number(record.amount_received_cash) || 0;
  
            const isGreen = value > expected && value !== 0;
            const isRed = value < expected && value !== 0;
            const style = {
              color: isGreen ? "green" : isRed ? "red" : "black",
              fontWeight: (isGreen || isRed) && value !== 0 ? "bold" : "normal",
            };
  
            return <span >{formatCurrency(value)}</span>;
          },
          width: 150,
        },
        
        {
          title: "Difference",
          align: "left",
          key: "cash_difference",
          render: (text, record) => {
            const expected = Number(record.amount_received_cash) || 0;
            const counted = Number(record.cash_received) || 0;
            const difference = counted - expected;
        
            const isNegligible = Math.abs(difference) < 0.01; // Tolerance for small values like -0 or -0.00
        
            const style = {
              color: difference > 0 ? "green" : difference < 0 && !isNegligible ? "red" : "black",
              fontWeight: !isNegligible ? "bold" : "normal",
            };
        
            return <span style={style}>{formatCurrency(difference)}</span>;
          },
          width: 150,
        },
        
        {
          title: "Posted",
          align: "left",
          key: "amount_received_cash_post",
          render: (text, record) => ({
            children: formatCurrency(record?.amount_received_cash_post),
          }),
          width: 150,
        },
      ],
      width: 450,
    },
    {
      title: "Card",
      children: [
        {
          title: "Expected",
          align: "left",
          key: "amount_received_card",
          render: (text, record) => ({
            children: formatCurrency(record?.amount_received_card),
          }),
          width: 150,
        },
        {
          title: "Counted",
          align: "left",
          key: "card_received",
          render: (text, record) => {
            const value = Number(record.card_received) || 0;
            const expected = Number(record.amount_received_card) || 0;
  
            const isGreen = value > expected && value !== 0;
            const isRed = value < expected && value !== 0;
            const style = {
              color: isGreen ? "green" : isRed ? "red" : "black",
              fontWeight: (isGreen || isRed) && value !== 0 ? "bold" : "normal",
            };
  
            return <span >{formatCurrency(value)}</span>;
          },
          width: 150,
        },
        
        {
          title: "Difference",
          align: "left",
          key: "card_difference",
          render: (text, record) => {
            const expected = Number(record.amount_received_card) || 0;
            const counted = Number(record.card_received) || 0;
            const difference = counted - expected;
            const isNegligible = Math.abs(difference) < 0.01; // Tolerance for small values like -0 or -0.00
        
            const style = {
              color: difference > 0 ? "green" : difference < 0 && !isNegligible ? "red" : "black",
              fontWeight: !isNegligible ? "bold" : "normal",
            };
  
           
  
            return <span style={style}>{formatCurrency(difference)}</span>;
          },
          width: 150,
        },
        {
          title: "Posted",
          align: "left",
          key: "amount_received_card_post",
          render: (text, record) => ({
            children: formatCurrency(record?.amount_received_card_post),
          }),
          width: 150,
        },
      ],
      width: 450,
    },
  ];
  
  const convertArrayOfObjectsToCSV = (array) => {
    // Define the field mappings
    const fieldMap = {
      driver_name: "Driver Name",
      registration_number: " Truck Reg.",
      driverRoute: "Route",
      amount_received_cash: "Total Cash",
      amount_received_card: 'Total Card'

      // Add more field mappings as needed
    };

    const fieldOrder = ['driver_name', 'registration_number', 'title', 'amount_received_cash', 'amount_received_card']; // Define the desired order

  
    // Filter out unwanted fields and rename them
    const filteredArray = array.map(obj => {
      const newObj = {};
      Object.keys(obj).forEach(key => {
        if (key !== 'formatted_date' && key !== 'route_status_id' && key !== 'card_received' && key !== 'cash_received') { // Exclude 'formatted_date' field
          if (fieldMap[key]) {
            newObj[fieldMap[key]] = obj[key]; // Rename field
          } else {
            newObj[key] = obj[key]; // Keep the field as is
          }
        }
      });
      return newObj;
    });
  
    // Generate CSV
    // const header = Object.keys(filteredArray[0]).join(',');
    // const rows = filteredArray.map(obj => Object.values(obj).join(','));
    // return header + '\n' + rows.join('\n');
    const header = fieldOrder.map(field => fieldMap[field] || field).join(',');
    const rows = filteredArray.map(obj => fieldOrder.map(field => obj[fieldMap[field]] || obj[field] || '').join(','));
    return header + '\n' + rows.join('\n');
  };
  

  // Function to trigger the download of the CSV file
  const downloadCSV = () => {
    const csv = convertArrayOfObjectsToCSV(amountDiscrepanciesData);
    const filename = `${deliveryDate}-AmountDis.csv`;
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const totalCashexpected = amountDiscrepanciesData.reduce(
    (accumulator, currentValue) => accumulator + (currentValue.amount_received_cash || 0),
    0
  );
  const totalCashCountedSum = amountDiscrepanciesData.reduce(
    (accumulator, currentValue) =>
      accumulator + (currentValue.cash_received || 0),
    0
  );
  const totalCardExpected = amountDiscrepanciesData.reduce(
    (accumulator, currentValue) => accumulator + (currentValue.amount_received_card || 0),
    0
  );
  const totalCardCounted =  amountDiscrepanciesData.reduce(
    (accumulator, currentValue) =>
      accumulator + (currentValue.card_received || 0),
    0
  );

  return (
    <div className={classes.dataSheet}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', marginBottom: '5px'}}>
      {
        amountDiscrepanciesData.length > 0 && <Button type="primary" onClick={downloadCSV}>Download</Button>

      }
      </div>
      {amountDiscrepanciesData.length > 0 &&

        <>
          <div className="view-page">
          <div>
  <Row
    gutter={[16, 16]}
    style={{
      background: "#f0f2f5",
      padding: "8px",
      borderRadius: "8px",
    }}
  >
    {/* Cash Section */}
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
      <div
        style={{
          background: "#ffffff",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Total Cash Expected"
                value={formatCurrency(totalCashexpected)}
                valueStyle={{
                  color: "#3f8600",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Total Cash Counted"
                value={formatCurrency(totalCashCountedSum)}

                valueStyle={{
                  color: "#3f8600",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Cash Difference"
                value={formatCurrency(totalCashCountedSum - totalCashexpected)}
                
                valueStyle={{
                  color:
                    totalCashexpected - totalCashCountedSum > 0
                      ? "#cf1322"
                      : "#3f8600",
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Col>

    {/* Card Section */}
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
      <div
        style={{
          background: "#ffffff",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Total Card Expected"
                value={formatCurrency(totalCardExpected)}

                valueStyle={{
                  color: "#3f8600",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Total Card Counted"
                value={formatCurrency(totalCardCounted)}

                valueStyle={{
                  color: "#3f8600",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Card Difference"
                value={formatCurrency(totalCardCounted - totalCardExpected)}

                valueStyle={{
                  color:
                    totalCardExpected - totalCardCounted > 0
                      ? "#cf1322"
                      : "#3f8600",
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Col>
  </Row>
</div>


            <>
              <Table
                style={{ overflowX: "auto", marginTop: "10px" }}
                columns={dataCol}
                dataSource={amountDiscrepanciesData?.sort((a, b) => a.title.localeCompare(b.title))}
                pagination={false}
                bordered
              />
            </>
            {/* <Divider style={{ height: '5px', background: 'red'}} /> */}
            <Divider style={{ height: '8px', background: '#006cb8' }} />

          </div>
        </>
      }
      <Message type={type} msg={loadMessage} />
    </div>
  );
};

export default AmountDiscrepancy;
