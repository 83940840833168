import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  message,
  Modal,
  Row,
  Table,
  Tag,
  Tooltip,
} from "antd";
import "./reports.css";
import { makeStyles } from "@mui/styles";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import moment from "moment";
import { hideLoading, showLoading, useLoadingDispatch } from "../../context/loadingContext";
import { useSelector } from "react-redux";
import { deliveryStatuses, StockChangeReasons, userRolesTypes } from "../../Components/common/constants";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { domain, env, netsuite_url, tms_uploads_bucket, tms_uploads_cdn } from "../../Components/config";
import { CloseOutlined } from "@ant-design/icons";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button as UploadButton } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import qs from "query-string";
import TextArea from "antd/es/input/TextArea";


const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: '15px',
    background: "white",
    "& .ant-card .ant-card-body": {
      padding: "4px",
      backgroundColor: "rgb(240, 240, 240)",
    },
    "& .ant-card .ant-card-head": {
      padding: "0px 4px",
      // backgroundColor: "rgb(240, 240, 240)",
      minHeight: '40px'
    },
    "& .ant-statistic .ant-statistic-content": {
      fontSize: "12px",
      fontWeight: "bold",
    },
    "& .ant-statistic .ant-statistic-title": {
      fontSize: "12px",
    },
    "& .example-custom-input": {
      width: "100% !important",
    },
    "& .react-datepicker-wrapper": {
      width: "100% !important",
    },
    "& .ant-input[disabled]": {
      background: "none",
      color: "black",
      // fontWeight: "500",
    },
  },
}));

const InventoryReport = ({
  inventoryData,
  setInventoryData,
  deliveryDate,
  getInventoryData,
  setInventoryAdjustmentInternalID,
  inventoryAdjustmentInternalID,
  setInventoryAdjustmentCode,
  inventoryAdjustmentCode,
  setInventoryPostDate,
  inventoryPostDate,
  routes,
  notes,
  setNotes,
  files,
  setFiles,
  currentItemFiles,
  setCurrentItemFiles,
  receiverName,
  setReceiverName,
  deletedFiles,
  setDeletedFiles,
  signature,
  setSignature,
  allRoutesCompleted,
  onClose
}) => {
  const classes = useStyles();
  const [loadMessage, setLoadMessage] = useState("");
  const [type, setType] = useState("");
  const [selectedDates, setSelectedDates] = useState(null);
  var layoutDispatch = useLoadingDispatch();

  const { roles } = useSelector((state) => state.AppState?.userTypes);
  const UserType = useSelector((state) => state.AppState?.userTypes?.type);
  const matchedRoles = roles
    .map((role) => {
      return userRolesTypes.find((userRole) => userRole.id === role.role_id);
    })
    .filter(Boolean);
  const includesFinance = matchedRoles.some((item) => item.title === "Finance");
  const signatureCanvasRef = useRef(null);

  // const [notes, setNotes] = useState("");
  // const [files, setFiles] = useState([]);
  // const [currentItemFiles, setCurrentItemFiles] = useState([]);
  // const [receiverName, setReceiverName] = useState("");
  // const [deletedFiles, setDeletedFiles] = React.useState([]);
  // const [signature, setSignature] = useState("");

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
    setSignature('')

  };

  const saveSignature = () => {
    const signatureImage = signatureCanvasRef.current.toDataURL();
    setSignature(signatureImage);
  };

  const handleSubmitImage = (e) => {
    e.preventDefault();
    let acceptedFiles = e.target.files;

    for (let file of acceptedFiles) {
      const randomString = Math.random().toString(36).substring(2, 10);
      const newFileName = `${file.name.split(".")[0]}-${randomString}.${file.name.split(".")[1]
        }`;
      setLoadMessage("");
      setFiles((prevFiles) => [
        ...prevFiles,
        {
          name: newFileName,
          display_name: newFileName,
          file: file,
          ContentType: file.type,
        },
      ]);
      setCurrentItemFiles((prevFiles) => [...prevFiles, newFileName]);
    }
  };

  const getUploadUrl = async (file) => {
    const params = {
      bucket: tms_uploads_bucket,
      ContentType: file.file.type,
      Key: env + "/inventory_adjustments/" + deliveryDate + "/" + file.name,
    };
    const response = await fetch(
      `${domain}/generate-put-url?${qs.stringify(params)}`,
      {
        headers: {
          "Content-Type": params.ContentType,
        },
      }
    );
    return response.json();
  };

  const uploadFileToS3 = async (uploadUrl, file, contentType) => {
    const response = await fetch(uploadUrl.putURL, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": contentType,
      },
    });
    if (response.status !== 200) {
      throw new Error("File upload failed.");
    }
  };

  const handleDeleteFile = (fileName) => {
    const updatedFiles = files?.filter((file) => file.name !== fileName);
    setFiles(updatedFiles);
    setCurrentItemFiles(currentItemFiles?.filter((item) => item !== fileName));
  };

  const handleDeleteCurrentFile = (itemToDelete) => {
    setCurrentItemFiles(
      currentItemFiles.filter((item) => item !== itemToDelete)
    );
    if (!deletedFiles.includes(itemToDelete)) {
      setDeletedFiles((prevDeletedFiles) => [
        ...prevDeletedFiles,
        itemToDelete,
      ]);
    }
  };




  console.log('inventoryData', inventoryData);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState([]);

  const recordDetails = async (details, item_id) => {
    if (inventoryData.posted) {
      showLoading(layoutDispatch)
      const dataToSend = {
        delivery_date: moment(deliveryDate).format("YYYY-MM-DD"),
        item_id: item_id
      }
      try {
        const response = await Auth.fetch("/route/load-inventory-adjustments-by-item", {
          method: "POST",
          body: JSON.stringify(dataToSend),
        });
        if (response?.ack) {
          const enhanced = response?.inventory_adjustments.map(returnItem => {
            const matchingRoute = routes?.find(route => route.id == returnItem.route_status_id);
            return {
              ...returnItem,
              title: matchingRoute?.title,
              driver_name: matchingRoute?.driver_name,
              registration_number: matchingRoute?.registration_number,
            };
          });
          console.log('enhanced', enhanced);

          setSelectedRecord(enhanced)
          setModalOpen(true)
        }
      } catch (error) {
        console.log(error);
        setType("error")
        setLoadMessage('Something went wrong')

      } finally {
        hideLoading(layoutDispatch)
      }
    } else {
      setSelectedRecord(details)
      setModalOpen(true)
    }
  }
  const handleModalClose = () => {
    setModalOpen(false)
    setSelectedRecord([])
  };
  const dataCol = [

    {
      title: 'Details',
      align: "left",
      width: 100,
      render(record) {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Button
              type="primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                recordDetails(record?.details, record?.item_id);
              }}
            >Select</Button>
          </div>
        );
      },
    },
    {
      title: "Item Code",
      dataIndex: "item_id",
      key: "item_id",
      width: 80,
    },
    {
      title: "Item Name",
      dataIndex: "item_name",
      key: "item_name",
      width: 160,
    },
    {
      title: "Ordered QTY",
      dataIndex: "quantity",
      key: "quantity",
      width: 80,
      align: 'center'
    },
    {
      title: "Delivered QTY",
      dataIndex: "delivered_quantity",
      key: "delivered_quantity",
      width: 80,
      align: 'center'

    },
    {
      title: "Returned QTY",
      render: (text, record) => {
        return (
          <span>

            <>{Number(record?.quantity) - Number(record?.delivered_quantity)}</>
          </span>
        );
      },
      width: 80,
      align: 'center'

    },
    {
      title: "Warehouse Received QTY (Quarantine)",
      dataIndex: "returned_quantity",
      key: "returned_quantity",
      width: 80,
      align: 'center'

    },
    {
      title: "Returned To Stock QTY",
      dataIndex: "returned_quantity_to_stock",
      key: "returned_quantity_to_stock",
      width: 80,
      align: 'center'

    },
    {
      title: "Damages",
      render: (text, record) => {
        const value = record?.returned_quantity_to_damages || 0;
        return (
          <span>
            {value > 0 ? (
              <Tag color="red">{value}</Tag>
            ) : (
              <span>{value}</span>
            )}
          </span>
        );
      },
      width: 100,
      align: "center",
    },

    {
      title: "Damages Posted",
      render: (_, record, index) => (
        <Input
          type="number"
          value={record.returned_quantity_to_damages_posted}
          onChange={(e) => handleInputChange(e.target.value, index)}
          disabled={inventoryData?.posted}
        />
      ),
      width: 150,
      align: 'center'
    }
  ];

  const handleInputChange = (value, index) => {
    const updatedInventoryAdjustments = [...inventoryData.inventory_adjustments];
    updatedInventoryAdjustments[index].returned_quantity_to_damages_posted = value;

    // Update the state with the new adjustments
    setInventoryData({
      ...inventoryData,
      inventory_adjustments: updatedInventoryAdjustments
    });
  };



  const handleSubmit = async () => {
    // showLoading(layoutDispatch)
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }

    if (!signature) {
      setType("error");
      setLoadMessage("Please sign before Posting" + str);
      return;
    }
    if (!receiverName) {
      setType("error");
      setLoadMessage("Please enter Name before Posting" + str);
      return;
    }

    const dataToSend = {
      delivery_date: moment(deliveryDate).format("YYYY-MM-DD"),
      inventory_adjustments: inventoryData?.inventory_adjustments,
      posting_date: moment(selectedDates).format("YYYY-MM-DD"),
      notes: notes,
      posted_by: receiverName,
      files: currentItemFiles,
      signature: signature
    };
    const emailPayload = {
      deliveryDate: deliveryDate,
      inventoryAdjustments: inventoryData?.inventory_adjustments,
      postedBy: receiverName,
      notes: notes,
      files: currentItemFiles.map(
        (file) => `${tms_uploads_cdn}/${env}/inventory_adjustments/${deliveryDate}/${file.replace(/\+/g, '%2B')}`
      ),
      signature: signature

    }
    console.log('dataToSend', dataToSend);


    try {
      const response = await Auth.fetch("/route/save-inventory-adjustments", {
        method: "POST",
        body: JSON.stringify(dataToSend),
      });
      if (response.ack) {
        const uploadPromises = []
        if (files.length > 0) {
          files.forEach((f) => {
            uploadPromises.push(
              getUploadUrl(f)
                .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                .catch(error => console.error("Error uploading file:", error))
            );
          });
        }
        await Promise.all(uploadPromises);

        setType("success");
        setLoadMessage(response.message);
        getInventoryData()

        //Production url and token for email
        if (env === 'prod') {
          const EmailApiResponse = await fetch("https://tmsemail.api-magnafoodservice.co.uk/api/email/inventory-adjustment", {
            method: "POST",
            headers: {
              Authorization: "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Im5vb3JAZ21haWwuY29tIiwidXNlcl9pZCI6MSwiaWF0IjoxNzI4NDE0NjIzLCJhdWQiOiJodHRwczovL3d3dy5tYWduYWZvb2RzZXJ2aWNlLmNvLnVrLyIsImlzcyI6Ik1hZ25hIEZvb2QgU2VydmljZSIsInN1YiI6Im5vb3JAZ21haWwuY29tIn0.fb--ERLcBgLhrec6Ed_rAR_uGiBWyFdLN-4fYjjdhjw411DODxyg058A7VVQuLAgD5A8OEa-9jOar0eOTqZk3WGnP8mhh_Tx0smCmiVNY73Odn0x0KZgVyx1yf4W6nEzAHJImmXwzbnPBTIVww7CG2a47GZ8M_XQxFJNhovOnIAEoIZdFz989QdyS_8UpTVIr1UV6nbpZ01ZNuHlU-ZBxJ9zPez1bD7Q9U8Gg59AeJsr3vp6XPDuWmW0C2G3Z3YO-5jT7e1S-lFNg7_8YTH5ju7RWlvdjOJ8lGIiQAVF4aRMXIJh3HHPYBdUWugj7Zb0ZF8F4rz8sR4zNgqEUXJtPQ",
              "Content-Type": "application/json"
            },
            body: JSON.stringify(emailPayload)
          });
          if (EmailApiResponse.ok === true) {


            setLoadMessage("Email sent ")
            setType('success')
          } else {
            setLoadMessage("Email Sendind Failure")
            setType('error')
          }
        } else {
          const EmailApiResponse = await fetch("http://localhost:7420/api/email/inventory-adjustment", {
            method: "POST",
            headers: {
              Authorization: "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Im5vb3JAZ21haWwuY29tIiwidXNlcl9pZCI6MSwiaWF0IjoxNzI3ODgwNDE0LCJleHAiOjE3NTk0MTY0MTQsImF1ZCI6Imh0dHBzOi8vd3d3Lm1hZ25hZm9vZHNlcnZpY2UuY28udWsvIiwiaXNzIjoiTWFnbmEgRm9vZCBTZXJ2aWNlIiwic3ViIjoibm9vckBnbWFpbC5jb20ifQ.Bidb4jyOqnI4JdF9o0WpcEqiGQDpxv47KZ2mFyhFhLwj0LSui0ukFFH889J3tdVs3A-8ah-rk5026bfhEpneyUzkPl0IEE1iTBt4rdmXnUytXQRY38AsSiMv_v0S1gFL0JJtMh2Odzh3JpPbALaqEhCX0P2Kvhy9jNeczzWL8-rYw9b9WVgVN_mBd8AcdzgbzbeVOPd9hDL24im5QXwd6wIT9hme3e-lyBvW24bEp-ozxwaujVAZrtdh8BoSTTD2lyn0nKHc7iErGFjhFvtIw2pzvtnorNzfWJ2hB2BABQ0JOruxnxVks7NbNAMyBhchQhGV5af6ssDAZ4YaKUeNpw",
              "Content-Type": "application/json"
            },
            body: JSON.stringify(emailPayload)
          });
          if (EmailApiResponse.ok === true) {


            setLoadMessage("Email sent ")
            setType('success')
          } else {
            setLoadMessage("Email Sendind Failure")
            setType('error')
          }
        }


      } else {
        setType("error");
        setLoadMessage(response.message + str);
      }
    } catch (error) {
      console.log(error);
      setType('error')
      setLoadMessage('something went wrong' + str)
    } finally {
      hideLoading(layoutDispatch)
    }
  };

  const handleRetryInventoryAdjustments = async () => {
    // showLoading(layoutDispatch)
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }

    if (!signature) {
      setType("error");
      setLoadMessage("Please sign before Posting" + str);
      return;
    }
    if (!receiverName) {
      setType("error");
      setLoadMessage("Please enter Name before Posting" + str);
      return;
    }

    const dataToSend = {
      delivery_date: moment(deliveryDate).format("YYYY-MM-DD"),
      inventory_adjustments: inventoryData?.inventory_adjustments,
      posting_date: moment(selectedDates).format("YYYY-MM-DD"),
      notes: notes,
      posted_by: receiverName,
      files: currentItemFiles,
      signature: signature
    };
    const emailPayload = {
      deliveryDate: deliveryDate,
      inventoryAdjustments: inventoryData?.inventory_adjustments,
      postedBy: receiverName,
      notes: notes,
      files: currentItemFiles.map(
        (file) => `${tms_uploads_cdn}/${env}/inventory_adjustments/${deliveryDate}/${file.replace(/\+/g, '%2B')}`
      ),
      signature: signature

    }
    console.log('dataToSend', dataToSend);


    try {
      const response = await Auth.fetch("/route/retry-save-inventory-adjustments", {
        method: "POST",
        body: JSON.stringify(dataToSend),
      });
      if (response.ack) {
        const uploadPromises = []
        if (files.length > 0) {
          files.forEach((f) => {
            uploadPromises.push(
              getUploadUrl(f)
                .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                .catch(error => console.error("Error uploading file:", error))
            );
          });
        }
        await Promise.all(uploadPromises);

        setType("success");
        setLoadMessage(response.message);
        getInventoryData()

        //Production url and token for email
        if (env === 'prod') {
          const EmailApiResponse = await fetch("https://tmsemail.api-magnafoodservice.co.uk/api/email/inventory-adjustment", {
            method: "POST",
            headers: {
              Authorization: "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Im5vb3JAZ21haWwuY29tIiwidXNlcl9pZCI6MSwiaWF0IjoxNzI4NDE0NjIzLCJhdWQiOiJodHRwczovL3d3dy5tYWduYWZvb2RzZXJ2aWNlLmNvLnVrLyIsImlzcyI6Ik1hZ25hIEZvb2QgU2VydmljZSIsInN1YiI6Im5vb3JAZ21haWwuY29tIn0.fb--ERLcBgLhrec6Ed_rAR_uGiBWyFdLN-4fYjjdhjw411DODxyg058A7VVQuLAgD5A8OEa-9jOar0eOTqZk3WGnP8mhh_Tx0smCmiVNY73Odn0x0KZgVyx1yf4W6nEzAHJImmXwzbnPBTIVww7CG2a47GZ8M_XQxFJNhovOnIAEoIZdFz989QdyS_8UpTVIr1UV6nbpZ01ZNuHlU-ZBxJ9zPez1bD7Q9U8Gg59AeJsr3vp6XPDuWmW0C2G3Z3YO-5jT7e1S-lFNg7_8YTH5ju7RWlvdjOJ8lGIiQAVF4aRMXIJh3HHPYBdUWugj7Zb0ZF8F4rz8sR4zNgqEUXJtPQ",
              "Content-Type": "application/json"
            },
            body: JSON.stringify(emailPayload)
          });
          if (EmailApiResponse.ok === true) {


            setLoadMessage("Email sent ")
            setType('success')
          } else {
            setLoadMessage("Email Sendind Failure")
            setType('error')
          }
        } else {
          const EmailApiResponse = await fetch("http://localhost:7420/api/email/inventory-adjustment", {
            method: "POST",
            headers: {
              Authorization: "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Im5vb3JAZ21haWwuY29tIiwidXNlcl9pZCI6MSwiaWF0IjoxNzI3ODgwNDE0LCJleHAiOjE3NTk0MTY0MTQsImF1ZCI6Imh0dHBzOi8vd3d3Lm1hZ25hZm9vZHNlcnZpY2UuY28udWsvIiwiaXNzIjoiTWFnbmEgRm9vZCBTZXJ2aWNlIiwic3ViIjoibm9vckBnbWFpbC5jb20ifQ.Bidb4jyOqnI4JdF9o0WpcEqiGQDpxv47KZ2mFyhFhLwj0LSui0ukFFH889J3tdVs3A-8ah-rk5026bfhEpneyUzkPl0IEE1iTBt4rdmXnUytXQRY38AsSiMv_v0S1gFL0JJtMh2Odzh3JpPbALaqEhCX0P2Kvhy9jNeczzWL8-rYw9b9WVgVN_mBd8AcdzgbzbeVOPd9hDL24im5QXwd6wIT9hme3e-lyBvW24bEp-ozxwaujVAZrtdh8BoSTTD2lyn0nKHc7iErGFjhFvtIw2pzvtnorNzfWJ2hB2BABQ0JOruxnxVks7NbNAMyBhchQhGV5af6ssDAZ4YaKUeNpw",
              "Content-Type": "application/json"
            },
            body: JSON.stringify(emailPayload)
          });
          if (EmailApiResponse.ok === true) {


            setLoadMessage("Email sent ")
            setType('success')
          } else {
            setLoadMessage("Email Sendind Failure")
            setType('error')
          }
        }


      } else {
        setType("error");
        setLoadMessage(response.message + str);
      }
    } catch (error) {
      console.log(error);
      setType('error')
      setLoadMessage('something went wrong' + str)
    } finally {
      hideLoading(layoutDispatch)
    }
  };

  useEffect(() => {
    const initialDates = inventoryData?.posted ? inventoryPostDate : deliveryDate

    setSelectedDates(initialDates);
  }, [inventoryData]);

  const downloadCSV = () => {
    // Prepare data for CSV
    const csvData = inventoryData?.inventory_adjustments.map((record) => ({

      "Item Code": record.item_id || "",
      "Item Name": record.item_name || "",
      "Ordered QTY": record.quantity || 0,
      "Delivered QTY": record.delivered_quantity || 0,
      "Returned QTY": record.quantity - record.delivered_quantity || 0,
      "Warehouse Received QTY (Quarantine)": record.returned_quantity || 0,
      "Returned To Stock QTY": record.returned_quantity_to_stock || 0,
      "Damages": record.returned_quantity_to_damages || 0,
      "Damages Posted": record.returned_quantity_to_damages_posted || 0,
    }));
    const csvString =
      "data:text/csv;charset=utf-8," +
      [
        Object.keys(csvData[0]).join(","), // Header row
        ...csvData.map((row) => Object.values(row).join(",")), // Data rows
      ].join("\n");

    // Create a link and trigger download
    const link = document.createElement("a");
    link.href = encodeURI(csvString);
    link.download = `inventory_adjustments_${moment().format("YYYY-MM-DD")}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const detailsCol = [
    {
      title: "Route",
      dataIndex: "title",
      key: "title",
      width: 80,
    },
    {
      title: "Driver",
      dataIndex: "driver_name",
      key: "driver_name",
      width: 120,
    },
    {
      title: "Vehicle",
      dataIndex: "registration_number",
      key: "registration_number",
      width: 80,
    },
    {
      title: "Customer",
      align: 'center',
      render(record) {
        return (
          <span><a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${record.customer_internal_id}`} target="_blank">{record.customer_name}</a></span>
        );
      },
      width: 90,
    },
    {
      title: "Invoice",
      render(record) {
        return (
          <span>
            <a
              href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${record?.inv_internal_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {record?.invoice_no}
            </a>
          </span>
        );
      },
      width: 90,
    },
    {
      title: "Order",
      render(record) {
        return (
          <span>
            <a
              href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${record?.internal_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {record?.sale_order_no}
            </a>
          </span>
        );
      },
      width: 90,
    },

    {
      title: "Ordered QTY",
      dataIndex: "quantity",
      key: "quantity",
      width: 80,
      align: 'center'
    },
    {
      title: "Delivered QTY",
      dataIndex: "delivered_quantity",
      key: "delivered_quantity",
      width: 80,
      align: 'center'

    },
    {
      title: "Returned QTY",
      render: (text, record) => {
        return (
          <span>

            <>{Number(record?.quantity) - Number(record?.delivered_quantity)}</>
          </span>
        );
      },
      width: 80,
      align: 'center'

    },
    {
      title: "Warehouse Received QTY (Quarantine)",
      dataIndex: "returned_quantity",
      key: "returned_quantity",
      width: 80,
      align: 'center'

    },
    {
      title: "Returned To Stock QTY",
      dataIndex: "returned_quantity_to_stock",
      key: "returned_quantity_to_stock",
      width: 80,
      align: 'center'

    },
    {
      title: "Damages",
      render: (text, record) => {
        const value = record?.returned_quantity_to_damages || 0;
        return (
          <span>
            {value > 0 ? (
              <Tag color="red">{value}</Tag>
            ) : (
              <span>{value}</span>
            )}
          </span>
        );
      },
      width: 100,
      align: "center",
    },

    {
      title: "Customer Delivery Status",
      render: (record) => {
        const status = deliveryStatuses.find(item => item.id === record?.delivery_status)
        return (
          <span>{status ? status.title : ''}</span>
        );
      },
      width: 120,
      align: 'center'

    },
    {
      title: "Warehouse Delivery Status",
      render: (record) => {
        const status = StockChangeReasons.find(item => item.id === record?.returned_quantity_to_stock_status)
        return (
          <span>{status ? status.title : ''}</span>
        );
      },
      width: 120,
      align: 'center'

    },
  ];

  return (
    <div className={classes.dataSheet}>
      <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>

        <div>
          <Button type="primary" style={{marginRight: "5px"}} onClick={downloadCSV}>
            Download
          </Button>
          
          {(inventoryData?.posted &&
            (inventoryAdjustmentInternalID && <Link
              to={`${netsuite_url}/app/accounting/transactions/invadjst.nl?id=${inventoryAdjustmentInternalID}&whence=`}
              target="_blank"
              style={{ marginLeft: "5px" }}
            >
              <span>
                View Inventory Adjustment {inventoryAdjustmentCode ? `(${inventoryAdjustmentCode})` : ''}
              </span>
            </Link>)
          )}
          {(inventoryData?.posted &&
            (!inventoryAdjustmentInternalID && <Button
              onClick={() => {
                Modal.confirm({
                  title: 'Post Inventory Adjustments',
                  centered: true,
                  content: `Are you sure to post inventory adjustments?`,
                  onOk: () => handleRetryInventoryAdjustments(),
                  okText: 'Post',
                  width: 600,
                  okButtonProps: { size: "large" },
                  cancelButtonProps: { size: "large" },
                });
              }}
              type="primary"
            >
              Retry Post to Netsuite
            </Button>)
          )}
        </div>
      </div>

      {inventoryData?.inventory_adjustments.length > 0 && (
        <>
          <div className="view-page">

            <Table
              style={{ overflowX: "auto", marginTop: "10px" }}
              columns={dataCol}
              dataSource={inventoryData?.inventory_adjustments}
              pagination={false}
            />
            <Divider style={{ height: "2px", background: "#006cb8" }} />

            <div style={{ display: "flex", alignItems: "center" }}>
              <h4 style={{ fontWeight: "bold" }}>Posting Date:</h4>

              <>
                {(UserType == 1 || includesFinance) && (
                  <>
                    <DatePicker
                      format={"YYYY-MM-DD"}
                      style={{ width: 160, marginLeft: "10px" }}
                      value={
                        selectedDates
                          ? dayjs(selectedDates)
                          : null
                      }
                      onChange={(_, dateStr) => {
                        setSelectedDates(dateStr);
                      }}
                      placeholder="Select Date"
                      size="large"
                      disabled={
                        inventoryData?.posted
                      }
                    />
                  </>
                )}
              </>
            </div>

            <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>Notes</h4>
            <div style={{ marginBottom: "10px" }}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <TextArea
                    autoSize={{ minRows: 6, maxRows: 12 }}
                    label="Notes"
                    placeholder="Enter Any Specific Notes"
                    onChange={(e) => setNotes(e.target.value)}
                    value={notes}
                    disabled={inventoryData?.posted}
                  />
                </Col>
              </Row>
            </div>
            <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
              Posted By<span style={{ color: "red", fontSize: "15px" }}>*</span>
            </h4>
            <div>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                  <Input
                    value={receiverName}
                    onChange={(e) => setReceiverName(e.target.value)}
                    placeholder="Enter receiver Name"
                    disabled={inventoryData?.posted}

                  />
                </Col>
              </Row>
            </div>

            <div
              style={{
                width: 450,
                display: "flex",
                alignItems: 'end',
                justifyContent: "space-between",
                marginTop: "15px",
              }}
            >
              <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
                Signature<span style={{ color: "red", fontSize: "15px" }}>*</span>
              </h4>
              <Button
                size="large"
                danger
                onClick={clearSignature}
              >
                Clear Signature
              </Button>
            </div>
            <div className="sign-con">
              <SignatureCanvas
                ref={signatureCanvasRef}
                value={signature}
                penColor="purple"
                canvasProps={{
                  width: 450,
                  height: 200,
                  className: "signature-canvas",
                }}
                onEnd={saveSignature}
              />
              <div>
                {signature && <img height={150} width={250} src={signature} />}
              </div>
            </div>

            <Divider />

            <div style={{ display: 'flex' }}>
              <UploadButton
                component="label"
                variant="contained"
                sx={{ marginTop: "10px" }}
                startIcon={<CloudUploadIcon />}
                disabled={inventoryData?.posted}

              >
                Add files
                <input
                  type="file"
                  hidden
                  multiple
                  capture
                  accept="image/*, video/*"
                  // onChange={(e) => {
                  //   handleSubmitImage(e);
                  // }}
                  onChange={(e) => {
                    const myfiles = e.target.files;
                    if (myfiles.length > 10) {
                      message.error("A maximum of 10 images can be uploaded at a time.");
                      e.target.value = "";
                    } else {
                      handleSubmitImage(e);
                    }
                  }}
                />
              </UploadButton>
            </div>
            {currentItemFiles?.length > 0 && <h4>Current Files:</h4>}
            <Image.PreviewGroup>
              {currentItemFiles?.length > 0 &&
                currentItemFiles
                  .filter((item) => !files.some((file) => file.name === item))
                  .map((item) => {
                    const encodedFileName = item.replace(/\+/g, '%2B');
                    const imageUrl = `${tms_uploads_cdn}/${env}/inventory_adjustments/${deliveryDate}/${item.replace(/\+/g, '%2B')}`;
                    return (
                      <div
                        style={{
                          position: "relative",
                          marginRight: "10px",
                          display: "inline-block",
                        }}
                      >
                        <Image
                          width={200}
                          height={150}
                          style={{ padding: "5px", border: "1px solid lightgrey" }}
                          src={imageUrl}
                        />
                        <Tooltip title="Delete">
                          <CloseOutlined
                            style={{
                              fontSize: "30px",
                              position: "absolute",
                              right: 5,
                              top: 5,
                              color: "#c12828",
                            }}
                            onClick={() => handleDeleteCurrentFile(item)}
                          />
                        </Tooltip>
                      </div>
                    );
                  })}
            </Image.PreviewGroup>
            <Divider />
            {files?.length > 0 && <h4>New Files:</h4>}
            <Image.PreviewGroup>
              {files?.length > 0 &&
                files?.map((files) => {
                  return (
                    <div
                      style={{
                        position: "relative",
                        marginRight: "10px",
                        display: "inline-block",
                      }}
                    >
                      <Image
                        width={200}
                        height={150}
                        style={{ padding: "5px", border: "1px solid lightgrey" }}
                        src={URL.createObjectURL(files.file)}
                      />
                      <Tooltip title="Delete">
                        <CloseOutlined
                          style={{
                            fontSize: "30px",
                            position: "absolute",
                            right: 5,
                            top: 5,
                            color: "#c12828",
                          }}
                          onClick={() => handleDeleteFile(files.name)}
                        />
                      </Tooltip>
                    </div>
                  );
                })}
            </Image.PreviewGroup>
            {files?.length > 0 && <Divider />}
          </div>
        </>
      )}
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          onClick={onClose}
          danger
          size="large"
          style={{ marginRight: "10px" }}
        >
          Cancel
        </Button>
        {
          !inventoryData?.posted && (
            <>
              {(UserType == 1 || includesFinance) && (
                <>
                  {allRoutesCompleted ? (
                    <Button
                      disabled={inventoryData?.posted}
                      onClick={() => {
                        Modal.confirm({
                          title: 'Post Inventory Adjustments',
                          centered: true,
                          content: `Are you sure you want to post inventory adjustments?`,
                          onOk: () => handleSubmit(),
                          okText: 'Post',
                          width: 600,
                          okButtonProps: { size: 'large' },
                          cancelButtonProps: { size: 'large' },
                        });
                      }}
                      type="primary"
                      size="large"
                    >
                      Post to Netsuite
                    </Button>
                  ) : (
                    <Button
                      // disabled
                      onClick={() => {
                        Modal.error({
                          title: 'Action Restricted',
                          content: 'Inventory adjustment can not be posted, all stock discrepancies are not posted!',
                          centered: true,
                          width: 600,
                          okButtonProps: { size: 'medium' },
                        });
                      }}
                      type="primary"
                      size="large"
                    >
                      Post to Netsuite
                    </Button>
                  )}

                </>
              )}
            </>
          )
        }

      </div>
      <Message type={type} msg={loadMessage} />
      <Modal
        open={modalOpen}
        onCancel={handleModalClose}
        okButtonProps={{ style: { display: "none" } }}
        title={`${selectedRecord[0]?.item_name} - (${selectedRecord[0]?.item_id})`}
        cancelText="Close"
        width={'90vw'}
        style={{
          // top: 20,
          height: '90vh',
          overflowY: 'auto'
        }}
      >
        <>
          <Table
            style={{ overflowX: "auto", marginTop: "10px" }}
            columns={detailsCol}
            dataSource={selectedRecord}
            pagination={false}
          />
        </>

      </Modal>
    </div>
  );
};

export default InventoryReport;
