import React, { useState, useEffect, useLayoutEffect } from "react";
import { Card, Col, Divider, Row, Statistic, Table, Switch, Button, Tag, Tooltip, Input, message, Modal, DatePicker, Select } from "antd";
import { makeStyles } from "@mui/styles";
import { CheckOutlined, CloseOutlined, DeleteFilled, EditOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import moment from "moment";
import { hideLoading, showLoading, useLoadingDispatch } from '../../context/loadingContext';
import { Pagination } from "@mui/material";
import { issuePriorities } from "../../Components/common/constants";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import { Autocomplete, Box, Grid, IconButton, TextField, Typography } from "@mui/material";
const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: "5px",
    
  },
}));

const WorkOrderList = () => {
  const classes = useStyles();
  const { Option } = Select
  var layoutDispatch = useLoadingDispatch();
  const navigate = useNavigate();
  const [loadMessage, setLoadMessage] = useState("");
  const [type, setType] = useState("");
  const [allWorkOrders, setAllWorkOrders] = useState([]);
  const [page, setPage] = React.useState(1);
  const [pageChange, setPageChange] = React.useState(false);
  const [limit] = React.useState(50);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);

  const [vehicles, setVehicles] = useState([])
  
  const currentDate = new Date();
      const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
      const [startDate, setStartDate] = useState(firstDayOfMonth);
      const [endDate, setEndDate] = useState(lastDayOfMonth);
      const [selectedVehicle, setSelectedVehicle] = useState(null);
      const [selectedText, setSelectedText] = useState("");


  const getAllVehicles = async () => {
        showLoading(layoutDispatch);
        try {
          const response = await Auth.fetch(`/vehicle/vehicles`);
          if (response) {
            hideLoading(layoutDispatch);
            if (response?.ack) {
              setVehicles(response.vehicles);
            } else {
              setVehicles([])
            }
          }
        } catch (err) {
          console.log(err);
        } finally{
          hideLoading(layoutDispatch)
        }
      }
  
      useLayoutEffect(() => {
        getAllVehicles()
      }, [])
  

  const handleChange = (event, value) => {
    setPage(value);
    setPageChange(true)
  };

  const deleteWorkOrder = async (id) => {
        showLoading(layoutDispatch);
        try {
          const res = await Auth.fetch(`/inventory/work-order`, {
            method: "DELETE",
            body: JSON.stringify({ id: id })
    
          });
          if (res?.ack) {
            getAllWorkOrders();
            message.success(res?.message);
          } else {
            message.error(res?.message);
          }
        } catch (err) {
          console.log(err);
          message.error(err?.message);
        } finally {
          hideLoading(layoutDispatch);
        }
      };

  const workOrderColumns = [
    {
      title: 'Date',
      render: (record)=> {
        return (
          <>
          <span>{ record.work_order_date ? moment(record.work_order_date, "YYYY-MM-DD").format("YYYY-MM-DD") : ''}</span>
        </>
        )
      }
    },
    {
      align: "center",
      title: "Vehicle",
      render: (record) => {
        const object = vehicles.find((obj) => obj.id === record?.vehicle_id);
        return (
          <span >
            {object?.registration_number ? object?.registration_number : ""}
          </span>
        );
      },
    },
    {
        title: 'Code',
        dataIndex: 'work_order_code',
        key: 'work_order_code',
    },
    {
      title: 'Priority',
      render: (record) => {
        const object = issuePriorities?.find((obj) => obj.id === record?.priority);
        return (
          <span >
            {object?.title || ""}
          </span>
        );
      },
  },
    
    {
            title: 'Due Date',
            render: (record)=> {
              return (
                <>
                <span>{ record.due_date ? moment(record.due_date, "YYYY-MM-DD").format("YYYY-MM-DD") : ''}</span>
              </>
              )
            }
          },
        
        {
          title: 'Action',
          key: 'action',
          align: 'right',
          render: (text, record) => (
            <div>
              {
                record.status == 1 && (
                  <EditOutlined
            style={{
              fontSize: "25px",
              cursor: "pointer",
              marginRight: "5px",
              color: "#006cb8",
            }} onClick={() => navigate(`/workshop_work_order/${record.id}`)}/>
                )
              }
    
            
            {
              record.status == 1 && (
                <DeleteFilled style={
                  {
                    fontSize: "25px",
                    cursor: "pointer",
                    color: "#ff0000",
                }} onClick={() => {
                  Modal.confirm({
                    title: 'Delete Work Order',
                    centered: true,
                    content: `Are you sure you want to delete this work order?`,
                    onOk:()=> deleteWorkOrder(record.id),
                    okText: 'Delete',
                    cancelText: 'Cancel',
                    width: 600,
                    okButtonProps: { size: "large"  },
                    cancelButtonProps: { size: "large" },
                  });
                }} 
                />
              )
            }
            
            </div>
            )
        },
    
  ];

  const getAllWorkOrders = async (download) => {
    let search = {};
    if (page && pageChange) search.page = page;
    if (startDate) search.start_date = moment(startDate).format("YYYY-MM-DD");
        if (endDate) search.end_date = moment(endDate).format("YYYY-MM-DD");
        if (selectedVehicle) search.vehicle_id = selectedVehicle;
        if (selectedText) search.text = selectedText;

   if (download) search.download = 1;

    showLoading(layoutDispatch);
    try {
    const res = await Auth.fetch("/inventory/work-orders", {
        method: "POST",
        body: JSON.stringify(search),
      });
      if (res?.ack) {
       if (download == 1) {
           window.open(res.file_url, "_blank", "noreferrer");
         } else {
        setAllWorkOrders(res?.work_orders);
        setTotalPages(Math.ceil(res?.total_count / limit));
        setTotalCount(res.total_count);
         }
      } else {
        setAllWorkOrders([])
        message.error(res?.message)
      }
    } catch (err){
        console.log(err);
        message.error(err?.message)
    } finally {
        hideLoading(layoutDispatch);
    }
  };

  useEffect(() => {
            message.config({
              top: window.innerHeight - 100, 
              duration: 3, 
            });
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => getAllWorkOrders(), 1000);
    return () => clearTimeout(timeOutId);
  }, [
    page,
    startDate,
      endDate,
      selectedVehicle,
      selectedText
  ]);

  return (
    <div className={classes.dataSheet}>
        <div className="page-header">
        <h2>Work Order List</h2>
        <Button onClick={()=> navigate('/workshop_work_order/new')} type="primary" icon={<PlusOutlined />}>Add Work Order</Button>
        </div>
        <Grid container rowSpacing={2} columnSpacing={1.5}>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <label htmlFor="pcnDate">Start Date</label>
          <DatePicker
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={startDate ? dayjs(startDate) : null}
               onChange={(_, dateStr) => {
                setStartDate(dateStr);
                setPageChange(false)
                setPage(1)
              }}
              placeholder="Select Date" 
               />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <label htmlFor="pcnDate">End Date</label>
          <DatePicker
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={endDate ? dayjs(endDate) : null}
               onChange={(_, dateStr) => {
                setEndDate(dateStr);
                setPageChange(false)
                setPage(1)
              }}
              placeholder="Select Date" 
               />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <label htmlFor="route">Vehicles</label>
          <Select
              style={{
                width: "100%",
              }}
              showSearch
              size="large"
              value={selectedVehicle}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                setSelectedVehicle(value)
                setPageChange(false)
                setPage(1)
              }}
            >
              {vehicles.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item?.registration_number}
                </Option>
              ))}
            </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <label htmlFor="Status">Text</label>
          
          <Input 
           placeholder="Search"
           value={selectedText}
           onChange={(e)=>{
              setSelectedText(e.target.value);
              setPageChange(false)
             setPage(1)
             }}
             size="large"
          />
          
        </Grid>
      </Grid>
        <div className={classes.antTable}>
                <Table
                  columns={workOrderColumns}
                  style={{ overflowX: "auto", marginTop: '10px' }}
                  pagination={false}
                  dataSource={allWorkOrders}
                  footer={() => {
                    const startRecord = (page - 1) * limit + 1;
                    const endRecord = Math.min(page * limit, allWorkOrders?.length ? totalCount : 0); 
                    return `Showing ${startRecord}-${endRecord} of ${allWorkOrders?.length ? totalCount : "0"} Records`;
                  }}
                />
              </div>
        
              <div style={{ float: "right", margin: "10px" }}>
                <Pagination
                  page={page}
                  count={totalPages}
                  variant="outlined"
                  color="primary"
                  onChange={handleChange}
                />
              </div>
      <Message type={type} msg={loadMessage} />
    </div>
  );
};

export default WorkOrderList;
