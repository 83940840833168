import { useState, useEffect } from "react";

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return width;
};
export default useWindowWidth;

export const formatWeight = (weight, locale = "en-GB") => {
  let value =
    typeof weight === "number"
      ? weight
      : Number(weight?.replace(/[^\d.-]/g, ""));
  value = isNaN(value) ? 0 : value;
  const roundedValue = value.toFixed(2);
  return new Intl.NumberFormat(locale, {
    style: "unit",
    unit: "kilogram",
  }).format(roundedValue);
};

export const formatCurrency = (amount, locale = "en-GB", currency = "GBP") => {
  let value =
    typeof amount === "number"
      ? amount
      : Number(amount?.replace(/[^\d.-]/g, ""));
  value = isNaN(value) ? 0 : value;
  const roundedValue = value.toFixed(2);
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(roundedValue);
};

export const applyStatusColor = (status) => {
  switch (status) {
    case "Delivered":
      return "#4099FF";
    case "Postponed":
      return "#FFB64D";
    case "Not Delivered":
      return "#FB4A3F";
    default:
      return "";
  }
};

export const getOrderById = ({ data, id }) => {
  const result = data?.find((item) => item.id === id);
  if (result) return result.order;
};

export const calculateTotals = (data) => {
  return data?.reduce(
    (acc, route) => {
      acc.weight += route.order_weight;
      acc.orders += route.total_orders;
      acc.revenue += route.order_value;
      acc.postponed += route.postponed_orders;
      acc.completed += route.completed_orders;
      acc.delivered_weight += route.delivered_weight;
      acc.order_value_delivered += route.order_value_delivered;
      acc.routes += 1;
      return acc;
    },
    {
      weight: 0,
      orders: 0,
      routes: 0,
      revenue: 0,
      postponed: 0,
      completed: 0,
      delivered_weight: 0,
      order_value_delivered: 0,
    }
  );
};

export function getNewFileName(oldFileName) {
  const extension = oldFileName?.slice(oldFileName?.lastIndexOf('.'));
  const baseName = oldFileName?.slice(0, oldFileName?.lastIndexOf('.'));
  const randomString = Math.random().toString(36).substring(2, 12);
  return `${baseName}-${randomString}${extension}`;
}

export function extractOriginalFileName(modifiedFileName) {
  const extensionIndex = modifiedFileName?.lastIndexOf('.');
  const extension = modifiedFileName?.slice(extensionIndex);
  const randomStringPattern = /-\w{10}(?=\.\w+$)/;
  const baseName = modifiedFileName?.replace(randomStringPattern, '');
  return baseName;
}