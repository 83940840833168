import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Card, Col, Divider, Row, Select, Table, Switch, Button, Tag, Tooltip, Input, DatePicker, message } from "antd";
import { makeStyles } from "@mui/styles";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import moment from "moment";
import { hideLoading, showLoading, useLoadingDispatch } from '../../context/loadingContext';
import { Pagination } from "@mui/material";
import { issuePriorities } from "../../Components/common/constants";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from 'dayjs';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button as UploadButton, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { extractOriginalFileName, getNewFileName } from '../../Components/common/CommonFun';
import qs from 'query-string';
import { domain, env, tms_uploads_bucket, tms_uploads_cdn } from '../../Components/config';
import { ExportOutlined } from '@ant-design/icons';

const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: "5px",
    "& .ant-input[disabled]": {
      background: "none",
      color: "black",
    },
  },
}));

const Issue = () => {
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { Option } = Select;
  const { TextArea } = Input;
  const [id, setId] = React.useState(params.id ? params.id : 0);
  const userName = localStorage.getItem("logged_in_user_name");

  const [loadMessage, setLoadMessage] = useState("");
  const [type, setType] = useState("");
  const [issueDetail, setissueDetail] = useState({
    priority: null,
    cause: "",
    creationDate: new Date(),
    notes: "",
    reportedBy: userName,
    reportedTo: null,
    dueDate: null,
    completionDate: null,
    vehicle: null,
    issue_code: "To be generated",
    work_order_id: null,
  });
  const [files, setFiles] = useState([]);
  const [currentItemFiles, setCurrentItemFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = React.useState([]);

  const [users, setUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);

   const [allWorkOrders, setAllWorkOrders] = useState([]);
    
      const getAllWorkOrders = async () => {
        showLoading(layoutDispatch);
        try {
          const response = await Auth.fetch(`/inventory/work-orders`);
          if (response) {
            hideLoading(layoutDispatch);
            if (response?.ack) {
              setAllWorkOrders(response?.work_orders);
            } else {
              setAllWorkOrders([])
            }
          }
        } catch (err) {
          console.log(err);
        } finally{
          hideLoading(layoutDispatch)
        }
      }
  

  const getAllVehicles = async () => {
        showLoading(layoutDispatch);
        try {
          const response = await Auth.fetch(`/vehicle/vehicles`);
          if (response) {
            hideLoading(layoutDispatch);
            if (response?.ack) {
              setVehicles(response.vehicles);
            } else {
              setVehicles([])
            }
          }
        } catch (err) {
          console.log(err);
        } finally{
          hideLoading(layoutDispatch)
        }
      }
  
  const getAssignees = async () => {
    showLoading(layoutDispatch);
        try {
          const response = await Auth.fetch(`/issue/issues-assignees`);
          if (response) {
            hideLoading(layoutDispatch);
            if (response?.ack) {
              setUsers(response.issue_assignees);
            } else {
              setUsers([])
            }
          }
        } catch (err) {
          console.log(err);
        } finally{
          hideLoading(layoutDispatch)
        }
      }

    useLayoutEffect(() => {
      getAssignees();
      getAllVehicles();
      getAllWorkOrders();
    }, [])


  const handleSubmitImage = (e) => {
    e.preventDefault();
    let acceptedFiles = e.target.files;

    for (let file of acceptedFiles) {
      const randomString = Math.random().toString(36).substring(2, 10);
      const newFileName = getNewFileName(file.name)
      setFiles((prevFiles) => [
        ...prevFiles,
        {
          name: newFileName,
          display_name: newFileName,
          file: file,
          ContentType: file.type,
        },
      ]);
      setCurrentItemFiles((prevFiles) => [...prevFiles, newFileName]);
    }
  };

  const handleDeleteFile = (fileName) => {
    const updatedFiles = files?.filter((file) => file.name !== fileName);
    setFiles(updatedFiles);
    setCurrentItemFiles(currentItemFiles?.filter((item) => item !== fileName));
  };

  const handleDeleteCurrentFile = (itemToDelete) => {
    setCurrentItemFiles(
      currentItemFiles.filter((item) => item !== itemToDelete)
    );
    if (!deletedFiles.includes(itemToDelete)) {
      setDeletedFiles((prevDeletedFiles) => [
        ...prevDeletedFiles,
        itemToDelete,
      ]);
    }
  };

  const getUploadUrl = async (file, recordID) => {
    const params = {
      bucket: tms_uploads_bucket,
      ContentType: file.file.type,
      Key: env + "/issues/" + recordID + "/" + file.name,
    };
    const response = await fetch(
      `${domain}/generate-put-url?${qs.stringify(params)}`,
      {
        headers: {
          "Content-Type": params.ContentType,
        },
      }
    );
    return response.json();
  };

  const uploadFileToS3 = async (uploadUrl, file, contentType) => {
    const response = await fetch(uploadUrl.putURL, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": contentType,
      },
    });
    if (response.status !== 200) {
      console.log('response', response);
      message.error("File upload failed.")
      throw new Error("File upload failed.");
    }
  };

  const handleSubmit = async () => {
    const isEmptyOrUndefined = (value) => value === '' || value === null || value === undefined;
    const validations = [
      { key: 'creationDate', message: 'Please add issue date & time' },
      { key: 'priority', message: 'Please add issue priority' },
      { key: 'cause', message: 'Please add issue title' },
      { key: 'reportedTo', message: 'Please add issue reportedTo' },
      { key: 'dueDate', message: 'Please add issue due date' },
    ];

    for (const { key, message: errorMessage } of validations) {
      if (isEmptyOrUndefined(issueDetail?.[key])) {
        message.error(errorMessage);
        return;
      }
    }

    const assignee = users?.find((user) => user.id == issueDetail?.reportedTo)
      const emailPayload = {
        type: id > 0 ? 1 : 2,
        assignee: assignee,
      }

    showLoading(layoutDispatch);
    const isUpdate = id > 0;
    const dataToSend = {
      id: isUpdate ? id : undefined,
      issue_date: moment(issueDetail.creationDate).format("YYYY-MM-DD HH:mm:ss"),
      issue_completion_date: isUpdate ? issueDetail.completionDate : undefined,
      issue_due_date: moment(issueDetail.dueDate).format("YYYY-MM-DD"),
      priority: issueDetail.priority,
      title: issueDetail.cause,
      assigned_to: issueDetail.reportedTo,
      notes: issueDetail.notes,
      files: currentItemFiles ? currentItemFiles : [],
      vehicle_id: issueDetail?.vehicle,
      work_order_id: issueDetail?.work_order_id,
    }
    try {
      const method = isUpdate ? "PUT" : "POST";
      const res = await Auth.fetch("/issue/issue", {
        method: method,
        body: JSON.stringify(dataToSend),
      })
      if (res?.ack) {
        const record = isUpdate ? id : res?.record_id;
        console.log(res);
        const uploadPromises = []
        if (files.length > 0) {
          files.forEach((f) => {
            uploadPromises.push(
              getUploadUrl(f, record)
                .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                .catch(error => console.error("Error uploading file:", error))
            );
          });
        }
        await Promise.all(uploadPromises);
        message.success(res?.message)
        if (env === "prod") {
          fetch("https://tmsemail.api-magnafoodservice.co.uk/api/email/issue", {
            method: "POST",
            headers: {
              Authorization: "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Im5vb3JAZ21haWwuY29tIiwidXNlcl9pZCI6MSwiaWF0IjoxNzI4NDE0NjIzLCJhdWQiOiJodHRwczovL3d3dy5tYWduYWZvb2RzZXJ2aWNlLmNvLnVrLyIsImlzcyI6Ik1hZ25hIEZvb2QgU2VydmljZSIsInN1YiI6Im5vb3JAZ21haWwuY29tIn0.fb--ERLcBgLhrec6Ed_rAR_uGiBWyFdLN-4fYjjdhjw411DODxyg058A7VVQuLAgD5A8OEa-9jOar0eOTqZk3WGnP8mhh_Tx0smCmiVNY73Odn0x0KZgVyx1yf4W6nEzAHJImmXwzbnPBTIVww7CG2a47GZ8M_XQxFJNhovOnIAEoIZdFz989QdyS_8UpTVIr1UV6nbpZ01ZNuHlU-ZBxJ9zPez1bD7Q9U8Gg59AeJsr3vp6XPDuWmW0C2G3Z3YO-5jT7e1S-lFNg7_8YTH5ju7RWlvdjOJ8lGIiQAVF4aRMXIJh3HHPYBdUWugj7Zb0ZF8F4rz8sR4zNgqEUXJtPQ",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(emailPayload),
          }).catch((err) => console.error("Error sending email:", err)); 
        } else {
          fetch("http://localhost:7420/api/email/issue", {
            method: "POST",
            headers: {
              Authorization: "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Im5vb3JAZ21haWwuY29tIiwidXNlcl9pZCI6MSwiaWF0IjoxNzI3ODgwNDE0LCJleHAiOjE3NTk0MTY0MTQsImF1ZCI6Imh0dHBzOi8vd3d3Lm1hZ25hZm9vZHNlcnZpY2UuY28udWsvIiwiaXNzIjoiTWFnbmEgRm9vZCBTZXJ2aWNlIiwic3ViIjoibm9vckBnbWFpbC5jb20ifQ.Bidb4jyOqnI4JdF9o0WpcEqiGQDpxv47KZ2mFyhFhLwj0LSui0ukFFH889J3tdVs3A-8ah-rk5026bfhEpneyUzkPl0IEE1iTBt4rdmXnUytXQRY38AsSiMv_v0S1gFL0JJtMh2Odzh3JpPbALaqEhCX0P2Kvhy9jNeczzWL8-rYw9b9WVgVN_mBd8AcdzgbzbeVOPd9hDL24im5QXwd6wIT9hme3e-lyBvW24bEp-ozxwaujVAZrtdh8BoSTTD2lyn0nKHc7iErGFjhFvtIw2pzvtnorNzfWJ2hB2BABQ0JOruxnxVks7NbNAMyBhchQhGV5af6ssDAZ4YaKUeNpw",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(emailPayload),
          }).catch((err) => console.error("Error sending email:", err));
        }
        navigate('/workshop_issues')
      } else {
        message.error(res?.message)
      }

    } catch (err) {
      console.log(err);
    }
    finally {
      hideLoading(layoutDispatch);
    }
  };

  const getDataByID = async () => {
    showLoading(layoutDispatch)
    try {
      const res = await Auth.fetch(`/issue/issue?id=${id}`)
      if (res?.ack) {
        setCurrentItemFiles(res?.issue.files ? JSON.parse(res?.issue.files) : [])
        setissueDetail((prevDetails) => ({
          ...prevDetails,
          creationDate: res?.issue.issue_date,
          cause: res?.issue.title,
          priority: res?.issue.priority,
          notes: res?.issue.notes,
          reportedTo: res?.issue.assigned_to,
          dueDate: res?.issue.issue_due_date,
          completionDate: res?.issue.issue_completion_date,
          vehicle: res?.issue.vehicle_id,
          issue_code: res?.issue.issue_code,
          work_order_id: res?.issue?.work_order_id
        }))
      }
    } catch (err) {
      console.log(err);
    } finally {
      hideLoading(layoutDispatch)
    }
  }

  useEffect(() => {
    if (id > 0) {
      getDataByID()
    }
  }, [])

  useEffect(() => {
          message.config({
            top: window.innerHeight - 100, 
            duration: 3, 
          });
        }, []);

  return (
    <div className={classes.dataSheet}>
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <h3>Issue</h3>
        {
              id > 0 && (
                <div style={{ display: 'flex', alignItems: 'center'}}>
                  
                {
                  !issueDetail?.work_order_id > 0 && (

                    <Button style={{ marginRight: '10px'}} type="primary"
                    onClick={() => {
                      const conversionData = {
                        issue_id: id, 
                        due_date: issueDetail?.dueDate,
                        assignee: issueDetail?.reportedTo,
                        vehicle: issueDetail?.vehicle,
                        priority: issueDetail?.priority
                      }
                      navigate(`/workshop_work_order/new`, {
                      state: { conversionData: conversionData }
                    }
                  )}
                  }
                     >
                      Convert To Work Order
                      </Button>
                  )
                }
                 </div>
              )
            }
      </div>
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <span>
                        Code
                      </span>
                          <Input 
                          value={issueDetail?.issue_code}
                          disabled
                          placeholder='To be generated'
                           />
                      </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Date<span style={{ color: "red", fontSize: "15px" }}>*</span>
            </span>
            <DatePicker
              format={"YYYY-MM-DD HH:mm:ss"}
              value={issueDetail.creationDate ? dayjs(issueDetail.creationDate) : null}
              onChange={(_, dateStr) => {
                setissueDetail((prevDetails) => ({
                  ...prevDetails,
                  creationDate: dateStr,
                }))
              }}
              showTime
              style={{
                width: "100%",
              }}
              placeholder="Select issue date & time"
            />
          </Col>
          
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Title<span style={{ color: "red" }}>*</span>
            </span>
            <Input
              value={issueDetail?.cause}
              onChange={(e) => {
                setissueDetail((prevDetails) => ({
                  ...prevDetails,
                  cause: e.target.value,
                }))
              }}
              placeholder='Please enter title'
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Priority<span style={{ color: "red" }}>*</span>
            </span>
            <Select
              style={{
                width: "100%",
              }}
              showSearch
              value={issueDetail.priority}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                setissueDetail((prevDetails) => ({
                  ...prevDetails,
                  priority: value,
                }))
              }}
            >
              {issuePriorities.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Vehicle
            </span>
            <Select
              style={{
                width: "100%",
              }}
              showSearch
              value={issueDetail.vehicle}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                setissueDetail((prevDetails) => ({
                  ...prevDetails,
                  vehicle: value,
                }))
              }}
            >
              {vehicles.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item?.registration_number}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Reported By<span style={{ color: "red" }}>*</span>
            </span>
            <Input
              value={issueDetail?.reportedBy}
              disabled
              placeholder='Issue reported By'
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Reported To<span style={{ color: "red" }}>*</span>
            </span>
            <Select
              style={{
                width: "100%",
              }}
              showSearch
              value={issueDetail.reportedTo}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                setissueDetail((prevDetails) => ({
                  ...prevDetails,
                  reportedTo: value,
                }))
              }}
            >
              {users.map((item) => (
                <Option key={item.id} value={item.id}>
                  {`${item?.first_name} ${item.last_name}`}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                    <span>
                                      Work Order
                                    </span>
                                    <Select
                          style={{
                            width: "100%",
                          }}
                          showSearch
                          suffixIcon={
                            <>
                            {id > 0 && issueDetail?.work_order_id  && (
                              <Tooltip title="Go to Linked Work Order">
                              <ExportOutlined  onClick={()=> navigate(`/workshop_work_order/${issueDetail?.work_order_id}`)} style={{color: '#5b5858', fontSize: '25px' }} />
                              </Tooltip>
                            )}
                            </>
                        }
                          value={issueDetail.work_order_id}
                          placeholder="Please Select"
                          filterOption={(input, option) =>
                            option?.children?.toLowerCase().includes(input?.toLowerCase())
                          }
                          onChange={(value) => {
                            setissueDetail((prevDetails) => ({
                              ...prevDetails,
                              work_order_id: value,
                            }))
                          }}
                          
                        >
                          {allWorkOrders?.map((item) => (
                            <Option key={item?.id} value={item?.id}>
                              {`${item?.work_order_code}`}
                            </Option>
                          ))}
                        </Select>
                        </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Due Date<span style={{ color: "red", fontSize: "15px" }}>*</span>:
            </span>
            <DatePicker
              format={"YYYY-MM-DD"}
              value={issueDetail.dueDate ? dayjs(issueDetail.dueDate) : null}
              onChange={(_, dateStr) => {
                setissueDetail((prevDetails) => ({
                  ...prevDetails,
                  dueDate: dateStr,
                }))
              }}
              style={{
                width: "100%",
              }}
              placeholder="Select issue due date "
            />
          </Col>
          {
            id > 0 && (
              <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Completion Date:
            </span>
            <DatePicker
              format={"YYYY-MM-DD"}
              value={issueDetail.completionDate ? dayjs(issueDetail.completionDate) : null}
              onChange={(_, dateStr) => {
                setissueDetail((prevDetails) => ({
                  ...prevDetails,
                  completionDate: dateStr,
                }))
              }}
              style={{
                width: "100%",
              }}
              placeholder="Select issue due date "
            />
          </Col>
            )
          }
        </Row>
        <Row style={{ marginTop: '5px' }} gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <span>
              Notes:
            </span>
            <TextArea
              value={issueDetail?.notes}
              onChange={(e) => {
                setissueDetail((prevDetails) => ({
                  ...prevDetails,
                  notes: e.target.value,
                }))
              }}
              placeholder='Please enter notes of the issue.'
              autoSize={{ minRows: 3, maxRows: 6 }} />
          </Col>
        </Row>
        <Divider />

        <>
          <div>
            <UploadButton
              component="label"
              variant="contained"
              sx={{ marginTop: "10px" }}
              startIcon={<CloudUploadIcon />}
            >
              Add files
              <input
                type="file"
                hidden
                multiple
                capture
                onChange={(e) => {
                  const myfiles = e.target.files;

                  if (myfiles.length > 10) {
                    message.error("A maximum of 10 images can be uploaded at a time.");
                    e.target.value = "";
                  } else {
                    handleSubmitImage(e);
                  }
                }}
              />
            </UploadButton>
          </div>
          <Divider />
          {currentItemFiles?.length > 0 && <h4>Current Files:</h4>}
      <ul>
                  {currentItemFiles?.length > 0 &&
                    currentItemFiles
                      .filter(
                        (item) =>
                          !files.some((file) => file.name === item)
                      )
                      .map((item) => {
                        const encodedFileName = item.replace(/\+/g, '%2B');
                        return (
                          <li>
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={() => handleDeleteCurrentFile(item)}
                                >
                                  <DeleteIcon />
                                </IconButton>

                            <a
                              href={`${tms_uploads_cdn}/${env}/issues/${id}/${encodedFileName}`}
                              target="_blank"
                            >
                              {extractOriginalFileName(encodedFileName)}
                            </a>
                          </li>
                        );
                      })}
                </ul>
          {files?.length > 0 && <h4>Files:</h4>}
          <ul>
            {files.length > 0 &&
              files.map((files) => {
                const encodedFileName = files?.name.replace(/\+/g, '%2B');
                return (
                  <li>
                    {" "}
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => handleDeleteFile(files.name)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    {extractOriginalFileName(encodedFileName)}
                  </li>
                );
              })}
          </ul>
          <Divider />

        </>
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
        >
          <Button
            onClick={() => navigate("/workshop_issues")}
            danger
            size="large"
            style={{ marginRight: "10px" }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </>
      <Message type={type} msg={loadMessage} />
    </div>

  )
}

export default Issue
