import { Descriptions, Divider, Image, Modal, Result, Table, Tag } from 'antd';
import React from 'react'
import { env, netsuite_url, tms_uploads_cdn } from '../../Components/config';
import { PostponeReasons } from '../../Components/common/constants';
import { formatCurrency } from '../../Components/common/CommonFun';

const DetailsModal = ({filesOpen, handleFilesClose, selectedRecord, itemColumns, getStatusText, paymentAuthorizer, classes, rowClassName  }) => {
  return (
    <Modal
            open={filesOpen}
            onCancel={handleFilesClose}
            okButtonProps={{ style: { display: "none" } }}
            title="Order Details"
            cancelText="Close"
            width={'90vw'}
            style={{
              top: 20,
              height: '90vh',
              overflowY: 'auto'
            }}
          >
            <>
      {selectedRecord &&
      (
        selectedRecord.postponed_status ||
        (selectedRecord.postponed_files && selectedRecord.postponed_files.length > 0) ||
        selectedRecord.postponed_notes ||
        selectedRecord.received_by ||
        selectedRecord.notes ||
        selectedRecord.delivery_discount_req_status ||
        selectedRecord.delivery_discount_auth_by ||
        selectedRecord.payment_req_status ||
        selectedRecord.payment_auth_by ||
        selectedRecord.signature ||
        (selectedRecord.files && selectedRecord.files.length > 0) ||
        selectedRecord?.items ||
        selectedRecord?.delivery_Instructions ||
        selectedRecord?.amountReceivedCard ||
        selectedRecord?.amountReceivedCash 
      ) ? (
        <>
        {selectedRecord?.customerProjectId ? (
            <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
              <Descriptions.Item
                label="Shop Name"
                labelStyle={{ width: "200px" }}
              >
                {selectedRecord?.customerProjectId || ""}
              </Descriptions.Item>
            </Descriptions>
           ) : null}
            <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
              <Descriptions.Item
                label="Order No."
                labelStyle={{ width: "200px" }}
              >
                <span>
                        {selectedRecord?.invoice_no != null && selectedRecord?.invoice_no != "" ? (
                            <a 
                                href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${selectedRecord?.invoiceInternalId}`} 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                {selectedRecord?.invoice_no}
                            </a>
                        ) : (
                            <a 
                                href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${selectedRecord?.internalId}`} 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                {selectedRecord?.saleOrderNo}
                            </a>
                        )}
                    </span>
              </Descriptions.Item>
            </Descriptions>
           {selectedRecord?.customerName ? (
            <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
              <Descriptions.Item
                label="Customer Code"
                labelStyle={{ width: "200px" }}
              >
                <span><a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${selectedRecord.customerInternalId}`} target="_blank">{selectedRecord.customerName}</a></span>
              </Descriptions.Item>
            </Descriptions>
           ) : null}
           {selectedRecord?.amountReceivedCash > 0 ? (
                   <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
                     <Descriptions.Item
                       label="Amount Received Cash"
                       labelStyle={{ width: "200px" }}
                     >
                       {formatCurrency(selectedRecord?.amountReceivedCash)}
                     </Descriptions.Item>
                   </Descriptions>
                  ) : null}
                  {selectedRecord?.amountReceivedCard > 0 ? (
                   <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
                     <Descriptions.Item
                       label="Amount Received Card"
                       labelStyle={{ width: "200px" }}
                     >
                       {formatCurrency(selectedRecord?.amountReceivedCard)}
                     </Descriptions.Item>
                   </Descriptions>
                  ) : null}
          {selectedRecord?.postponed_status ? (
            <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
              <Descriptions.Item
                label="Postponement Status"
                labelStyle={{ width: "200px" }}
              >
                {PostponeReasons.find((obj) => obj.id === selectedRecord?.postponed_status)?.title || ""}
              </Descriptions.Item>
            </Descriptions>
           ) : null}
    
          {selectedRecord?.delivery_instructions && (
            <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
              <Descriptions.Item label="Delivery Notes" labelStyle={{ width: "200px" }}>
                {selectedRecord.delivery_instructions || ""}
              </Descriptions.Item>
            </Descriptions>
          )}
    
          {selectedRecord?.notes && (
            <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
              <Descriptions.Item label="Driver Notes" labelStyle={{ width: "200px" }}>
                {selectedRecord.notes || ""}
              </Descriptions.Item>
            </Descriptions>
          )}
    
          {selectedRecord?.delivery_discount_req_notes && (
            <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
              <Descriptions.Item
                label="Delivery Discount Notes"
                labelStyle={{ width: "200px" }}
              >
                {selectedRecord.delivery_discount_req_notes}
              </Descriptions.Item>
            </Descriptions>
          )}
    
          {selectedRecord?.payment_req_notes && (
            <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
              <Descriptions.Item
                label="Payment Discount Notes"
                labelStyle={{ width: "200px" }}
              >
                {selectedRecord.payment_req_notes}
              </Descriptions.Item>
            </Descriptions>
          )}
    
          {selectedRecord?.postponed_notes && (
            <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
              <Descriptions.Item label="Postpone Notes" labelStyle={{ width: "200px" }}>
                {selectedRecord.postponed_notes || ""}
              </Descriptions.Item>
            </Descriptions>
          )}
    
          
    
          {selectedRecord?.delivery_discount_req_status && (
            <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
              <Descriptions.Item
                label="Delivery Discount"
                labelStyle={{ width: "200px" }}
              >
                {getStatusText(selectedRecord.delivery_discount_req_status)}
              </Descriptions.Item>
            </Descriptions>
          )}
    
        
    
          {selectedRecord?.delivery_discount_auth_by && (
            <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
              <Descriptions.Item
                label="Delivery Auth By"
                labelStyle={{ width: "200px" }}
              >
                {(() => {
                  const authorizer = paymentAuthorizer?.find(
                    (obj) =>
                      obj?.id?.toString() ===
                      selectedRecord?.delivery_discount_auth_by?.toString()
                  );
                  return `${authorizer?.first_name || ""} ${authorizer?.last_name || ""}`;
                })()}
              </Descriptions.Item>
            </Descriptions>
          )}
    
          {selectedRecord?.payment_req_status && (
            <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
              <Descriptions.Item
                label="Payment Discount"
                labelStyle={{ width: "200px" }}
              >
                {getStatusText(selectedRecord.payment_req_status)}
              </Descriptions.Item>
            </Descriptions>
          )}
    
          
    
          {selectedRecord?.payment_auth_by > 0 && (
            <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
              <Descriptions.Item
                label="Payment Auth By"
                labelStyle={{ width: "200px" }}
              >
                {(() => {
                  const authorizer = paymentAuthorizer?.find(
                    (obj) => obj?.id === selectedRecord?.payment_auth_by
                  );
                  return `${authorizer?.first_name || ""} ${authorizer?.last_name || ""}`;
                })()}
              </Descriptions.Item>
            </Descriptions>
          )}
    
          {selectedRecord?.received_by && (
            <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
              <Descriptions.Item
                label="Receiver Name"
                labelStyle={{ width: "200px" }}
              >
                {selectedRecord.received_by || ""}
              </Descriptions.Item>
            </Descriptions>
          )}
    
          {selectedRecord?.signature != null && (
            <>
              <h4>Signature:</h4>
              <div
                style={{
                  position: "relative",
                  marginRight: "10px",
                  display: "inline-block",
                }}
              >
                <Image
                  width={150}
                  height={100}
                  style={{ padding: "5px", border: "1px solid lightgrey" }}
                  src={selectedRecord?.signature}
                />
              </div>
            </>
          )}
    
          <Divider />
    
          {selectedRecord?.files?.length > 0 && (
            <>
              <h4>Files</h4>
              <Image.PreviewGroup>
                {selectedRecord.files.map((item) => {
                  const imageUrl = `${tms_uploads_cdn}/${env}/route_orders/${selectedRecord?.routeStatusID}/${item}`;
                  return (
                    <div
                      key={item}
                      style={{
                        position: "relative",
                        marginRight: "10px",
                        display: "inline-block",
                      }}
                    >
                      <Image
                        width={200}
                        height={150}
                        style={{ padding: "5px", border: "1px solid lightgrey" }}
                        src={imageUrl}
                      />
                    </div>
                  );
                })}
              </Image.PreviewGroup>
            </>
          )}
          {selectedRecord?.postponed_files?.length > 0 && (
            <>
              <h4>Postpone Files</h4>
              <Image.PreviewGroup>
                {selectedRecord.postponed_files.map((item) => {
                  const imageUrl = `${tms_uploads_cdn}/${env}/postpone_order/${selectedRecord?.routeStatusID}/${item}`;
                  return (
                    <div
                      key={item}
                      style={{
                        position: "relative",
                        marginRight: "10px",
                        display: "inline-block",
                      }}
                    >
                      <Image
                        width={200}
                        height={150}
                        style={{ padding: "5px", border: "1px solid lightgrey" }}
                        src={imageUrl}
                      />
                    </div>
                  );
                })}
              </Image.PreviewGroup>
            </>
          )}
          <h2>Items</h2>
          <div className={classes.antTable}>
    
              <Table
              columns={itemColumns}
              dataSource={selectedRecord?.items?.filter(
                (item) => item.itemId !== "Delivery Charge"
              )}
              pagination={false}
              style={{ overflowX: "auto", marginTop: "10px", marginBottom: "20px" }}
              scroll={{
                y: 250,
              }}
              rowClassName={rowClassName}
              footer={() => {
                const deliveryChargeItem = selectedRecord?.items?.find(
                  (item) => item.itemId === "Delivery Charge"
                );
                return deliveryChargeItem
                  ? (
                    <div>
                      {
                        deliveryChargeItem.grossAmountIncludingTax > 0 && (
                          <Tag style={{  height: '40px', alignContent: 'center', fontWeight: 400 }} color="grey">
                          <span style={{fontSize: '18px'}}>{`Delivery Charges: ${'£' + deliveryChargeItem.grossAmountIncludingTax}`}
                        <span style={{fontSize: '14px', fontWeight: 'bolder'}}> Incl. VAT</span>
                      </span>
                      </Tag>
                        )
                      }
                    </div>
                  ) : (
                    <Tag color="grey" style={{ fontSize: '18px', height: '40px', alignContent: 'center', fontWeight: 400 }}>No Delivery Charges</Tag>
                  )
                }}
                />
                </div>
        </>
      ) : (
        <Result status="404" subTitle="Sorry, no data data to show." />
      )}
    </>
    
          </Modal>
  )
}

export default DetailsModal
