import moment from 'moment'
import React, { useEffect, useState } from 'react'
import AuthService from '../../Components/auth/AuthService';
import { Descriptions, Modal, Result, Table, Tag, Image, Divider, Statistic, Col, Card, Row, Button, Tooltip, Badge } from 'antd';
import { netsuite_url, tms_uploads_cdn, env } from '../../Components/config';
import { CheckOutlined, CloseOutlined, EyeFilled, InfoCircleOutlined } from '@ant-design/icons';
import { makeStyles } from "@mui/styles";
import { cardTerminals, deliveryStatuses, PostponeReasons } from '../../Components/common/constants';
import { formatCurrency } from '../../Components/common/CommonFun';
import DetailsModal from './DetailsModal';

const useStyles = makeStyles((theme) => ({
    dataSheet: {
        padding: '5px',
      "& .ant-card .ant-card-body": {
        padding: "4px",
        // backgroundColor: 'rgb(240, 240, 240)',
      },
      
      "& .ant-statistic .ant-statistic-content": {
        fontSize: "12px",
        fontWeight: "bold",
      },
      "& .ant-statistic .ant-statistic-title": {
        fontSize: "12px",
      },
      "& .ant-input[disabled]": {
        color: 'black !important',
        fontWeight: 500
      },
      "& .ant-card-bordered": {
      border: '1px solid #b1aeae'
    }
      
    },
    antTable: {
      "& .ant-table-tbody > tr > td": {
        borderBottom: "1px solid #b7b9bd",
      },
      "& .ant-table-footer": {
        padding: '16px 0px !important'
      }
    },
  }));

const DriverManifestTab = ({ selectedRoute, deliveryDate, driverManifestData, paymentAuthorizer }) => {

    const Auth = new AuthService();
    const classes = useStyles();

    const [message, setMessage] = useState('')
    
    const [loadMessage, setLoadMessage] = React.useState("");
    const [type, setType] = React.useState("");

    const [filesOpen, setFilesOpen] = React.useState(false);
    const [selectedRecord, setSelectedRecord] = React.useState("");


  const handleFilesOpen = (row) => {
    console.log("row", row);
    if (row) {
      setSelectedRecord(row);
    }
    setFilesOpen(true);
  };
  const handleFilesClose = () => setFilesOpen(false);

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return <Tag color="warning">Pending</Tag>;
      case 2:
        return <Tag color="success">Approved</Tag>;
      case 3:
        return <Tag color="error">Rejected</Tag>;
      default:
        return "";
    }
  };

    const dataCol = [
        {
          title: "S.No.",
          dataIndex: "rank",
          key: "rank",
          width: 55
        }, 
        {
          title: "Shop Code",
          render: (text,record) => {
            return <span><a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${record.customerInternalId}`} target="_blank">{record.customerName}</a></span>;
          },
          width: 100
        },
        {
          title: "Shop Name",
          dataIndex: "customerProjectId",
          key: "customerProjectId",
          width: 175
        },
        // {
        //   title: "Order No",
        //   render: (text, record) => {
        //     return <span><a href={`${config.netsuite_url}/app/accounting/transactions/custinvc.nl?id=${record.invoiceInternalId}`} target="_blank">{record.invoice_no}</a></span>;
        // },
          {
            title: 'Order No.',
            render: (text, record) => {
                return (
                    <span>
                        {record.invoice_no != null && record?.invoice_no != "" ? (
                            <a 
                                href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${record.invoiceInternalId}`} 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                {record.invoice_no}
                            </a>
                        ) : (
                            <a 
                                href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${record.internalId}`} 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                {record.saleOrderNo}
                            </a>
                        )}
                    </span>
                );
            },
            width: 100
          },
        {
          title: "Address",
          dataIndex: "ship_address1",
          key: "ship_address1",
          width: 180
        },
        {
          title: "Post Code",
          dataIndex: "ship_zip",
          key: "ship_zip",
          width: 100
        },
        {
          title: "Terms",
          dataIndex: "terms",
          key: "terms",
          width: 100
        },
        {
          title: "Total (£)",
          dataIndex: "orderAmount",
          key: "orderAmount",
          render: (text, record) => Number(record.orderAmount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) || '0.00',
          width: 100
        },
        {
          title: "Cash",
          dataIndex: "amountReceivedCash",
          key: "amountReceivedCash",
          render: (text, record) => Number(record.amountReceivedCash).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) || '0.00',
          width: 80
        },
        {
          title: "Card",
          dataIndex: "amountReceivedCard",
          key: "amountReceivedCard",
          render: (text, record) => Number(record.amountReceivedCard).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) || '0.00',
          width: 80
        },
        // {
        //   title: "Bank",
        //   dataIndex: "amountReceivedAccount",
        //   key: "amountReceivedAccount",
        //   render: (text, record) => Number(record.amountReceivedAccount).toLocaleString(undefined, {
        //     minimumFractionDigits: 2,
        //     maximumFractionDigits: 2
        //   }) || '0.00',
        //   width: 80
        // },
        {
          title: "Status",
          render(record) {
            let statusText;
            let backgroundColor;
            // let delivered_at = record?.delivered_at || "N/A";
            const delivered_at = record?.delivered_at && moment(record.delivered_at).isValid()
                  ? moment(record.delivered_at).format('YYYY-MM-DD HH:mm:ss')
                  : "N/A";
    
    
            switch (record?.delivered) {
              case 1:
                statusText = "Delivered";
                backgroundColor = "#80cb80";
                break;
              case 2:
                statusText = "Postponed";
                backgroundColor = "#f0d787";
                break;
                case -1:
                statusText = "Submitted";
                backgroundColor = "#1890FF";
                break;
              default:
                statusText = "Not Delivered";
                backgroundColor = "#edb1a7";
                break;
            }
    
            return {
              props: {
                style: {
                  background: backgroundColor,
                  textAlign: 'center',
    
                },
              },
              children: (
                <>
                {statusText}
                {record?.delivered === 1 && (
                  <Tooltip title={`Delivered at: ${delivered_at}`}>
                    <InfoCircleOutlined style={{ marginLeft: 8, color: "#000" }} />
                  </Tooltip>
                )}
              </>
              ),
            };
          },
          width: 150,
        },
        {
          title: "Action",
          align: "center",
          width: 75,
          render(record) {
            return (
                <Badge
                  color="#1890ff"
                  dot={record?.delivery_instructions || record?.notes || record?.payment_req_notes || record?.delivery_discout_notes || record?.postponed_notes }

                >
              <EyeFilled
                style={{ cursor: "pointer", fontSize: "25px" }}
                onClick={() => {
                  handleFilesOpen(record);
                }}
              />
              </Badge>
            );
          },
        },
      ];

      const itemColumns = [
        {
          title: "S No.",
          dataIndex: "serialNumber",
          key: "serialNumber",
          render: (text, record, index) => index + 1,
          width: 40,
        },
        {
          title: "Code",
          dataIndex: "itemId",
          key: "itemId",
          width: 40,
        },
        {
          title: "Name",
          dataIndex: "itemName",
          key: "itemName",
          width: 250,
        },
        {
          title: "QTY",
          dataIndex: "quantity",
          key: "quantity",
          width: 75,
          align: 'center',
        },
        {
          title: "Delivered QTY",
          dataIndex: "deliveredQuantity",
          key: "deliveredQuantity",
          width: 75,
          align: 'center',
        },
        {
          title: "Status",
          align: 'center',
          render: (record) => {
            const status = deliveryStatuses.find(item => item.id === record?.deliveryStatus)
            return (
               <span>{status ? status.title : ''}</span>
            );
          },
          width: 75,
        },
      ];

      const rowClassName = (record) => {
        const status = deliveryStatuses.find(item => item.id === record?.deliveryStatus);
        if (status?.title !== 'Delivered') {
          return 'highlight-row';
        }
        return '';
      };

    useEffect(() => {
        const driver = selectedRoute?.driver_name
      setMessage(`Hello ${driver}` )
    }, [selectedRoute])

      console.log(driverManifestData,'driverManifestData1');

      const genExtra = () => {
        const completed = driverManifestData[0]?.start_status === 2 ? true : false;
    
      return (
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <Button
              style={{
                marginRight: '5px',
                color: completed  ? 'green' : 'red',
                cursor: 'alias',
                pointerEvents: "none",
                padding: '4px 4px'
              }}
              icon={completed ? <CheckOutlined/> : <CloseOutlined />}
                >
                  Route Completed
            </Button>
      </div>
      )
    };
      
    
  return (
    <>
    <div className={classes.dataSheet}>
              {/* <Row gutter={[6, 16]} >
                <Col span={4}>
                  <Card style={{ backgroundColor: "#f0f0f0" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Total Cash Expected"
                        value={driverManifestData[0]?.totalCash}
                        precision={2}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                        prefix="£"
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={4}>
                  <Card style={{ backgroundColor: "#f0f0f0" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Total Card Expected"
                        value={driverManifestData[0]?.totalCard}
                        precision={2}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                        prefix="£"
                      />
                    </div>
                  </Card>
                </Col>
                
              </Row> */}
              <Row
    gutter={[4, 4]}
    style={{
      background: "#f0f2f5",
      padding: "4px",
      borderRadius: "8px",
    }}
  >
    {/* Cash Section */}
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
      <div
        style={{
          background: "#ffffff",
          padding: "4px",
          borderRadius: "8px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
          border: '2px solid #b1aeae'
        }}
      >
        <Row gutter={[6, 6]}>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Total Cash"
                value={formatCurrency(driverManifestData[0]?.totalCash)}
                valueStyle={{
                  color: "#1677FF",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Cash Received"
                value={formatCurrency(driverManifestData[0]?.cashReceived)}

                valueStyle={{
                  color: "#1677FF"
                  
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Difference"
                value={formatCurrency(driverManifestData[0]?.cashReceived - driverManifestData[0]?.totalCash)}
                valueStyle={{
                  color: driverManifestData[0]?.cashReceived - driverManifestData[0]?.totalCash > 0 
                ? "green" 
                : driverManifestData[0]?.cashReceived - driverManifestData[0]?.totalCash === 0 
                ? "blue" 
                : "red"

                  
                }}
              />
            </Card>
          </Col>
          
        </Row>
      </div>
    </Col>

    {/* Card Section */}
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
      <div
        style={{
          background: "#ffffff",
          padding: "4px",
          borderRadius: "8px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
          border: '2px solid #b1aeae'

        }}
      >
        <Row gutter={[6, 6]}>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Total Card"
                value={formatCurrency(driverManifestData[0]?.totalCard)}
                valueStyle={{
                  color: "#1677FF",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Card Received"
                value={formatCurrency(driverManifestData[0]?.cardReceived)}

                valueStyle={{
                  color: "#1677FF"
                  
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Difference"
                value={formatCurrency(driverManifestData[0]?.cardReceived - driverManifestData[0]?.totalCard)}
                valueStyle={{
                  color: driverManifestData[0]?.cardReceived - driverManifestData[0]?.totalCard > 0 
                ? "green" 
                : driverManifestData[0]?.cardReceived - driverManifestData[0]?.totalCard === 0 
                ? "#1677FF" 
                : "red"
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Col>
                   </Row>
      <Table
                  style={{ overflowX: "auto", marginTop: "10px" }}
                  columns={dataCol}
                  bordered
                  dataSource={driverManifestData[0]?.tableData}
                  pagination={false}
                  // scroll={{
                  //     y: 400,
                  //   }}
                    />
    </div>
    <DetailsModal filesOpen={filesOpen} handleFilesClose={handleFilesClose} selectedRecord={selectedRecord} itemColumns={itemColumns} getStatusText={getStatusText} paymentAuthorizer={paymentAuthorizer} classes={classes} rowClassName={rowClassName}/>
    {/* <Modal
        open={filesOpen}
        onCancel={handleFilesClose}
        okButtonProps={{ style: { display: "none" } }}
        title="Order Details"
        cancelText="Close"
        width={'90vw'}
        style={{
          top: 20,
          height: '90vh',
          overflowY: 'auto'
        }}
      >
        <>
  {selectedRecord &&
  (
    selectedRecord.postponed_status ||
    (selectedRecord.postponed_files && selectedRecord.postponed_files.length > 0) ||
    selectedRecord.postponed_notes ||
    selectedRecord.received_by ||
    selectedRecord.notes ||
    selectedRecord.delivery_discount_req_status ||
    selectedRecord.delivery_discount_auth_by ||
    selectedRecord.payment_req_status ||
    selectedRecord.payment_auth_by ||
    selectedRecord.signature ||
    (selectedRecord.files && selectedRecord.files.length > 0) ||
    selectedRecord?.items ||
    selectedRecord?.delivery_Instructions
  ) ? (
    <>
    {selectedRecord?.customerProjectId ? (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Shop Name"
            labelStyle={{ width: "200px" }}
          >
            {selectedRecord?.customerProjectId || ""}
          </Descriptions.Item>
        </Descriptions>
       ) : null}
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Order No."
            labelStyle={{ width: "200px" }}
          >
            <span>
                    {selectedRecord?.invoice_no != null && selectedRecord?.invoice_no != "" ? (
                        <a 
                            href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${selectedRecord?.invoiceInternalId}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            {selectedRecord?.invoice_no}
                        </a>
                    ) : (
                        <a 
                            href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${selectedRecord?.internalId}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            {selectedRecord?.saleOrderNo}
                        </a>
                    )}
                </span>
          </Descriptions.Item>
        </Descriptions>
       {selectedRecord?.customerName ? (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Customer Code"
            labelStyle={{ width: "200px" }}
          >
            <span><a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${selectedRecord.customerInternalId}`} target="_blank">{selectedRecord.customerName}</a></span>
          </Descriptions.Item>
        </Descriptions>
       ) : null}
       {selectedRecord?.amountReceivedCash.trim() ? (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Amount Received Cash"
            labelStyle={{ width: "200px" }}
          >
            {selectedRecord?.amountReceivedCash}
          </Descriptions.Item>
        </Descriptions>
       ) : null}
       {selectedRecord?.amountReceivedCard.trim() ? (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Amount Received Cash"
            labelStyle={{ width: "200px" }}
          >
            {selectedRecord?.amountReceivedCard}
          </Descriptions.Item>
        </Descriptions>
       ) : null}
      {selectedRecord?.postponed_status ? (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Postponement Status"
            labelStyle={{ width: "200px" }}
          >
            {PostponeReasons.find((obj) => obj.id === selectedRecord?.postponed_status)?.title || ""}
          </Descriptions.Item>
        </Descriptions>
       ) : null}

      {selectedRecord?.delivery_instructions && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item label="Delivery Notes" labelStyle={{ width: "200px" }}>
            {selectedRecord.delivery_instructions || ""}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.notes && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item label="Driver Notes" labelStyle={{ width: "200px" }}>
            {selectedRecord.notes || ""}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.delivery_discount_req_notes && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Delivery Discount Notes"
            labelStyle={{ width: "200px" }}
          >
            {selectedRecord.delivery_discount_req_notes}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.payment_req_notes && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Payment Discount Notes"
            labelStyle={{ width: "200px" }}
          >
            {selectedRecord.payment_req_notes}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.postponed_notes && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item label="Postpone Notes" labelStyle={{ width: "200px" }}>
            {selectedRecord.postponed_notes || ""}
          </Descriptions.Item>
        </Descriptions>
      )}

      

      {selectedRecord?.delivery_discount_req_status && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Delivery Discount"
            labelStyle={{ width: "200px" }}
          >
            {getStatusText(selectedRecord.delivery_discount_req_status)}
          </Descriptions.Item>
        </Descriptions>
      )}

    

      {selectedRecord?.delivery_discount_auth_by && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Delivery Auth By"
            labelStyle={{ width: "200px" }}
          >
            {(() => {
              const authorizer = paymentAuthorizer?.find(
                (obj) =>
                  obj?.id?.toString() ===
                  selectedRecord?.delivery_discount_auth_by?.toString()
              );
              return `${authorizer?.first_name || ""} ${authorizer?.last_name || ""}`;
            })()}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.payment_req_status && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Payment Discount"
            labelStyle={{ width: "200px" }}
          >
            {getStatusText(selectedRecord.payment_req_status)}
          </Descriptions.Item>
        </Descriptions>
      )}

      

      {selectedRecord?.payment_auth_by > 0 && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Payment Auth By"
            labelStyle={{ width: "200px" }}
          >
            {(() => {
              const authorizer = paymentAuthorizer?.find(
                (obj) => obj?.id === selectedRecord?.payment_auth_by
              );
              return `${authorizer?.first_name || ""} ${authorizer?.last_name || ""}`;
            })()}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.received_by && (
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Receiver Name"
            labelStyle={{ width: "200px" }}
          >
            {selectedRecord.received_by || ""}
          </Descriptions.Item>
        </Descriptions>
      )}

      {selectedRecord?.signature != null && (
        <>
          <h4>Signature:</h4>
          <div
            style={{
              position: "relative",
              marginRight: "10px",
              display: "inline-block",
            }}
          >
            <Image
              width={150}
              height={100}
              style={{ padding: "5px", border: "1px solid lightgrey" }}
              src={selectedRecord?.signature}
            />
          </div>
        </>
      )}

      <Divider />

      {selectedRecord?.files?.length > 0 && (
        <>
          <h4>Files</h4>
          <Image.PreviewGroup>
            {selectedRecord.files.map((item) => {
              const imageUrl = `${tms_uploads_cdn}/${env}/route_orders/${selectedRecord?.routeStatusID}/${item}`;
              return (
                <div
                  key={item}
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    display: "inline-block",
                  }}
                >
                  <Image
                    width={200}
                    height={150}
                    style={{ padding: "5px", border: "1px solid lightgrey" }}
                    src={imageUrl}
                  />
                </div>
              );
            })}
          </Image.PreviewGroup>
        </>
      )}
      {selectedRecord?.postponed_files?.length > 0 && (
        <>
          <h4>Postpone Files</h4>
          <Image.PreviewGroup>
            {selectedRecord.postponed_files.map((item) => {
              const imageUrl = `${tms_uploads_cdn}/${env}/postpone_order/${selectedRecord?.routeStatusID}/${item}`;
              return (
                <div
                  key={item}
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    display: "inline-block",
                  }}
                >
                  <Image
                    width={200}
                    height={150}
                    style={{ padding: "5px", border: "1px solid lightgrey" }}
                    src={imageUrl}
                  />
                </div>
              );
            })}
          </Image.PreviewGroup>
        </>
      )}
      <h2>Items</h2>
      <div className={classes.antTable}>

          <Table
          columns={itemColumns}
          dataSource={selectedRecord?.items?.filter(
            (item) => item.itemId !== "Delivery Charge"
          )}
          pagination={false}
          style={{ overflowX: "auto", marginTop: "10px", marginBottom: "20px" }}
          scroll={{
            y: 250,
          }}
          rowClassName={rowClassName}
          footer={() => {
            const deliveryChargeItem = selectedRecord?.items?.find(
              (item) => item.itemId === "Delivery Charge"
            );
            return deliveryChargeItem
              ? (
                <div>
                  {
                    deliveryChargeItem.grossAmountIncludingTax > 0 && (
                      <Tag style={{  height: '40px', alignContent: 'center', fontWeight: 400 }} color="grey">
                      <span style={{fontSize: '18px'}}>{`Delivery Charges: ${'£' + deliveryChargeItem.grossAmountIncludingTax}`}
                    <span style={{fontSize: '14px', fontWeight: 'bolder'}}> Incl. VAT</span>
                  </span>
                  </Tag>
                    )
                  }
                </div>
              ) : (
                <Tag color="grey" style={{ fontSize: '18px', height: '40px', alignContent: 'center', fontWeight: 400 }}>No Delivery Charges</Tag>
              )
            }}
            />
            </div>
    </>
  ) : (
    <Result status="404" subTitle="Sorry, no data data to show." />
  )}
</>

      </Modal> */}
                    </>
  )
}

export default DriverManifestTab
