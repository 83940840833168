import React, { useEffect, useState } from "react";
import {
  Col,
  Divider,
  Row,
  Table,
  Input,
  Button,
  Image,
  Popconfirm,
  Skeleton,
  Statistic,
  Card,
  Descriptions,
  Collapse,
  Tooltip,
  message,
} from "antd";
import "./reports.css";
import { makeStyles } from "@mui/styles";
import { CheckOutlined, CloseOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import moment from "moment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { IconButton, Button as UploadButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import qs from "query-string";
import config from "../../Components/config";
import { DatePickerField } from "../../Components/InputFields/InputFields";
import { hideLoading, showLoading, useLoadingDispatch } from "../../context/loadingContext";
import { List } from 'antd';
import TextArea from "antd/es/input/TextArea";
import { formatCurrency } from "../../Components/common/CommonFun";
const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: ' 15px',
    background: 'white',
    "& .ant-card .ant-card-body": {
      padding: "4px",
      backgroundColor: "rgb(240, 240, 240)",
    },
    "& .ant-card .ant-card-head": {
      padding: "0px 4px",
      // backgroundColor: "rgb(240, 240, 240)",
      minHeight: '40px'
    },
    "& .ant-statistic .ant-statistic-content": {
      fontSize: "12px",
      fontWeight: "bold",
    },
    "& .ant-statistic .ant-statistic-title": {
      fontSize: "12px",
    },
    "& .example-custom-input": {
      width: "100% !important",
    },
    "& .react-datepicker-wrapper": {
      width: "100% !important",
    },
    "& .ant-input[disabled]": {
      background: "none",
      color: "black",
      // fontWeight: "500",
    },
  },
}));

const FinancialSummary = ({
  financialSummaryData,
  deliveryDate,
}) => {
  const classes = useStyles();
  const [loadMessage, setLoadMessage] = useState("");
  const [type, setType] = useState("");
  const [oldBags, setOldBags] = React.useState([]);
  const [prevBags, setPrevBags] = React.useState([]);
  const [bags, setBags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const { Panel } = Collapse;
  var layoutDispatch = useLoadingDispatch();


  const getFinancialSummary = async (before) => {
    setIsLoading(true);
    try {
      const response = await Auth.fetch(
        `/route/financial-summary?delivery_date=${moment(deliveryDate).format(
          "YYYY-MM-DD"
        )}`
      );
      if (response.ack) {
        const oldBags1 = response?.financial_summaries;
        setOldBags(oldBags1);
        if (before !== undefined) {
          before.map(async (beforeBag, idx) => {
            const bag = oldBags1[idx];
            if (bag && bag.id && beforeBag.newFiles.length > 0) {
              const uploadPromises = beforeBag.newFiles.map(async (f, idx) => {
                try {
                  const uploadUrl = await getUploadUrl(f, bag.id);
                  await uploadFileToS3(uploadUrl, f.file, f.file.type);
                } catch (error) {
                  console.error("Error uploading file:", error);
                }
              });

              Promise.all(uploadPromises);
            }
          });
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (deliveryDate) {
      getFinancialSummary();
    }
  }, []);

  const getUploadUrl = async (file, id) => {
    const params = {
      bucket: config.tms_uploads_bucket,
      ContentType: file.file.type,
      Key: config.env + "/financial_summaries/" + id + "/" + file.name,
    };
    const response = await fetch(
      `${config.domain}/generate-put-url?${qs.stringify(params)}`,
      {
        headers: {
          "Content-Type": params.ContentType,
        },
      }
    );
    return response.json();
  };

  const uploadFileToS3 = async (uploadUrl, file, contentType) => {
    const response = await fetch(uploadUrl.putURL, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": contentType,
      },
    });
    if (response.status !== 200) {
      throw new Error("File upload failed.");
    }
  };


  function getNewFileName(oldFileName) {
    const extension = oldFileName?.slice(oldFileName?.lastIndexOf('.'));
    const baseName = oldFileName?.slice(0, oldFileName?.lastIndexOf('.'));
    const randomString = Math.random().toString(36).substring(2, 12);
    return `${baseName}-${randomString}${extension}`;
  }
  
  function extractOriginalFileName(modifiedFileName) {
    const extensionIndex = modifiedFileName?.lastIndexOf('.');
    const extension = modifiedFileName?.slice(extensionIndex);
    const randomStringPattern = /-\w{10}(?=\.\w+$)/;
    const baseName = modifiedFileName?.replace(randomStringPattern, '');
    return baseName;
  }

  const handleSubmitImage = (idx, e) => {
    e.preventDefault();
    let acceptedFiles = e.target.files;

    for (let file of acceptedFiles) {
      const randomString = Math.random().toString(36).substring(2, 10);
      // const newFileName = `${file.name.split(".")[0]}-${randomString}.${
      //   file.name.split(".")[1]
      // }`;
      const newFileName = getNewFileName(file.name)
      setLoadMessage("");
      const list = [...bags];
      let files1 = list[idx]["newFiles"];
      files1.push({
        name: newFileName,
        display_name: newFileName,
        file: file,
        ContentType: file.type,
      });
      let filesNameList = list[idx]["files"];
      filesNameList.push(newFileName);
      list[idx]["newFiles"] = files1 || [];
      list[idx]["files"] = filesNameList || [];
      setBags(list);
    }
  };
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    let newValue = value;
    if (name === "amount_banked" && parseFloat(value) > 20000) {
      let rand = Math.random() * 50;
      let str = " ";
      for (let i = 0; i < rand; i++) {
        str += " ";
      }
      setType('error');
      setLoadMessage('Input values between 1 to 20000 In Amomnt Banked' + str)
      newValue = 20000;
  }
    const list = [...bags];
    list[index][name] = newValue;

    if (name === "amount_banked" || name === "amount_per_bs") {
      const amount_banked = parseFloat(list[index]["amount_banked"]) || 0;
      const amount_per_bs = parseFloat(list[index]["amount_per_bs"]) || 0;
      list[index]["difference"] = (amount_banked - amount_per_bs).toFixed(2);
    }
    setBags(list);
  };

  const handleDateChange = (index, date) => {
    const list = [...bags];
    list[index]["date_banked"] = date || "";
    setBags(list);
  };

  const handleBankReflectionDateChange = (index, date) => {
    const list = [...bags];
    list[index]["bank_reflection_date"] = date || "";
    setBags(list);
  };

  const handleAddBag = () => {
    const newBag = {
      date_banked: new Date(),
      paying_slip_no: "",
      pivot_bag_no: "",
      amount_banked: "",
      amount_per_bs: "",
      difference: "",
      bank_reflection_date: null,
      files: [],
      newFiles: [],
      notes: ''
    };
    setBags([...bags, newBag]);
  };

  const handleRemoveBag = async (index, bag) => {
    if (bag.id > 0) {
      let rand = Math.random() * 50;
      let str = " ";
      for (let i = 0; i < rand; i++) {
        str += " ";
      }

      const dataToSend = {
        id: bag.id,
      };
      const response = await Auth.fetch("/route/financial-summary", {
        method: "DELETE",
        body: JSON.stringify(dataToSend),
      });
      if (response.ack) {
        setType("success");
        setLoadMessage(response.message);
      } else {
        setType("error");
        setLoadMessage(response.message + str);
      }
    }
    const list = [...bags];
    list.splice(index, 1);
    setBags(list);
  };

  const handleDeleteFile = (bagIndex, fileName) => {
    const bagId = bags[bagIndex].id;
    const updatedBags = bags.map((bag, index) => {
      if (index === bagIndex) {
        setDeletedFiles(prevDeletedFiles => [...prevDeletedFiles,  {name: fileName, id: bagId }]);
        const updatedFiles = bag.files.filter(file => file !== fileName);
        return {
          ...bag,
          files: updatedFiles
        };
      }
      return bag;
    });
    setBags(updatedBags);
  };

  const handleNewFileDelete  = (bagIndex, fileName) => {
    const updatedBags = bags.map((bag, index) => {
      if (index === bagIndex) {
        const updatedNewFiles = bag.newFiles.filter(file => file.name !== fileName);
        const updatedFiles = bag.files.filter(file => file !== fileName);
        return {
          ...bag,
          newFiles: updatedNewFiles,
          files: updatedFiles
        };
      }
      return bag;
    });
    setBags(updatedBags);
  };

  const dataCol = [
    {
      title: "Route Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Driver Name",
      dataIndex: "driver_name",
      key: "driver_name",
    },
    {
      title: "Truck Reg",
      dataIndex: "registration_number",
      key: "registration_number",
    },
    
    {
      title: "Cash Expected",
      key: "amount_received_cash",
      // render: (text, record) => Number(record.totalCash).toFixed(2) || 0,
      render: (text, record) => 
        `${formatCurrency(record?.amount_received_cash)}`
        // `£${Number(record.amount_received_cash).toLocaleString(undefined, {
        //   minimumFractionDigits: 2,
        //   maximumFractionDigits: 2
        // }) || '0.00'}`,
    },
    {
      title: "Cash Counted",
      key: "cash_received",
      render: (text, record) => 
        `${formatCurrency(record?.cash_received)}`

        // `£${Number(record.cash_received).toLocaleString(undefined, {
        //   minimumFractionDigits: 2,
        //   maximumFractionDigits: 2
        // }) || '0.00'}`,
    },
    
    {
      title: "Difference",
      key: "difference",
      render: (text, record) => {
        const difference = Number(record.cash_received - record.amount_received_cash).toFixed(2);
        const formattedDifference = difference.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        const value = formattedDifference === '-0.00' ? '0.00' : formattedDifference;
        const isPositive = Number(difference) > 0;
        const isNegative = Number(difference) < 0;
    
        const style = {
          color: isPositive ? "green" : isNegative ? "red" : "black",
          fontWeight: isPositive || isNegative ? "bold" : "normal"
        };
    
        return <span style={style}>{`£${value}`}</span>;
      }
    },
    {
      title: "Cash Posted",
      // key: "amount_received_cash_post",
      render: (text, record) => 
        `${formatCurrency(record?.amount_received_cash_post)}`

        // `£${Number(record.cash_received).toLocaleString(undefined, {
        //   minimumFractionDigits: 2,
        //   maximumFractionDigits: 2
        // }) || '0.00'}`,
    },
  ];

  const totalCashSum = financialSummaryData.reduce(
    (accumulator, currentValue) => accumulator + (currentValue.amount_received_cash || 0),
    0
  );
  const totalCashCountedSum = financialSummaryData.reduce(
    (accumulator, currentValue) =>
      accumulator + (currentValue.cash_received || 0),
    0
  );
  const differenceSum = financialSummaryData.reduce(
    (accumulator, currentValue) =>
      accumulator + (currentValue.cash_received - currentValue.amount_received_cash || 0),
    0
  );

  const formattedDifferenceSum =
    differenceSum >= 0
        ? `+${Number(differenceSum).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
        : Number(differenceSum).toLocaleString('en-US', { minimumFractionDigits: 2 }) === '-0.00'
        ? '0.00'
        : Number(differenceSum).toLocaleString('en-US', { minimumFractionDigits: 2 });

  const handleSubmit = async () => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }

    const requiredFields = [
      "date_banked",
      "paying_slip_no",
      "pivot_bag_no",
      "amount_banked",
      // "amount_per_bs",
    ];
    const isEmpty = bags.some((bag) =>
      requiredFields.some((field) => !bag[field])
    );

    if (isEmpty) {
      setLoadMessage("Please fill in all required fields." + str);
      setType("error");
      return;
    }

    const before = [...bags];
    setPrevBags(before);
    const dataToSend = {
      bags: bags.map((bag) => {
        const bagToSend = {
          date_banked: moment(bag.date_banked).format("YYYY-MM-DD"),
          bank_reflection_date: bag.bank_reflection_date
            ? moment(bag.bank_reflection_date).format("YYYY-MM-DD")
            : null,
          paying_slip_no: bag.paying_slip_no,
          pivot_bag_no: bag.pivot_bag_no,
          amount_banked: bag.amount_banked,
          amount_per_bs: bag.amount_per_bs,
          files: bag.files,
          notes: bag.notes
        };

        if (bag.id) {
          bagToSend.id = bag.id;
        }

        return bagToSend;
      }),
      delivery_date: moment(deliveryDate).format("YYYY-MM-DD"),
      deleted_files: deletedFiles 
    };

    try {
      const response = await Auth.fetch("/route/financial-summary", {
        method: "POST",
        body: JSON.stringify(dataToSend),
      });
      if (response.ack) {
        setType("success");
        setLoadMessage(response.message);
        getFinancialSummary(before);
      } else {
        setType("error");
        setLoadMessage(response.message + str);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (oldBags.length > 0) {
      setBags(
        oldBags.map((oldBag) => ({
          ...oldBag,
          date_banked: new Date(oldBag?.date_banked),
          files: JSON.parse(oldBag.files),
          notes: oldBag.notes,
          amount_banked: oldBag.amount_banked.toFixed(2),
          amount_per_bs: oldBag.amount_per_bs.toFixed(2),
          newFiles: [],
          bank_reflection_date: oldBag?.bank_reflection_date
            ? new Date(oldBag?.bank_reflection_date)
            : null,
          difference: (
            (parseFloat(oldBag.amount_banked) || 0) -
            (parseFloat(oldBag.amount_per_bs) || 0)
          ).toFixed(2),
        }))
      );
    } else {
      setBags([
        {
          date_banked: new Date(),
          paying_slip_no: "",
          pivot_bag_no: "",
          amount_banked: "",
          amount_per_bs: "",
          difference: "",
          bank_reflection_date: null,
          files: [],
          newFiles: [],
          notes: ''
        },
      ]);
    }
  }, [oldBags]);

//   const convertArrayOfObjectsToCSV = (array) => {
//     // Define the field mappings and their order
//     const fieldMap = {
//       driver_name: "Driver Name",
//       registration_number: "Truck Reg.",
//       title: "Route",
//       amount_received_cash: "Cash Expected",
//       cash_received: "Cash Counted",
//       difference: "Difference",
//       amount_received_cash_post: "Cash Posted",
//       // Add more field mappings as needed
//     };

//     const fieldOrder = ['driver_name', 'registration_number', 'title', 'amount_received_cash', 'cash_received', 'difference', 'amount_received_cash_post']; // Define the desired order

//     const arrayWithDifference = array.map(obj => {
//       const difference = Number(obj.cash_received - obj.amount_received_cash).toFixed(2);
//         // const formattedDifference = difference.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//       return { ...obj, difference:  difference };
//   });
//     // Filter out unwanted fields and rename them
//     const filteredArray = arrayWithDifference.map(obj => {
//       const newObj = {};
//       Object.keys(obj).forEach(key => {
//         if (key !== 'formatted_date' && key !== 'routeStatusID' && key !== 'cardReceived' && key !== 'totalAccount' && key !== 'totalCard') { // Exclude unwanted fields
//           if (fieldMap[key]) {
//             newObj[fieldMap[key]] = obj[key]; // Rename field
//           } else {
//             newObj[key] = obj[key]; // Keep the field as is
//           }
//         }
//       });
//       return newObj;
//     });

//     // Generate CSV with specified order
//     const header = fieldOrder.map(field => fieldMap[field] || field).join(',');
//     const rows = filteredArray.map(obj => fieldOrder.map(field => obj[fieldMap[field]] || obj[field] || '').join(','));
//     return header + '\n' + rows.join('\n');
// };


const convertArrayOfObjectsToCSV = (array) => {
  // Define the field mappings and their order
  const fieldMap = {
    driver_name: "Driver Name",
    registration_number: "Truck Reg.",
    title: "Route",
    amount_received_cash: "Cash Expected",
    cash_received: "Cash Counted",
    difference: "Difference",
    amount_received_cash_post: "Cash Posted",
    // Add more field mappings as needed
  };

  const fieldOrder = ['driver_name', 'registration_number', 'title', 'amount_received_cash', 'cash_received', 'difference', 'amount_received_cash_post']; // Define the desired order

  const arrayWithDifference = array.map(obj => {
    const difference = Number(obj.cash_received - obj.amount_received_cash).toFixed(2);
    return { ...obj, difference };
  });

  // Filter out unwanted fields and rename them
  const filteredArray = arrayWithDifference.map(obj => {
    const newObj = {};
    Object.keys(obj).forEach(key => {
      if (key !== 'formatted_date' && key !== 'routeStatusID' && key !== 'cardReceived' && key !== 'totalAccount' && key !== 'totalCard') {
        if (fieldMap[key]) {
          newObj[fieldMap[key]] = obj[key];
        } else {
          newObj[key] = obj[key];
        }
      }
    });
    return newObj;
  });

  // Calculate additional values
  const totalCashExpected = filteredArray.reduce((acc, curr) => acc + Number(curr['Cash Expected']), 0).toFixed(2);
  const totalCashCounted = filteredArray.reduce((acc, curr) => acc + Number(curr['Cash Counted']), 0).toFixed(2);
  const totalCashPosted = filteredArray.reduce((acc, curr) => acc + Number(curr['Cash Posted']), 0).toFixed(2);

  // Add new rows
  // const additionalRows = [
  //   { 'Driver Name': '', 'Truck Reg.': '', 'Route': 'Total Cash Expected', 'Cash Expected': totalCashExpected, 'Cash Counted': '', 'Difference': '', 'Cash Posted': '' },
  //   { 'Driver Name': '', 'Truck Reg.': '', 'Route': 'Front Desk Cash', 'Cash Expected': '', 'Cash Counted': frontDeskCash, 'Difference': '', 'Cash Posted': '' },
  //   { 'Driver Name': '', 'Truck Reg.': '', 'Route': 'Total Cash Counted', 'Cash Expected': '', 'Cash Counted': totalCashCounted, 'Difference': '', 'Cash Posted': '' },
  // ];

  // Combine filtered array with additional rows
  // const combinedArray = [...filteredArray, ...additionalRows];

  const additionalRows = [
    '', // Blank row
    `Total Cash Expected, ${totalCashExpected || 0}`,
    `Total Cash Counted, ${totalCashCounted || 0}`,
    `Total Cash Posted, ${totalCashPosted || 0}`,
    `Front Desk Cash, ${totalAmount || 0}`,
  ];

  
  // Generate CSV with specified order
  const header = fieldOrder.map(field => fieldMap[field] || field).join(',');
  const rows = filteredArray.map(obj => fieldOrder.map(field => obj[fieldMap[field]] || obj[field] || '').join(','));
  return header + '\n' + rows.join('\n') + '\n' + additionalRows.join('\n');
  // return header + '\n' + rows.join('\n');
};

// Function to trigger the download of the CSV file
const downloadCSV = () => {
    const csv = convertArrayOfObjectsToCSV(financialSummaryData);
    const filename = `${deliveryDate}financialSummary.csv`;
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


  // Define the values for coins and notes
const coinValues = {
  dc_one_pennies_coins: 0.01,
  dc_two_pennies_coins: 0.02,
  dc_five_pennies_coins: 0.05,
  dc_ten_pennies_coins: 0.10,
  dc_twenty_pennies_coins: 0.20,
  dc_fifty_pennies_coins: 0.50,
  dc_one_pound_coins: 1.00,
  dc_two_pound_coins: 2.00,
};

const noteValues = {
  dc_five_notes: 5,
  dc_ten_notes: 10,
  dc_twenty_notes: 20,
  dc_fifty_notes: 50,
};

// Function to calculate total coins and notes
const calculateTotals = (data) => {
  let totalCoins = 0;
  let totalNotes = 0;

  Object.keys(coinValues).forEach((key) => {
    totalCoins += (data[key] || 0) * coinValues[key];
  });

  Object.keys(noteValues).forEach((key) => {
    totalNotes += (data[key] || 0) * noteValues[key];
  });

  return {
    ...data,
    dc_change_total: totalCoins,
    dc_notes_total: totalNotes,
    dc_grand_total: totalCoins + totalNotes,
  };
};

const updatedFinancialSummaryData = financialSummaryData?.map(calculateTotals)

const DCChangeTotal = updatedFinancialSummaryData?.reduce(
  (accumulator, currentValue) => accumulator + (currentValue.dc_change_total || 0),
  0
);

const DCNoteTotal = updatedFinancialSummaryData?.reduce(
  (accumulator, currentValue) => accumulator + (currentValue.dc_notes_total || 0),
  0
);

const DCGrandTotal = updatedFinancialSummaryData?.reduce(
  (accumulator, currentValue) => accumulator + (currentValue.dc_grand_total || 0),
  0
);

const calculateTotal = (field) => updatedFinancialSummaryData?.reduce(
  (accumulator, currentValue) => accumulator + (currentValue[field] || 0),
  0
);

const dc_fifty_notes_count = calculateTotal('dc_fifty_notes');
const dc_twenty_notes_count = calculateTotal('dc_twenty_notes');
const dc_ten_notes_count = calculateTotal('dc_ten_notes');
const dc_five_notes_count = calculateTotal('dc_five_notes');

const dc_two_pound_coins_count = calculateTotal('dc_two_pound_coins');
const dc_one_pound_coins_count = calculateTotal('dc_one_pound_coins');
const dc_fifty_pennies_coins_count = calculateTotal('dc_fifty_pennies_coins');
const dc_twenty_pennies_coins_count = calculateTotal('dc_twenty_pennies_coins');
const dc_ten_pennies_coins_count = calculateTotal('dc_ten_pennies_coins');
const dc_five_pennies_coins_count = calculateTotal('dc_five_pennies_coins');
const dc_two_pennies_coins_count = calculateTotal('dc_two_pennies_coins');
const dc_one_pennies_coins_count = calculateTotal('dc_one_pennies_coins');


  const [fiftyPoundBillCount, setFiftyPoundBillCount] = useState("");
  const [twentyPoundBillCount, setTwentyPoundBillCount] = useState("");
  const [tenPoundBillCount, setTenPoundBillCount] = useState("");
  const [fivePoundBillCount, setFivePoundBillCount] = useState("");
  const [coinCount, setCoinCount] = useState({
    oneP: '',
    twoP: '',
    fiveP: '',
    tenP: '',
    TwentyP: '',
    fiftyP: '',
    onePound: '',
    twoPound: ''
  });

  const [totalCoinAmount, setTotalCoinAmount] = useState(0);
  const [totalNotesAmount, setTotalNotesAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [frontdeskCashupID, setFrontdeskCashupID] = React.useState(null);


  const calculateTotalCoinAmount = () => {
    const totalCoin =
      (coinCount.oneP ? parseInt(coinCount.oneP) : 0) * 0.01 +
      (coinCount.twoP ? parseInt(coinCount.twoP) : 0) * 0.02 +
      (coinCount.fiveP ? parseInt(coinCount.fiveP) : 0) * 0.05 +
      (coinCount.tenP ? parseInt(coinCount.tenP) : 0) * 0.10 +
      (coinCount.TwentyP ? parseInt(coinCount.TwentyP) : 0) * 0.20 +
      (coinCount.fiftyP ? parseInt(coinCount.fiftyP) : 0) * 0.50 +
      (coinCount.onePound ? parseInt(coinCount.onePound) : 0) * 1.00 +
      (coinCount.twoPound ? parseInt(coinCount.twoPound) : 0) * 2.00;

    setTotalCoinAmount(totalCoin.toFixed(2));

  }

  useEffect(() => {
    calculateTotalCoinAmount();
  }, [coinCount]);

  const calculateTotalNoteAmount = () => {
    const totalNote =
      (fiftyPoundBillCount ? parseInt(fiftyPoundBillCount) : 0) * 50.00 +
      (twentyPoundBillCount ? parseInt(twentyPoundBillCount) : 0) * 20.00 +
      (tenPoundBillCount ? parseInt(tenPoundBillCount) : 0) * 10.00 +
      (fivePoundBillCount ? parseInt(fivePoundBillCount) : 0) * 5.00;

    setTotalNotesAmount(totalNote.toFixed(2));
  }

  useEffect(() => {
    calculateTotalNoteAmount();
  }, [fiftyPoundBillCount, twentyPoundBillCount, tenPoundBillCount, fivePoundBillCount]);

  const calculateTotalAmount = () => {
    const total =
      (totalCoinAmount ? parseFloat(totalCoinAmount) : 0) + (totalNotesAmount ? parseFloat(totalNotesAmount) : 0)


    setTotalAmount(total);
  }

  useEffect(() => {
    calculateTotalAmount();
  }, [totalCoinAmount, totalNotesAmount]);


useEffect(() => {
  const fetchData = async () => {
    
      showLoading(layoutDispatch);
      try {
        const res = await Auth.fetch(`/route/frontdesk-cashup?delivery_date=${deliveryDate}`);
        if (res?.ack) {
          setFiftyPoundBillCount(res?.frontdesk_cashup?.fifty_notes || "")
          setTwentyPoundBillCount(res?.frontdesk_cashup?.twenty_notes || "")
          setTenPoundBillCount(res?.frontdesk_cashup?.ten_notes || "")
          setFivePoundBillCount(res?.frontdesk_cashup?.five_notes || "")
          setCoinCount((prevCoins) => ({
            ...prevCoins,
            oneP: res?.frontdesk_cashup?.one_pennies_coins,
            twoP: res?.frontdesk_cashup?.two_pennies_coins,
            fiveP: res?.frontdesk_cashup?.five_pennies_coins,
            tenP: res?.frontdesk_cashup?.ten_pennies_coins,
            TwentyP: res?.frontdesk_cashup?.twenty_pennies_coins,
            fiftyP: res?.frontdesk_cashup?.fifty_pennies_coins,
            onePound: res?.frontdesk_cashup?.one_pound_coins,
            twoPound: res?.frontdesk_cashup?.two_pound_coins,
          }));
          setFrontdeskCashupID(res?.frontdesk_cashup?.id > 0 ? res?.frontdesk_cashup?.id : null)
        }
      } catch (err) {
        console.log(err)
      } finally {
        hideLoading(layoutDispatch);
      }

  }
  fetchData()
}, [])

const genExtraFrontCashUp = () => {
    
  const completed = frontdeskCashupID > 0 ? true : false;
  return (
    <div style={{ display: 'flex', alignItems: 'center'}}>
      <Button
            style={{
              marginRight: '5px',
              color: completed  ? 'green' : 'red',
              cursor: 'alias',
              pointerEvents: "none",
              padding: '4px 4px'
            }}
            icon={completed ? <CheckOutlined/> : <CloseOutlined />}
              >
               Front Desk Cashup
          </Button>
        </div>
  )
};

const data = [
  
  {
    title: 'Route Cash',
    value: <span style={{ color: 'blue', fontWeight: "bold"}}>{formatCurrency(totalCashSum)}</span>
  },
  {
    title: 'Route Cash Counted',
    value: <span style={{ color: 'blue', fontWeight: "bold"}}>{formatCurrency(totalCashCountedSum)}</span>
  },
  {
    title: 'Route Cash Difference',
    value: <span style={{ color: 'red', fontWeight: "bold"}}>{formatCurrency(formattedDifferenceSum)}</span>
  },
  {
    title: 'Front Desk Cash',
    value: <span style={{ color: 'purple', fontWeight: "bold"}}>{formatCurrency(totalAmount)}</span>
  },
  {
    title: 'Total Cash',
    value: <span style={{ color: 'green', fontWeight: "bold"}}>{formatCurrency(totalAmount + totalCashCountedSum)}</span>

  },
];


  return (
    <div className={classes.dataSheet}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', marginBottom: '5px'}}>
      {
        financialSummaryData.length > 0 && <Button type="primary" onClick={downloadCSV}>Download</Button>

      }
      </div>

      {/* <List
    grid={{
      gutter: 16, 
      xs: 1, 
      sm: 2, 
      md: 3, 
      lg: 3, 
      xl: 3, 
      xxl: 6, 
    }}
    dataSource={data}
    renderItem={(item) => (
      <List.Item>
        <Card title={item.title}>{item.value}</Card>
      </List.Item>
    )}
  /> */}

<Row
    gutter={[16, 16]}
    style={{
      background: "#f0f2f5",
      padding: "8px",
      borderRadius: "8px",
    }}
  >
    {/* Cash Section */}
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
      <div
        style={{
          background: "#ffffff",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Route Cash"
                value={formatCurrency(totalCashSum)}
                
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Route Cash Counted"
                value={formatCurrency(totalCashCountedSum)}

                valueStyle={{
                  color: "green",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Route Cash Difference"
                value={formatCurrency(formattedDifferenceSum)}
                
                valueStyle={{
                  // color:"#cf1322",
                  color: formattedDifferenceSum > 0 ? "green" : "#cf1322",

                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Col>

    {/* Card Section */}
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
      <div
        style={{
          background: "#ffffff",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Front Desk Cash"
                value={formatCurrency(totalAmount)}

                valueStyle={{
                  color: "green",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Total Cash"
                value={formatCurrency(totalAmount + totalCashCountedSum)}

                valueStyle={{
                  color: "green",
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Col>
  </Row>
  
      
      {financialSummaryData.length > 0 && (
        <>
          <div className="view-page">
            <div>
              {/* <Row style={{ justifyContent: "end" }} gutter={[6, 16]}>
              <Col span={4}>
                  <Card>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Front Desk Cash"
                        value={totalAmount.toFixed(2) || 0}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={3}>
                  <Card>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Route Cash"
                        value={totalCashSum.toFixed(2) || 0}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={4}>
                  <Card>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Route Cash Counted"
                        value={totalCashCountedSum.toFixed(2) || 0}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={4}>
                  <Card>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Route Cash Difference "
                        value={formattedDifferenceSum}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                        precision={2}
                      />
                    </div>
                  </Card>
                </Col>
              </Row> */}
            </div>
            <Collapse style={{ margin: "10px 0px" }}>
                    <Panel
                      header={
                        <>
                          <span style={{ fontWeight: "bold" }}>
                            Front Desk Cash Up
                          </span>
                        </>
                      }
                    extra={genExtraFrontCashUp()}>
                      <Descriptions bordered size={'small'} >
                      <Descriptions.Item label={`£50`}> Count: {fiftyPoundBillCount || 0} 
                      <br/>
                      Total: {"£" +(fiftyPoundBillCount ? parseInt(fiftyPoundBillCount) * 50.00 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£20`}> Count: {twentyPoundBillCount || 0} 
                      <br/>
                      Total: {"£" +(twentyPoundBillCount ? parseInt(twentyPoundBillCount) * 20.00 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£10`}> Count: {tenPoundBillCount || 0} 
                      <br/>
                      Total: {"£" +(tenPoundBillCount ? parseInt(tenPoundBillCount) * 10.00 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£5`}> Count: {fivePoundBillCount || 0} 
                      <br/>
                      Total: {"£" +(fivePoundBillCount ? parseInt(fivePoundBillCount) * 5.00 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£2`}> Count: {coinCount.twoPound || 0} 
                      <br/>
                      Total: {"£" +(coinCount.twoPound ? parseInt(coinCount.twoPound) * 2.00 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£1`}> Count: {coinCount.onePound || 0} 
                      <br/>
                      Total: {"£" +(coinCount.onePound ? parseInt(coinCount.onePound) * 1.00 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£50p`}> Count: {coinCount.fiftyP || 0} 
                      <br/>
                      Total: {"£" +(coinCount.fiftyP ? parseInt(coinCount.fiftyP) * 0.50 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£20p`}> Count: {coinCount.TwentyP || 0} 
                      <br/>
                      Total: {"£" +(coinCount.TwentyP ? parseInt(coinCount.TwentyP) * 0.20 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£10p`}> Count: {coinCount.tenP || 0} 
                      <br/>
                      Total: {"£" +(coinCount.tenP ? parseInt(coinCount.tenP) * 0.10 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£5p`}> Count: {coinCount.fiveP || 0} 
                      <br/>
                      Total: {"£" +(coinCount.fiveP ? parseInt(coinCount.fiveP) * 0.05 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£2p`}> Count: {coinCount.twoP || 0} 
                      <br/>
                      Total: {"£" +(coinCount.twoP ? parseInt(coinCount.twoP) * 0.02 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£1p`}> Count: {coinCount.oneP || 0} 
                      <br/>
                      Total: {"£" +(coinCount.oneP ? parseInt(coinCount.oneP) * 0.01 : 0).toFixed(2)}</Descriptions.Item>

                      </Descriptions>

                      <Row gutter={[6, 16]}>
                       
                       <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
                       Total Notes
                        </h4>
                       <Input
                        placeholder=" Total Notes Amount"
                        disabled
                        type="number"
                        value={totalNotesAmount}
                        />
                       </Col>
                       <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
                       Total Coins
                        </h4>
                       <Input
                        placeholder=" Total Coins Amount"
                        disabled
                        type="number"
                        value={totalCoinAmount}
                        />
                       </Col>
                       <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
                       Grand Total
                        </h4>
                       <Input
                        placeholder=" Total Amount"
                        disabled
                        type="number"
                        value={totalAmount}
                        />
                       </Col>
                      </Row>
                      <Row>
                      </Row>
                      <Divider />
                      
                    </Panel>
                  </Collapse>


            <Table
              style={{ overflowX: "auto", marginTop: "10px" }}
              columns={dataCol}
              // dataSource={financialSummaryData}
              dataSource={financialSummaryData?.sort((a, b) => a.title.localeCompare(b.title))}
              pagination={false}
            />
            <Divider style={{ height: "2px", background: "#006cb8" }} />
          </div>

          <h3>Add Details of Amount Banked</h3>
          {isLoading ? (
            <Skeleton active />
          ) : (
            <>
              {bags.map((bag, index) => (
                <div key={index}>
                  <Row gutter={[16, 4]}>
                    <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                      <h5 style={{ margin: "1px 0px" }}>
                        Date Banked{" "}
                        <span style={{ color: "red", fontSize: "10px" }}>
                          *
                        </span>
                      </h5>
                      <DatePickerField
                        todayButton="Today"
                        dateFormat="dd-MM-yyyy"
                        selected={bag.date_banked}
                        onChange={(date) => {
                          handleDateChange(index, date);
                        }}
                        style={{ width: "100%" }}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                      <h5 style={{ margin: "1px 0px" }}>
                        Paying Slip No.
                        <span style={{ color: "red", fontSize: "10px" }}>
                          *
                        </span>
                      </h5>
                      <Input
                        name="paying_slip_no"
                        value={bag.paying_slip_no}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="Paying in Slip No."
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                      <h5 style={{ margin: "1px 0px" }}>
                        Pivot Bag No.
                        <span style={{ color: "red", fontSize: "10px" }}>
                          *
                        </span>
                      </h5>
                      <Input
                        name="pivot_bag_no"
                        value={bag.pivot_bag_no}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="Pivot Bag No."
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                      <h5 style={{ margin: "1px 0px" }}>
                        Amount Banked
                        <span style={{ color: "red", fontSize: "10px" }}>
                          *
                        </span>
                      </h5>
                      <Input
                        name="amount_banked"
                        type="number"
                        value={bag.amount_banked}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="Amount Banked"
                        min={0}
                        step="0.01"
                        max={20000}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                      <h5 style={{ margin: "1px 0px" }}>
                        Amount as per BS
                        {/* <span style={{ color: "red", fontSize: "10px" }}>
                          *
                        </span> */}
                      </h5>
                      <Input
                        name="amount_per_bs"
                        type="number"
                        value={bag.amount_per_bs}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="Enter Amount as per BS"
                        min={0}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                      <h5 style={{ margin: "1px 0px" }}>Difference</h5>
                      <Input
                        name="difference"
                        type="number"
                        disabled
                        value={bag.difference}
                        placeholder="Difference"
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                      <h5 style={{ margin: "1px 0px" }}>
                        Bank Reflection Date
                        {/* <span style={{ color: "red", fontSize: "10px" }}>
                          *
                        </span> */}
                      </h5>
                      <DatePickerField
                        todayButton="Today"
                        dateFormat="dd-MM-yyyy"
                        selected={bag.bank_reflection_date}
                        onChange={(date) => {
                          handleBankReflectionDateChange(index, date);
                        }}
                        style={{ width: "100%" }}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                      <h5 style={{ margin: "1px 0px" }}>
                        Notes
                      </h5>
                      <TextArea
                        name="notes"
                        autoSize
                        value={bag.notes}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="Notes"
                      />
                    </Col>
                    <Col>
                      <div>
                        <UploadButton
                          component="label"
                          variant="contained"
                          sx={{ marginTop: "15px" }}
                          startIcon={<CloudUploadIcon />}
                        >
                          Add files
                          <input
                            type="file"
                            accept="image/*, video/*"

                            hidden
                            multiple
                            // onChange={(e) => {
                            //   handleSubmitImage(index, e);
                            // }}
                            onChange={(e) => {
                              const myfiles = e.target.files;
                              if (myfiles.length > 10) {
                                message.error("A maximum of 10 images can be uploaded at a time.");
                                e.target.value = "";
                              } else {
                                handleSubmitImage(index, e);
                              }
                            }}
                          />
                        </UploadButton>
                      </div>
                    </Col>
                  </Row>
                  <Divider />
                  {bag.files?.length > 0 && <h4>Current Files:</h4>}
                  <Image.PreviewGroup>
                        {bag.files?.length > 0 &&
                      bag.files
                        .filter(
                          (item) =>
                            !bag.newFiles.some((file) => file.name === item)
                        )
                        .map((item) => {
                          const lastHyphenIndex = item.lastIndexOf("-");
                          const dotBeforeExtensionIndex = item.lastIndexOf(".");
                          const newFileName =
                            item.slice(0, lastHyphenIndex) +
                            item.slice(dotBeforeExtensionIndex);
                            const encodedFileName = item.replace(/\+/g, '%2B');
                          return (
                                <div
                                  style={{
                                    position: "relative",
                                    marginRight: "10px",
                                    display: "inline-block",
                                  }}
                                >
                                  <Image
                                    width={200}
                                    height={150}
                                    style={{
                                      padding: "5px",
                                      border: "1px solid lightgrey",
                                    }}
                                    src={`${config.tms_uploads_cdn}/${config.env}/financial_summaries/${bag.id}/${encodedFileName}`}
                                  />
                                  <Tooltip title="Delete">
                                    <CloseOutlined
                                      style={{
                                        fontSize: "30px",
                                        position: "absolute",
                                        right: 5,
                                        top: 5,
                                        color: "#c12828",
                                      }}
                                      onClick={() => handleDeleteFile(index,item)}
                                    />
                                  </Tooltip>
                                </div>
                              );
                            })}
                      </Image.PreviewGroup>
                  {/* <ul>
                    {bag.files?.length > 0 &&
                      bag.files
                        .filter(
                          (item) =>
                            !bag.newFiles.some((file) => file.name === item)
                        )
                        .map((item) => {
                          const lastHyphenIndex = item.lastIndexOf("-");
                          const dotBeforeExtensionIndex = item.lastIndexOf(".");
                          const newFileName =
                            item.slice(0, lastHyphenIndex) +
                            item.slice(dotBeforeExtensionIndex);
                            const encodedFileName = item.replace(/\+/g, '%2B');
                          return (
                            <li>
                              <IconButton
                                aria-label="delete"
                                color="primary"
                                onClick={() => handleDeleteFile(index, item)}
                              >
                                <DeleteIcon />
                              </IconButton>
                              <a
                                href={`${config.tms_uploads_cdn}/${config.env}/financial_summaries/${bag.id}/${encodedFileName}`}
                                target="_blank"
                              >
                                {extractOriginalFileName(item)}
                              </a>
                            </li>
                          );
                        })}
                  </ul> */}

                  {bag.newFiles?.length > 0 && <h4>New Files:</h4>}
                  <Image.PreviewGroup>
                        {bag.newFiles?.length > 0 &&
                      bag.newFiles.map((files) => {
                        return (
                              <div
                                style={{
                                  position: "relative",
                                  marginRight: "10px",
                                  display: "inline-block",
                                }}
                              >
                                <Image
                                  width={200}
                                  height={150}
                                  style={{
                                    padding: "5px",
                                    border: "1px solid lightgrey",
                                  }}
                                  src={URL.createObjectURL(files.file)}
                                />
                                <Tooltip title="Delete">
                                  <CloseOutlined
                                    style={{
                                      fontSize: "30px",
                                      position: "absolute",
                                      right: 5,
                                      top: 5,
                                      color: "#c12828",
                                    }}
                                    onClick={() => handleNewFileDelete(index, files.name)}
                                  />
                                </Tooltip>
                              </div>
                            );
                          })}
                      </Image.PreviewGroup>
                  {/* <ul>
                    {bag.newFiles?.length > 0 &&
                      bag.newFiles.map((files) => {
                        return <li> <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={() => handleNewFileDelete(index, files.name)}
                      >
                        <DeleteIcon />
                      </IconButton>{files.name}</li>;
                      })}
                  </ul> */}
                  <Divider />
                  {index > 0 && (
                    <>
                      <Row>
                        <Col>
                          {bag.id > 0 ? (
                            <Popconfirm
                              title="Remove bag"
                              description="Are you sure you want to remove this bag?"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => handleRemoveBag(index, bag)}
                            >
                              <Button
                                type="dashed"
                                block
                                danger
                                icon={<MinusOutlined />}
                                style={{ margin: "15px 0px" }}
                              >
                                Remove Bag
                              </Button>
                            </Popconfirm>
                          ) : (
                            <Button
                              type="dashed"
                              onClick={() => handleRemoveBag(index, bag)}
                              block
                              danger
                              icon={<MinusOutlined />}
                              style={{ margin: "15px 0px" }}
                            >
                              Remove Bag
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                              <Divider style={{ height: "2px", background: "#006cb8" }} />

                </div>
              ))}
            </>
          )}

          <Row>
            <Col>
              <Button
                type="dashed"
                onClick={handleAddBag}
                block
                icon={<PlusOutlined />}
              >
                Add Another Bag
              </Button>
            </Col>
          </Row>
          <Button
            style={{ float: "right" }}
            type="primary"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </>
      )}
      <Message type={type} msg={loadMessage} />
    </div>
  );
};

export default FinancialSummary;
