import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { Button as UploadButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React, { useEffect, useState } from "react";
import { FormButton } from "../../Components/UiElements/UiElements";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  ExcessPaidBy,
  PaidBy,
  accidentTypes,
  accidentStatus,
  yesNo,
  DriverTypesCheck,
  accidentFaults,
  completedOptions,
} from "../../Components/common/constants";
// import DeleteIcon from "@mui/icons-material/Delete";
import {
  hideLoading,
  showLoading,
  useLoadingDispatch,
} from "../../context/loadingContext";
import AuthService from "../../Components/auth/AuthService";
import config from "../../Components/config";
import moment from "moment";
import qs from "query-string";
import Message from "../../Components/common/Message";
import { DatePickerField } from "../../Components/InputFields/InputFields";
import DatePicker from "react-date-picker";
import logo from '../../../src/assets/images/magna-logo.png'
import {
  Divider,
  Popconfirm,
  Skeleton,
  Button as AntButton,
  Row,
  Col,
  Input,
  Radio,
  DatePicker as AntDate,
  Select,
  Badge,
  Image,
  Tooltip,
  message
} from "antd";
import { CloseOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { ActionDialog } from "../../Components/actionDialog/ActionDialog";
import './accident.css'
import dayjs from "dayjs";
import jsPDF from "jspdf";

const useStyles = makeStyles((theme) => ({
  dateField: {
    width: "40%",
    marginRight: "10px",
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "thin solid #b6babf",
      borderRadius: '5px',
    }
  },
  dataSheet: {
    "& .example-custom-input": {
      width: "100% !important",
    },
    "& .react-datepicker-wrapper": {
      width: "100% !important",
    },
  },
  home: {
    "& .ant-ribbon-wrapper": {
      width: "100% !important"
    }
  },
  inputText: {
    ".MuiTextField-root": {
      width: '200px'
    }
  }
}));

const Auth = new AuthService();

const Index = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { TextArea } = Input;
  const { Option } = Select;
  const [id, setId] = React.useState(params.id ? params.id : 0);

  const policyHolder = "Magna Foodservice Ltd";
  const policyNumber = "A20268 Direct Commercials Ltd";
  var layoutDispatch = useLoadingDispatch();
  //Accident States
  const [code, setCode] = React.useState('');
  const [allVehicles, setAllVehicle] = React.useState([]);
  const [allRoutes, setAllRoutes] = React.useState([]);
  const [allDrivers, setAllDrivers] = React.useState([]);
  const [allEquipments, setAllEquipments] = React.useState([]);
  const [selectedDriver, setSelectedDriver] = React.useState({
    id: "",
    title: "",
  });
  const [selectedVehicle, setSelectedVehicle] = React.useState({
    id: "",
    title: "",
  });
  const [selectedRoute, setSelectedRoute] = React.useState({
    id: "",
    title: "",
  });
  const [selectedEquipment, setSelectedEquipment] = React.useState("");
  const [selectedPaidBy, setSelectedPaidBy] = React.useState({
    id: "",
    title: "",
  });
  const [selectedExPaidBy, setSelectedExPaidBy] = React.useState({
    id: "",
    title: "",
  });
  const [selectedFormSubmission, setSelectedFormSubmission] = React.useState({
    id: "", title: ""
  });
  const [selectedType, setSelectedType] = React.useState('');

  const [closeStatus, setCloseStatus] = React.useState('');
  const [selectedExAmount, setSelectedExAmount] = React.useState('');
  const [selectedAmount, setSelectedAmount] = React.useState('');
  const [ourReference, setOurReference] = React.useState('');
  const [otherReference, setOtherReference] = React.useState('');
  const [paidBy, setPaidBy] = React.useState('');
  const [accidentDate, setAccidentDate] = useState(null);
  const [caseOpenDate, setCaseOpenDate] = useState(null);
  const [caseCloseDate, setCaseCloseDate] = useState(null);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [errors, setErrors] = useState({
    code: "",
    driver: "",
    vehicle: "",
    type: "",
    route: "",
    formSubmission: "",
    accidentDate: "",
    caseOpenDate: "",
    caseCloseDate: "",
    amount: '',
    exAmount: '',
    paidBy: '',
    exPaidBy: '',
    ourRef: '',
    otherRef: '',
    equipment: "",
    contactNumber: "",
    driverType: "",
    otherName: "",
    otherVehicle: "",
    otherContact: "",
    otherPolicy: "",
    otherEmail: "",
    otherInsurer: "",
    otherAddress: "",
    otherDamage: "",
    injuryNotes: "",
    damageNotes: "",
    policeNotes: "",
    notes: "",
    fault: '',
    witnessOneName: "",
    witnessOneContact: "",
    witnessOneAddress: "",
    accidentLocation: ""
  });

  const [selfDetails, setSelfDetails] = React.useState({
    contact: "",
    driver_type: "",
    fault: "",
    accidentLocation: ""
  });

  const [otherPartyDetails, setOtherPartyDetails] = React.useState({
    email: "",
    policy_number: "",
    name: "",
    vehicle: "",
    contact: "",
    address: "",
    insurer_name: "",
    damage: ""
  });

  const [witnessDetails, setWitnessDetails] = React.useState({
    witness_name_1: "",
    witness_contact_number_1: "",
    witness_address_1: "",
    witness_name_2: "",
    witness_contact_number_2: "",
    witness_address_2: "",
    witness_name_3: "",
    witness_contact_number_3: "",
    witness_address_3: "",
  });

  const [anyInjury, setAnyInjury] = React.useState({
    isInjured: 2,
    injuryNotes: "",
  });

  const [anyDamage, setAnyDamage] = React.useState({
    isDamaged: 2,
    damageNotes: "",
  });

  const [police, setPolice] = React.useState({
    isPolice: 2,
    policeNotes: "",
  });
  const [notes, setNotes] = React.useState("");

  const [files, setFiles] = useState([]);
  const [currentItemFiles, setCurrentItemFiles] = useState([]);
  const [deletedFilesSimple, setDeletedFilesSimple] = React.useState([]);

  const [accidentStatuses, setAccidentStatuses] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [oldAccidentStatuses, setOldAccidentStatuses] = React.useState([]);
  const [actionDialogProps, setActionDialogProps] = useState({
    message: "",
    type: "",
    isOpen: false,
  });

  const [informationExchanged, setInformationExchanged] = useState({
    date: null,
    notes: '',
    completed: null,
    files: [],
    newFiles: [],
  });

  const [liabilityAccepted, setLiabilityAccepted] = useState({
    date: null,
    notes: '',
    completed: null,
    files: [],
    newFiles: [],
  });

  const [paymentSettled, setPaymentSettled] = useState({
    date: null,
    notes: '',
    completed: null,
    files: [],
    newFiles: [],
  });

  const [closedCase, setClosedCase] = useState({
    date: null,
    notes: '',
    completed: null,
    files: [],
    newFiles: [],
  });

  const ShowActionDialog = (
    message,
    type,
    isOpen,
    OnOkCallback,
    OnCancellCallback
  ) => {
    setActionDialogProps((prevState) => ({
      ...prevState,
      message: message,
      type: type,
      isOpen: isOpen,
      onOk: OnOkCallback,
      onCancel: OnCancellCallback,
    }));
  };


  // useEffect(() => {
  //   getAllVehicles();
  //   getAllDrivers();
  //   getAllRoutes()
  // }, []);

  const getUploadUrl = async (file, id) => {
    const params = {
      bucket: config.tms_uploads_bucket,
      ContentType: file.file.type,
      Key: config.env + "/accidents/" + id + "/" + file.name,
    };
    const response = await fetch(
      `${config.domain}/generate-put-url?${qs.stringify(params)}`,
      {
        headers: {
          "Content-Type": params.ContentType,
        },
      }
    );
    return response.json();
  };

  const uploadFileToS3 = async (uploadUrl, file, contentType) => {
    const response = await fetch(uploadUrl.putURL, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": contentType,
      },
    });
    if (response.status !== 200) {
      throw new Error("File upload failed.");
    }
  };

  const handleCloseAccident = async () => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }

    const isEmptyOrUndefined = (value) => value === '' || value === null || value === undefined;

    const checkAndSetError = (condition, key, message) => {
      if (condition) {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: message }));
        setType('error');
        setLoadMessage(`${message}${str}`);
        return true;
      }
      return false;
    };

    if (
      checkAndSetError(isEmptyOrUndefined(code), 'code', 'Code is required') ||
      checkAndSetError(isEmptyOrUndefined(accidentDate), 'accidentDate', 'Accident Date is required') ||
      checkAndSetError(isEmptyOrUndefined(caseOpenDate), 'caseOpenDate', 'Case open date is required') ||
      checkAndSetError(isEmptyOrUndefined(caseCloseDate), 'caseOpenDate', 'Case close date is required') ||
      checkAndSetError(isEmptyOrUndefined(selectedType?.id), 'type', 'Type is required') ||
      (selectedType?.id === '1' && checkAndSetError(isEmptyOrUndefined(selectedRoute?.id), 'route', 'Route is required')) ||
      ((selectedType?.id === '1' || selectedType?.id == '2') && checkAndSetError(isEmptyOrUndefined(selectedVehicle?.id), 'vehicle', 'Registration is required')) ||
      ((selectedType?.id == '1' || selectedType?.id == '2') && checkAndSetError(isEmptyOrUndefined(selectedDriver?.id), 'driver', 'Driver is required')) ||
      (selectedType?.id == '3' && checkAndSetError(isEmptyOrUndefined(selectedEquipment?.id), 'equipment', 'Equipment is required')) ||
      checkAndSetError(isEmptyOrUndefined(selectedFormSubmission?.id), 'formSubmission', 'Form Submission Status is required') ||
      checkAndSetError(isEmptyOrUndefined(selfDetails?.driver_type?.id), 'driverType', 'Agency Driver YES/NO is required') ||
      checkAndSetError(isEmptyOrUndefined(selfDetails?.fault?.id), 'fault', 'Select Fault ') ||
      checkAndSetError(isEmptyOrUndefined(selfDetails?.contact), 'contactNumber', 'Contact number is required') ||
      checkAndSetError(isEmptyOrUndefined(selfDetails?.accidentLocation), 'accidentLocation', 'Accident Location is required') ||
      checkAndSetError(isEmptyOrUndefined(ourReference), 'ourRef', 'Our insurance Reference is required') ||
      checkAndSetError(isEmptyOrUndefined(otherPartyDetails?.name), 'otherName', 'Other Party Name is required') ||
      checkAndSetError(isEmptyOrUndefined(otherPartyDetails?.vehicle), 'otherVehicle', 'Other Party Registration No. is required') ||
      checkAndSetError(isEmptyOrUndefined(otherPartyDetails?.contact), 'otherContact', 'Other Party Contact Number is required') ||
      checkAndSetError(isEmptyOrUndefined(otherPartyDetails?.policy_number), 'otherPolicy', 'Other Party Policy Number is required') ||
      checkAndSetError(isEmptyOrUndefined(otherPartyDetails?.email), 'otherEmail', 'Other Party email is required') ||
      checkAndSetError(isEmptyOrUndefined(otherPartyDetails?.insurer_name), 'otherInsurer', 'Other Party Insurer Name is required') ||
      checkAndSetError(isEmptyOrUndefined(otherPartyDetails?.address), 'otherAddress', 'Other Party Address is required') ||
      checkAndSetError(isEmptyOrUndefined(otherPartyDetails?.damage), 'otherDamage', 'Damage or Point of impact is required') ||
      checkAndSetError(isEmptyOrUndefined(otherReference), 'otherRef', 'Other party Insurance reference is required') ||
      checkAndSetError(isEmptyOrUndefined(anyInjury?.injuryNotes), 'injuryNotes', 'injury Notes are required') ||
      checkAndSetError(isEmptyOrUndefined(anyDamage?.damageNotes), 'damageNotes', 'Damage Notes are required') ||
      checkAndSetError(isEmptyOrUndefined(police?.policeNotes), 'policeNotes', 'Police Notes are required') ||
      checkAndSetError(isEmptyOrUndefined(notes), 'notes', 'Notes are required') ||
      checkAndSetError(isEmptyOrUndefined(selectedAmount), 'amount', 'Claim Amount is required') ||
      checkAndSetError(isEmptyOrUndefined(selectedPaidBy?.id), 'paidBy', 'Claim Paid by is required') ||
      checkAndSetError(isEmptyOrUndefined(selectedExAmount), 'exAmount', 'Excess Amount Paid by is required') ||
      checkAndSetError(isEmptyOrUndefined(selectedExPaidBy?.id), 'exPaidBy', 'Excess Amount Paid by is required') ||
      checkAndSetError(isEmptyOrUndefined(witnessDetails?.witness_name_1), 'witnessOneName', 'Enter Witness 1 name') ||
      checkAndSetError(isEmptyOrUndefined(witnessDetails?.witness_address_1), 'witnessOneAddress', 'Enter Witness 1 address') ||
      checkAndSetError(isEmptyOrUndefined(witnessDetails?.witness_contact_number_1), 'witnessOneContact', 'Enter Witness 1 contact')
    ) {
      return;
    }

    const dataToSend = {
      id: id,
      status: 0
    };
    const pcnUpdateData = {
      driver_id: selectedDriver?.id,
      code: code,
      type: selectedType?.id,
      vehicle_id: selectedVehicle?.id,
      route_id: selectedRoute?.id,
      paid_by: selectedPaidBy?.id,
      amount: selectedAmount,
      excess_paid_by: selectedExPaidBy?.id,
      excess_amount: selectedExAmount,
      our_insurance_number: ourReference,
      other_party_insurance_number: otherReference,
      accident_form_submitted: selectedFormSubmission?.id,
      accident_date: moment(accidentDate).format("YYYY-MM-DD"),
      case_open_date: caseOpenDate ? moment(caseOpenDate).format("YYYY-MM-DD") : null,
      case_close_date: caseCloseDate ? moment(caseCloseDate).format("YYYY-MM-DD") : null,
      id: id,
      policy_holder: policyHolder,
      policy_number: policyNumber,
      contact_number: selfDetails?.contact,
      agency_driver: selfDetails?.driver_type?.id,
      fault: selfDetails?.fault?.id,
      accident_location: selfDetails?.accidentLocation,
      other_party_name: otherPartyDetails?.name,
      other_party_registration_no: otherPartyDetails?.vehicle,
      other_party_contact_number: otherPartyDetails?.contact,
      other_party_policy_number: otherPartyDetails?.policy_number,
      other_party_email: otherPartyDetails?.email,
      other_party_insurer: otherPartyDetails?.insurer_name,
      other_party_address: otherPartyDetails?.address,
      other_party_impact: otherPartyDetails?.damage,
      injured: anyInjury?.isInjured,
      injured_notes: anyInjury?.injuryNotes,
      damage: anyDamage?.isDamaged,
      damage_notes: anyDamage?.damageNotes,
      police_involved: police?.isPolice,
      police_notes: police?.policeNotes,
      notes: notes,
      witness_name_1: witnessDetails?.witness_name_1,
      witness_contact_number_1: witnessDetails?.witness_contact_number_1,
      witness_address_1: witnessDetails?.witness_address_1,
      witness_name_2: witnessDetails?.witness_name_2,
      witness_contact_number_2: witnessDetails?.witness_contact_number_2,
      witness_address_2: witnessDetails?.witness_address_2,
      witness_name_3: witnessDetails?.witness_name_3,
      witness_contact_number_3: witnessDetails?.witness_contact_number_3,
      witness_address_3: witnessDetails?.witness_address_3,
      information_exchanged_status: informationExchanged?.completed,
      information_exchanged_date: informationExchanged?.date,
      information_exchanged_files: informationExchanged?.files,
      information_exchanged_notes: informationExchanged?.notes,
      liability_accepted_status: liabilityAccepted?.completed,
      liability_accepted_date: liabilityAccepted?.date,
      liability_accepted_files: liabilityAccepted?.files,
      liability_accepted_notes: liabilityAccepted?.notes,
      payment_settled_status: paymentSettled?.completed,
      payment_settled_date: paymentSettled?.date,
      payment_settled_files: paymentSettled?.files,
      payment_settled_notes: paymentSettled?.notes,
      closed_case_status: closedCase?.completed,
      closed_case_date: closedCase?.date,
      closed_case_files: closedCase?.files,
      closed_case_notes: closedCase?.notes,
      driver_files: currentItemFiles
    };

    ShowActionDialog(
      "Are you sure to want to Close this Accident case?",
      "confirm",
      true,
      async function () {
        try {
          showLoading(layoutDispatch);
          const accidentUpdateResponse = await Auth.fetch("/accident/accident", {
            method: "PUT",
            body: JSON.stringify(pcnUpdateData)
          });

          if (accidentUpdateResponse.ack === true) {
            const uploadPromises = []
            if (informationExchanged.newFiles.length > 0) {
              informationExchanged.newFiles.forEach((f) => {
                uploadPromises.push(
                  getUploadUrl(f, id)
                    .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                    .catch(error => console.error("Error uploading file:", error))
                );
              });
            }
            if (liabilityAccepted.newFiles.length > 0) {
              liabilityAccepted.newFiles.forEach((f) => {
                uploadPromises.push(
                  getUploadUrl(f, id)
                    .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                    .catch(error => console.error("Error uploading file:", error))
                );
              });
            }
            if (paymentSettled.newFiles.length > 0) {
              paymentSettled.newFiles.forEach((f) => {
                uploadPromises.push(
                  getUploadUrl(f, id)
                    .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                    .catch(error => console.error("Error uploading file:", error))
                );
              });
            }
            if (closedCase.newFiles.length > 0) {
              closedCase.newFiles.forEach((f) => {
                uploadPromises.push(
                  getUploadUrl(f, id)
                    .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                    .catch(error => console.error("Error uploading file:", error))
                );
              });
            }
            if (files.length > 0) {
              files.forEach((f) => {
                uploadPromises.push(
                  getUploadUrl(f, id)
                    .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                    .catch(error => console.error("Error uploading file:", error))
                );
              });
            }
            await Promise.all(uploadPromises);
            const closedStatusResponse = await Auth.fetch("/accident/accident-closed-status", {
              method: "PUT",
              body: JSON.stringify(dataToSend)
            });

            if (closedStatusResponse.ack === true) {
              setLoadMessage(closedStatusResponse.message);
              setType('success');
              navigate("/accident");

            } else {
              setLoadMessage(closedStatusResponse.message);
              setType('error');
            }
          } else {
            hideLoading(layoutDispatch);
            setLoadMessage(accidentUpdateResponse.message);
            setType('error');
          }
        } catch (err) {
          console.log(err);
          setLoadMessage("An error occurred while processing the request");
          setType("error");
        } finally {
          hideLoading(layoutDispatch);
        }
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }

    const isEmptyOrUndefined = (value) => value === '' || value === null || value === undefined;

    const checkAndSetError = (condition, key, message) => {
      if (condition) {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: message }));
        setType('error');
        setLoadMessage(`${message}${" ".repeat(Math.random() * 50)}`);
        return true;
      }
      return false;
    };

    if (
      checkAndSetError(isEmptyOrUndefined(code), 'code', 'Code is required') ||
      checkAndSetError(isEmptyOrUndefined(accidentDate), 'accidentDate', 'Accident Date is required') ||
      checkAndSetError(isEmptyOrUndefined(caseOpenDate), 'caseOpenDate', 'Case open date is required') ||
      checkAndSetError(isEmptyOrUndefined(selectedType?.id), 'type', 'Type is required') ||
      (selectedType?.id === '1' && checkAndSetError(isEmptyOrUndefined(selectedRoute?.id), 'route', 'Route is required')) ||
      ((selectedType?.id === '1' || selectedType?.id == '2') && checkAndSetError(isEmptyOrUndefined(selectedVehicle?.id), 'vehicle', 'Registration is required')) ||
      ((selectedType?.id == '1' || selectedType?.id == '2') && checkAndSetError(isEmptyOrUndefined(selectedDriver?.id), 'driver', 'Driver is required')) ||
      (selectedType?.id == '3' && checkAndSetError(isEmptyOrUndefined(selectedEquipment?.id), 'equipment', 'Equipment is required')) ||
      checkAndSetError(isEmptyOrUndefined(selectedFormSubmission?.id), 'formSubmission', 'Form Submission Status is required') ||
      checkAndSetError(isEmptyOrUndefined(selfDetails?.driver_type?.id), 'driverType', 'Agency Driver YES/NO is required') ||
      checkAndSetError(isEmptyOrUndefined(selfDetails?.fault?.id), 'fault', 'Select Fault') ||
      checkAndSetError(isEmptyOrUndefined(selfDetails?.accidentLocation), 'accidentLocation', 'Accident Location is required') ||
      // checkAndSetError(isEmptyOrUndefined(selfDetails?.contact), 'contactNumber', 'Contact number is required') ||
      // checkAndSetError(isEmptyOrUndefined(ourReference), 'ourRef', 'Our insurance Reference is required') ||
      checkAndSetError(isEmptyOrUndefined(otherPartyDetails?.name), 'otherName', 'Other Party Name is required') ||
      checkAndSetError(isEmptyOrUndefined(otherPartyDetails?.vehicle), 'otherVehicle', 'Other Party Registration No. is required') ||
      checkAndSetError(isEmptyOrUndefined(otherPartyDetails?.contact), 'otherContact', 'Other Party Contact Number is required') ||
      // checkAndSetError(isEmptyOrUndefined(otherPartyDetails?.policy_number), 'otherPolicy', 'Other Party Policy Number is required') ||
      checkAndSetError(isEmptyOrUndefined(otherPartyDetails?.email), 'otherEmail', 'Other Party email is required') ||
      // checkAndSetError(isEmptyOrUndefined(otherPartyDetails?.insurer_name), 'otherInsurer', 'Other Party Insurer Name is required') ||
      checkAndSetError(isEmptyOrUndefined(otherPartyDetails?.address), 'otherAddress', 'Other Party Address is required') ||
      checkAndSetError(isEmptyOrUndefined(otherPartyDetails?.damage), 'otherDamage', 'Damage or Point of impact is required') ||
      // checkAndSetError(isEmptyOrUndefined(otherReference), 'otherRef', 'Other party Insurance reference is required') ||
      checkAndSetError(isEmptyOrUndefined(anyInjury?.injuryNotes), 'injuryNotes', 'injury Notes are required') ||
      checkAndSetError(isEmptyOrUndefined(anyDamage?.damageNotes), 'damageNotes', 'Damage Notes are required') ||
      checkAndSetError(isEmptyOrUndefined(police?.policeNotes), 'policeNotes', 'Police Notes are required') ||
      checkAndSetError(isEmptyOrUndefined(notes), 'notes', 'Notes are required') ||
      checkAndSetError(isEmptyOrUndefined(selectedAmount), 'amount', 'Claim Amount is required') ||
      checkAndSetError(isEmptyOrUndefined(selectedPaidBy?.id), 'paidBy', 'Claim Paid by is required') ||
      checkAndSetError(isEmptyOrUndefined(selectedExAmount), 'exAmount', 'Excess Amount Paid by is required') ||
      checkAndSetError(isEmptyOrUndefined(selectedExPaidBy?.id), 'exPaidBy', 'Excess Amount Paid by is required') ||
      checkAndSetError(isEmptyOrUndefined(witnessDetails?.witness_name_1), 'witnessOneName', 'Enter Witness 1 name') ||
      // checkAndSetError(isEmptyOrUndefined(witnessDetails?.witness_address_1), 'witnessOneAddress', 'Enter Witness 1 address') ||
      checkAndSetError(isEmptyOrUndefined(witnessDetails?.witness_contact_number_1), 'witnessOneContact', 'Enter Witness 1 contact')
    ) {
      return;
    }

    const dataToSend = {
      code: code,
      driver_id: selectedDriver?.id || "",
      route_id: selectedRoute?.id || "",
      vehicle_id: selectedVehicle?.id || "",
      paid_by: selectedPaidBy?.id || "",
      amount: selectedAmount || "",
      excess_paid_by: selectedExPaidBy?.id || "",
      excess_amount: selectedExAmount || "",
      our_insurance_number: ourReference || "",
      other_party_insurance_number: otherReference || "",
      status: 1,
      type: selectedType?.id || "",
      accident_form_submitted: selectedFormSubmission?.id || "",
      // accident_date: accidentDate ? moment(accidentDate).format("YYYY-MM-DD"): null,
      accident_date: accidentDate ?? null,
      case_open_date: caseOpenDate ? moment(caseOpenDate).format("YYYY-MM-DD") : null,
      case_close_date: caseCloseDate ? moment(caseCloseDate).format("YYYY-MM-DD") : null,
      id: id,
      policy_holder: policyHolder || "",
      policy_number: policyNumber || "",
      contact_number: selfDetails?.contact || "",
      agency_driver: selfDetails?.driver_type?.id || '',
      fault: selfDetails?.fault?.id || '',
      accident_location: selfDetails?.accidentLocation || '',
      other_party_name: otherPartyDetails?.name || "",
      other_party_registration_no: otherPartyDetails?.vehicle || "",
      other_party_contact_number: otherPartyDetails?.contact || "",
      other_party_policy_number: otherPartyDetails?.policy_number || "",
      other_party_email: otherPartyDetails?.email || "",
      other_party_insurer: otherPartyDetails?.insurer_name || "",
      other_party_address: otherPartyDetails?.address || "",
      other_party_impact: otherPartyDetails?.damage || "",
      injured: anyInjury?.isInjured || "",
      injured_notes: anyInjury?.injuryNotes || "",
      damage: anyDamage?.isDamaged || "",
      damage_notes: anyDamage?.damageNotes || "",
      police_involved: police?.isPolice || "",
      police_notes: police?.policeNotes || "",
      notes: notes || "",
      witness_name_1: witnessDetails?.witness_name_1 || "",
      witness_contact_number_1: witnessDetails?.witness_contact_number_1 || "",
      witness_address_1: witnessDetails?.witness_address_1 || "",
      witness_name_2: witnessDetails?.witness_name_2 || "",
      witness_contact_number_2: witnessDetails?.witness_contact_number_2 || "",
      witness_address_2: witnessDetails?.witness_address_2 || "",
      witness_name_3: witnessDetails?.witness_name_3 || "",
      witness_contact_number_3: witnessDetails?.witness_contact_number_3 || "",
      witness_address_3: witnessDetails?.witness_address_3 || "",
      information_exchanged_status: informationExchanged?.completed,
      information_exchanged_date: informationExchanged?.date,
      information_exchanged_files: informationExchanged?.files,
      information_exchanged_notes: informationExchanged?.notes,
      liability_accepted_status: liabilityAccepted?.completed,
      liability_accepted_date: liabilityAccepted?.date,
      liability_accepted_files: liabilityAccepted?.files,
      liability_accepted_notes: liabilityAccepted?.notes,
      payment_settled_status: paymentSettled?.completed,
      payment_settled_date: paymentSettled?.date,
      payment_settled_files: paymentSettled?.files,
      payment_settled_notes: paymentSettled?.notes,
      closed_case_status: closedCase?.completed,
      closed_case_date: closedCase?.date,
      closed_case_files: closedCase?.files,
      closed_case_notes: closedCase?.notes,
      driver_files: currentItemFiles
    };
    if (selectedType?.id == '3') {
      dataToSend.equipment_id = selectedEquipment?.id || "";
    }
    if (id > 0) {
      showLoading(layoutDispatch);
      try {
        const response = await Auth.fetch("/accident/accident", {
          method: "PUT",
          body: JSON.stringify(dataToSend),
        })
        if (response.ack === true) {
          const uploadPromises = []
          if (informationExchanged.newFiles.length > 0) {
            informationExchanged.newFiles.forEach((f) => {
              uploadPromises.push(
                getUploadUrl(f, id)
                  .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                  .catch(error => console.error("Error uploading file:", error))
              );
            });
          }
          if (liabilityAccepted.newFiles.length > 0) {
            liabilityAccepted.newFiles.forEach((f) => {
              uploadPromises.push(
                getUploadUrl(f, id)
                  .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                  .catch(error => console.error("Error uploading file:", error))
              );
            });
          }
          if (paymentSettled.newFiles.length > 0) {
            paymentSettled.newFiles.forEach((f) => {
              uploadPromises.push(
                getUploadUrl(f, id)
                  .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                  .catch(error => console.error("Error uploading file:", error))
              );
            });
          }
          if (closedCase.newFiles.length > 0) {
            closedCase.newFiles.forEach((f) => {
              uploadPromises.push(
                getUploadUrl(f, id)
                  .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                  .catch(error => console.error("Error uploading file:", error))
              );
            });
          }
          if (files.length > 0) {
            files.forEach((f) => {
              uploadPromises.push(
                getUploadUrl(f, id)
                  .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                  .catch(error => console.error("Error uploading file:", error))
              );
            });
          }
          await Promise.all(uploadPromises);
          hideLoading(layoutDispatch);
          navigate("/accident");
        } else {
          hideLoading(layoutDispatch);
          setType('error')
          setLoadMessage(response?.message)
        }

      } catch (error) {
        console.log(error);
        hideLoading(layoutDispatch);

      }


    } else {
      showLoading(layoutDispatch);
      try {
        const response = await Auth.fetch("/accident/accident", {
          method: "POST",
          body: JSON.stringify(dataToSend),
        })
        if (response.ack === true) {
          const uploadPromises = []
          if (informationExchanged.newFiles.length > 0) {
            informationExchanged.newFiles.forEach((f) => {
              uploadPromises.push(
                getUploadUrl(f, response?.record_id)
                  .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                  .catch(error => console.error("Error uploading file:", error))
              );
            });
          }
          if (liabilityAccepted.newFiles.length > 0) {
            liabilityAccepted.newFiles.forEach((f) => {
              uploadPromises.push(
                getUploadUrl(f, response?.record_id)
                  .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                  .catch(error => console.error("Error uploading file:", error))
              );
            });
          }
          if (paymentSettled.newFiles.length > 0) {
            paymentSettled.newFiles.forEach((f) => {
              uploadPromises.push(
                getUploadUrl(f, id)
                  .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                  .catch(error => console.error("Error uploading file:", error))
              );
            });
          }
          if (closedCase.newFiles.length > 0) {
            closedCase.newFiles.forEach((f) => {
              uploadPromises.push(
                getUploadUrl(f, id)
                  .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                  .catch(error => console.error("Error uploading file:", error))
              );
            });
          }
          await Promise.all(uploadPromises);
          hideLoading(layoutDispatch);
          navigate("/accident");
        } else {
          hideLoading(layoutDispatch);
        }
      } catch (err) {
        console.log(err);
        hideLoading(layoutDispatch);
      }
    }
  };



  React.useEffect(() => {
    const fetchData = async () => {
      showLoading(layoutDispatch);
      try {
        const equipmentList = await Auth.fetch("/equipment/equipments");
        if (equipmentList?.ack) {
          setAllEquipments(equipmentList?.equipments)
          console.log('equipmentList', equipmentList);
          const routesData = await Auth.fetch("/route/all-routes", {
            method: "POST",
          });
          if (routesData?.ack) {
            setAllRoutes(routesData.routes)
            const driverData = await Auth.fetch(`/driver/drivers`);
            if (driverData?.ack) {
              setAllDrivers(driverData?.drivers)
              const vehicleData = await Auth.fetch(`/vehicle/vehicles`);
              if (vehicleData?.ack) {
                setAllVehicle(vehicleData.vehicles)
                if (id > 0) {
                  showLoading(layoutDispatch);

                  const res = await Auth.fetch(`/accident/accident?id=${id}`);
                  if (res?.ack) {
                    const formSubmission = yesNo.find((obj) => obj.id == res?.accident?.accident_form_submitted);
                    const paidByType = PaidBy.find((obj) => obj.id == res?.accident?.paid_by);
                    setSelectedPaidBy(paidByType);
                    const exPaidByType = ExcessPaidBy.find((obj) => obj.id == res?.accident?.excess_paid_by);
                    setCode(res?.accident?.code);
                    setSelectedExPaidBy(exPaidByType);
                    setSelectedExAmount(res?.accident?.excess_amount);
                    setOurReference(res?.accident?.our_insurance_number);
                    setOtherReference(res?.accident?.other_party_insurance_number)
                    const routeType = accidentTypes.find((obj) => obj.id == res?.accident?.type);
                    setSelectedType(routeType);
                    console.log('routeEquipment', equipmentList);
                    const routeEquipment = equipmentList?.equipments.find((obj) => obj.id == res?.accident?.equipment_id);
                    console.log('routeEquipment', routeEquipment);
                    setSelectedEquipment(routeEquipment);
                    setSelectedFormSubmission(formSubmission);
                    setSelectedAmount(res?.accident?.amount)
                    setCloseStatus(res?.accident?.status)
                    const newDriver = driverData.drivers.find(
                      (obj) => obj.id == res?.accident?.driver_id
                    );
                    setSelectedDriver(newDriver)
                    const newRoute = routesData.routes.find(
                      (obj) => obj.id == res?.accident?.route_id
                    );
                    setSelectedRoute(newRoute)
                    const newVehicle = vehicleData?.vehicles.find(
                      (obj) => obj.id == res?.accident?.vehicle_id
                    );
                    setSelectedVehicle(newVehicle)
                    setAccidentDate(new Date(res?.accident?.accident_date));
                    // setAccidentDate(res?.accident?.accident_date);
                    const openDate = res?.accident?.case_open_date ? new Date(res?.accident?.case_open_date) : null
                    setCaseOpenDate(openDate)
                    const closeDate = res?.accident?.case_close_date ? new Date(res?.accident?.case_close_date) : null
                    setCaseCloseDate(closeDate)


                    const driverType = DriverTypesCheck.find((obj) => obj.id == res?.accident?.agency_driver);
                    const faultType = accidentFaults.find((obj) => obj.id == res?.accident?.fault);
                    setSelfDetails((prev) => ({
                      ...prev,
                      driver_type: driverType,
                      contact: res?.accident?.contact_number,
                      fault: faultType,
                      accidentLocation: res?.accident?.accident_location
                    }));

                    setOtherPartyDetails((prev) => ({
                      ...prev,
                      name: res?.accident?.other_party_name,
                      vehicle: res?.accident?.other_party_registration_no,
                      contact: res?.accident?.other_party_contact_number,
                      policy_number: res?.accident?.other_party_policy_number,
                      email: res?.accident?.other_party_email,
                      insurer_name: res?.accident?.other_party_insurer,
                      address: res?.accident?.other_party_address,
                      damage: res?.accident?.other_party_impact,
                    }))
                    setWitnessDetails((prev) => ({
                      ...prev,
                      witness_name_1: res?.accident?.witness_name_1,
                      witness_contact_number_1: res?.accident?.witness_contact_number_1,
                      witness_address_1: res?.accident?.witness_address_1,
                      witness_name_2: res?.accident?.witness_name_2,
                      witness_contact_number_2: res?.accident?.witness_contact_number_2,
                      witness_address_2: res?.accident?.witness_address_2,
                      witness_name_3: res?.accident?.witness_name_3,
                      witness_contact_number_3: res?.accident?.witness_contact_number_3,
                      witness_address_3: res?.accident?.witness_address_3
                    }))
                    setAnyInjury((prev) => ({
                      ...prev,
                      isInjured: res?.accident?.injured,
                      injuryNotes: res?.accident?.injured_notes,
                    }))
                    setAnyDamage((prev) => ({
                      ...prev,
                      isDamaged: res?.accident?.damage,
                      damageNotes: res?.accident?.damage_notes,
                    }))
                    setPolice((prev) => ({
                      ...prev,
                      isPolice: res?.accident?.police_involved,
                      policeNotes: res?.accident?.police_notes,
                    }))
                    setInformationExchanged((prev) => ({
                      ...prev,
                      completed: res?.accident?.information_exchanged_status,
                      notes: res?.accident?.information_exchanged_notes,
                      files: res?.accident?.information_exchanged_files ? JSON.parse(res?.accident?.information_exchanged_files) : [],
                      date: res?.accident?.information_exchanged_date ? new Date(res?.accident?.information_exchanged_date) : null,
                      newFiles: [],
                    }))
                    setLiabilityAccepted((prev) => ({
                      ...prev,
                      completed: res?.accident?.liability_accepted_status,
                      notes: res?.accident?.liability_accepted_notes,
                      files: res?.accident?.liability_accepted_files ? JSON.parse(res?.accident?.liability_accepted_files) : [],
                      date: res?.accident?.liability_accepted_date ? new Date(res?.accident?.liability_accepted_date) : null,
                      newFiles: [],
                    }))
                    setPaymentSettled((prev) => ({
                      ...prev,
                      completed: res?.accident?.payment_settled_status,
                      notes: res?.accident?.payment_settled_notes,
                      files: res?.accident?.payment_settled_files ? JSON.parse(res?.accident?.payment_settled_files) : [],
                      date: res?.accident?.payment_settled_date ? new Date(res?.accident?.payment_settled_date) : null,
                      newFiles: [],
                    }))
                    setClosedCase((prev) => ({
                      ...prev,
                      completed: res?.accident?.closed_case_status,
                      notes: res?.accident?.closed_case_notes,
                      files: res?.accident?.closed_case_files ? JSON.parse(res?.accident?.closed_case_files) : [],
                      date: res?.accident?.closed_case_date ? new Date(res?.accident?.closed_case_date) : null,
                      newFiles: [],
                    }))
                    setNotes(res?.accident?.notes)
                    setCurrentItemFiles(JSON.parse(res?.accident?.driver_files) || []);
                  }
                } else {
                  setAccidentDate(new Date());
                  setCaseOpenDate(null);
                  setCaseCloseDate(null);
                }
              } else {
                setType('error')
                setLoadMessage(vehicleData.message)
              }
            } else {
              setType('error')
              setLoadMessage(driverData.message)
            }
          } else {
            setType('error')
            setLoadMessage(routesData.message)
          }
        } else {
          setType('error')
          setLoadMessage(equipmentList.message)
        }


      } catch (err) {
        console.log(err);
      } finally {
        hideLoading(layoutDispatch);
      }
    }
    fetchData()
  }, [id]);

  const deleteNormalFiles = (fileName) => {
    const updatedFiles = files?.filter((file) => file.name !== fileName);
    setFiles(updatedFiles);
    setCurrentItemFiles(currentItemFiles?.filter((item) => item !== fileName));
  };

  const handleDeleteFile = (status, fileName) => {
    const updateFiles = (state, setState) => {
      const updatedFiles = state.files.filter((file) => file !== fileName);
      setState(prev => ({
        ...prev,
        files: updatedFiles
      }));
    };

    switch (status) {
      case 'info':
        updateFiles(informationExchanged, setInformationExchanged);
        break;
      case 'lib':
        updateFiles(liabilityAccepted, setLiabilityAccepted);
        break;
      case 'pay':
        updateFiles(paymentSettled, setPaymentSettled);
        break;
      case 'close':
        updateFiles(closedCase, setClosedCase);
        break;
      default:
        console.error("Invalid status provided");
    }
  };


  const handleDeleteNewFile = (status, fileName) => {
    const updateState = (state, setState) => {
      const updatedFiles = state.files.filter((file) => file !== fileName);
      const updatedNewFiles = state.newFiles.filter((file) => file.name !== fileName);

      setState(prev => ({
        ...prev,
        files: updatedFiles,
        newFiles: updatedNewFiles,
      }));
    };

    switch (status) {
      case 'info':
        updateState(informationExchanged, setInformationExchanged);
        break;
      case 'lib':
        updateState(liabilityAccepted, setLiabilityAccepted);
        break;
      case 'pay':
        updateState(paymentSettled, setPaymentSettled);
        break;
      case 'close':
        updateState(closedCase, setClosedCase);
        break;
      default:
        console.error("Invalid status provided");
    }
  };

  function getNewFileName(oldFileName) {
    const extension = oldFileName?.slice(oldFileName?.lastIndexOf('.'));
    const baseName = oldFileName?.slice(0, oldFileName?.lastIndexOf('.'));
    const randomString = Math.random().toString(36).substring(2, 12);
    return `${baseName}-${randomString}${extension}`;
  }
  
  function extractOriginalFileName(modifiedFileName) {
    const extensionIndex = modifiedFileName?.lastIndexOf('.');
    const extension = modifiedFileName?.slice(extensionIndex);
    const randomStringPattern = /-\w{10}(?=\.\w+$)/;
    const baseName = modifiedFileName?.replace(randomStringPattern, '');
    return baseName;
  }

  const handleSubmitImage = (e) => {
    e.preventDefault();
    let acceptedFiles = e.target.files;

    for (let file of acceptedFiles) {
      const randomString = Math.random().toString(36).substring(2, 10);
      // const newFileName = `${file.name.split(".")[0]}-${randomString}.${file.name.split(".")[1]}`;
      const newFileName = getNewFileName(file.name);
      setLoadMessage("");
      setFiles((prevFiles) => [
        ...prevFiles,
        {
          name: newFileName,
          display_name: newFileName,
          file: file,
          ContentType: file.type,
        },
      ]);
      setCurrentItemFiles((prevFiles) => [...prevFiles, newFileName]);
    }
  };

  const handleDeleteCurrentFile = (itemToDelete) => {
    setCurrentItemFiles(
      currentItemFiles.filter((item) => item !== itemToDelete)
    );
    if (!deletedFilesSimple.includes(itemToDelete)) {
      setDeletedFilesSimple((prevDeletedFiles) => [
        ...prevDeletedFiles,
        itemToDelete,
      ]);
    }
  };

  const handleSubmitImageMulti = (e, status) => {
    e.preventDefault();
    let acceptedFiles = e.target.files;
    const randomString = Math.random().toString(36).substring(2, 10);

    const updateState = (state, setState) => {
      let newFiles = [...state.newFiles];
      let filesNameList = [...state.files];

      for (let file of acceptedFiles) {
        const newFileName = `${file.name.split('.')[0]}-${randomString}.${file.name.split('.')[1]}`;
        newFiles.push({
          name: newFileName,
          display_name: newFileName,
          file: file,
          ContentType: file.type,
        });
        filesNameList.push(newFileName);
      }

      setState(prev => ({
        ...prev,
        newFiles,
        files: filesNameList,
      }));
    };

    switch (status) {
      case 'info':
        updateState(informationExchanged, setInformationExchanged);
        break;
      case 'lib':
        updateState(liabilityAccepted, setLiabilityAccepted);
        break;
      case 'pay':
        updateState(paymentSettled, setPaymentSettled);
        break;
      case 'close':
        updateState(closedCase, setClosedCase);
        break;
      default:
        console.error("Invalid status provided");
    }
  };

//   const generatePDF = () => {
//     const doc = new jsPDF();
//     let currentY = 20; // Track the Y position on the page dynamically
//     const pageWidth = doc.internal.pageSize.width;

//     doc.addImage(logo, "PNG", 10, 10, 50, 20);

//     // Add Date and Time
//     doc.setFontSize(12);
//     doc.text(`Date: ${dayjs(accidentDate).format("YYYY-MM-DD")}`, 153, 15);
//     doc.text(`Time: ${dayjs(accidentDate).format("HH:mm")}`, 153, 20);
//     doc.text(`Case Open Date: ${caseOpenDate ? dayjs(caseOpenDate).format("YYYY-MM-DD") : "N/A"}`, 153, 25);
//     doc.text(`Case Close Date: ${caseCloseDate ? dayjs(caseCloseDate).format("YYYY-MM-DD") : "N/A"}`, 153, 30);
  
//     // Add Header
//     doc.setFontSize(18);
//     doc.text("Accident Report", 80, currentY);
//     currentY += 20;
  
//     // Function to draw table-like rows
//     const drawRow = (label, value, yPosition) => {
//         doc.setFontSize(12);
//         const labelWidth = 50;
//         const valueWidth = 130;
//         doc.text(label, 10, yPosition);
//         doc.text(value, 10 + labelWidth, yPosition);
//         doc.rect(10, yPosition - 5, labelWidth + valueWidth, 10); // Draw row border
//     };
  
//     // Accident Details Section
//     doc.setFontSize(16);
//     doc.text("Accident Details", 10, currentY);
//     currentY += 10;
//     drawRow("Date:", dayjs(accidentDate).format("YYYY-MM-DD HH:mm"), currentY);
//     drawRow("Code:", code, currentY + 10);
//     drawRow("Policy Holder:", policyHolder, currentY + 20);
//     drawRow("Policy Number:", policyNumber, currentY + 30);
//     currentY += 40;
  
//     // Driver Details Section
//     doc.setFontSize(16);
//     doc.text("Driver Details", 10, currentY);
//     currentY += 10;
//     drawRow("Contact:", selfDetails.contact, currentY);
//     drawRow("Agency Driver:", selfDetails.driver_type?.title, currentY + 10);
//     drawRow("Fault:", selfDetails.fault?.title, currentY + 20);
//     currentY += 30;
  
//     // Other Party Details Section
//     doc.setFontSize(16);
//     doc.text("Other Party Details", 10, currentY);
//     currentY += 10;
//     drawRow("Name:", otherPartyDetails.name, currentY);
//     drawRow("Vehicle:", otherPartyDetails.vehicle, currentY + 10);
//     drawRow("Contact:", otherPartyDetails.contact, currentY + 20);
//     currentY += 30;
  
//     // Witness Details Section
//     doc.setFontSize(16);
//     doc.text("Witness Details", 10, currentY);
//     currentY += 10;
//     drawRow("Name:", witnessDetails.witness_name_1, currentY);
//     drawRow("Contact:", witnessDetails.witness_contact_number_1, currentY + 10);
//     drawRow("Address:", witnessDetails.witness_address_1, currentY + 20);
//     currentY += 30;
  
//     // Check if content overflows the page before adding Injury Details
//     if (currentY + 30 > 290) {
//         doc.addPage();
//         currentY = 20; // Reset position on the new page
//     }
  
//     // Injury Details Section
//     doc.setFontSize(16);
//     doc.text("Injury Details", 10, currentY);
//     currentY += 10;
//     drawRow("Injured:", anyInjury.isInjured === 1 ? "Yes" : "No", currentY);
//     drawRow("Injury Notes:", anyInjury.injuryNotes, currentY + 10);
//     currentY += 30;
  
//     // Check if "Additional Notes" will fit on the page, else add a new page
//     if (currentY + 50 > 290) {
//         doc.addPage();
//         currentY = 20;
//     }
  
//     // Additional Notes Section
//     doc.setFontSize(16);
//     doc.text("Additional Notes", 10, currentY);
//     currentY += 10;
//     doc.setFontSize(12);
  
//     // Box for Notes
//     const noteLines = doc.splitTextToSize(notes, pageWidth - 20); // Adjust width to fit within page margins
//     const boxHeight = noteLines.length * 10 + 10; // Calculate box height dynamically
  
//     doc.rect(10, currentY, pageWidth - 20, boxHeight); 
//     doc.text(noteLines, 15, currentY + 10); // Render the split lines inside the box
//     currentY += boxHeight + 10;
  
//     doc.save(`Accident_Report_${id}.pdf`);
// };

console.log('values', selectedDriver, selectedVehicle);


const generatePDF = () => {
  const doc = new jsPDF();
  let currentY = 20; // Track the Y position on the page dynamically
  const pageWidth = doc.internal.pageSize.width;
  const labelWidth = 60; // Width for labels
  const valueWidth = 125; // Width for values
  const margin = 4; // Margin inside rows
  const rowHeight = 10; // Height of each row
  const sectionMargin = 20; // Margin after each section heading

  doc.addImage(logo, "PNG", 10, 10, 50, 20);

  // Add Date and Time
  doc.setFontSize(12);
  doc.text(`Date: ${dayjs(accidentDate).format("YYYY-MM-DD")}`, 153, 15);
  doc.text(`Time: ${dayjs(accidentDate).format("HH:mm")}`, 153, 20);
  doc.text(`Case Open Date: ${caseOpenDate ? dayjs(caseOpenDate).format("YYYY-MM-DD") : "N/A"}`, 153, 25);
  doc.text(`Case Close Date: ${caseCloseDate ? dayjs(caseCloseDate).format("YYYY-MM-DD") : "N/A"}`, 153, 30);

  // Add Header
  doc.setFontSize(18);
  doc.text("Accident Report", 80, currentY);
  currentY += 20;

  // Function to draw table-like rows with vertical line and margins
  const drawRow = (label, value, yPosition) => {
      doc.setFontSize(8);
      // Draw label
      doc.text(label, 10 + margin, yPosition + rowHeight - margin);
      // Draw vertical line between label and value
      doc.line(10 + labelWidth + margin, yPosition, 10 + labelWidth + margin, yPosition + rowHeight);
      // Draw value
      doc.text(value ? value : '', 10 + valueWidth + 2 * margin, yPosition + rowHeight - margin);
      // Draw row border
      doc.rect(10, yPosition, labelWidth + valueWidth + 2 * margin, rowHeight);
  };

  // Function to draw sections with dynamic page breaks
  const drawSection = (title, data, yOffset) => {
      doc.setFontSize(15);
      // Check if there's enough space for the section heading and the data
      if (currentY + sectionMargin + (data.length * (rowHeight + margin)) > 290) {
          doc.addPage();
          currentY = 20; // Reset position on the new page
      }
      doc.text(title, 10, currentY + 15);
      currentY += sectionMargin;
      doc.setFontSize(8);
      
      data.forEach((item, index) => {
          drawRow(item?.label, item?.value, currentY + (index * (rowHeight + margin)));
      });
      currentY += data?.length * (rowHeight + margin) + margin;
  };

  // Accident Details Section
  // drawSection("Accident Details", [
  //     { label: "Date:", value: dayjs(accidentDate).format("YYYY-MM-DD HH:mm") },
  //     { label: "Code:", value: code },
  //     { label: "Policy Holder:", value: policyHolder },
  //     { label: "Policy Number:", value: policyNumber },
  //     { label: "Type:", value: selectedType.title },
  //     { label: "Route:", value: selectedRoute?.title },
  //     { label: "Vehicle Reg:", value: selectedVehicle?.registration_number },
  //     { label: "Driver:", value: selectedDriver?.driver_name },
  //     { label: "Accident Form Submission:", value: selectedFormSubmission?.title },
  //     { label: "Contact:", value: selfDetails.contact },
  //     { label: "Agency Driver:", value: selfDetails.driver_type?.title },
  //     { label: "Fault:", value: selfDetails.fault?.title },
  //     { label: "Our Insurance Ref:", value: ourReference },

  // ], 5);
  const accidentDetails = [
    { label: "Code:", value: code },
    { label: "Policy Holder:", value: policyHolder },
    { label: "Policy Number:", value: policyNumber },
    { label: "Type:", value: selectedType?.title },
    { label: "Vehicle Reg:", value: selectedVehicle?.registration_number },
    { label: "Driver:", value: selectedDriver?.driver_name },
    { label: "Accident Form Submission:", value: selectedFormSubmission?.title },
    { label: "Contact:", value: selfDetails?.contact },
    { label: "Agency Driver:", value: selfDetails?.driver_type?.title },
    { label: "Fault:", value: selfDetails.fault?.title },
    { label: "Our Insurance Ref:", value: ourReference }
  ];

  if (selectedType.id == 1) {
  accidentDetails.splice(5, 0, { label: "Route:", value: selectedRoute?.title });
}

drawSection("Accident Details", accidentDetails, 5);

  // Driver Details Section
  // drawSection("Driver Details", [
  //     { label: "Fault:", value: selfDetails.fault?.title }
  // ], 5);

  // Other Party Details Section
  drawSection("Other Party Details", [
      { label: "Name:", value: otherPartyDetails?.name },
      { label: "Vehicle:", value: otherPartyDetails?.vehicle },
      { label: "Contact:", value: otherPartyDetails?.contact },
      { label: "Policy Number:", value: otherPartyDetails?.policy_number },
      { label: "Email:", value: otherPartyDetails?.email },
      { label: "Insurer Name:", value: otherPartyDetails?.insurer_name },
      { label: "Address:", value: otherPartyDetails?.address },
      { label: "Damage:", value: otherPartyDetails?.damage },
      { label: "Insurance Ref:", value: otherReference },
  ], 5);

  // Witness Details Section
  drawSection("Witness Details", [
      { label: "Name-1:", value: witnessDetails?.witness_name_1 },
      { label: "Contact-1:", value: witnessDetails?.witness_contact_number_1 },
      { label: "Address-1:", value: witnessDetails?.witness_address_1 },
      { label: "Name-2:", value: witnessDetails?.witness_name_2 },
      { label: "Contact-2:", value: witnessDetails?.witness_contact_number_2 },
      { label: "Address-2:", value: witnessDetails?.witness_address_2 },
      { label: "Name-3:", value: witnessDetails?.witness_name_3 },
      { label: "Contact-3:", value: witnessDetails?.witness_contact_number_3 },
      { label: "Address-3:", value: witnessDetails?.witness_address_3 }
  ], 5);

  // Check if content overflows the page before adding Injury Details
  if (currentY + 30 > 290) {
      doc.addPage();
      currentY = 20; // Reset position on the new page
  }

  // Injury Details Section
  drawSection("Injury Details", [
      { label: "Injured:", value: anyInjury?.isInjured === 1 ? "Yes" : "No" },
      { label: "Injury Notes:", value: anyInjury?.injuryNotes }
  ], 5);

  // Injury Details Section
  drawSection("Property Damage Details", [
    { label: "Damaged:", value: anyDamage?.isDamaged === 1 ? "Yes" : "No" },
    { label: "Damage Notes:", value: anyDamage?.damageNotes }
], 5);

drawSection("Police Details", [
  { label: "Police Involved:", value: police?.isPolice === 1 ? "Yes" : "No" },
  { label: "Damage Notes:", value: police?.policeNotes }
], 5);

  // Check if "Additional Notes" will fit on the page, else add a new page
  if (currentY + 50 > 290) {
      doc.addPage();
      currentY = 20;
  }

  // Additional Notes Section
  doc.setFontSize(16);
  doc.text("Additional Notes", 10, currentY);
  currentY += 10;
  doc.setFontSize(12);

  // Box for Notes
  const noteLines = doc.splitTextToSize(notes, pageWidth - 20); // Adjust width to fit within page margins
  const boxHeight = noteLines.length * 10 + 10; // Calculate box height dynamically

  doc.rect(10, currentY, pageWidth - 20, boxHeight); 
  doc.text(noteLines, 15, currentY + 10); // Render the split lines inside the box
  currentY += boxHeight + 10;

  doc.save(`Accident_Report_${id}.pdf`);
};



  return (
    <div className={classes.home}>
    {
      id > 0 && (
        <div style={{ display: "flex", justifyContent: 'end'}}>
      <AntButton size="large" type="primary" onClick={generatePDF}>Download</AntButton>
      </div>
      )
    }
      
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={12}>
          <h1
            style={{ float: "left", marginBottom: "-5px", marginTop: "-5px" }}
          >
            {closeStatus === 0 ? "Closed Accident" : `${id > 0 ? "Update" : "Add"} Accident`}
          </h1>
        </Grid>
        <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
          <Stack spacing={2}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>Accident Date<span style={{ color: "red", fontSize: "15px" }}>*</span>:</span>
              <AntDate
                format={'YYYY-MM-DD HH:mm'}
                style={{ width: 160 }}
                disabled={closeStatus === 0}
                value={accidentDate ? dayjs(accidentDate) : null}
                onChange={(_, dateStr) => {
                  setAccidentDate(dateStr);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    accidentDate: '',
                  }));
                }}
                showTime
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>Case Open Date<span style={{ color: "red", fontSize: "15px" }}>*</span>:</span>
              <AntDate
                format={'YYYY-MM-DD'}
                disabled={closeStatus === 0}
                style={{ width: 160 }}
                value={caseOpenDate ? dayjs(caseOpenDate) : null}
                onChange={(_, dateStr) => {
                  setCaseOpenDate(dateStr);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    caseOpenDate: '',
                  }));
                }}
                placeholder="Select date"
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label htmlFor="pcnRecDate">Case Close Date:</label>
              <AntDate
                format={'YYYY-MM-DD'}
                style={{ width: 160 }}
                disabled={closeStatus === 0}
                value={caseCloseDate ? dayjs(caseCloseDate) : null}
                onChange={(_, dateStr) => {
                  setCaseCloseDate(dateStr);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    caseCloseDate: '',
                  }));
                }}
                placeholder="Select date"
              />
            </div>
          </Stack>
        </Grid>
        <div className="accident-box" style={{ marginTop: '10px' }}>
          <span className="accident-box-heading">
            Accident Details
          </span>
          <Row gutter={[16, 16]}>
            <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                placeholder="Enter Code"
                value={code}
                required
                onChange={(e) => {
                  setCode(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    code: '',
                  }));
                }}
                error={errors?.code === "" ? false : true}
                helperText={errors?.code}
                id="code"
                label="Code"
                disabled={closeStatus === 0}

              />
            </Col>
            <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                id="policy_holder"
                size="small"
                label="Policy Holder"
                variant="outlined"
                value={policyHolder}
                disabled
              />
            </Col>
            <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                id="policy_number"
                size="small"
                label="Policy Number"
                variant="outlined"
                value={policyNumber}
                disabled
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Autocomplete
                id="type"
                options={accidentTypes}
                getOptionLabel={(option) => option.title ? option.title : ''}
                value={selectedType}
                onChange={(e, values) => {
                  setSelectedType(values)
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    type: '',
                  }))
                }}

                disabled={closeStatus === 0}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Type"
                    placeholder="Type"
                    size="small"
                    required
                    error={errors?.type === "" ? false : true}
                    helperText={errors?.type}
                  />
                )}
              />
            </Col>

            {
              selectedType?.id == '3' && (
                <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
                  <Autocomplete
                    id="equiment"
                    options={allEquipments}
                    getOptionLabel={(option) =>
                      option.title ? `${option.title} - (${option.serial})` : ""
                    }
                    value={selectedEquipment}
                    onChange={(e, values) => {
                      setSelectedEquipment(values)
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        equipment: '',
                      }))
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Equipment"
                        placeholder="Equipement"
                        size="small"
                        required
                        disabled={closeStatus === 0}
                        error={errors?.equipment === "" ? false : true}
                        helperText={errors?.equipment}
                      />
                    )}
                  />
                </Col>

              )}

            {
              selectedType?.id == '1' && (
                <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
                  <Autocomplete
                    id="routes"
                    disabled={closeStatus === 0}
                    options={allRoutes}
                    getOptionLabel={(option) =>
                      option.title ? `${option.title} (${option.code})` : ""
                    }
                    value={selectedRoute}
                    onChange={(e, values) => {
                      setSelectedRoute(values)
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        route: '',
                      }))
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Route"
                        placeholder="Route"
                        size="small"
                        required
                        error={errors?.route === "" ? false : true}
                        helperText={errors?.route}
                      />
                    )}
                  />
                </Col>

              )
            }
            {
              selectedType?.id != '3' && (
                <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
                  <Autocomplete
                    id="vehicle"
                    disabled={closeStatus === 0}
                    options={allVehicles}
                    getOptionLabel={(option) =>
                      option.registration_number ? option.registration_number : ""
                    }
                    value={selectedVehicle}
                    onChange={(e, values) => {
                      setSelectedVehicle(values)
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        vehicle: '',
                      }))
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Vehicle Reg."
                        placeholder="Vehicle Reg"
                        size="small"
                        required
                        error={errors?.vehicle === "" ? false : true}
                        helperText={errors?.vehicle}
                      />
                    )}
                  />
                </Col>

              )}

            <>
              {
                selectedType?.id != '3' && (

                  <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Autocomplete
                      id="driver"
                      disabled={closeStatus === 0}
                      options={allDrivers}
                      getOptionLabel={(option) =>
                        option.driver_name ? option.driver_name : ""
                      }
                      value={selectedDriver}
                      onChange={(e, values) => {
                        setSelectedDriver(values)
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          driver: '',
                        }))
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Driver"
                          placeholder="Driver"
                          size="small"
                          required
                          error={errors?.driver === "" ? false : true}
                          helperText={errors?.driver}
                        />
                      )}
                    />
                  </Col>

                )}


            </>

            <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Autocomplete
                id="accident-form-submit"
                disabled={closeStatus === 0}
                options={yesNo}
                getOptionLabel={(option) => option.title}
                value={selectedFormSubmission}
                onChange={(e, values) => {
                  setSelectedFormSubmission(values);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    formSubmission: '',
                  }))
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Accident Form Submitted"
                    placeholder="Accident form Submitted"
                    size="small"
                    required
                    error={errors?.formSubmission === "" ? false : true}
                    helperText={errors?.formSubmission}
                  />
                )}
              />
            </Col>

            <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                id="phone"
                size="small"
                label="Contact Number"
                variant="outlined"
                placeholder="Contact Number"
                value={selfDetails?.contact}
                onChange={(e) => {
                  setSelfDetails((prevDetails) => ({
                    ...prevDetails,
                    contact: e.target.value
                  }))
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    contactNumber: '',
                  }))
                }}
                error={errors?.contactNumber === "" ? false : true}
                helperText={errors?.contactNumber}
                disabled={closeStatus === 0}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Autocomplete
                id="vehicle"
                options={DriverTypesCheck}
                getOptionLabel={(option) => option.title ? option.title : ''}
                value={selfDetails?.driver_type}
                onChange={(e, values) => {
                  setSelfDetails((prevDetails) => ({
                    ...prevDetails,
                    driver_type: values
                  }))
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    driverType: '',
                  }))
                }}
                disabled={closeStatus === 0}

                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="Agency Driver? YES / NO"
                    placeholder="Agency Driver? YES / NO"
                    required
                    error={errors?.driverType === "" ? false : true}
                    helperText={errors?.driverType}
                  />
                )}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                disabled={closeStatus === 0}
                fullWidth
                id="ourReference"
                size="small"
                label="Our Insurance Reference"
                variant="outlined"
                value={ourReference}
                onChange={(e) => {
                  setOurReference(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    ourRef: '',
                  }))
                }}
                error={errors?.ourRef === "" ? false : true}
                helperText={errors?.ourRef}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Autocomplete
                id="fault"
                options={accidentFaults}
                getOptionLabel={(option) => option.title ? option.title : ''}
                value={selfDetails?.fault}
                onChange={(e, values) => {
                  setSelfDetails((prevDetails) => ({
                    ...prevDetails,
                    fault: values
                  }))
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    fault: '',
                  }))
                }}
                disabled={closeStatus === 0}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="Fault"
                    placeholder="Fault"
                    required
                    error={errors?.fault === "" ? false : true}
                    helperText={errors?.fault}
                  />
                )}
              />
            </Col>
            <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                id="phone"
                size="small"
                label="Accident Location"
                required
                variant="outlined"
                placeholder="Accident Location"
                value={selfDetails?.accidentLocation}
                onChange={(e) => {
                  setSelfDetails((prevDetails) => ({
                    ...prevDetails,
                    accidentLocation: e.target.value
                  }))
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    accidentLocation: '',
                  }))
                }}
                error={errors?.accidentLocation === "" ? false : true}
                helperText={errors?.accidentLocation}
                disabled={closeStatus === 0}
              />
            </Col>
          </Row>
        </div>


        <div className="accident-box" style={{ marginTop: '20px' }}>
          <span className="accident-box-heading">
            Other Party Details
          </span>
          <Row gutter={[16, 16]}>
            <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                id="other_name"
                size="small"
                label="Name"
                variant="outlined"
                placeholder="Name"
                value={otherPartyDetails?.name}
                onChange={(e) => {
                  setOtherPartyDetails((prevDetails) => ({
                    ...prevDetails,
                    name: e.target.value
                  }))
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    otherName: '',
                  }))
                }}
                required
                error={errors?.otherName === "" ? false : true}
                helperText={errors?.otherName}
                disabled={closeStatus === 0}

              />
            </Col>
            <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                id="other_vehicle"
                size="small"
                label="Registration Number"
                variant="outlined"
                placeholder="Registration Number"
                value={otherPartyDetails?.vehicle}
                onChange={(e) => {
                  setOtherPartyDetails((prevDetails) => ({
                    ...prevDetails,
                    vehicle: e.target.value
                  }))
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    otherVehicle: '',
                  }))
                }}
                required
                error={errors?.otherVehicle === "" ? false : true}
                helperText={errors?.otherVehicle}
                disabled={closeStatus === 0}

              />
            </Col>
            <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                id="other_phone"
                size="small"
                label="Contact Number"
                variant="outlined"
                placeholder="Contact Number"
                value={otherPartyDetails?.contact}
                onChange={(e) => {
                  setOtherPartyDetails((prevDetails) => ({
                    ...prevDetails,
                    contact: e.target.value
                  }))
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    otherContact: '',
                  }))
                }}
                required
                error={errors?.otherContact === "" ? false : true}
                helperText={errors?.otherContact}
                disabled={closeStatus === 0}

              />
            </Col>
            <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                id="other_policy_no"
                size="small"
                label="Policy Number"
                variant="outlined"
                placeholder="Policy Number"
                value={otherPartyDetails?.policy_number}
                onChange={(e) => {
                  setOtherPartyDetails((prevDetails) => ({
                    ...prevDetails,
                    policy_number: e.target.value
                  }))
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    otherPolicy: '',
                  }))
                }}
                
                error={errors?.otherPolicy === "" ? false : true}
                helperText={errors?.otherPolicy}
                disabled={closeStatus === 0}

              />
            </Col>
            <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                id="other_email"
                size="small"
                label="Email Address"
                variant="outlined"
                placeholder="Email Address"
                value={otherPartyDetails?.email}
                onChange={(e) => {
                  setOtherPartyDetails((prevDetails) => ({
                    ...prevDetails,
                    email: e.target.value
                  }))
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    otherEmail: '',
                  }))
                }}
                required
                error={errors?.otherEmail === "" ? false : true}
                helperText={errors?.otherEmail}
                disabled={closeStatus === 0}

              />
            </Col>
            <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                id="other_insure_name"
                size="small"
                label="Insurer's Name"
                variant="outlined"
                placeholder="Insurer's Name"
                value={otherPartyDetails?.insurer_name}
                onChange={(e) => {
                  setOtherPartyDetails((prevDetails) => ({
                    ...prevDetails,
                    insurer_name: e.target.value
                  }))
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    otherInsurer: '',
                  }))
                }}
                error={errors?.otherInsurer === "" ? false : true}
                helperText={errors?.otherInsurer}
                disabled={closeStatus === 0}

              />
            </Col>
            <Col Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                id="other_addresss"
                size="small"
                label="Address"
                variant="outlined"
                placeholder="Address"
                value={otherPartyDetails?.address}
                onChange={(e) => {
                  setOtherPartyDetails((prevDetails) => ({
                    ...prevDetails,
                    address: e.target.value
                  }))
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    otherAddress: '',
                  }))
                }}
                required
                error={errors?.otherAddress === "" ? false : true}
                helperText={errors?.otherAddress}
                disabled={closeStatus === 0}

              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                id="other_damage"
                size="small"
                label="Damage to vehicle / Point of impact"
                variant="outlined"
                placeholder="Damage to vehicle / Point of impact"
                value={otherPartyDetails?.damage}
                onChange={(e) => {
                  setOtherPartyDetails((prevDetails) => ({
                    ...prevDetails,
                    damage: e.target.value
                  }))
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    otherDamage: '',
                  }))
                }}
                required
                error={errors?.otherDamage === "" ? false : true}
                helperText={errors?.otherDamage}
                disabled={closeStatus === 0}

              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                disabled={closeStatus === 0}
                fullWidth
                id="otherReference"
                size="small"
                label="Other Party Insurance Reference"
                variant="outlined"
                value={otherReference}
                onChange={(e) => {
                  setOtherReference(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    otherRef: '',
                  }))
                }}
                error={errors?.otherRef === "" ? false : true}
                helperText={errors?.otherRef}
              />
            </Col>
          </Row>
        </div>

        <div className="accident-box" style={{ marginTop: '20px' }}>
          <span className="accident-box-heading">Witness Details</span>

          <div className={classes.dataSheet}>
            <div >
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                  <TextField
                    fullWidth
                    id="name_1"
                    size="small"
                    label="1-Witness Name"
                    variant="outlined"
                    placeholder="Enter Name"
                    value={witnessDetails?.witness_name_1}
                    onChange={(e) => {
                      setWitnessDetails((prevDetails) => ({
                        ...prevDetails,
                        witness_name_1: e.target.value
                      }))
                      setErrors((prevErrors) => ({
                      ...prevErrors,
                      witnessOneName: '',
                    }))
                    }}
                    required
                    error={errors?.witnessOneName === "" ? false : true}
                    helperText={errors?.witnessOneName}
                    disabled={closeStatus === 0}
                  />
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                  <TextField
                    fullWidth
                    id="contact_1"
                    size="small"
                    label="1-Contact Number"
                    variant="outlined"
                    placeholder="Enter Contact Number"
                    value={witnessDetails?.witness_contact_number_1}
                    onChange={(e) => {
                      setWitnessDetails((prevDetails) => ({
                        ...prevDetails,
                        witness_contact_number_1: e.target.value
                      }))
                      setErrors((prevErrors) => ({
                      ...prevErrors,
                      witnessOneContact: '',
                    }))
                    }}
                    required
                    error={errors?.witnessOneContact === "" ? false : true}
                    helperText={errors?.witnessOneContact}
                    disabled={closeStatus === 0}
                  />
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                  <TextField
                    fullWidth
                    id="address_1"
                    size="small"
                    label="1-Address"
                    variant="outlined"
                    placeholder="Enter Address"
                    value={witnessDetails?.witness_address_1}
                    onChange={(e) => {
                      setWitnessDetails((prevDetails) => ({
                        ...prevDetails,
                        witness_address_1: e.target.value
                      }))
                      setErrors((prevErrors) => ({
                      ...prevErrors,
                      witnessOneContact: '',
                    }))
                    }}
                    error={errors?.witnessOneAddress === "" ? false : true}
                    helperText={errors?.witnessOneAddress}
                    disabled={closeStatus === 0}

                  />
                </Col>

                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                  <TextField
                    fullWidth
                    id="name_2"
                    size="small"
                    label="2-Witness Name"
                    variant="outlined"
                    placeholder="Enter Name"
                    value={witnessDetails?.witness_name_2}
                    onChange={(e) => {
                      setWitnessDetails((prevDetails) => ({
                        ...prevDetails,
                        witness_name_2: e.target.value
                      }))
                    }}
                    disabled={closeStatus === 0}

                  />
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                  <TextField
                    fullWidth
                    id="contact_2"
                    size="small"
                    label="2-Contact Number"
                    variant="outlined"
                    placeholder="Enter Contact Number"
                    value={witnessDetails?.witness_contact_number_2}
                    onChange={(e) => {
                      setWitnessDetails((prevDetails) => ({
                        ...prevDetails,
                        witness_contact_number_2: e.target.value
                      }))
                    }}
                    disabled={closeStatus === 0}

                  />
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                  <TextField
                    fullWidth
                    id="address_2"
                    size="small"
                    label="2-Address"
                    variant="outlined"
                    placeholder="Enter Address"
                    value={witnessDetails?.witness_address_2}
                    onChange={(e) => {
                      setWitnessDetails((prevDetails) => ({
                        ...prevDetails,
                        witness_address_2: e.target.value
                      }))
                    }}
                    disabled={closeStatus === 0}

                  />
                </Col>

                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                  <TextField
                    fullWidth
                    id="name_3"
                    size="small"
                    label="3-Witness Name"
                    variant="outlined"
                    placeholder="Enter Name"
                    value={witnessDetails?.witness_name_3}
                    onChange={(e) => {
                      setWitnessDetails((prevDetails) => ({
                        ...prevDetails,
                        witness_name_3: e.target.value
                      }))
                    }}
                    disabled={closeStatus === 0}

                  />
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                  <TextField
                    fullWidth
                    id="contact_3"
                    size="small"
                    label="3-Contact Number"
                    variant="outlined"
                    placeholder="Enter Contact Number"
                    value={witnessDetails?.witness_contact_number_3}
                    onChange={(e) => {
                      setWitnessDetails((prevDetails) => ({
                        ...prevDetails,
                        witness_contact_number_3: e.target.value
                      }))
                    }}
                    disabled={closeStatus === 0}

                  />
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                  <TextField
                    fullWidth
                    id="address_3"
                    size="small"
                    label="3-Address"
                    variant="outlined"
                    placeholder="Enter Address"
                    value={witnessDetails?.witness_address_3}
                    onChange={(e) => {
                      setWitnessDetails((prevDetails) => ({
                        ...prevDetails,
                        witness_address_3: e.target.value
                      }))
                    }}
                    disabled={closeStatus === 0}

                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="accident-box" style={{ marginTop: '15px' }}>
          <span className="accident-box-heading">Additional Info</span>
          <Row style={{ padding: '4px' }} gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <span style={{ fontWeight: 'bold' }}>Anyone Injured?</span>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <Radio.Group onChange={(e) => {
                setAnyInjury((prev) => ({
                  ...prev,
                  isInjured: e.target.value
                }))
              }} disabled={closeStatus === 0} value={anyInjury?.isInjured}>
                <Radio value={1}>Yes</Radio>
                <Radio value={2}>No</Radio>
              </Radio.Group>
            </Col>
            <Col xs={24} sm={12} md={8} lg={10} xl={16}>
              <TextArea
                autoSize
                disabled={closeStatus === 0}
                onChange={(e) => {
                  setAnyInjury((prev) => ({
                    ...prev,
                    injuryNotes: e.target.value
                  }))
                  setErrors((prev) => ({
                    ...prev,
                    injuryNotes: ""
                  }))
                }}
                status={errors?.injuryNotes === "" ? "" : "error"}
                value={anyInjury?.injuryNotes}
              />
            </Col>

          </Row>
          <Row style={{ padding: '4px' }} gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <span style={{ fontWeight: 'bold' }}>Property Damage?</span>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <Radio.Group
                onChange={(e) => {
                  setAnyDamage((prev) => ({
                    ...prev,
                    isDamaged: e.target.value
                  }))
                }}
                value={anyDamage?.isDamaged}
                disabled={closeStatus === 0}
              >
                <Radio value={1}>Yes</Radio>
                <Radio value={2}>No</Radio>
              </Radio.Group>
            </Col>
            <Col xs={24} sm={12} md={8} lg={10} xl={16}>
              <TextArea
                onChange={(e) => {
                  setAnyDamage((prev) => ({
                    ...prev,
                    damageNotes: e.target.value
                  }))
                  setErrors((prev) => ({
                    ...prev,
                    damageNotes: ""
                  }))
                }}
                status={errors?.damageNotes === "" ? "" : "error"}
                value={anyDamage?.damageNotes}
                disabled={closeStatus === 0}
                autoSize
                stat
              />
            </Col>

          </Row>
          <Row style={{ padding: '4px' }} gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <span style={{ fontWeight: 'bold' }}>Police Involved?</span>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <Radio.Group
                onChange={(e) => {
                  setPolice((prev) => ({
                    ...prev,
                    isPolice: e.target.value
                  }))
                }}
                value={police?.isPolice}
                disabled={closeStatus === 0}
              >
                <Radio value={1}>Yes</Radio>
                <Radio value={2}>No</Radio>
              </Radio.Group>
            </Col>
            <Col xs={24} sm={12} md={8} lg={10} xl={16}>
              <TextArea
                onChange={(e) => {
                  setPolice((prev) => ({
                    ...prev,
                    policeNotes: e.target.value
                  }))
                  setErrors((prev) => ({
                    ...prev,
                    policeNotes: ""
                  }))
                }}
                status={errors?.policeNotes === "" ? "" : "error"}
                value={police?.policeNotes}
                disabled={closeStatus === 0}
                autoSize
              />
            </Col>

          </Row>
        </div>


        <div className="accident-box" style={{ marginTop: '15px' }}>
          <span className="accident-box-heading">Amount Details</span>
          <Row style={{ padding: '4px' }} gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                disabled={closeStatus === 0}
                fullWidth
                id="amount"
                size="small"
                label="Claim Amount"
                variant="outlined"
                type="number"
                value={selectedAmount}
                onChange={(e) => {
                  setSelectedAmount(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    amount: '',
                  }))
                }}
                error={errors?.amount === "" ? false : true}
                helperText={errors?.amount}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Autocomplete
                id="paid-type"
                disabled={closeStatus === 0}
                options={PaidBy}
                getOptionLabel={(option) => option.title}
                value={selectedPaidBy}
                onChange={(e, values) => {
                  setSelectedPaidBy(values);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    paidBy: '',
                  }))
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Claim Paid By"
                    placeholder="Claim Paid By"
                    size="small"
                    error={errors?.paidBy === "" ? false : true}
                    helperText={errors?.paidBy}
                  />
                )}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <TextField
                disabled={closeStatus === 0}
                fullWidth
                id="exAmount"
                size="small"
                label="Excess Amount"
                variant="outlined"
                type="number"
                value={selectedExAmount}
                onChange={(e) => {
                  setSelectedExAmount(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    exAmount: '',
                  }))
                }}
                error={errors?.exAmount === "" ? false : true}
                helperText={errors?.exAmount}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <Autocomplete
                id="paid-type"
                disabled={closeStatus === 0}
                options={ExcessPaidBy}
                getOptionLabel={(option) => option.title}
                value={selectedExPaidBy}
                onChange={(e, values) => {
                  setSelectedExPaidBy(values);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    exPaidBy: '',
                  }))
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Excess Paid By"
                    placeholder="Excess Paid By"
                    size="small"
                    error={errors?.exPaidBy === "" ? false : true}
                    helperText={errors?.exPaidBy}
                  />
                )}
              />
            </Col>
          </Row>
        </div>
        <div className="accident-box" style={{ marginTop: '15px' }}>
          <span className="accident-box-heading">Notes</span>
          <div>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  label="Notes"
                  placeholder="Enter Any Specific Notes"
                  onChange={(e) => {
                    setNotes(e.target.value)
                    setErrors((prev) => ({
                      ...prev,
                      notes: ""
                    }))
                  }}
                  status={errors?.notes === "" ? "" : "error"}
                  value={notes}
                  disabled={closeStatus === 0}
                />
              </Col>
            </Row>
          </div>
        </div>

        <div className="accident-box" style={{ marginTop: '15px' }}>
          <span className="accident-box-heading">Files</span>
          <div>
          <UploadButton
            component="label"
            variant="contained"
            sx={{ marginTop: "10px" }}
            startIcon={<CloudUploadIcon />}
          >
            Add files
            <input
              type="file"
              hidden
              multiple
              capture
              // onChange={(e) => {
              //   handleSubmitImage(e);
              // }}
              onChange={(e) => {
                const myfiles = e.target.files;
            
                if (myfiles.length > 10) {
                  message.error("A maximum of 10 images can be uploaded at a time.");
                  e.target.value = "";
                } else {
                  handleSubmitImage(e);
                }
              }}
            />
          </UploadButton>
        </div>
        {currentItemFiles?.length > 0 && <h4>Current Files:</h4>}
        <ul>
                  {currentItemFiles?.length > 0 &&
                    currentItemFiles
                      .filter(
                        (item) =>
                          !files.some((file) => file.name === item)
                      )
                      .map((item) => {
                        const lastHyphenIndex = item.lastIndexOf("-");
                        const dotBeforeExtensionIndex = item.lastIndexOf(".");
                        const newFileName =
                          item.slice(0, lastHyphenIndex) +
                          item.slice(dotBeforeExtensionIndex);
                      const encodedFileName = item.replace(/\+/g, '%2B');

                        return (
                          <li>
                            {
                              closeStatus != 0 && (
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={() => handleDeleteCurrentFile(item)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )
                            }

                            <a
                              href={`${config.tms_uploads_cdn}/${config.env}/accidents/${id}/${item}`}
                              target="_blank"
                            >
                              {extractOriginalFileName(encodedFileName)}
                            </a>
                          </li>
                        );
                      })}
                </ul>
        {/* <Image.PreviewGroup>
          {currentItemFiles?.length > 0 &&
            currentItemFiles
              .filter((item) => !files.some((file) => file.name === item))
              .map((item) => {
                const imageUrl = `${config.tms_uploads_cdn}/${config.env}/accidents/${id}/${item}`;
                return (
                  <div
                    style={{
                      position: "relative",
                      marginRight: "10px",
                      display: "inline-block",
                    }}
                  >
                    <Image
                      width={200}
                      height={150}
                      style={{ padding: "5px", border: "1px solid lightgrey" }}
                      src={imageUrl}
                    />
                    <Tooltip title="Delete">
                        <CloseOutlined
                          style={{
                            fontSize: "30px",
                            position: "absolute",
                            right: 5,
                            top: 5,
                            color: "#c12828",
                          }}
                          onClick={() => handleDeleteCurrentFile(item)}
                        />
                      </Tooltip>
                  </div>
                );
              })}
        </Image.PreviewGroup> */}
        <Divider />
        {files?.length > 0 && <h4>New Files:</h4>}
                <ul>
                  {files.length > 0 &&
                    files.map((files) => {
                      const encodedFileName = files?.name.replace(/\+/g, '%2B');
                      return (
                        <li>
                          {" "}
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => deleteNormalFiles(files.name)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {extractOriginalFileName(encodedFileName)}
                        </li>
                      );
                    })}
                </ul>
        {/* <Image.PreviewGroup>
          {files?.length > 0 &&
            files.map((files) => {
              return (
                <div
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    display: "inline-block",
                  }}
                >
                  <Image
                    width={200}
                    height={150}
                    style={{ padding: "5px", border: "1px solid lightgrey" }}
                    src={URL.createObjectURL(files.file)}
                  />
                  <Tooltip title="Delete">
                    <CloseOutlined
                      style={{
                        fontSize: "30px",
                        position: "absolute",
                        right: 5,
                        top: 5,
                        color: "#c12828",
                      }}
                      onClick={() => deleteNormalFiles(files.name)}
                    />
                  </Tooltip>
                </div>
              );
            })}
        </Image.PreviewGroup> */}
        </div>


        <Badge.Ribbon text={informationExchanged?.completed == 1 ? "Completed" : "Incomplete"} color={informationExchanged?.completed == 1 ? "green" : "red"}>
          <div className="accident-box" style={{ marginTop: '15px' }}>
            <span className="accident-box-heading">Information Exchanged</span>
            <div className={classes.dataSheet}>
              <div>
                <Row gutter={[16, 4]}>
                  <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <h5 style={{ margin: "1px 0px" }}>Date <span style={{ color: "red", fontSize: "10px" }}>
                      *
                    </span></h5>
                    <AntDate
                      format={'YYYY-MM-DD'}
                      style={{ width: '100%' }}
                      disabled={closeStatus === 0}
                      value={informationExchanged?.date ? dayjs(informationExchanged?.date) : null}
                      onChange={(_, dateStr) => {
                        setInformationExchanged((prevDetails) => ({
                          ...prevDetails,
                          date: dateStr
                        }))
                      }}
                      placeholder="Select date"
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <h5 style={{ margin: "1px 0px" }}>Notes </h5>
                    <TextArea autoSize
                      name="notes"
                      disabled={closeStatus === 0}
                      value={informationExchanged?.notes}
                      onChange={(e) => {
                        setInformationExchanged((prevDetails) => ({
                          ...prevDetails,
                          notes: e.target.value
                        }))
                      }}
                      placeholder="Notes"
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <h5 style={{ margin: "1px 0px" }}>Completed </h5>
                    <Select
                      style={{
                        width: "100%",
                      }}
                      value={informationExchanged?.completed}
                      placeholder="Completed ? YES/NO"
                      onChange={(value) => {
                        setInformationExchanged((prev) => ({
                          ...prev,
                          completed: value
                        }))
                      }}
                    >
                      {completedOptions.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.title}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  {
                    closeStatus !== 0 && (
                      <Col>
                        <div>
                          <UploadButton
                            component="label"
                            variant="contained"
                            sx={{ marginTop: "15px" }}
                            startIcon={<CloudUploadIcon />}
                          >
                            Add files
                            <input
                              type="file"
                              hidden
                              multiple
                              // onChange={(e) => {
                              //   handleSubmitImageMulti(e, 'info');
                              // }}
                              onChange={(e) => {
                                            const myfiles = e.target.files;
                                        
                                            if (myfiles.length > 10) {
                                              message.error("A maximum of 10 images can be uploaded at a time.");
                                              e.target.value = "";
                                            } else {
                                              handleSubmitImageMulti(e, 'info');
                                            }
                                          }}
                            />
                          </UploadButton>
                        </div>
                      </Col>
                    )
                  }
                </Row>
                {informationExchanged?.files?.length > 0 && <h4>Documents:</h4>}
                <ul>
                  {informationExchanged?.files?.length > 0 &&
                    informationExchanged?.files
                      .filter(
                        (item) =>
                          !informationExchanged?.newFiles.some((file) => file.name === item)
                      )
                      .map((item) => {
                        const lastHyphenIndex = item.lastIndexOf("-");
                        const dotBeforeExtensionIndex = item.lastIndexOf(".");
                        const newFileName =
                          item.slice(0, lastHyphenIndex) +
                          item.slice(dotBeforeExtensionIndex);
                        return (
                          <li>
                            {
                              closeStatus != 0 && (
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={() => handleDeleteFile('info', item)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )
                            }

                            <a
                              href={`${config.tms_uploads_cdn}/${config.env}/accidents/${id}/${item}`}
                              target="_blank"
                            >
                              {newFileName}
                            </a>
                          </li>
                        );
                      })}
                </ul>

                {informationExchanged?.newFiles?.length > 0 && <h4>New Documents:</h4>}
                <ul>
                  {informationExchanged?.newFiles?.length > 0 &&
                    informationExchanged?.newFiles.map((files) => {
                      return (
                        <li>
                          {" "}
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => handleDeleteNewFile('info', files.name)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {files.name}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </Badge.Ribbon>
        <Badge.Ribbon text={liabilityAccepted?.completed == 1 ? "Completed" : "Incomplete"} color={liabilityAccepted?.completed == 1 ? "green" : "red"}>
          <div className="accident-box" style={{ marginTop: '15px' }}>
            <span className="accident-box-heading">Liability Accepted</span>
            <div className={classes.dataSheet}>
              <div>
                <Row gutter={[16, 4]}>
                  <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <h5 style={{ margin: "1px 0px" }}>Date </h5>
                    <AntDate
                      format={'YYYY-MM-DD'}
                      style={{ width: '100%' }}
                      disabled={closeStatus === 0}
                      value={liabilityAccepted?.date ? dayjs(liabilityAccepted?.date) : null}
                      onChange={(_, dateStr) => {
                        setLiabilityAccepted((prevDetails) => ({
                          ...prevDetails,
                          date: dateStr
                        }))
                      }}
                      placeholder="Select date"
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <h5 style={{ margin: "1px 0px" }}>Notes </h5>
                    <TextArea autoSize
                      name="notes"
                      disabled={closeStatus === 0}
                      value={liabilityAccepted?.notes}
                      onChange={(e) => {
                        setLiabilityAccepted((prevDetails) => ({
                          ...prevDetails,
                          notes: e.target.value
                        }))
                      }}
                      placeholder="Notes"
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <h5 style={{ margin: "1px 0px" }}>Completed </h5>
                    <Select
                      style={{
                        width: "100%",
                      }}
                      value={liabilityAccepted?.completed}
                      placeholder="Completed ? YES/NO"
                      onChange={(value) => {
                        setLiabilityAccepted((prev) => ({
                          ...prev,
                          completed: value
                        }))
                      }}
                    >
                      {completedOptions.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.title}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  {
                    closeStatus !== 0 && (
                      <Col>
                        <div>
                          <UploadButton
                            component="label"
                            variant="contained"
                            sx={{ marginTop: "15px" }}
                            startIcon={<CloudUploadIcon />}
                          >
                            Add files
                            <input
                              type="file"
                              hidden
                              multiple
                              // onChange={(e) => {
                              //   handleSubmitImageMulti(e, 'lib');
                              // }}
                              onChange={(e) => {
                                            const myfiles = e.target.files;
                                        
                                            if (myfiles.length > 10) {
                                              message.error("A maximum of 10 images can be uploaded at a time.");
                                              e.target.value = "";
                                            } else {
                                              handleSubmitImageMulti(e, 'lib');
                                            }
                                          }}
                            />
                          </UploadButton>
                        </div>
                      </Col>
                    )
                  }
                </Row>
                {liabilityAccepted?.files?.length > 0 && <h4>Documents:</h4>}
                <ul>
                  {liabilityAccepted?.files?.length > 0 &&
                    liabilityAccepted?.files
                      .filter(
                        (item) =>
                          !liabilityAccepted?.newFiles.some((file) => file.name === item)
                      )
                      .map((item) => {
                        const lastHyphenIndex = item.lastIndexOf("-");
                        const dotBeforeExtensionIndex = item.lastIndexOf(".");
                        const newFileName =
                          item.slice(0, lastHyphenIndex) +
                          item.slice(dotBeforeExtensionIndex);
                        return (
                          <li>
                            {
                              closeStatus != 0 && (
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={() => handleDeleteFile('lib', item)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )
                            }

                            <a
                              href={`${config.tms_uploads_cdn}/${config.env}/accidents/${id}/${item}`}
                              target="_blank"
                            >
                              {newFileName}
                            </a>
                          </li>
                        );
                      })}
                </ul>

                {liabilityAccepted?.newFiles?.length > 0 && <h4>New Documents:</h4>}
                <ul>
                  {liabilityAccepted?.newFiles?.length > 0 &&
                    liabilityAccepted?.newFiles.map((files) => {
                      return (
                        <li>
                          {" "}
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => handleDeleteNewFile('lib', files.name)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {files.name}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </Badge.Ribbon>
        <Badge.Ribbon text={paymentSettled?.completed == 1 ? "Completed" : "Incomplete"} color={paymentSettled?.completed == 1 ? "green" : "red"}>
          <div className="accident-box" style={{ marginTop: '15px' }}>
            <span className="accident-box-heading">Payment Settled</span>
            <div className={classes.dataSheet}>
              <div>
                <Row gutter={[16, 4]}>
                  <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <h5 style={{ margin: "1px 0px" }}>Date</h5>
                    <AntDate
                      format={'YYYY-MM-DD'}
                      style={{ width: '100%' }}
                      disabled={closeStatus === 0}
                      value={paymentSettled?.date ? dayjs(paymentSettled?.date) : null}
                      onChange={(_, dateStr) => {
                        setPaymentSettled((prevDetails) => ({
                          ...prevDetails,
                          date: dateStr
                        }))
                      }}
                      placeholder="Select date"
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <h5 style={{ margin: "1px 0px" }}>Notes </h5>
                    <TextArea autoSize
                      name="notes"
                      disabled={closeStatus === 0}
                      value={paymentSettled?.notes}
                      onChange={(e) => {
                        setPaymentSettled((prevDetails) => ({
                          ...prevDetails,
                          notes: e.target.value
                        }))
                      }}
                      placeholder="Notes"
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <h5 style={{ margin: "1px 0px" }}>Completed </h5>
                    <Select
                      style={{
                        width: "100%",
                      }}
                      value={paymentSettled?.completed}
                      placeholder="Completed ? YES/NO"
                      onChange={(value) => {
                        setPaymentSettled((prev) => ({
                          ...prev,
                          completed: value
                        }))
                      }}
                    >
                      {completedOptions.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.title}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  {
                    closeStatus !== 0 && (
                      <Col>
                        <div>
                          <UploadButton
                            component="label"
                            variant="contained"
                            sx={{ marginTop: "15px" }}
                            startIcon={<CloudUploadIcon />}
                          >
                            Add files
                            <input
                              type="file"
                              hidden
                              multiple
                              // onChange={(e) => {
                              //   handleSubmitImageMulti(e, 'pay');
                              // }}
                              onChange={(e) => {
                                            const myfiles = e.target.files;
                                        
                                            if (myfiles.length > 10) {
                                              message.error("A maximum of 10 images can be uploaded at a time.");
                                              e.target.value = "";
                                            } else {
                                              handleSubmitImageMulti(e, 'pay');
                                            }
                                          }}
                            />
                          </UploadButton>
                        </div>
                      </Col>
                    )
                  }
                </Row>
                {paymentSettled?.files?.length > 0 && <h4>Documents:</h4>}
                <ul>
                  {paymentSettled?.files?.length > 0 &&
                    paymentSettled?.files
                      .filter(
                        (item) =>
                          !paymentSettled?.newFiles.some((file) => file.name === item)
                      )
                      .map((item) => {
                        const lastHyphenIndex = item.lastIndexOf("-");
                        const dotBeforeExtensionIndex = item.lastIndexOf(".");
                        const newFileName =
                          item.slice(0, lastHyphenIndex) +
                          item.slice(dotBeforeExtensionIndex);
                        return (
                          <li>
                            {
                              closeStatus != 0 && (
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={() => handleDeleteFile('pay', item)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )
                            }

                            <a
                              href={`${config.tms_uploads_cdn}/${config.env}/accidents/${id}/${item}`}
                              target="_blank"
                            >
                              {newFileName}
                            </a>
                          </li>
                        );
                      })}
                </ul>

                {paymentSettled?.newFiles?.length > 0 && <h4>New Documents:</h4>}
                <ul>
                  {paymentSettled?.newFiles?.length > 0 &&
                    paymentSettled?.newFiles.map((files) => {
                      return (
                        <li>
                          {" "}
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => handleDeleteNewFile('pay', files.name)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {files.name}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </Badge.Ribbon>
        <Badge.Ribbon text={closedCase?.completed == 1 ? "Completed" : "Incomplete"} color={closedCase?.completed == 1 ? "green" : "red"}>
          <div className="accident-box" style={{ marginTop: '15px' }}>
            <span className="accident-box-heading">Closed</span>
            <div className={classes.dataSheet}>
              <div>
                <Row gutter={[16, 4]}>
                  <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <h5 style={{ margin: "1px 0px" }}>Date </h5>
                    <AntDate
                      format={'YYYY-MM-DD'}
                      style={{ width: '100%' }}
                      disabled={closeStatus === 0}
                      value={closedCase?.date ? dayjs(closedCase?.date) : null}
                      onChange={(_, dateStr) => {
                        setClosedCase((prevDetails) => ({
                          ...prevDetails,
                          date: dateStr
                        }))
                      }}
                      placeholder="Select date"
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <h5 style={{ margin: "1px 0px" }}>Notes </h5>
                    <TextArea autoSize
                      name="notes"
                      disabled={closeStatus === 0}
                      value={closedCase?.notes}
                      onChange={(e) => {
                        setClosedCase((prevDetails) => ({
                          ...prevDetails,
                          notes: e.target.value
                        }))
                      }}
                      placeholder="Notes"
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <h5 style={{ margin: "1px 0px" }}>Completed</h5>
                    <Select
                      style={{
                        width: "100%",
                      }}
                      value={closedCase?.completed}
                      placeholder="Completed ? YES/NO"
                      onChange={(value) => {
                        setClosedCase((prev) => ({
                          ...prev,
                          completed: value
                        }))
                      }}
                    >
                      {completedOptions.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.title}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  {
                    closeStatus !== 0 && (
                      <Col>
                        <div>
                          <UploadButton
                            component="label"
                            variant="contained"
                            sx={{ marginTop: "15px" }}
                            startIcon={<CloudUploadIcon />}
                          >
                            Add files
                            <input
                              type="file"
                              hidden
                              multiple
                              // onChange={(e) => {
                              //   handleSubmitImageMulti(e, 'close');
                              // }}
                              onChange={(e) => {
                                            const myfiles = e.target.files;
                                        
                                            if (myfiles.length > 10) {
                                              message.error("A maximum of 10 images can be uploaded at a time.");
                                              e.target.value = "";
                                            } else {
                                              handleSubmitImageMulti(e, 'close');
                                            }
                                          }}
                            />
                          </UploadButton>
                        </div>
                      </Col>
                    )
                  }
                </Row>
                {closedCase?.files?.length > 0 && <h4>Documents:</h4>}
                <ul>
                  {closedCase?.files?.length > 0 &&
                    closedCase?.files
                      .filter(
                        (item) =>
                          !closedCase?.newFiles.some((file) => file.name === item)
                      )
                      .map((item) => {
                        const lastHyphenIndex = item.lastIndexOf("-");
                        const dotBeforeExtensionIndex = item.lastIndexOf(".");
                        const newFileName =
                          item.slice(0, lastHyphenIndex) +
                          item.slice(dotBeforeExtensionIndex);
                        return (
                          <li>
                            {
                              closeStatus != 0 && (
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={() => handleDeleteFile('close', item)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )
                            }

                            <a
                              href={`${config.tms_uploads_cdn}/${config.env}/accidents/${id}/${item}`}
                              target="_blank"
                            >
                              {newFileName}
                            </a>
                          </li>
                        );
                      })}
                </ul>

                {closedCase?.newFiles?.length > 0 && <h4>New Documents:</h4>}
                <ul>
                  {closedCase?.newFiles?.length > 0 &&
                    closedCase?.newFiles.map((files) => {
                      return (
                        <li>
                          {" "}
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => handleDeleteNewFile('close', files.name)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {files.name}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </Badge.Ribbon>

      </Grid>
      <Grid item xs={12}>
        <Box style={{ float: "right", marginTop: "40px" }}>
          {
            (id > 0 && closeStatus !== 0) && <FormButton
              type="lock"
              onClick={handleCloseAccident}
            >
              Close
            </FormButton>
          }
          <FormButton
            type="close"
            style={{ marginRight: "10px" }}
            onClick={() => {
              navigate("/accident");
            }}
          >
            Cancel
          </FormButton>
          {
            closeStatus !== 0 && (
              <FormButton
                type="save"
                onClick={handleSubmit}
              >
                Save
              </FormButton>
            )
          }
          {/* <FormButton type="save" onClick={handleSubmit}>
            Save
          </FormButton> */}
        </Box>
      </Grid>
      <Message type={type} msg={loadMessage} />
      <ActionDialog
        message={actionDialogProps.message}
        type={actionDialogProps.type}
        isOpen={actionDialogProps.isOpen}
        onClose={actionDialogProps.onClose}
        cancelText='Cancel'
        onSubmit={() => {
          actionDialogProps.onOk();
        }}
        onCancel={() =>
          setActionDialogProps((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
      />
    </div>
  );
};

export default Index;
