import React, { useState, useEffect } from "react";
import { Card, Col, Divider, Row, Statistic, Table, Switch, Button, Tag, Tooltip, Input, message, Modal, Select, DatePicker } from "antd";
import { makeStyles } from "@mui/styles";
import { CheckOutlined, CloseOutlined, DeleteFilled, EditOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import moment from "moment";
import { hideLoading, showLoading, useLoadingDispatch } from '../../context/loadingContext';
import { Pagination } from "@mui/material";
import { InventorySuppliers, issuePriorities, maintenanceSuppliers } from "../../Components/common/constants";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import { Autocomplete, Box, Grid, IconButton, TextField, Typography } from "@mui/material";

const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: "5px",
    
  },
}));

const PurchaseOrderList = () => {
  const classes = useStyles();
  const { Option } = Select
  var layoutDispatch = useLoadingDispatch();
  const navigate = useNavigate();
  const [loadMessage, setLoadMessage] = useState("");
  const [type, setType] = useState("");
  const [allPurchaseOrders, setAllPurchaseOrders] = useState([]);
  const [page, setPage] = React.useState(1);
  const [pageChange, setPageChange] = React.useState(false);
  const [limit] = React.useState(50);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);

   const currentDate = new Date();
      const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
      const [startDate, setStartDate] = useState(firstDayOfMonth);
      const [endDate, setEndDate] = useState(lastDayOfMonth);
      const [selectedText, setSelectedText] = useState("");
  

  const handleChange = (event, value) => {
    setPage(value);
    setPageChange(true)
  };

  const deletePurchaseOrder = async (id) => {
        showLoading(layoutDispatch);
        try {
          const res = await Auth.fetch(`/inventory/purchase-order`, {
            method: "DELETE",
            body: JSON.stringify({ id: id })
    
          });
          if (res?.ack) {
            getAllPurchaseOrders();
            message.success(res?.message);
          } else {
            message.error(res?.message);
          }
        } catch (err) {
          console.log(err);
          message.error(err?.message);
        } finally {
          hideLoading(layoutDispatch);
        }
      };

  const purchaseOrderColumns = [
    {
        title: 'Code',
        dataIndex: 'order_code',
        key: 'order_code',
    },
    {
            title: 'Order Date',
            render: (record)=> {
              return (
                <>
                <span>{ record.order_date ? moment(record.order_date, "YYYY-MM-DD").format("YYYY-MM-DD") : ''}</span>
              </>
              )
            }
          },
    {
          align: "center",
          title: "Supplier",
          render: ({ supplier_id }) => {
            const object = InventorySuppliers.find((obj) => obj.id == supplier_id);
            return (
              <span >
                {object?.title}
              </span>
            );
          },
        },
        {
          title: 'Action',
          key: 'action',
          align: 'right',
          render: (text, record) => (
            <div>
              {
                record.status == 1 && (
                  <EditOutlined
            style={{
              fontSize: "25px",
              cursor: "pointer",
              marginRight: "5px",
              color: "#006cb8",
            }} onClick={() => navigate(`/workshop_purchase_order/${record.id}`)}/>
                )
              }
    
            
            {
              record.status == 1 && (
                <DeleteFilled style={
                  {
                    fontSize: "25px",
                    cursor: "pointer",
                    color: "#ff0000",
                }} onClick={() => {
                  Modal.confirm({
                    title: 'Delete Purchase Order',
                    centered: true,
                    content: `Are you sure you want to delete this purchase Order?`,
                    onOk:()=> deletePurchaseOrder(record.id),
                    okText: 'Delete',
                    cancelText: 'Cancel',
                    width: 600,
                    okButtonProps: { size: "large"  },
                    cancelButtonProps: { size: "large" },
                  });
                }} 
                />
              )
            }
            
            </div>
            )
        },
    
  ];

  const getAllPurchaseOrders = async (download) => {
    let search = {};
    if (page && pageChange) search.page = page;
    if (startDate) search.start_date = moment(startDate).format("YYYY-MM-DD");
        if (endDate) search.end_date = moment(endDate).format("YYYY-MM-DD");
        if (selectedText) search.text = selectedText;

   if (download) search.download = 1;

    showLoading(layoutDispatch);
    try {
    const res = await Auth.fetch("/inventory/purchase-orders", {
        method: "POST",
        body: JSON.stringify(search),
      });
      if (res?.ack) {
       if (download == 1) {
           window.open(res.file_url, "_blank", "noreferrer");
         } else {
        setAllPurchaseOrders(res?.purchase_orders);
        setTotalPages(Math.ceil(res?.total_count / limit));
        setTotalCount(res.total_count);
         }
      } else {
        setAllPurchaseOrders([])
        message.error(res?.message)
      }
    } catch (err){
        console.log(err);
        message.error(err?.message)
    } finally {
        hideLoading(layoutDispatch);
    }
  };

  useEffect(() => {
            message.config({
              top: window.innerHeight - 100, 
              duration: 3, 
            });
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => getAllPurchaseOrders(), 1000);
    return () => clearTimeout(timeOutId);
  }, [
    page,
    startDate,
      endDate,
      selectedText
  ]);

  return (
    <div className={classes.dataSheet}>
        <div className="page-header">
        <h2>Purchase Order List</h2>
        <Button onClick={()=> navigate('/workshop_purchase_order/new')} type="primary" icon={<PlusOutlined />}>Add Purchase Order</Button>
        </div>
        <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                  <label htmlFor="pcnDate">Start Date</label>
                  <DatePicker
                       format={'YYYY-MM-DD'}
                       style={{ width: '100%'}}
                       size="large"
                       value={startDate ? dayjs(startDate) : null}
                       onChange={(_, dateStr) => {
                        setStartDate(dateStr);
                        setPageChange(false)
                        setPage(1)
                      }}
                      placeholder="Select Date" 
                       />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                  <label htmlFor="pcnDate">End Date</label>
                  <DatePicker
                       format={'YYYY-MM-DD'}
                       style={{ width: '100%'}}
                       size="large"
                       value={endDate ? dayjs(endDate) : null}
                       onChange={(_, dateStr) => {
                        setEndDate(dateStr);
                        setPageChange(false)
                        setPage(1)
                      }}
                      placeholder="Select Date" 
                       />
                </Grid>
                
                <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                  <label htmlFor="Status">Text</label>
                  
                  <Input 
                   placeholder="Search"
                   value={selectedText}
                   onChange={(e)=>{
                      setSelectedText(e.target.value);
                      setPageChange(false)
                     setPage(1)
                     }}
                     size="large"
                  />
                  
                </Grid>
              </Grid>
        <div className={classes.antTable}>
                <Table
                  columns={purchaseOrderColumns}
                  style={{ overflowX: "auto", marginTop: '10px' }}
                  pagination={false}
                  dataSource={allPurchaseOrders}
                  footer={() => {
                    const startRecord = (page - 1) * limit + 1;
                    const endRecord = Math.min(page * limit, allPurchaseOrders?.length ? totalCount : 0); 
                    return `Showing ${startRecord}-${endRecord} of ${allPurchaseOrders?.length ? totalCount : "0"} Records`;
                  }}
                />
              </div>
        
              <div style={{ float: "right", margin: "10px" }}>
                <Pagination
                  page={page}
                  count={totalPages}
                  variant="outlined"
                  color="primary"
                  onChange={handleChange}
                />
              </div>
      <Message type={type} msg={loadMessage} />
    </div>
  );
};

export default PurchaseOrderList;
