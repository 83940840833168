import React, { useEffect, useLayoutEffect, useState } from "react";
import { Card, Col, Divider, Row, Statistic, Table, Switch, Button, Tag, Tooltip, Input, message, Modal, DatePicker, Select } from "antd";
import { makeStyles } from "@mui/styles";
import { CheckOutlined, CloseOutlined, DeleteFilled, EditOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import moment from "moment";
import { hideLoading, showLoading, useLoadingDispatch } from '../../context/loadingContext';
import { Pagination } from "@mui/material";
import { issuePriorities } from "../../Components/common/constants";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Autocomplete, Box, Grid, IconButton, TextField, Typography } from "@mui/material";


const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: "5px",
    
  },
}));

const IssueList = () => {
  const classes = useStyles();
  const { Option } = Select
  var layoutDispatch = useLoadingDispatch();
  const navigate = useNavigate();
  const [loadMessage, setLoadMessage] = useState("");
  const [type, setType] = useState("");
  const [allIssues, setAllIssues] = useState([]);
  const [page, setPage] = React.useState(1);
  const [pageChange, setPageChange] = React.useState(false);
  const [limit] = React.useState(50);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);

  const [users, setUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);


    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    const [startDate, setStartDate] = useState(firstDayOfMonth);
    const [endDate, setEndDate] = useState(lastDayOfMonth);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedText, setSelectedText] = useState("");
    
  
    const getAllVehicles = async () => {
          showLoading(layoutDispatch);
          try {
            const response = await Auth.fetch(`/vehicle/vehicles`);
            if (response) {
              hideLoading(layoutDispatch);
              if (response?.ack) {
                setVehicles(response.vehicles);
              } else {
                setVehicles([])
              }
            }
          } catch (err) {
            console.log(err);
          } finally{
            hideLoading(layoutDispatch)
          }
        }
    
    const getAssignees = async () => {
      showLoading(layoutDispatch);
          try {
            const response = await Auth.fetch(`/issue/issues-assignees`);
            if (response) {
              hideLoading(layoutDispatch);
              if (response?.ack) {
                setUsers(response.issue_assignees);
              } else {
                setUsers([])
              }
            }
          } catch (err) {
            console.log(err);
          } finally{
            hideLoading(layoutDispatch)
          }
        }
  
      useLayoutEffect(() => {
        getAssignees();
        getAllVehicles();
      }, [])
  

  const handleChange = (event, value) => {
    setPage(value);
    setPageChange(true)
  };

  const getAllIssues = async (download) => {
    let search = {};
    if (page && pageChange) search.page = page;
    if (startDate) search.start_date = moment(startDate).format("YYYY-MM-DD");
    if (endDate) search.end_date = moment(endDate).format("YYYY-MM-DD");
    if (selectedVehicle) search.vehicle_id = selectedVehicle;
    if (selectedText) search.text = selectedText;

   if (download) search.download = 1;

    showLoading(layoutDispatch);
    try {
    const res = await Auth.fetch("/issue/issues", {
        method: "POST",
        body: JSON.stringify(search),
      });
      if (res?.ack) {
       if (download == 1) {
           window.open(res.file_url, "_blank", "noreferrer");
         } else {
        setAllIssues(res?.issues);
        setTotalPages(Math.ceil(res?.total_count / limit));
        setTotalCount(res.total_count);
         }
      } else {
        setAllIssues([])
        message.error(res?.message)
      }
    } catch (err){
        console.log(err);
        message.error(err?.message)
    } finally {
        hideLoading(layoutDispatch);
    }
  };

  useEffect(() => {
      const timeOutId = setTimeout(() => getAllIssues(), 1000);
      return () => clearTimeout(timeOutId);
    }, [
      page,
      startDate,
      endDate,
      selectedVehicle,
      selectedText
    ]);

  const deleteIssue = async (id) => {
      showLoading(layoutDispatch);
      try {
        const res = await Auth.fetch(`/issue/issue`, {
          method: "DELETE",
          body: JSON.stringify({ id: id })
  
        });
        if (res?.ack) {
          getAllIssues();
          message.success(res?.message);
        } else {
          message.error(res?.message);
        }
      } catch (err) {
        console.log(err);
        message.error(err?.message);
      } finally {
        hideLoading(layoutDispatch);
      }
    };

  const issueColumns = [
    {
      title: 'Code',
      dataIndex: 'issue_code',
      key: 'issue_code',
  },
    {
      title: 'Date',
      render: (record)=> {
        return (
          <>
          <span>{moment(record.issue_date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")}</span>
        </>
        )
       
      }
    },
    {
      title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (status) => (
            <Tag color={status == 1 ? 'green' : 'red'} key={status}>
              {status == 1 ? 'Active' : 'Inactive'}
            </Tag>
          ),
    },
    {
      align: "center",
      title: "Priority",
      render: ({ priority }) => {
        const object = issuePriorities.find((obj) => obj.id === priority);
        return (
          <span >
            {object?.title}
          </span>
        );
      },
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Reported To',
        render: ({ assigned_to }) => {
          const object = users?.find((obj) => obj.id === assigned_to);
          return (
            <span >
              {`${object?.first_name || ""} ${object?.last_name || ""}`}
            </span>
          );
        },
    },
    {
      title: 'Vehicle',
      render: ({ vehicle_id }) => {
        const object = vehicles?.find((obj) => obj.id === vehicle_id);
        return (
          <span >
            {`${object?.registration_number || ""}`}
          </span>
        );
      },
  },
    {
        title: 'Due Date',
        render: (record)=> {
          return (
            <>
            <span>{ record.issue_due_date ? moment(record.issue_due_date, "YYYY-MM-DD").format("YYYY-MM-DD") : ''}</span>
          </>
          )
         
        }
      },
      {
        title: 'Completion Date',
        render: (record)=> {
          return (
            <>
            <span>{ record.issue_completion_date ? moment(record.issue_completion_date, "YYYY-MM-DD").format("YYYY-MM-DD") : ''}</span>
          </>
          )
        }
      },
      {
        title: 'Action',
        key: 'action',
        align: 'right',
        render: (text, record) => (
          <div>
            {
              record.status == 1 && (
                <EditOutlined
          style={{
            fontSize: "25px",
            cursor: "pointer",
            marginRight: "5px",
            color: "#006cb8",
          }} onClick={() => navigate(`/workshop_issues/${record.id}`)}/>
              )
            }
  
          
          {
            record.status == 1 && (
              <DeleteFilled style={
                {
                  fontSize: "25px",
                  cursor: "pointer",
                  color: "#ff0000",
              }} onClick={() => {
                Modal.confirm({
                  title: 'Delete Issue',
                  centered: true,
                  content: `Are you sure you want to delete this issue?`,
                  onOk:()=> deleteIssue(record.id),
                  okText: 'Delete',
                  cancelText: 'Cancel',
                  width: 600,
                  okButtonProps: { size: "large"  },
                  cancelButtonProps: { size: "large" },
                });
              }} 
              />
            )
          }
          
          </div>
          )
      },
  ];

   useEffect(() => {
            message.config({
              top: window.innerHeight - 100, 
              duration: 3, 
            });
  }, []);

  return (
    <div className={classes.dataSheet}>
        <div className="page-header">
        <h2>Issue List</h2>
        <Button onClick={()=> navigate('/workshop_issues/new')} type="primary" icon={<PlusOutlined />}>Add Issue</Button>
        </div>
        <Grid container rowSpacing={2} columnSpacing={1.5}>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <label htmlFor="pcnDate">Start Date</label>
          <DatePicker
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={startDate ? dayjs(startDate) : null}
               onChange={(_, dateStr) => {
                setStartDate(dateStr);
                setPageChange(false)
                setPage(1)
              }}
              placeholder="Select Date" 
               />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <label htmlFor="pcnDate">End Date</label>
          <DatePicker
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={endDate ? dayjs(endDate) : null}
               onChange={(_, dateStr) => {
                setEndDate(dateStr);
                setPageChange(false)
                setPage(1)
              }}
              placeholder="Select Date" 
               />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <label htmlFor="route">Vehicles</label>
          <Select
              style={{
                width: "100%",
              }}
              showSearch
              size="large"
              value={selectedVehicle}
              placeholder="Please Select"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                setSelectedVehicle(value)
                setPageChange(false)
                setPage(1)
              }}
            >
              {vehicles.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item?.registration_number}
                </Option>
              ))}
            </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <label htmlFor="Status">Text</label>
          
          <Input 
           placeholder="Search"
           value={selectedText}
           onChange={(e)=>{
              setSelectedText(e.target.value);
              setPageChange(false)
             setPage(1)
             }}
             size="large"
          />
          
        </Grid>
      </Grid>
        <div className={classes.antTable}>
                <Table
                  columns={issueColumns}
                  style={{ overflowX: "auto", marginTop: '10px' }}
                  pagination={false}
                  dataSource={allIssues?.filter((item) => item.status == 1)}
                  footer={() => {
                    const startRecord = (page - 1) * limit + 1;
                    const endRecord = Math.min(page * limit, allIssues.length ? totalCount : 0); 
                    return `Showing ${startRecord}-${endRecord} of ${allIssues.length ? totalCount : "0"} Records`;
                  }}
                />
              </div>
        
              <div style={{ float: "right", margin: "10px" }}>
                <Pagination
                  page={page}
                  count={totalPages}
                  variant="outlined"
                  color="primary"
                  onChange={handleChange}
                />
              </div>
      <Message type={type} msg={loadMessage} />
    </div>
  );
};

export default IssueList;
