import React, { useEffect, useState } from "react";
import { Button, DatePicker, Divider, Input, message, Modal, Select, Tabs, Row, Col, Tooltip, Image, Alert } from "antd";
import AuthService from "../auth/AuthService";
import config, { tms_uploads_cdn } from "../config";
import { BgColorsOutlined, DownloadOutlined } from "@ant-design/icons";
import { completeStatuses, HelperReminderTasks, vehicleServiceTasks } from "../common/constants";
import dayjs from "dayjs";
import qs from "query-string";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button as UploadButton, IconButton } from "@mui/material";
import { CloseOutlined } from "@ant-design/icons";
import { parse } from "papaparse";
import moment from "moment";
import { hideLoading, showLoading, useLoadingDispatch } from "../../context/loadingContext";
import DeleteIcon from "@mui/icons-material/Delete";

const Auth = new AuthService();

const EditHelperReminderModal = ({ isModalOpen, setIsModalOpen, allHelpers, selectedRecord, setSelectedRecord, searchFunction }) => {
  const { Option } = Select;
  const { TextArea } = Input;
  var layoutDispatch = useLoadingDispatch();

  const [selectedHelper, setSelectedHelper] = React.useState(null);
  const [selectedTask, setSelectedTask] = React.useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [taskCompleted, setTaskCompleted] = React.useState(null);
  const [record, setRecord] = React.useState(selectedRecord);
  const [notes, setNotes] = React.useState("");

  const [files, setFiles] = useState([]);
  const [currentItemFiles, setCurrentItemFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = React.useState([]);

  console.log('selectedRecord', selectedRecord);

  useEffect(()=>{
    if(selectedRecord) {
      setCurrentItemFiles(selectedRecord?.files ? JSON.parse(selectedRecord?.files) : [])
      setNotes(selectedRecord?.notes || '')
      setSelectedHelper(selectedRecord?.helper_id);
      setTaskCompleted(selectedRecord?.status)
      setSelectedTask(selectedRecord?.type)

      setDueDate(selectedRecord?.due_date ? new Date(selectedRecord?.due_date) : null)
    }
  },[selectedRecord])


  const handleCancel = () => {
    setIsModalOpen(false);
    resetState()
  };

  const handleOk = async () => {
    const isEmptyOrUndefined = (value) => value === '' || value === null || value === undefined;
    if(isEmptyOrUndefined(selectedHelper)) {
    message.error('Please select vehicle')
    return;
    }
    if(isEmptyOrUndefined(selectedTask)) {
      message.error('Please select service task')
      return;
    }
    if(isEmptyOrUndefined(dueDate)) {
      message.error('Please select due date')
      return;
    }
    showLoading(layoutDispatch);
    const dataToSend = {
      helper_id : selectedHelper, 
      due_date: dueDate ? moment(dueDate).format("YYYY-MM-DD") : null,
      type:selectedTask,
      notes: notes,
      files: currentItemFiles,
      // status: taskCompleted,
      id: selectedRecord?.id
    }
    try {
      const res = await Auth.fetch("/reminder/reminder-helper", {
        method: "PUT",
        body: JSON.stringify(dataToSend),
      })
      if(res?.ack) {
        console.log(res);
        const uploadPromises = []
        if (files.length > 0) {
          files.forEach((f) => {
            uploadPromises.push(
              getUploadUrl(f, selectedRecord?.id)
                .then(uploadUrl => uploadFileToS3(uploadUrl, f.file, f.file.type))
                .catch(error => console.error("Error uploading file:", error))
            );
          });
        }
        await Promise.all(uploadPromises);

        message.success(res?.message)
        setIsModalOpen(false);
        searchFunction()
      } else {
        message.error(res?.message)
      }

    } catch (err) {
      console.log(err);
    } 
    finally {
      hideLoading(layoutDispatch);
    }
  };

  const handleSubmitImage = (e) => {
    e.preventDefault();
    let acceptedFiles = e.target.files;

    for (let file of acceptedFiles) {
      const randomString = Math.random().toString(36).substring(2, 10);
      const newFileName = `${file.name.split(".")[0]}-${randomString}.${
        file.name.split(".")[1]
      }`;
      setFiles((prevFiles) => [
        ...prevFiles,
        {
          name: newFileName,
          display_name: newFileName,
          file: file,
          ContentType: file.type,
        },
      ]);
      setCurrentItemFiles((prevFiles) => [...prevFiles, newFileName]);
    }
  };

  const handleDeleteFile = (fileName) => {
    const updatedFiles = files?.filter((file) => file.name !== fileName);
    setFiles(updatedFiles);
    setCurrentItemFiles(currentItemFiles?.filter((item) => item !== fileName));
  };

  const handleDeleteCurrentFile = (itemToDelete) => {
    setCurrentItemFiles(
      currentItemFiles.filter((item) => item !== itemToDelete)
    );
    if (!deletedFiles.includes(itemToDelete)) {
      setDeletedFiles((prevDeletedFiles) => [
        ...prevDeletedFiles,
        itemToDelete,
      ]);
    }
  };

  const getUploadUrl = async (file, recordID) => {
    const params = {
      bucket: config.tms_uploads_bucket,
      ContentType: file.file.type,
      Key: config.env + "/reminders-helper/" + recordID + "/" + file.name,
    };
    const response = await fetch(
      `${config.domain}/generate-put-url?${qs.stringify(params)}`,
      {
        headers: {
          "Content-Type": params.ContentType,
        },
      }
    );
    return response.json();
  };

  const uploadFileToS3 = async (uploadUrl, file, contentType) => {
    const response = await fetch(uploadUrl.putURL, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": contentType,
      },
    });
    if (response.status !== 200) {
      throw new Error("File upload failed.");
    }
  };

  const resetState = () => {
    setNotes('');
    setFiles([]);
    setCurrentItemFiles([]);
    setDeletedFiles([]);
    setSelectedHelper(null);
    setSelectedTask(null);
    setDueDate(null)
    setTaskCompleted(null)
    setSelectedRecord("")
  };

  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day');
  };

  useEffect(() => {
    message.config({
      top: window.innerHeight - 100, 
      duration: 3, 
    });
  }, [])

  return (
    <>
      <Modal
        width={800}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        title={"Edit Helper Reminder "}
        footer={[
          <>
                <Button
                  onClick={handleCancel}
                >
                  {"Cancel"}
                </Button>
                <Button
                  type="primary"
                  onClick={handleOk}
                >
                  {"Update"}
                </Button>
          </>,
        ]}
      >
        <>
        <Divider />
        <>
        <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Helper<span style={{ color: "red" }}>*</span>
            </span>
            <Select
              style={{
                width: "100%",
              }}
              disabled
              value={selectedHelper}
              placeholder="Please Select"
              onChange={(value) => {
                setSelectedHelper(value);
              }}
            >
              {allHelpers.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.first_name}
                </Option>
              ))}
            </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Task<span style={{ color: "red" }}>*</span>
            </span>
            <Select
              style={{
                width: "100%",
              }}
              disabled
              value={selectedTask}
              placeholder="Please Select"
              onChange={(value) => {
                setSelectedTask(value);
              }}
            >
              {HelperReminderTasks.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>
              Due Date<span style={{ color: "red", fontSize: "15px" }}>*</span>:
            </span>
            <DatePicker
              format={"YYYY-MM-DD"}
              value={dueDate ? dayjs(dueDate) : null}
              onChange={(_, dateStr) => {
                setDueDate(dateStr);
              }}
              style={{
                width: "100%",
              }}
              placeholder="Select date"
              disabledDate={disabledDate}
            />
            </Col>
            
            {/* <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <span>Completed</span>
        <Select
        style={{
          width: "100%",
        }}
        value={taskCompleted}
        placeholder="Please Select"
        onChange={(value) => {
          setTaskCompleted(value)
        }}
      >
        {completeStatuses.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.title}
          </Option>
        ))}
      </Select>
            </Col> */}
          </Row>
          <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <span>
              Notes:
            </span>
                <TextArea value={notes} onChange={(e)=> setNotes(e.target.value)} autoSize={{ minRows: 3, maxRows: 6 }} />
            </Col>
          </Row>
        </>
        
        <>
          <div>
        <UploadButton
          component="label"
          variant="contained"
          sx={{ marginTop: "10px" }}
          startIcon={<CloudUploadIcon />}
        >
          Add files
          <input
            type="file"
            hidden
            multiple
            capture
            // onChange={(e) => {
            //   handleSubmitImage(e);
            // }}
            onChange={(e) => {
                          const myfiles = e.target.files;
                          if (myfiles.length > 10) {
                            message.error("A maximum of 10 images can be uploaded at a time.");
                            e.target.value = "";
                          } else {
                            handleSubmitImage(e);
                          }
                        }}
          />
        </UploadButton>
      </div>
      {currentItemFiles?.length > 0 && <h4>Current Files:</h4>}
      <ul>
                  {currentItemFiles?.length > 0 &&
                    currentItemFiles
                      .filter(
                        (item) =>
                          !files.some((file) => file.name === item)
                      )
                      .map((item) => {
                        const lastHyphenIndex = item.lastIndexOf("-");
                        const dotBeforeExtensionIndex = item.lastIndexOf(".");
                        const newFileName =
                          item.slice(0, lastHyphenIndex) +
                          item.slice(dotBeforeExtensionIndex);
                        return (
                          <li>
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={() => handleDeleteCurrentFile(item)}
                                >
                                  <DeleteIcon />
                                </IconButton>

                            <a
                              href={`${config.tms_uploads_cdn}/${config.env}/reminders-helper/${selectedRecord?.id}/${item}`}
                              target="_blank"
                            >
                              {newFileName}
                            </a>
                          </li>
                        );
                      })}
                </ul>
      {/* <Image.PreviewGroup>
        {currentItemFiles?.length > 0 &&
          currentItemFiles
            .filter((item) => !files.some((file) => file.name === item))
            .map((item) => {
              const imageUrl = `${config.tms_uploads_cdn}/${config.env}/reminders-helper/${selectedRecord?.id}/${item}`;
              return (
                <div
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    display: "inline-block",
                  }}
                >
                  <Image
                    width={200}
                    height={150}
                    style={{ padding: "5px", border: "1px solid lightgrey" }}
                    src={imageUrl}
                  />
                  <Tooltip title="Delete">
                    <CloseOutlined
                      style={{
                        fontSize: "30px",
                        position: "absolute",
                        right: 5,
                        top: 5,
                        color: "#c12828",
                      }}
                      onClick={() => handleDeleteCurrentFile(item)}
                    />
                  </Tooltip>
                  
                </div>
              );
            })}
      </Image.PreviewGroup> */}
        <Divider />
      {files?.length > 0 && <h4>Files:</h4>}
      <ul>
                  {files.length > 0 &&
                    files.map((files) => {
                      return (
                        <li>
                          {" "}
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => handleDeleteFile(files.name)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {files.name}
                        </li>
                      );
                    })}
                </ul>
      {/* <Image.PreviewGroup>
        {files?.length > 0 &&
          files.map((files) => {
            return (
              <div
                style={{
                  position: "relative",
                  marginRight: "10px",
                  display: "inline-block",
                }}
              >
                <Image
                  width={200}
                  height={150}
                  style={{ padding: "5px", border: "1px solid lightgrey" }}
                  src={URL.createObjectURL(files.file)}
                />
                <Tooltip title="Delete">
                  <CloseOutlined
                    style={{
                      fontSize: "30px",
                      position: "absolute",
                      right: 5,
                      top: 5,
                      color: "#c12828",
                    }}
                    onClick={() => handleDeleteFile(files.name)}
                  />
                </Tooltip>
              </div>
            );
          })}
      </Image.PreviewGroup> */}
        {/* <Alert message="Informational Notes" type="info" showIcon /> */}
            
          </>
        </>
          
      </Modal>
    </>
  );
};

export default EditHelperReminderModal;
