import * as React from "react";
import { Grid, TextField } from "@mui/material";
import AuthService from "../../Components/auth/AuthService";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, margin } from "@mui/system";
import Message from "../../Components/common/Message";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import {
  statuses,
  userTypes,
  userRolesTypes,
  selectYesNo,
} from "../../Components/common/constants";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import { FormButton } from "../../Components/UiElements/UiElements";
import { Checkbox, Col, Row } from "antd";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => ({
  dataSheet: {
   "& .large-checkbox .ant-checkbox-wrapper": {
      display: "flex",
      alignItems: "center",
    },
    "& .large-checkbox .ant-checkbox-inner": {
      width: '36px',
      height: '36px',
    },
    
    "& .large-checkbox .ant-checkbox-inner::after": {
      width: "12px",
      height: "24px"
    },
    
    "& .large-checkbox .ant-checkbox": {
      fontSize: "16px",
    },
    "& .large-checkbox": {
      marginTop: "16px",
      alignItems: "center",
    }
  },
}));

const Auth = new AuthService();

export default function User() {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();

  var layoutDispatch = useLoadingDispatch();

  const [id, setId] = React.useState(params.id ? params.id : 0);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [userType, setUserType] = React.useState({ id: 1, title: "Admin" });
  const [userStatus, setUserStatus] = React.useState({
    id: "1",
    title: "Active",
  });
  const [payAuthRole, setPayAuthRole] = React.useState(null);
  const [reminderEmailRole, setReminderEmailRole] = React.useState(null);
  const [inventoryEmailRole, setInventoryEmailRole] = React.useState(null);

  const [userRole, setUserRole] = React.useState();
  const [firstNameError, setFirstNameError] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [userTypeError, setUserTypeError] = React.useState("");
  const [userStatusError, setUserStatusError] = React.useState("");
  // const [userPasswordError, setUserPasswordError] = React.useState("");

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [isPaymentAuthorizer, setIsPaymentAuthorizer] = React.useState(false);
  const [isReminderEmailReceiver, setIsReminderEmailReceiver] = React.useState(false);
  const [isInventoryEmailReceiver, setIsInventoryEmailReceiver] = React.useState(false);
  const [isIssueAssignee, setisIssueAssignee] = React.useState(false);

  const handlePaymentAuthorizerChange = (event) => {
    setIsPaymentAuthorizer(event.target.checked);
  };

  const handleReminderEmailReceiverChange = (event) => {
    setIsReminderEmailReceiver(event.target.checked);
  };

  const handleInventoryEmailReceiverChange = (event) => {
    setIsInventoryEmailReceiver(event.target.checked);
  };

  const handleIssueAssigneeChange = (event) => {
    setisIssueAssignee(event.target.checked) 
  };

  React.useEffect(() => {
    if (id > 0) {
      showLoading(layoutDispatch);
      Auth.fetch(`/user/user?id=${id}`)
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack) {
            setFirstName(res.user.first_name);
            setLastName(res.user.last_name);
            setPhone(res.user.phone);
            setEmail(res.user.email);
            const userRoles1 = userTypes.find((obj) => obj.id == res.user.type);
            setUserType(userRoles1);

            const selectedAuthorizer = selectYesNo.find((obj) => obj.id == res.user.payment_authorizer);
            setPayAuthRole(selectedAuthorizer);
            setIsPaymentAuthorizer(res.user.payment_authorizer == 1 ? true : false);

            const selectedEmailReminder = selectYesNo.find((obj) => obj.id == res.user.reminder_email_receiver);
            setReminderEmailRole(selectedEmailReminder);
            setIsReminderEmailReceiver(res.user.reminder_email_receiver == 1 ? true : false);

            const selectedEmailInventory = selectYesNo.find((obj) => obj.id == res.user.inventory_adjustment_email_receiver);
            setInventoryEmailRole(selectedEmailInventory);
            setIsInventoryEmailReceiver(res.user.inventory_adjustment_email_receiver == 1 ? true : false);
            setisIssueAssignee(res.user.issue_assignee == 1 ? true : false);

            const userStatus1 = statuses.find(
              (obj) => obj.id == res.user.status
            );
            setUserStatus(userStatus1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const onClickCancel = () => {
    navigate("/users");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (!(id > 0)) {
    //   if (code === "") {
    //     setCodeError("Required");
    //     return;
    //   } else {
    //     setCodeError("");
    //   }
    // }

    if (firstName === "") {
      setFirstNameError("Required");
      return;
    } else {
      setFirstNameError("");
    }

    if (lastName === "") {
      setLastNameError("Required");
      return;
    } else {
      setLastNameError("");
    }

    if (email === "") {
      setEmailError("Required");
      return;
    } else {
      setEmailError("");
    }

    if (phone === "") {
      setPhoneError("Required");
      return;
    } else {
      setPhoneError("");
    }

    if (
      userType == undefined ||
      userType.id == undefined ||
      userType.id == ""
    ) {
      setUserTypeError("Required");
      return;
    } else {
      setUserTypeError("");
    }

    if (
      userStatus == undefined ||
      userStatus.id == undefined ||
      userStatus.id == ""
    ) {
      setUserStatusError("Required");
      return;
    } else {
      setUserStatusError("");
    }

    if (id > 0) {
      showLoading(layoutDispatch);
      const data = {
        first_name: firstName,
        last_name: lastName,
        phone: phone,
        type: userType.id,
        status: userStatus.id,
        email: email,
        id: id,
        password: password,
        // payment_authorizer: payAuthRole?.id,
        payment_authorizer: isPaymentAuthorizer ? 1 : 0,
        reminder_email_receiver: isReminderEmailReceiver ? 1 : 0,
        inventory_adjustment_email_receiver: isInventoryEmailReceiver ? 1 : 0,
        issue_assignee: isIssueAssignee ? 1 : 0
        // reminder_email_receiver: reminderEmailRole?.id,
        // inventory_adjustment_email_receiver: inventoryEmailRole?.id,
      };
      if (userRole) data.role_id = userRole.id;
      Auth.fetch("/user/user", {
        method: "PUT",
        body: JSON.stringify(data),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/users");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoading(true);
      showLoading(layoutDispatch);
      const data = {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: email,
        password: password,
        type: userType.id,
        status: userStatus.id,
        // payment_authorizer: payAuthRole?.id,
        // reminder_email_receiver: reminderEmailRole?.id,
        // inventory_adjustment_email_receiver: inventoryEmailRole?.id,
        payment_authorizer: isPaymentAuthorizer ? 1 : 0,
        reminder_email_receiver: isReminderEmailReceiver ? 1 : 0,
        inventory_adjustment_email_receiver: isInventoryEmailReceiver ? 1 : 0,
        issue_assignee: isIssueAssignee ? 1 : 0

      };
      if (userRole) data.role_id = userRole.id;
      Auth.fetch("/user/user", {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/users");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChangeUserType = (event, values) => {
    setUserType(values);
  };

  const onChangeUserRole = (event, values) => {
    setUserRole(values);
  };

  const onChangeUserStatus = (event, values) => {
    setUserStatus(values);
  };

  const paymentAuthStatus = (event, values) => {
    setPayAuthRole(values);
  };

  const reminderNotificationStatus = (event, values) => {
    setReminderEmailRole(values);
  };

  const inventoryNotificationStatus = (event, values) => {
    setInventoryEmailRole(values);
  };

  return (
    <div style={{ width: "100%" }}>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <h1> {id > 0 ? "Update" : "Add"} User</h1>

        <Row gutter={[6, 16]}>
          <Col sm={24} md={12} lg={8} xl={8}> 
          <TextField
              value={firstName}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
              size="small"
              label="First Name"
              id="firstName"
              error={firstNameError === "" ? false : true}
              helperText={firstNameError}
            />
          </Col>
          <Col sm={24} md={12} lg={8} xl={8}> 
          <TextField
              value={lastName}
              onChange={(event) => {
                setLastName(event.target.value);
              }}
              size="small"
              label="Last Name"
              id="lastName"
              error={lastNameError === "" ? false : true}
              helperText={lastNameError}
            />
          </Col>
          <Col sm={24} md={12} lg={8} xl={8}>
          <TextField
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              label="Email"
              size="small"
              id="email"
              error={emailError === "" ? false : true}
              helperText={emailError}
            />
          </Col>
          <Col sm={24} md={12} lg={8} xl={8}>
          <TextField
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              size="small"
              label="Password"
              type="password"
              id="password"
            />
          </Col>
          <Col sm={24} md={12} lg={8} xl={8}>
          <TextField
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
              label="Phone Number"
              id="phone"
              size="small"
              error={phoneError === "" ? false : true}
              helperText={phoneError}
            />
          </Col>
          <Col sm={24} md={12} lg={8} xl={8}>
          <Autocomplete
              id="user_Type"
              options={userTypes}
              getOptionLabel={(option) => option.title}
              value={userType}
              onChange={onChangeUserType}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // variant="standard"
                  size="small"
                  label="Type"
                  placeholder="User Type"
                  error={userTypeError === "" ? false : true}
                  helperText={userTypeError}
                />
              )}
            />
          </Col>
          {userType.id === 2 && (
          <Col sm={24} md={12} lg={8} xl={8}>
              <>
                <Autocomplete
                  id="user_Type_Role"
                  options={userRolesTypes}
                  getOptionLabel={(option) => option.title}
                  value={userRole}
                  onChange={onChangeUserRole}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="User Role"
                      placeholder="User Role"
                      size="small"
                    />
                  )}
                />
                <br />
              </>
          </Col>
            )}
          <Col sm={24} md={12} lg={8} xl={8}>
          <Autocomplete
              id="user_status"
              options={statuses}
              getOptionLabel={(option) => option.title}
              value={userStatus}
              onChange={onChangeUserStatus}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Status"
                  placeholder="Status"
                  error={userStatusError === "" ? false : true}
                  helperText={userStatusError}
                />
              )}
            />
          </Col>
          {/* <Col sm={24} md={12} lg={8} xl={8}>
          <Autocomplete
              id="payment_auth"
              options={selectYesNo}
              getOptionLabel={(option) => option.title}
              value={payAuthRole}
              onChange={paymentAuthStatus}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Payment Authorizer"
                  placeholder="Is Payment Authorizer?"
                />
              )}
            />
          </Col>
          <Col sm={24} md={12} lg={8} xl={8}>
          <Autocomplete
              id="reminder_email"
              options={selectYesNo}
              getOptionLabel={(option) => option.title}
              value={reminderEmailRole}
              onChange={reminderNotificationStatus}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Reminder Email Receiver"
                  placeholder="Is Reminder Email Receiver?"
                />
              )}
            />
          </Col>
          <Col sm={24} md={12} lg={8} xl={8}>
          <Autocomplete
              id="inventory_email"
              options={selectYesNo}
              getOptionLabel={(option) => option.title}
              value={inventoryEmailRole}
              onChange={inventoryNotificationStatus}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Inventory Email Receiver"
                  placeholder="Is Inventory Email Receiver?"
                />
              )}
            />
          </Col> */}
          </Row>
          <div className={classes.dataSheet}>
          <div >
        <Checkbox
        className="large-checkbox"
          checked={isPaymentAuthorizer}
          onChange={handlePaymentAuthorizerChange}
        >
          Payment Authorizer
        </Checkbox>
      </div>
      <div>
        <Checkbox
        className="large-checkbox"
          checked={isReminderEmailReceiver}
          onChange={handleReminderEmailReceiverChange}
        >
          Reminder Email Receiver
        </Checkbox>
      </div>
      <div>
        <Checkbox
        className="large-checkbox"
          checked={isInventoryEmailReceiver}
          onChange={handleInventoryEmailReceiverChange}
        >
          Inventory Email Receiver
        </Checkbox>
      </div>
      <div>
        <Checkbox
        className="large-checkbox"
          checked={isIssueAssignee}
          onChange={handleIssueAssigneeChange}
        >
          Issue Assignee
        </Checkbox>
      </div>
          </div>
          
        <Box style={{ float: "right", marginTop: "40px" }}>
          <FormButton type="close" onClick={onClickCancel}>
            Cancel
          </FormButton>
          <FormButton type="save" onClick={handleSubmit}>
            Save
          </FormButton>
        </Box>

        <Message type={type} msg={loadMessage} />
      </Box>
    </div>
  );
}
