import React, { useEffect, useState } from "react";
import { Card, Col, Divider, Row, Statistic, Table, Switch, Button, Tag, Tooltip, Input, Modal, message } from "antd";
import { makeStyles } from "@mui/styles";
import { CheckOutlined, CloseOutlined, DeleteFilled, EditOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import moment from "moment";
import { hideLoading, showLoading, useLoadingDispatch } from '../../context/loadingContext';
import { Pagination } from "@mui/material";
import { issuePriorities } from "../../Components/common/constants";
import { useNavigate } from "react-router-dom";

const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: "5px",
    
  },
}));

const AssetList = () => {
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const navigate = useNavigate();
  const [loadMessage, setLoadMessage] = useState("");
  const [type, setType] = useState("");
  const [allAssets, setAllAssets] = useState([]);
  const [page, setPage] = React.useState(1);
  const [pageChange, setPageChange] = React.useState(false);
  const [limit] = React.useState(50);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);
  

  const handleChange = (event, value) => {
    setPage(value);
    setPageChange(true)
  };

  const assetColumns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Serial Number',
        dataIndex: 'serial_number',
        key: 'serial_number',
    },
    {
      title: 'Make',
      dataIndex: 'make',
      key: 'make',
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status == 1 ? 'green' : 'red'} key={status}>
          {status == 1 ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <div>
          {
            record.status == 1 && (
              <EditOutlined 
        style={{
          fontSize: "25px",
          cursor: "pointer",
          marginRight: "5px",
          color: "#006cb8",
        }} onClick={() => navigate(`/single-asset/${record.id}`)}/>
            )
          }

        
        {
          record.status == 1 && (
            <DeleteFilled style={
              {
                fontSize: "25px",
                cursor: "pointer",
                color: "#ff0000",
            }} onClick={() => {
              Modal.confirm({
                title: 'Delete Asset',
                centered: true,
                content: `Are you sure you want to delete this asset?`,
                onOk:()=> deleteAsset(record.id),
                okText: 'Delete',
                cancelText: 'Cancel',
                width: 600,
                okButtonProps: { size: "large"  },
                cancelButtonProps: { size: "large" },
              });
            }} 
            />
          )
        }
        
        </div>
        )
    },
    
  ];

  const deleteAsset = async (id) => {
    showLoading(layoutDispatch);
    try {
      const res = await Auth.fetch(`/asset/asset`, {
        method: "DELETE",
        body: JSON.stringify({ id: id })

      });
      if (res?.ack) {
        getAllAssets();
        message.success(res?.message);
      } else {
        message.error(res?.message);
      }
    } catch (err) {
      console.log(err);
      message.error(err?.message);
    } finally {
      hideLoading(layoutDispatch);
    }
  };
  
    const getAllAssets = async (download) => {
         let search = {};
         if (page && pageChange) search.page = page;
    
        if (download) search.download = 1;
    
         showLoading(layoutDispatch);
         try {
         const res = await Auth.fetch("/asset/assets", {
             method: "POST",
             body: JSON.stringify(search),
           });
           if (res?.ack) {
            if (download == 1) {
                window.open(res.file_url, "_blank", "noreferrer");
              } else {
             setAllAssets(res?.assets)
             setTotalPages(Math.ceil(res?.total_count / limit));
             setTotalCount(res.total_count);
              }
           } else {
            setAllAssets([])
             message.error(res?.message)
           }
         } catch (err){
             console.log(err);
             message.error(err?.message)
         } finally {
             hideLoading(layoutDispatch);
         }
       };
     
       useEffect(() => {
         const timeOutId = setTimeout(() => getAllAssets(), 1000);
         return () => clearTimeout(timeOutId);
       }, [
         page
       ]);

  useEffect(() => {
      message.config({
        top: window.innerHeight - 100, 
        duration: 5, 
      });
    }, [])

  return (
    <div className={classes.dataSheet}>
        <div className="page-header">
        <h2>Assets</h2>
        <Button onClick={()=> navigate('/single-asset/new')} type="primary" icon={<PlusOutlined />}>Add Asset</Button>
        </div>
        <div className={classes.antTable}>
                <Table
                  columns={assetColumns}
                  style={{ overflowX: "auto", marginTop: '10px' }}
                  pagination={false}
                  dataSource={allAssets?.filter((asset) => asset.status == 1)}
                  footer={() => {
                    const startRecord = (page - 1) * limit + 1;
                    const endRecord = Math.min(page * limit, allAssets?.length ? totalCount : 0); 
                    return `Showing ${startRecord}-${endRecord} of ${allAssets?.length ? totalCount : "0"} Records`;
                  }}
                />
              </div>
        
              <div style={{ float: "right", margin: "10px" }}>
                <Pagination
                  page={page}
                  count={totalPages}
                  variant="outlined"
                  color="primary"
                  onChange={handleChange}
                />
              </div>
      <Message type={type} msg={loadMessage} />
    </div>
  );
};

export default AssetList;
