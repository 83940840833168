import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Col,
  Divider,
  Row,
  Statistic,
  Table,
  Switch,
  Input,
  Checkbox,
  Form,
  Collapse,
  Button,
  Select,
  Image,
  Tooltip,
  FloatButton,
  message,
} from "antd";
import "./reports.css";
import { makeStyles } from "@mui/styles";
import { CheckOutlined, CloseOutlined, CloudDownloadOutlined, ExpandAltOutlined } from "@ant-design/icons";
import Message from "../../Components/common/Message";
// import { netsuite_url } from "../../Components/config";
import AuthService from "../../Components/auth/AuthService";
import { StockChangeReasons } from "../../Components/common/constants";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button as UploadButton } from "@mui/material";
import config  from "../../Components/config";
import qs from "query-string";
import SignatureCanvas from "react-signature-canvas";
import { showLoading, hideLoading, useLoadingDispatch } from "../../context/loadingContext";


const colors = ["#f0f0f0", "#e6f7ff", "#faf0e6", "#fcf8e3"];
const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: '1px 15px',
    "& .ant-card .ant-card-body": {
      padding: "4px",
      // backgroundColor: 'rgb(240, 240, 240)',
    },
    "& .ant-table-thead>tr>th": {
      padding: "0px 4px !important"
    },
    "& .ant-statistic .ant-statistic-content": {
      fontSize: "12px",
      fontWeight: "bold",
    },
    "& .ant-statistic .ant-statistic-title": {
      fontSize: "12px",
    },
    "& .ant-form-item-label >label": {
      fontWeight: "bold",
    },
  },
}));

const GoodsReturnTab = ({
  goodsReturnData,
  setGoodsReturnData,
  deliveryQuantities,
  setDeliveryQuantities,
  updateGoodsReturn,
  updateGoodsReturnQuantity
}) => {
  const classes = useStyles();
  const { TextArea } = Input;
  const signatureCanvasRefs = useRef([]);
  const layoutDispatch = useLoadingDispatch()
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  // const [deliveryQuantities, setDeliveryQuantities] =
  //   React.useState(deliveryQuantities1);
  const [disabledInputs, setDisabledInputs] = React.useState({});
  const [isLoading, setIsLoading] = React.useState("");
  const [tableCheckbox, setTableCheckbox] = React.useState({});
  const [returnedStockCheckbox, setReturnedStockCheckbox] = React.useState({});
  const [returnedStockDisableInputs, setReturnedStockDisableInputs] =
    React.useState({});


    console.log('deliveryQuantities', deliveryQuantities);
    
  const readOnly = localStorage.getItem("read_only") === "true";
  const generateColor = (index) => {
    const colors = ["#f0f0f0"];
    return colors[0];
  };
  const { Panel } = Collapse;
  const { Option } = Select;

  const [disabledRoutes, setDisabledRoutes] = React.useState([]);


  useEffect(() => {
    localStorage.setItem("disabledGoodskRoutes", JSON.stringify(disabledRoutes));
  }, [disabledRoutes]);
  
  useEffect(() => {
    // Retrieve previously stored disabledRoutes from localStorage
    const storedDisabledRoutes = JSON.parse(localStorage.getItem("disabledGoodskRoutes")) || [];
  
    const newDisabledRoutes = goodsReturnData
      .filter(stock => stock.goods_return_updated_by > 0)
      .map(stock => stock.routeStatusID);
  
    // Merge stored disabledRoutes with new ones, avoiding duplicates
    const updatedDisabledRoutes = [...new Set([...storedDisabledRoutes, ...newDisabledRoutes])];
  
    // Update the state and localStorage
    setDisabledRoutes(updatedDisabledRoutes);
    localStorage.setItem("disabledGoodsRoutes", JSON.stringify(updatedDisabledRoutes));
  }, [goodsReturnData]);

  const handleDeliveryQuantityChange = (value, record, commentId) => {
    const isFullQuantity = value === record.orgReturnedQuantity;
    if (value === "full") {
      value = record.orgReturnedQuantity;
      // commentId = 11;
    }
    if (value === "notFull") {
      value = 0;
      // commentId = null;
    }
    const updatedDeliveryQuantities = {
      ...deliveryQuantities,
      [record.itemPalletId]: {
        ...deliveryQuantities[record.itemPalletId],
        returned_quantity: value,
        // delivery_status: commentId,
      },
    };
    if (isFullQuantity) {
      setDisabledInputs((prevState) => ({
        ...prevState,
        [record.itemPalletId]: true,
      }));
      setTableCheckbox((prevState) => ({
        ...prevState,
        [record.itemPalletId]: true,
      }));
    }
    setDeliveryQuantities(updatedDeliveryQuantities);
  };

  // console.log('deliveryQuantities', deliveryQuantities)

  const handleReturnToStockQuantityChange = (value, record, commentId) => {
    const isFullQuantity = value === record.orgReturnedQuantity;
    if (value === "full") {
      value = record.orgReturnedQuantity;
      commentId = 3;
    }
    if (value === "notFull") {
      value = 0;
      commentId = null;
    }
    const updatedDeliveryQuantities = {
      ...deliveryQuantities,
      [record.itemPalletId]: {
        ...deliveryQuantities[record.itemPalletId],
        returned_quantity_to_stock: value,
        returned_quantity_to_stock_status: commentId,
      },
    };
    if (isFullQuantity) {
      setReturnedStockDisableInputs((prevState) => ({
        ...prevState,
        [record.rank]: true,
      }));
      setReturnedStockCheckbox((prevState) => ({
        ...prevState,
        [record.rank]: true,
      }));
    }
    setDeliveryQuantities(updatedDeliveryQuantities);
  };

  const handleCheckboxChange = (isChecked, itemPalletId) => {
    setDisabledInputs((prevState) => ({
      ...prevState,
      [itemPalletId]: isChecked,
    }));

    setTableCheckbox((prevState) => ({
      ...prevState,
      [itemPalletId]: isChecked,
    }));
  };

  const handleReturndStockCheckboxChange = (isChecked, rank) => {
    setReturnedStockDisableInputs((prevState) => ({
      ...prevState,
      [rank]: isChecked,
    }));

    setReturnedStockCheckbox((prevState) => ({
      ...prevState,
      [rank]: isChecked,
    }));
  };

  const handleSwitchToggle = async (
    itemPalletID,
    returnedQuantity,
    // returnedQuantityToStock,
    // returnedStockStatus,
    checked,
    type
  ) => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    const dataToSend = {
      pallet_item_id: itemPalletID,
      returned_quantity: returnedQuantity,
      // returned_quantity_to_stock: returnedQuantityToStock,
      // returned_quantity_to_stock_status: returnedStockStatus
    };
    if (type === 1)
      // Receiver toggle
      dataToSend.returned_quantity_approved_rec = checked ? 1 : 0;
    else if (type === 2)
      // Supervisor toggle
      dataToSend.returned_quantity_approved_sup = checked ? 1 : 0;

    try {
      const response = await Auth.fetch(
        "/route/change-quantity-received-status",
        {
          method: "POST",
          body: JSON.stringify(dataToSend),
        }
      );
      if (response.ack) {
        setType("success");
        setLoadMessage(response.message + str);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const dataCol = [
    {
      title: "S.No.",
      dataIndex: "rank",
      key: "rank",
      width: 55,
    },
    {
      title: "Shop Code",
      // dataIndex: "customerName",
      // key: "customerName",
      render: (text, record) => {
        return (
          <span>
            <a
              href={`${config.netsuite_url}/app/common/entity/custjob.nl?id=${record.customerInternalId}`}
              target="_blank"
            >
              {record.customerName}
            </a>
          </span>
        );
      },
      width: 100,
    },
    {
      title: "Shop Name",
      dataIndex: "customerProjectId",
      key: "customerProjectId",
      width: 200
    },

    {
      title: 'Order No.',
      render: (text, record) => {
          return (
              <span>
                  {record.invoice_no != null && record?.invoice_no != "" ? (
                      <a 
                          href={`${config.netsuite_url}/app/accounting/transactions/custinvc.nl?id=${record.invoiceInternalId}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                      >
                          {record.invoice_no}
                      </a>
                  ) : (
                      <a 
                          href={`${config.netsuite_url}/app/accounting/transactions/salesord.nl?id=${record.internalId}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                      >
                          {record.saleOrderNo}
                      </a>
                  )}
              </span>
          );
      },
      width: 100
    },
    {
      title: "Item Code",
      dataIndex: "itemId",
      key: "itemId",
      width: 100,
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      width: 200,
    },
    {
      title: "Ordered Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 80,
      align: "center",
    },
    {
      title: "Returned Qty",
      dataIndex: "orgReturnedQuantity",
      key: "orgReturnedQuantity",
      width: 80,
      align: "center",
    },
    {
      title: "Reason",
      dataIndex: "status",
      key: "status",
      width: 100,
    },
    {
      title: "Warehouse Received Qty (Quarantine)",
      dataIndex: "returned_quantity",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
          }}
        >
          <Input
            type="number"
            style={{ width: "75px", marginRight: "5px" }}
            value={
              deliveryQuantities[record.itemPalletId]?.returned_quantity || 0
            }
            disabled={disabledInputs[record.itemPalletId]}
            onChange={(e) => {
              const newValue = Math.max(0, e.target.value);
              handleDeliveryQuantityChange(
                newValue,
                record,
                deliveryQuantities[record.itemPalletId]?.delivery_status
              );
            }}
          />
          <Checkbox
            checked={tableCheckbox[record.itemPalletId]}
            onChange={(e) => {
              const isChecked = e.target.checked;
              handleCheckboxChange(isChecked, record.itemPalletId);
              handleDeliveryQuantityChange(
                isChecked ? "full" : "notFull",
                record,
                deliveryQuantities[record.itemPalletId]?.delivery_status
              );
            }}
            disabled={readOnly}
          >
            Full
          </Checkbox>
        </div>
      ),
      width: 140,
    },
    
  ];

  const [expandAll, setExpandAll] = useState(false);

  const onClickExpand = () => {
    let goodsReturnData1 = [...goodsReturnData];

    if (expandAll) {
      goodsReturnData1.map((route, rIdx) => {
        route.expanded = false;
      });
    } else {
      goodsReturnData1.map((route, rIdx) => {
        route.expanded = true;
      });
    }
    setGoodsReturnData(goodsReturnData1);
    setExpandAll(!expandAll);
  };

  const handleRouteExpand = (route_idx) => {
    const updatedData = [...goodsReturnData];
    updatedData[route_idx].expanded = !updatedData[route_idx].expanded;
    setGoodsReturnData(updatedData);
  };

  useEffect(() => {
    if (goodsReturnData.length === 1) {
      setGoodsReturnData((prevData) => {
        return prevData.map((item) => ({
          ...item,
          expanded: true,
        }));
      });
    }
  }, []);

  useEffect(() => {
    if (goodsReturnData) {
      const updatedCheckboxState = goodsReturnData.reduce((acc, dataItem) => {
        dataItem.tableData.forEach((item) => {
          if (item.orgReturnedQuantity === item.returnedQuantity) {
            acc[item.itemPalletId] = true;
          }
        });
        return acc;
      }, {});
  
      setTableCheckbox((prevState) => ({
        ...prevState,
        ...updatedCheckboxState,
      }));
    }
  }, [goodsReturnData]);
  

  const getUploadUrl = async (file, id) => {
    const params = {
      bucket: config.tms_uploads_bucket,
      ContentType: file.file.type,
      Key: config.env + "/goods_return/" + id + "/" + file.name,
    };
    const response = await fetch(
      `${config.domain}/generate-put-url?${qs.stringify(params)}`,
      {
        headers: {
          "Content-Type": params.ContentType,
        },
      }
    );
    return response.json();
  };

  const uploadFileToS3 = async (uploadUrl, file, contentType) => {
    const response = await fetch(uploadUrl.putURL, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": contentType,
      },
    });
    if (response.status !== 200) {
      throw new Error("File upload failed.");
    }
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const list = [...goodsReturnData];
    list[index][name] = value;
    setGoodsReturnData(list);
  };

  const clearSignature = (index) => {
    if (signatureCanvasRefs.current[index]) {
      signatureCanvasRefs.current[index].clear();
    }
  };

  const saveSignature = (index) => {
    if (signatureCanvasRefs.current[index]) {
      const signatureImage = signatureCanvasRefs.current[index].toDataURL();
      const list = [...goodsReturnData];
      list[index].warehouse_signature = signatureImage; 
      setGoodsReturnData(list);
    }
  };

  
  useEffect(() => {
    signatureCanvasRefs.current.forEach((canvas) => {
      if (canvas) {
        canvas.clear();
      }
    });
  }, []);


  const handleFuelCardToggle = async(index, checked) => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    const dataObject = goodsReturnData.find((item, idx) => idx === index);
    console.log("dataObject", dataObject, checked);
    const dataToSend = {
      route_status_id: dataObject?.routeStatusID,
      driver_id: dataObject?.driverID,
      fuel_card_returned: checked ? 1 : 0
    }
    try {
      const response = await Auth.fetch("/route/update-fuel-card-return", {
        method: "POST",
        body: JSON.stringify(dataToSend),
      });
      if(response.ack) {
        setType('success');
        setLoadMessage(response.message + str)
      } else {
        setType('error');
        setLoadMessage(response.message + str)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const saveFiles = async (id) => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    const dataObject = goodsReturnData.find((item, idx) => idx === id);
    if (dataObject.files < 1) {
      setType('error')
      setLoadMessage('Add at least one file to save data!' + str)
      return;
    }
    console.log("dataObject", dataObject);
    const dataToSend = {
      route_status_id: dataObject?.routeStatusID,
      files: dataObject.files,
      notes: dataObject.notes
    };
    try {
      const response = await Auth.fetch("/route/update-goods-return-files", {
        method: "POST",
        body: JSON.stringify(dataToSend),
      });
      if(response.ack) {
        goodsReturnData.map(async (goodsItem, idx) => {
          if (goodsItem.newFiles.length > 0) {
            const uploadPromises = goodsItem.newFiles.map(async (f, idx) => {
              try {
                const uploadUrl = await getUploadUrl(f, goodsItem.routeStatusID);
                await uploadFileToS3(uploadUrl, f.file, f.file.type);
              } catch (error) {
                console.error("Error uploading file:", error);
              }
            });

            Promise.all(uploadPromises);
          }
        });
        setType('success');
        setLoadMessage(response.message + str)
      } else {
        setType('error');
        setLoadMessage(response.message + str)
      }

    } catch (err) {
      console.log(err)
    }
  };


const downloadCSVFile = (id) => {
  const dataObject = goodsReturnData.find((item, idx) => idx === id);
  console.log("dataObject", dataObject);

  // Extract formatted_date and driver fields
  const formattedDate = dataObject.formatted_date.replace(/ /g, "-"); // Replace spaces with dashes

  // Define the filename
  const filename = `${formattedDate}_${dataObject.driverRoute}_GoodsReturn.csv`;

  // Define fields to exclude
  const excludedFields = [
      "expanded",
      "formatted_date",
      "routeStatusID",
      "files",
      "newFiles",
      "driverID",
      "fuelCardReturn",
      "notes"
  ];

  // Define a mapping object to rename tableData headers
  const tableDataHeaderMapping = {
      rank: "S No.",
      customerName: "Shop Code",
      itemId: "Item ID",
      itemName: "Item Name",
      invoiceNo: "Invoice No",
      quantity: "Ordered Quantity",
      orgReturnedQuantity: "Returned Quantity",
      returnedQuantity: "Warehouse Received Qty (Quarantine)",
      returnedQuantityApprovedSup: "Checked by Receiver",
      status: "Status"
  };

  // Define fields to exclude from tableData
  const excludedTableDataFields = [
      "invoiceInternalId",
      "palletOrderID",
      "customerInternalId",
      "itemPalletId",
      "returnedQuantityApprovedRec",
      // "returnedQuantityApprovedSup",
      "returnedQuantityToStockStatus",
      "returnedQuantityToStock"
  ];

  // Define the sequence of simple fields
  const simpleFieldsSequence = [
      'route',
      'driver',
      'truck',
      'driverRoute',
  ];

  // Define the sequence of table fields
  const tableFieldsSequence = [
      'rank',
      'customerName',
      // 'customerProjectId',
      'invoiceNo',
      'itemId',
      'itemName',
      'quantity',
      'orgReturnedQuantity',
      'returnedQuantity',
      // 'returnedQuantityToStock',
      'status',
      'returnedQuantityApprovedSup',
  ];

  // Format simple fields data into CSV string
  let csvData = "";

  simpleFieldsSequence.forEach(field => {
      if (excludedFields.includes(field)) return;

      let fieldName = field;
      if (field === "route") fieldName = "Route No";
      if (field === "driver") fieldName = "Driver";
      if (field === "truck") fieldName = "Truck Reg.";
      if (field === "driverRoute") fieldName = "Route";

      csvData += `${fieldName},${dataObject[field]}\n`;
  });

  // Process tableData if it exists
  const tableDataField = 'tableData';
  if (dataObject[tableDataField]) {
      // Map headers to their renamed counterparts and exclude unwanted fields
      const renamedHeaders = tableFieldsSequence
          .filter(header => !excludedTableDataFields.includes(header))
          .map(header => tableDataHeaderMapping[header] || header);

      // Add tableData headers to CSV
      csvData += `${renamedHeaders.join(",")}\n`;

      // Loop through each row in tableData and add to CSV
      dataObject[tableDataField].forEach(row => {
          const rowData = tableFieldsSequence
              .filter(key => !excludedTableDataFields.includes(key))
              .map(key => {
                if (key === 'returnedQuantityApprovedSup') {
                    return row[key] === 1 ? 'Yes' : 'No';
                }
                return row[key];
            });
          csvData += `${rowData.join(",")}\n`;
      });
  }

  // Create a Blob object from the CSV data
  const blob = new Blob([csvData], { type: "text/csv" });

  // Create a temporary URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element to trigger the download
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);

  // Append the link to the body and click it to trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up: remove the link and revoke the URL
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};


  const genExtra = () => {
    
  const posted = goodsReturnData[0]?.goods_return_updated_by > 0 ? true : false;
  const completed = goodsReturnData[0]?.start_status === 2 ? true : false;

    return (
      <div style={{ display: 'flex', alignItems: 'center'}}>
        {/* <Button
            style={{
              marginRight: '5px',
              color: completed  ? 'green' : 'red',
              cursor: 'alias',
              pointerEvents: "none",
              padding: '4px 4px'
            }}
            icon={completed ? <CheckOutlined/> : <CloseOutlined />}
              >
                Route Completed
          </Button> */}
            <Button
              style={{
                marginRight: '5px',
                color: posted || disabledRoutes.includes(goodsReturnData[0]?.routeStatusID) ? 'green' : 'red',
                cursor: 'alias',
                pointerEvents: "none",
                padding: '4px 4px'
              }}
              icon={posted || disabledRoutes.includes(goodsReturnData[0]?.routeStatusID) ? <CheckOutlined/> : <CloseOutlined />}
                >
                  Goods Return
            </Button>
          
    <Tooltip title="Download">
    <CloudDownloadOutlined
    style={{ fontSize: '25px', color: '#1677FF'}}
    onClick={(e) => {
      e.stopPropagation();
      downloadCSVFile(0);
    }}
     />
     </Tooltip>
    </div>
    )
};

  const handleSubmitImage = (idx, e) => {
    e.preventDefault();
    let acceptedFiles = e.target.files;

    for (let file of acceptedFiles) {
      const randomString = Math.random().toString(36).substring(2, 10);
      const newFileName = `${file.name.split(".")[0]}-${randomString}.${
        file.name.split(".")[1]
      }`;
      setLoadMessage("");
      const list = [...goodsReturnData];
      let files1 = list[idx]["newFiles"];
      files1.push({
        name: newFileName,
        display_name: newFileName,
        file: file,
        ContentType: file.type,
      });
      let filesNameList = list[idx]["files"];
      filesNameList.push(newFileName);
      list[idx]["newFiles"] = files1 || [];
      list[idx]["files"] = filesNameList || [];
      setGoodsReturnData(list);
    }
  };


  const handleDeleteNewFile = (idx, fileName) => {
    const updatedStatus = goodsReturnData.map((newItem, index) => {
      if (index === idx) {
        const updatedNewFiles = newItem.newFiles.filter(
          (file) => file.name !== fileName
        );
        const updatedFiles = newItem.files.filter((file) => file !== fileName);
        return {
          ...newItem,
          newFiles: updatedNewFiles,
          files: updatedFiles,
        };
      }
      return newItem;
    });
    setGoodsReturnData(updatedStatus);
  };

  const handleDeleteFile = (idx, fileName) => {
    const updatesStatuses = goodsReturnData.map((newItem, index) => {
      if (index === idx) {
        
        const updatedFiles = newItem.files.filter((file) => file !== fileName);
        return {
          ...newItem,
          files: updatedFiles,
        };
      }
      return newItem;
    });
    setGoodsReturnData(updatesStatuses);
  };

  console.log("goodsDel", goodsReturnData);

  const saveInfo = async (index) => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    setIsLoading(true)
    const dataObject = goodsReturnData.find((item, idx) => idx === index);
    if (dataObject.files < 1) {
      setType('error')
      setLoadMessage('Please add at least one file to proceed.' + str)
      return;
    }
    if (!dataObject.warehouse_received_by) {
      setType('error')
      setLoadMessage('Please enter receiver name to proceed.' + str)
      return;
    }
    if (!dataObject.warehouse_signature) {
      setType('error')
      setLoadMessage('Please sign to proceed.' + str)
      return;
    }
    const goodsData = goodsReturnData[index];
    const filteredQuantities = goodsData.tableData.reduce((result, item) => {
      // Check if the quantity exists for the itemPalletId
      if (deliveryQuantities[item.itemPalletId] !== undefined) {
        // Push only the quantity into the result array
        result.push(deliveryQuantities[item.itemPalletId]);
      }
      return result;
    }, []); 
    const dataToSend = {
      goods_returns: filteredQuantities,
      route_status_id : goodsData?.routeStatusID,
      files: dataObject.files,
      notes: dataObject.notes,
      warehouse_received_by: dataObject.warehouse_received_by,
      warehouse_signature: dataObject.warehouse_signature
    };
    const payload = goodsReturnData.flatMap(route =>
      route.tableData.map(item => ({
        pallet_item_id: item.itemPalletId,
        returned_quantity: deliveryQuantities[item.itemPalletId]?.returned_quantity || 0,
      }))
    );
    // const dataToSend = {
    //   route_status_id : goodsData?.routeStatusID,
    //   goods_returns: payload
    // }
    showLoading(layoutDispatch)
    try {
      const response = await Auth.fetch(
        "/route/update-goods-return",
        {
          method: "POST",
          body: JSON.stringify(dataToSend),
        }
      );
      if (response.ack) {
        setType("success");
        setLoadMessage(response.message + str);
        const updatedDisabledRoutes = [...disabledRoutes, goodsData?.routeStatusID];
            setDisabledRoutes(updatedDisabledRoutes);
            localStorage.setItem(
              "disabledGoodsRoutes",
              JSON.stringify(updatedDisabledRoutes)
            );
            updateGoodsReturn(dataObject.warehouse_received_by, dataObject.warehouse_signature)
            updateGoodsReturnQuantity(filteredQuantities)
            goodsReturnData.map(async (goodsItem, idx) => {
              if (goodsItem.newFiles.length > 0) {
                const uploadPromises = goodsItem.newFiles.map(async (f, idx) => {
                  try {
                    const uploadUrl = await getUploadUrl(f, goodsItem.routeStatusID);
                    await uploadFileToS3(uploadUrl, f.file, f.file.type);
                  } catch (error) {
                    console.error("Error uploading file:", error);
                  }
                });
    
                Promise.all(uploadPromises);
              }
            });
      } else {
        setType("error");
        setLoadMessage(response.message + str);
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    hideLoading(layoutDispatch)
    }
  }

  const [expandedKeys, setExpandedKeys] = useState(
    goodsReturnData
      .filter(
        (item) =>
          (item.tableData?.length == 0) && (item.driver_returns_notes || item.driver_returns_files.length > 0)
      )
      .map((item) => `panel-${item.routeStatusID}`)
  );

  const handleCollapseChange = (keys) => {
    setExpandedKeys(keys);
  };

  return (
    <div  style={{ position: "relative" }} className={classes.dataSheet}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <div>{genExtra()}</div>
      </div>
      {goodsReturnData.length > 0 &&
        goodsReturnData?.sort((a, b) => a.driverRoute.localeCompare(b.driverRoute)).map((data, index) => (
          <>
            <div className="view-page">   
                  <Collapse
                   style={{ margin: "10px 0px" }}
                   activeKey={expandedKeys}
                   onChange={handleCollapseChange}
                   >
                    <Panel
                      header={
                        <>
                          <span style={{ fontWeight: "bold" }}>
                            Driver Return Files & Notes
                          </span>
                        </>
                      }
                      key={`panel-${data.routeStatusID}`}
                    >
                      <TextArea autoSize 
                    name="notes"
                    value={data.driver_returns_notes}
                    // onChange={(e) => handleInputChange(index, e)}
                    placeholder="Notes"
                  />
                      <Row
                        gutter={[6, 16]}
                        style={{
                          marginTop: "10px",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                      </Row>
                      {data.driver_returns_files?.length > 0 && <h4>Driver Return Files:</h4>}
                      <br />
                      <Image.PreviewGroup>
                        {data?.driver_returns_files?.length > 0 &&
                          data?.driver_returns_files
                            .map((item) => {
                              const imageUrl = `${config?.tms_uploads_cdn}/${
                                config.env
                              }/driver_returns/${data.routeStatusID}/${item}`;
                              return (
                                <div
                                  style={{
                                    position: "relative",
                                    marginRight: "10px",
                                    display: "inline-block",
                                  }}
                                >
                                  <Image
                                    width={200}
                                    height={150}
                                    style={{
                                      padding: "5px",
                                      border: "1px solid lightgrey",
                                    }}
                                    src={imageUrl}
                                  />
                                </div>
                              );
                            })}
                      </Image.PreviewGroup>
                    </Panel>
                  </Collapse>

                  <>
                    <Table
                      style={{ overflowX: "auto", marginTop: "10px" }}
                      columns={dataCol}
                      dataSource={data.tableData}
                      pagination={false}
                      bordered={true}
                      scroll={{
                        y: 350,
                      }}
                    />
                  </>
                  <Divider style={{ height: "3px", background: "#006cb8" }} />
                  <h3>Route Files & Notes</h3>
                  <>
                  <TextArea autoSize 
                    name="notes"
                    style={{ width: '50%'}}
                    value={data.notes}
                    onChange={(e) => handleInputChange(index, e)}
                    placeholder="Notes"
                  />
                  <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
          Receiver Name<span style={{ color: "red", fontSize: "15px" }}>*</span>
        </h4>
        <div>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={6}>
              <Input
                value={data.warehouse_received_by}
                name='warehouse_received_by'
                onChange={(e) => handleInputChange(index, e)}
                placeholder="Enter receiver Name"
              />
            </Col>
          </Row>
        </div>
        
        <div
        style={{
          width: 450,
          display: "flex",
          alignItems: 'end',
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
          Receiver Signature<span style={{ color: "red", fontSize: "15px" }}>*</span>
        </h4>
        <Button
          size="large"
          danger
          onClick={() => clearSignature(index)}
        >
          Clear Signature
        </Button>
      </div>
        <div className="sign-con">
          <SignatureCanvas
            ref={(el) => (signatureCanvasRefs.current[index] = el)}
            value={data?.warehouse_signature}
            penColor="purple"
            canvasProps={{
              width: 450,
              height: 200,
              className: "signature-canvas",
            }}
          onEnd={() => saveSignature(index)}
          />
          <div>
          {data?.warehouse_signature && <img height={150} width={250} src={data.warehouse_signature} />}
          </div>
        </div>
                      <Row
                        gutter={[6, 16]}
                        style={{
                          marginTop: "10px",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <UploadButton
                            component="label"
                            variant="contained"
                            sx={{ marginTop: "10px" }}
                            startIcon={<CloudUploadIcon />}
                          >
                            Add files
                            <input
                              type="file"
                              hidden
                              multiple
                              capture
                              accept="image/*, video/*"
                              // onChange={(e) => {
                              //   handleSubmitImage(index, e);
                              // }}
                              onChange={(e) => {
                                const myfiles = e.target.files;
                                if (myfiles.length > 10) {
                                  message.error("A maximum of 10 images can be uploaded at a time.");
                                  e.target.value = "";
                                } else {
                                  handleSubmitImage(index,e);
                                }
                              }}
                            />
                          </UploadButton>
                        </div>
                      </Row>
                      {data.files?.length > 0 && <h4>Current Files:</h4>}
                      <br />
                      <Image.PreviewGroup>
                        {data?.files?.length > 0 &&
                          data?.files
                            .filter(
                              (item) =>
                                !data.newFiles.some(
                                  (file) => file.name === item
                                )
                            )
                            .map((item) => {
                              const imageUrl = `${config?.tms_uploads_cdn}/${
                                config.env
                              }/goods_return/${data.routeStatusID}/${item}`;
                              return (
                                <div
                                  style={{
                                    position: "relative",
                                    marginRight: "10px",
                                    display: "inline-block",
                                  }}
                                >
                                  <Image
                                    width={200}
                                    height={150}
                                    style={{
                                      padding: "5px",
                                      border: "1px solid lightgrey",
                                    }}
                                    src={imageUrl}
                                  />
                                  <Tooltip title="Delete">
                                    <CloseOutlined
                                      style={{
                                        fontSize: "30px",
                                        position: "absolute",
                                        right: 5,
                                        top: 5,
                                        color: "#c12828",
                                      }}
                                      onClick={() => handleDeleteFile(index,item)}
                                    />
                                  </Tooltip>
                                </div>
                              );
                            })}
                      </Image.PreviewGroup>
                      {data.newFiles?.length > 0 && <h4>New Files:</h4>}
                      <br />
                      <Image.PreviewGroup>
                        {data.newFiles?.length > 0 &&
                          data.newFiles.map((files) => {
                            return (
                              <div
                                style={{
                                  position: "relative",
                                  marginRight: "10px",
                                  display: "inline-block",
                                }}
                              >
                                <Image
                                  width={200}
                                  height={150}
                                  style={{
                                    padding: "5px",
                                    border: "1px solid lightgrey",
                                  }}
                                  src={URL.createObjectURL(files.file)}
                                />
                                <Tooltip title="Delete">
                                  <CloseOutlined
                                    style={{
                                      fontSize: "30px",
                                      position: "absolute",
                                      right: 5,
                                      top: 5,
                                      color: "#c12828",
                                    }}
                                    onClick={() => handleDeleteNewFile(index, files.name)}
                                  />
                                </Tooltip>
                              </div>
                            );
                          })}
                      </Image.PreviewGroup>
                      {/* <div style={{ display: "flex", justifyContent: 'end'}}>
                      <Button  type="primary" onClick={() => saveInfo(index)}>
                        Save Goods Return 
                      </Button>
                      </div> */}
                <FloatButton
                  description={`Save Goods Return`}
                  shape="square"
                  type="primary"
                  onClick={() => saveInfo(index)}
                  style={{
                    right: 30,
                        bottom: 80,
                    // bottom: buttonBottom,
                    minWidth: '165px',
                    backgroundColor: '#e0e0e0',
                    background: '#e0e0e0',
                  }}
                />
              </>
            </div>
          </>
        ))}
      <Message type={type} msg={loadMessage} />
    </div>
  );
};

export default GoodsReturnTab;
